import { FieldProps } from "formik";
import * as React from "react";
import CustomSelect from "../../Forms/CustomSelect";
import { QuotationFormValues } from "../types";
import "./index.scss";
import VehicleModelsQuery from "./VehicleModelsQuery";

const VehicleModelField = (props: FieldProps<QuotationFormValues>) => {
  const { form, field } = props;
  const make = form.values.vehicle.make;

  const Placeholder = () => (
    <CustomSelect
      isDisabled={true}
      placeholder="Select model..."
      name={field.name}
    />
  );

  return (
    <>
      {make ? (
        <VehicleModelsQuery
          make={make}
          isCommercial={!!form.values.vehicle.isCommercial}
        >
          {({ loading, error, data }) => {
            const options =
              loading || !data
                ? []
                : data.vehicles.vehicleModels.map(x => ({
                    value: x.id,
                    label: x.name
                  }));

            if (loading || !data) {
              return <Placeholder />;
            }

            return (
              <CustomSelect
                placeholder="Select model..."
                isDisabled={loading || !data}
                name={field.name}
                value={
                  options.find(option => option.value === field.value) || null
                }
                onChange={option => {
                  form.setFieldValue(field.name, (option as any).value);
                  form.setFieldValue("vehicle.derivative", undefined);
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
                options={options}
              />
            );
          }}
        </VehicleModelsQuery>
      ) : (
        <Placeholder />
      )}
    </>
  );
};

export default VehicleModelField;
