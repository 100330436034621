import { connect, FormikProps } from "formik";
import { debounce, DebounceSettings, isEqual } from "lodash";
import { Component } from "react";

interface FormikEffectsProps {
  delay?: number;
  debounceOptions?: DebounceSettings;
  onChange: (
    prevValues: any,
    currentValues: any,
    formikProps: FormikProps<any>
  ) => void;
}

interface FormikEffectsPropsEnhanced extends FormikEffectsProps {
  formik: FormikProps<any>;
}

const SAVE_DELAY = 100;

/** Provides an onChange callback within a formik Form */
class FormikEffects extends Component<FormikEffectsPropsEnhanced> {
  constructor(props: FormikEffectsPropsEnhanced) {
    super(props);

    this.onChange = debounce(
      this.onChange.bind(this),
      props.delay || SAVE_DELAY,
      props.debounceOptions || {
        leading: true,
        trailing: true
      }
    );
  }

  public componentDidUpdate(prevProps: FormikEffectsPropsEnhanced) {
    this.onChange(prevProps);
  }

  public render() {
    return null;
  }

  private onChange(prevProps: FormikEffectsPropsEnhanced) {
    const { formik } = this.props;
    const hasChanged =
      !isEqual(prevProps.formik.values, formik.values) ||
      !isEqual(prevProps.formik.errors, formik.errors);

    if (hasChanged) {
      this.props.onChange(
        prevProps.formik.values,
        formik.values,
        this.props.formik
      );
    }
  }
}

export default connect<FormikEffectsProps>(FormikEffects);
