import ApolloClient from "apollo-client";
import { FormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import FormikEffects from "../../Forms/FormikEffects";
import {
  CALCULATE_ALPHERA_QUOTATION,
  CALCULATE_MANN_ISLAND_QUOTATION,
  CalculateMannIslandQuotationData,
  CalculateAlpheraQuotationData,
} from "../../Quotations/CalculateQuotationService";
import quotationRequestValidator from "../../Quotations/quotationRequestValidator";
import { QuotationRequest } from "../../Quotations/types";
import { ProductTypeEnum } from "../../types";
import { Proposal } from "../types";

class ProposalFormQuotationCalculator extends React.Component<
  WithApolloClient<{}>
> {
  private requestId = 0;

  public constructor(props: any) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.calculateQuotationValues = this.calculateQuotationValues.bind(this);
  }

  public render() {
    return (
      <FormikEffects
        onChange={this.handleFormChange}
        delay={300}
        debounceOptions={{ leading: false, trailing: true }}
      />
    );
  }

  /** Detect changes to the proposal which will require new finance values to be calculated on the server */
  private handleFormChange(
    oldValues: Proposal,
    currentValues: Proposal,
    formikProps: FormikProps<Proposal>
  ) {
    if (!formikProps.dirty) {
      return;
    }

    const keys = [
      "dealerId",
      "targetBy",
      "targetByValue",
      "vehicle.cAP",
      "vehicle.mileage",
      "vehicle.maxAnnualMileage",
      "vehicle.isNew",
      "vehicle.dateOfRegistration",
      "finance.period",
      "finance.productType",
      "finance.cashPrice",
      "finance.cashDeposit",
      "finance.partExchange",
      "finance.partExchangeSettlement",
      "finance.extras",
      "finance.rFL",
    ];

    if (
      !currentValues.quotationId &&
      keys.some((k) => get(oldValues, k) !== get(currentValues, k))
    ) {
      const isMannIslandDealer = currentValues?.dealer?.isMannIslandDealer;

      this.calculateQuotationValues(
        this.getQuotationRequest(currentValues),
        formikProps,
        isMannIslandDealer
      );
    }
  }

  /** Get the variables required for getting a quotation from the server */
  private getQuotationRequest(values: Proposal): QuotationRequest {
    const { dealerId, targetBy, targetByValue } = values;

    const {
      period,
      productType,
      cashPrice,
      cashDeposit,
      partExchange,
      partExchangeSettlement,
      extras,
      rFL,
    } = values.finance;

    const { cAP, mileage, maxAnnualMileage, isNew, dateOfRegistration } =
      values.vehicle;

    const returnValues = {
      dealerId,
      minTerm: period,
      maxTerm: period,
      productTypes: [productType] as ProductTypeEnum[],
      cAP,
      mileage,
      maxAnnualMileage,
      isNew,
      dateOfRegistration,
      targetBy,
      targetByValue,
      cashPrice,
      cashDeposit,
      partExchange,
      partExchangeSettlement,
      extras,
      rFL,
    };

    if (!values.dealer?.isMultiQuote && values.dealer?.isMannIslandDealer) {
      const {
        capId,
        imported,
        regNo,
        vehicleType,
        isCommercial,
        vatQualifying,
      } = values.vehicle;
      return {
        capId: capId ? +capId : undefined,
        import: imported,
        regNo,
        vehicleType,
        usage: isCommercial ? "commercial" : "Social",
        vatQualify: vatQualifying,
        ...returnValues,
      };
    }

    return returnValues;
  }

  /** Get finance values from the server in response to changes in the form */
  private calculateQuotationValues(
    req: QuotationRequest,
    formikProps: FormikProps<Proposal>,
    isMannIslandDealer: Boolean = false
  ) {
    const { client } = this.props;
    const { touched, setFieldValue, setFieldTouched } = formikProps;

    const readOnlyFields = [
      "monthlyPayment",
      "rate",
      "aprRate",
      "balloonPayment",
      "acceptanceFee",
      "optionFee",
    ];

    const updateField = (field: string, value?: number | null) => {
      setFieldValue(field as any, value, false);
      setFieldTouched(field as any, true, false);
    };

    const clearFields = () =>
      readOnlyFields.forEach((x) =>
        setFieldValue(`finance.${x}` as any, null, false)
      );

    const isValid = quotationRequestValidator.isValidSync(req);

    if (isValid) {
      this.requestId += 1;
      const currentRequestId = this.requestId;

      calculateQuotation(client, isMannIslandDealer, req)
        .then(({ data: { calculateQuotationList } }) => {
          const result =
            calculateQuotationList &&
            calculateQuotationList.results.find((x) => x.term === req.maxTerm);
          const unableToQuote =
            calculateQuotationList &&
            calculateQuotationList.unableToQuote.find(
              (x) => x.term === req.maxTerm
            );

          // Make sure that only the results of the last issued request
          // are applied to the form.
          if (result && currentRequestId === this.requestId) {
            updateField("finance.monthlyPayment", result.monthlyPayment);
            updateField("finance.rate", result.flatRate);
            updateField("finance.aprRate", result.aprRate);
            updateField("finance.balloonPayment", result.guaranteedFutureValue);
            updateField("finance.acceptanceFee", result.arrangementFee);
            updateField("finance.optionFee", result.completionFee);
          } else {
            clearFields();
          }

          setFieldValue("FORMSTATE_noQuotationResults", !result, false);

          setFieldValue(
            "FORMSTATE_noQuotationResultsReasons",
            unableToQuote ? unableToQuote.messages : null,
            false
          );
        })
        .then(() => formikProps.validateForm());
    } else {
      setFieldValue("FORMSTATE_noQuotationResults", false, false);
      setFieldValue("FORMSTATE_noQuotationResultsReasons", null, false);
      if (touched && touched.finance) {
        clearFields();
      }
    }
  }
}

function calculateQuotation(
  client: ApolloClient<any>,
  isMannIslandDealer: Boolean,
  req: QuotationRequest
) {
  if (isMannIslandDealer) {
    return client
      .query<CalculateMannIslandQuotationData>({
        query: CALCULATE_MANN_ISLAND_QUOTATION,
        variables: { input: req },
      })
      .then(({ data }) => {
        return {
          data: {
            calculateQuotationList: data.calculateMannIslandQuotationList,
          },
        };
      });
  }
  return client
    .query<CalculateAlpheraQuotationData>({
      query: CALCULATE_ALPHERA_QUOTATION,
      variables: { input: req },
    })
    .then(({ data }) => {
      return {
        data: {
          calculateQuotationList: data.calculateAlpheraQuotationList,
        },
      };
    });
}

export default withApollo<{}>(ProposalFormQuotationCalculator);
