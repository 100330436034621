import {
  faArrowDown,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldProps, FormikErrors, FormikProps } from "formik";
import * as React from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import {
  formatCurrency,
  formatEnumValue,
  getFullName,
  getMonthsAndYearsText,
  getSingleLineAddress,
  getSingleLineVehicle,
  hasAddress,
  hasBusinessDetails,
  hasDirectorDetails,
  hasEmploymentDetails,
  roundNumber,
} from "../../../utils";
import { useDirectDealDealer } from "../../Dealers/DealerQuery";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import { WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import AddressRow from "../../shared/AddressRow";
import SummaryRow, {
  YesNoSummaryRow,
} from "../../shared/SummarySection/SummaryRow";
import SummaryDisplaySection from "../../shared/SummarySection/SummarySection";
import {
  BankDetails,
  DrivingLicenseLookup,
  Individual,
  Proposal,
  ProposalFinance,
} from "../types";

export const summarySectionWatchFields: WatchFields<Proposal> = {
  proposalCustomerQuestions: {
    customerUnderstandsAgreement: true,
    customerHappyAgreementIsGoodValue: true,
    customerAdditionalInfo: true,
    customerSaysDealerAnsweredQuestions: true,
    tNC: true,
  },
};

//#region Form fields check

const hasCustomerDetails = (customer: Individual) => {
  return (
    customer &&
    (customer.title ||
      customer.forename ||
      customer.surname ||
      customer.dOB ||
      customer.maritalStatus ||
      customer.drivingLicense ||
      customer.mobile ||
      customer.email)
  );
};

const hasBankDetails = (bank: BankDetails) => {
  return (
    bank &&
    (bank.accountName ||
      bank.accountNumber ||
      bank.branch ||
      bank.bank ||
      bank.sortCode)
  );
};

const hasFinanceDetails = (fin: ProposalFinance) => {
  return (
    fin &&
    (fin.rate ||
      fin.period ||
      fin.monthlyPayment ||
      fin.balloonPayment ||
      fin.partExchange ||
      fin.partExchangeSettlement ||
      fin.cashDeposit ||
      fin.cashPrice ||
      fin.productType)
  );
};

const hasErrorsInOtherSections = (errors: FormikErrors<Proposal>) => {
  const errorKeys = Object.keys(errors);
  if (!errorKeys.length) {
    return false;
  }

  const sectionFields = [
    "tNC",
    "customerUnderstandsAgreement",
    "customerHappyAgreementIsGoodValue",
    "customerAdditionalInfo",
    "customerSaysDealerAnsweredQuestions",
  ];
  return !errorKeys.every((k) => sectionFields.includes(k));
};

//#endregion

const SummarySection = ({
  values,
  isValid,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}: FormikProps<Proposal>) => {
  // Direct deal query used because only dealer name is required for this page
  // If this changes, need to take direct customer permissions into account (i.e. account managers - READ)
  const { dealer, loading: dealerLoading } = useDirectDealDealer(
    values.dealerId
  );

  const { lenderName } = values.finance;

  return (
    <div className="mb-4">
      {!hasErrorsInOtherSections(errors) ? (
        <Alert
          fade={false}
          color="info"
          key="valid-message"
          className="alert-clickable"
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        >
          <FontAwesomeIcon icon={faArrowDown} className="mr-3" />
          <b>Scroll down</b> to review the details and submit the proposal
        </Alert>
      ) : (
        <Alert fade={false} color="warning" key="invalid-message">
          More details are required before the proposal can be submitted
        </Alert>
      )}

      {!dealerLoading && dealer?.name ? (
        <SummaryDisplaySection key="dealer" title="Dealer">
          <SummaryRow title="Dealer name" value={dealer.name} />
        </SummaryDisplaySection>
      ) : null}

      {hasCustomerDetails(values.individualCustomer) ? (
        <SummaryDisplaySection key="customer-details" title="Customer details">
          <SummaryRow
            title="Full name"
            value={
              values.individualCustomer.title &&
              values.individualCustomer.forename &&
              values.individualCustomer.surname
                ? getFullName(values.individualCustomer)
                : null
            }
            highlight={true}
          />
          <SummaryRow
            title="Date of birth"
            value={values.individualCustomer.dOB}
          />
          <SummaryRow
            title="Marital Status"
            value={formatEnumValue(values.individualCustomer.maritalStatus)}
          />
          <SummaryRow
            title="Driving License"
            value={
              values.individualCustomer.drivingLicense
                ? DrivingLicenseLookup[values.individualCustomer.drivingLicense]
                    .description
                : null
            }
          />
          <SummaryRow title="Mobile" value={values.individualCustomer.mobile} />
          <SummaryRow
            title="Email address"
            value={values.individualCustomer.email}
          />
        </SummaryDisplaySection>
      ) : null}

      {hasBusinessDetails(values.business) ? (
        <SummaryDisplaySection key="business-details" title="Business details">
          <SummaryRow title="Business name" value={values.business.name} />
          <SummaryRow
            title="Industry"
            value={values.business.natureOfBusiness}
          />
          <SummaryRow
            title="Contact name"
            value={values.business.contactName}
          />
          <SummaryRow
            title="Contact position"
            value={values.business.contactPosition}
          />
          <SummaryRow title="Email" value={values.business.email} />
          <SummaryRow title="Mobile" value={values.business.mobile} />
          <SummaryRow
            title="Business type"
            value={formatEnumValue(values.business.businessType)}
          />
          <SummaryRow
            title="Business type description"
            value={values.business.otherBusinessType}
          />
          <SummaryRow
            title="Registration number"
            value={values.business.registrationNumber}
          />
          <SummaryRow
            title="Date established"
            value={values.business.established}
          />
          <SummaryRow
            title="Address"
            value={getSingleLineAddress(values.business.address)}
          />
          <SummaryRow
            title="Time at address"
            value={
              (values.business.yearsAtAddress ||
                values.business.yearsAtAddress === 0) &&
              (values.business.monthsAtAddress ||
                values.business.monthsAtAddress === 0)
                ? getMonthsAndYearsText(
                    values.business.yearsAtAddress * 12 +
                      values.business.monthsAtAddress
                  )
                : null
            }
          />
        </SummaryDisplaySection>
      ) : null}

      {values.business && hasDirectorDetails(values.business.directors[0]) ? (
        <SummaryDisplaySection key="director-details" title="Director details">
          {values.business.directors.map((director, i) => (
            <SummaryDisplaySection subTitle={`Director ${i + 1}`} key={i}>
              <SummaryRow title="Full name" value={getFullName(director)} />
              <SummaryRow title="Date of birth" value={director.dOB} />
              <SummaryRow title="Email" value={director.email} />
              <SummaryRow title="Mobile" value={director.mobile} />
              <SummaryRow
                title="Marital Status"
                value={formatEnumValue(director.maritalStatus)}
              />
              <SummaryRow
                title="Driving License"
                value={
                  director.drivingLicense
                    ? DrivingLicenseLookup[director.drivingLicense].description
                    : null
                }
              />
              <SummaryRow
                title="Guarantor"
                value={director.guarantor ? "Yes" : "No"}
              />
              <SummaryRow
                title="Address"
                value={getSingleLineAddress(director.address)}
              />
              {i === 0 && (
                <SummaryRow
                  title="Monthly rent/mortgage"
                  value={formatCurrency(
                    director.address.totalMonthlyRentOrMortgage
                  )}
                />
              )}
              <SummaryRow
                title="Home ownership"
                value={formatEnumValue(director.owner)}
              />
              <SummaryRow
                title="Ownership details"
                value={director.otherStatus}
              />
              <SummaryRow
                title="Time at address"
                value={
                  (director.homeYears || director.homeYears === 0) &&
                  (director.homeMonths || director.homeMonths === 0)
                    ? getMonthsAndYearsText(
                        director.homeYears * 12 + director.homeMonths
                      )
                    : null
                }
              />
              <SummaryRow
                title="Gross annual income"
                value={formatCurrency(director.earnings)}
              />
            </SummaryDisplaySection>
          ))}
        </SummaryDisplaySection>
      ) : null}

      {values.individualCustomer &&
      hasAddress(values.individualCustomer.homeAddresses[0]) ? (
        <SummaryDisplaySection key="home-address" title="Home address">
          {values.individualCustomer.homeAddresses.map((address, index) => (
            <div key={index}>
              <AddressRow address={address} index={index} />
              <SummaryRow
                title="Home ownership:"
                value={`${formatEnumValue(address.ownership)} ${
                  address.otherOwnership || ""
                }`}
              />
            </div>
          ))}
        </SummaryDisplaySection>
      ) : null}

      {values.individualCustomer &&
      hasEmploymentDetails(values.individualCustomer.employmentDetails[0]) ? (
        <SummaryDisplaySection key="employer" title="Employer">
          {values.individualCustomer.employmentDetails.map((value, index) => (
            <div key={index}>
              <p className="summary-section-title">
                {index > 0 ? `Previous Employer ${index}` : "Current Employer"}
              </p>
              <SummaryRow
                title="Employment terms"
                value={formatEnumValue(value.employmentTerms)}
              />
              <SummaryRow
                title="Employment status"
                value={formatEnumValue(value.employmentStatus)}
              />
              <SummaryRow
                title="Employment type"
                value={formatEnumValue(value.employmentType)}
              />
              <SummaryRow title="Industry" value={value.industry} />
              <SummaryRow title="Occupation" value={value.occupation} />
              <SummaryRow title="Employer name" value={value.employerName} />
              <SummaryRow
                title="Address"
                value={
                  value.address ? getSingleLineAddress(value.address) : null
                }
              />
              {value.earnings && (
                <SummaryRow
                  title="Gross annual income"
                  value={
                    value.earnings || value.earnings === 0
                      ? formatCurrency(value.earnings)
                      : null
                  }
                />
              )}

              <SummaryRow
                title="Time with employer"
                value={
                  (value.yearsWithEmployer || value.yearsWithEmployer === 0) &&
                  (value.monthsWithEmployer || value.monthsWithEmployer === 0)
                    ? getMonthsAndYearsText(
                        value.yearsWithEmployer * 12 + value.monthsWithEmployer
                      )
                    : null
                }
              />
              <SummaryRow title="Telephone" value={value.address?.telephone} />
            </div>
          ))}
        </SummaryDisplaySection>
      ) : null}

      {hasBankDetails(values.bankDetails) ? (
        <SummaryDisplaySection key="bank-details" title="Bank details">
          <SummaryRow
            title="Account number"
            value={values.bankDetails.accountNumber}
          />
          <SummaryRow title="Sort Code" value={values.bankDetails.sortCode} />
          <SummaryRow title="Bank" value={values.bankDetails.bank} />
          <SummaryRow title="Branch" value={values.bankDetails.branch} />
          <SummaryRow
            title="Account name"
            value={values.bankDetails.accountName}
          />
          <SummaryRow
            title="Time with bank"
            value={
              (values.bankDetails.yearsWithBank ||
                values.bankDetails.yearsWithBank === 0) &&
              (values.bankDetails.monthsWithBank ||
                values.bankDetails.monthsWithBank === 0)
                ? getMonthsAndYearsText(
                    values.bankDetails.yearsWithBank * 12 +
                      values.bankDetails.monthsWithBank
                  )
                : null
            }
          />
        </SummaryDisplaySection>
      ) : null}

      {values.vehicle &&
      (values.vehicle.make ||
        values.vehicle.model ||
        values.vehicle.derivative) ? (
        <SummaryDisplaySection key="vehicle" title="Vehicle">
          <SummaryRow
            title="Vehicle"
            value={getSingleLineVehicle(values.vehicle)}
            highlight={true}
          />
          <YesNoSummaryRow
            title="Used or new"
            value={values.vehicle.isNew}
            trueText="New"
            falseText="Used"
          />
          <SummaryRow title="Mileage" value={values.vehicle.mileage} />
          <SummaryRow
            title="Max annual mileage"
            value={values.vehicle.maxAnnualMileage}
          />
        </SummaryDisplaySection>
      ) : null}

      {hasFinanceDetails(values.finance) ? (
        <SummaryDisplaySection key="finance" title="Finance">
          <SummaryRow
            title="Cash price"
            value={
              values.finance.cashPrice || values.finance.cashPrice === 0
                ? formatCurrency(values.finance.cashPrice)
                : null
            }
            highlight={true}
          />
          <SummaryRow
            title="Deposit"
            value={
              values.finance.cashDeposit || values.finance.cashDeposit === 0
                ? formatCurrency(values.finance.cashDeposit)
                : null
            }
          />
          <SummaryRow
            title="Monthly payment"
            value={
              values.finance.monthlyPayment ||
              values.finance.monthlyPayment === 0
                ? formatCurrency(values.finance.monthlyPayment)
                : null
            }
            highlight={true}
          />
          <SummaryRow
            title="Part exchange value"
            value={
              values.finance.partExchange || values.finance.partExchange === 0
                ? formatCurrency(values.finance.partExchange)
                : null
            }
          />
          <SummaryRow
            title="Part ex. settlement"
            value={
              values.finance.partExchangeSettlement ||
              values.finance.partExchangeSettlement === 0
                ? formatCurrency(values.finance.partExchangeSettlement)
                : null
            }
          />
          <SummaryRow
            title="Period"
            value={
              values.finance.period || values.finance.period === 0
                ? `${values.finance.period} months`
                : null
            }
          />
          <SummaryRow title="Loan type" value={values.finance.productType} />
          <SummaryRow
            title="Balloon payment"
            value={
              values.finance.balloonPayment ||
              values.finance.balloonPayment === 0
                ? formatCurrency(values.finance.balloonPayment)
                : null
            }
          />
          <SummaryRow
            title="APR"
            value={
              values.finance.aprRate || values.finance.aprRate === 0
                ? `${roundNumber(values.finance.aprRate)}%`
                : null
            }
            highlight={true}
          />
          {!dealer?.isMultiQuote && (
            <SummaryRow
              title="Flat rate"
              value={
                values.finance.rate || values.finance.rate === 0
                  ? `${roundNumber(values.finance.rate)}%`
                  : null
              }
            />
          )}
          {dealer?.isMultiQuote && (
            <SummaryRow title="Lender" value={lenderName} highlight={true} />
          )}
        </SummaryDisplaySection>
      ) : null}

      {values.notes || values.salesPerson ? (
        <SummaryDisplaySection key="dealer-notes" title="Dealer notes">
          <SummaryRow title="Sales person" value={values.salesPerson} />
          <SummaryRow title="Notes" value={values.notes} />
        </SummaryDisplaySection>
      ) : null}
      <Container key="tnc" className="tnc-panel">
        <Row>
          <Col>
            {values.proposalCustomerQuestions.customerPresent && (
              <div className="proposal-alert">
                <p className="text-info lead">Customer questions</p>
                <Row className="mb-4">
                  <RadioField
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    name="proposalCustomerQuestions.customerUnderstandsAgreement"
                    title="Do you feel you fully understand the features of the agreement and it's a good fit with you needs?"
                  />
                </Row>

                <Row className="mb-4">
                  <RadioField
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    name="proposalCustomerQuestions.customerHappyAgreementIsGoodValue"
                    title="Are you happy that the agreement represents good value for money, considering your needs and circumstances?"
                  />
                </Row>
                <Row className="mb-4">
                  <RadioField
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    name="proposalCustomerQuestions.customerAdditionalInfo"
                    title="Is there any information you think the finance company offering this agreement should be aware of? For example, anything about your health or that of people close to you, any concerns about your finances, or anything else that mean you might at any stage need a little more support?"
                  />
                </Row>

                <Row className="mb-3">
                  <RadioField
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    name="proposalCustomerQuestions.customerSaysDealerAnsweredQuestions"
                    title={`Did ${
                      dealer ? dealer.name : "the dealer"
                    } offer to answer any questions you have on the agreement features and terms 
                    and made you aware they will receive a commission for introducing your details?`}
                  />
                </Row>
                {[
                  "customerSaysDealerAnsweredQuestions",
                  "customerAdditionalInfo",
                  "customerHappyAgreementIsGoodValue",
                  "customerUnderstandsAgreement",
                ].some((x) => (values as any)[x] === false) && (
                  <p className="text-info mb-0">
                    Please ensure you explain the responsibilities of a secured
                    finance agreement to the customer
                  </p>
                )}
              </div>
            )}

            <Field name={"proposalCustomerQuestions.tNC"}>
              {(fieldProps: FieldProps<any>) => (
                <Row>
                  <Col lg={8}>
                    <FormGroupWrapper className="mb-0" {...fieldProps}>
                      <div className="custom-control custom-checkbox mb-0 mt-3">
                        <input
                          id={fieldProps.field.name}
                          type="checkbox"
                          name={fieldProps.field.name}
                          className="custom-control-input pointer"
                          checked={!!fieldProps.field.value}
                          onChange={(e) => {
                            fieldProps.form.setFieldValue(
                              fieldProps.field.name,
                              !fieldProps.field.value,
                              true
                            );
                            fieldProps.form.setFieldTouched(
                              fieldProps.field.name,
                              true,
                              true
                            );
                          }}
                        />
                        <label
                          htmlFor={fieldProps.field.name}
                          className="custom-control-label font-weight-bold pointer"
                        >
                          I have read and accept the{" "}
                          <a
                            href="https://www.eurodrivefinance.co.uk/initial-disclosure"
                            target="blank"
                          >
                            Terms and Conditions{" "}
                            <sup>
                              <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                            </sup>
                          </a>
                        </label>
                      </div>
                    </FormGroupWrapper>
                  </Col>
                </Row>
              )}
            </Field>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SummarySection;
