import { ApolloLink, HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";
import DebounceLink from "apollo-link-debounce";
import config from "../config";
import configureErrorLink from "./configureErrorLink";

const DEFAULT_DEBOUNCE_TIMEOUT = 100;

const authLink = (getToken: () => Promise<any>) =>
  setContext(async (_, { headers }) => {
    const token = await getToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

const configureApolloLink = async ({
  getToken
}: {
  getToken: () => Promise<any>;
}): Promise<ApolloLink> => {
  const httpLink = new HttpLink({
    credentials: "same-origin",
    uri: config.GRAPHQL_ENDPOINT
  });

  const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);
  const errorLink = configureErrorLink();

  return ApolloLink.from([
    errorLink,
    authLink(getToken),
    debounceLink,
    httpLink
  ]);
};

export default configureApolloLink;
