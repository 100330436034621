import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as React from "react";

export default React.memo(
  ({
    className,
    style
  }: {
    className?: string;
    style?: React.CSSProperties;
  }) => (
    <h6 className={classnames("loader", className || "")} style={style}>
      <FontAwesomeIcon icon={faSpinner} spin={true} /> Loading
    </h6>
  )
);
