import { Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import { Col, Row } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import FormInputField from "../../Forms/FormInputField";
import { WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import { Proposal } from "../types";
import { ContextNames } from "../../types";

export const dealerNotesSectionWatchFields: WatchFields<Proposal> = {
  salesPerson: true,
  notes: true,
  distanceSelling: true,
};

const DealerNotesSection = (
  props: FormikProps<Proposal> & { context?: ContextNames }
) => {
  const { context } = props;
  return (
    <>
      <Row>
        <Field
          colSize={5}
          name={"salesPerson"}
          type="text"
          title="Sales person"
          autoFocus={true}
          component={FormInputField}
          readOnly={context === ContextNames.DIRECT_DEAL}
        />
      </Row>
      <Row>
        <Field name={"notes"}>
          {({ field, form }: FieldProps<any>) => (
            <Col lg={8}>
              <FormGroupWrapper field={field} form={form} title="Notes">
                <textarea
                  className="form-control"
                  rows={2}
                  {...field}
                  value={field.value || ""}
                  readOnly={context === ContextNames.DIRECT_DEAL}
                />
              </FormGroupWrapper>
            </Col>
          )}
        </Field>
      </Row>
      <Row className="mb-3">
        <RadioField
          colSize={5}
          name="distanceSelling"
          title="Type of sales transaction"
          options={[
            { label: "Sale on premise", value: false },
            { label: "Distance selling", value: true },
          ]}
          vertical={false}
          readOnly={context === ContextNames.DIRECT_DEAL}
        />
      </Row>
    </>
  );
};

export default DealerNotesSection;
