import * as React from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

interface QuotationErrorModalProps {
  showErrorModal: boolean;
  errorMessage?: string | null;
  toggle: () => void;
}

const QuotationErrorModal: React.SFC<QuotationErrorModalProps> = React.memo(
  ({ showErrorModal, errorMessage, toggle }: QuotationErrorModalProps) => {
    return (
      <Modal
        isOpen={showErrorModal}
        toggle={toggle}
        centered={true}
        modalTransition={{ timeout: 100 }}
        className="quotation-form-modal"
      >
        <>
          <ModalHeader toggle={toggle}>
            We're having an issue retrieving this quote.
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={{ size: "auto" }}>
                <p>
                  Please continue with the application and we'll get back to you
                  with a quote as soon as we can
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </>
      </Modal>
    );
  }
);

export default QuotationErrorModal;
