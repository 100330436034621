import { Field, FormikProps } from "formik";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { formatEnumValue } from "../../../utils";
import AddressFormSection from "../../AddressLookup/AddressFormSection";
import FollowUpQuestions from "../../Dealers/DealerForm/FollowUpQuestions";
import FormikEffects from "../../Forms/FormikEffects";
import FormInputField from "../../Forms/FormInputField";
import RadioField from "../../Forms/RadioField";
import SelectField from "../../Forms/SelectField";
import CountryField from "../../Settings/Countries/CountryField";
import { useIndustryList } from "../IndustryListQuery";
import {
  EmploymentDetails,
  EmploymentStatus,
  EmploymentTerms,
  EmploymentType,
  EmploymentTypesRequiringEmployerName,
  Proposal,
} from "../types";
import { addressSchema } from "./individualCustomerValidationSchema";

interface EmploymentDetailsSectionProps {
  index: number;
  remove: () => void;
  employmentDetails: EmploymentDetails;
}

const employmentOccupationOptions = [
  "Administration / Clerical",
  "Director / Board member",
  "Miscellaneous",
  "Production worker",
  "Professional",
  "Skilled manual",
  "Taxi driver",
  "Unskilled manual",
  // "Administrator",
  // "Clerical",
  // "Armed forces",
  // "Director",
  // "Senior manager",
  // "Board member",
  // "Middle manager",
  // "Miscellaneous",
  // "Production worker",
  // "Professional",
  // "Skilled manual",
  // "Taxi driver or chauffeur",
  // "Unskilled manual",
].map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const employmentTermsOptions = Object.keys(EmploymentTerms).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const employmentStatusOptions = Object.keys(EmploymentStatus).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const employmentTypeOptions = Object.keys(EmploymentType)
  .filter((x) => x !== EmploymentType.UNKNOWN)
  .map((x) => ({
    label: formatEnumValue(x),
    value: x,
  }));

const EmploymentDetailsSection = ({
  index,
  remove,
  employmentDetails,
  ...rest
}: EmploymentDetailsSectionProps & FormikProps<any>) => {
  const address = (employmentDetails && employmentDetails.address) || {};

  const isEmployed =
    !employmentDetails?.employmentType ||
    EmploymentTypesRequiringEmployerName.includes(
      employmentDetails.employmentType
    );

  const { industryList } = useIndustryList();

  const industryOptions =
    industryList?.industryList?.map((i) => ({
      label: i,
      value: i,
    })) || [];

  return (
    <>
      <FormikEffects
        onChange={(prevValues: Proposal, currentValues: Proposal) => {
          // Make the employer address null if the customer is not employed.
          const prevEmployers =
            prevValues?.individualCustomer?.employmentDetails || [];
          const currentEmployers =
            currentValues?.individualCustomer?.employmentDetails || [];

          prevEmployers.forEach((prev, i) => {
            const current = currentEmployers[i];
            const prevEmploymentType = prev.employmentType;
            const currentEmploymentType = current?.employmentType;

            const requiresEmployerInfo =
              !!currentEmploymentType &&
              EmploymentTypesRequiringEmployerName.includes(
                currentEmploymentType
              );

            const path = `individualCustomer.employmentDetails.${i}`;

            if (
              currentEmploymentType &&
              currentEmploymentType !== prevEmploymentType &&
              !requiresEmployerInfo
            ) {
              rest.setFieldValue(`${path}.address`, null);
              rest.setFieldValue(`${path}.earnings`, 0);

              if (current.employmentTerms === undefined) {
                rest.setFieldValue(
                  `${path}.employmentTerms`,
                  EmploymentTerms.PERMANENT,
                  false
                );
              }
              if (current.employmentStatus === undefined) {
                rest.setFieldValue(
                  `${path}.employmentStatus`,
                  EmploymentStatus.FULL_TIME,
                  false
                );
              }
            } else if (requiresEmployerInfo && !current.address) {
              rest.setFieldValue(`${path}.address`, addressSchema.default());
              if (!current.earnings) {
                rest.setFieldValue(`${path}.earnings`, null);
              }
            }
          });

          if (
            currentValues.individualCustomer
              .selfEmployedDirectorOrBoardMember !==
            prevValues.individualCustomer.selfEmployedDirectorOrBoardMember
          ) {
            rest.setFieldValue(
              "individualCustomer.employmentDetails.0.occupation",
              ""
            );
            rest.setFieldTouched(
              "individualCustomer.employmentDetails.0.occupation",
              true
            );
          }
        }}
      />
      {index === 0 ? (
        <>
          <Row>
            <RadioField
              title="Is the customer self employed, a director or a board member?"
              name="individualCustomer.selfEmployedDirectorOrBoardMember"
              colSize={7}
              autoFocus={true}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Row>
          <FollowUpQuestions
            show={
              rest.values.individualCustomer.selfEmployedDirectorOrBoardMember
            }
          >
            <Row>
              <CountryField
                colSize={7}
                name="individualCustomer.countryOfActivityId"
                label="Country of activity"
              />
            </Row>
          </FollowUpQuestions>
        </>
      ) : null}

      <Row>
        <SelectField
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.employmentType`}
          title="Employment type"
          options={employmentTypeOptions}
        />
      </Row>
      <Row>
        <RadioField
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.employmentTerms`}
          title="Employment terms"
          options={employmentTermsOptions}
        />
      </Row>
      <Row>
        <RadioField
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.employmentStatus`}
          title="Employment status"
          options={employmentStatusOptions}
        />
      </Row>
      <Row>
        <SelectField
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.industry`}
          title="Industry"
          options={industryOptions}
        />
      </Row>
      {!!rest.values.individualCustomer.selfEmployedDirectorOrBoardMember &&
      index === 0 ? (
        <Row>
          <SelectField
            colSize={7}
            name={`individualCustomer.employmentDetails.${index}.occupation`}
            title="Occupation"
            options={employmentOccupationOptions}
          />
        </Row>
      ) : (
        <Row>
          <Field
            colSize={5}
            name={`individualCustomer.employmentDetails.${index}.occupation`}
            type="text"
            title="Occupation"
            readOnly={!isEmployed && !employmentDetails.occupation}
            component={FormInputField}
          />
        </Row>
      )}

      <Row>
        <Field
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.employerName`}
          type="text"
          title="Employer name"
          readOnly={!isEmployed && !employmentDetails.employerName}
          component={FormInputField}
        />
      </Row>
      <AddressFormSection
        {...rest}
        address={address}
        title="Address"
        readOnly={!isEmployed && !employmentDetails.address}
        pathToAddress={`individualCustomer.employmentDetails.${index}.address`}
      />
      <Row>
        <Field
          colSize={5}
          name={`individualCustomer.employmentDetails.${index}.address.telephone`}
          type="text"
          title="Telephone"
          readOnly={!isEmployed && !employmentDetails?.address}
          component={FormInputField}
        />
      </Row>
      {index === 0 ? (
        <Row>
          <Field
            colSize={5}
            name={`individualCustomer.employmentDetails.${index}.earnings`}
            type="number"
            min={0}
            title="Gross annual income"
            units="£"
            component={FormInputField}
          />
        </Row>
      ) : null}

      <Row>
        <Col lg={5}>
          <Row>
            <Field
              colSize={6}
              name={`individualCustomer.employmentDetails.${index}.yearsWithEmployer`}
              type="number"
              min={0}
              title="Years with employer"
              placeholder="Years"
              component={FormInputField}
            />
            <Field
              colSize={6}
              name={`individualCustomer.employmentDetails.${index}.monthsWithEmployer`}
              type="number"
              min={0}
              max={12}
              title="Months"
              placeholder="months"
              component={FormInputField}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EmploymentDetailsSection;
