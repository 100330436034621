import debounce from "debounce-promise";
import {
  Formik,
  FormikProps,
  FormikTouched,
  InjectedFormikProps,
} from "formik";
import memoizeOne from "memoize-one";
import * as React from "react";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import MultiSectionForm, {
  CreateOrUpdateMode,
  DirectDealProps,
  FormSectionInfo,
  SaveDraftProps,
} from "../../Forms/MultiSectionForm";
import { LoggedInUserProps, withLoggedInUser } from "../../LoggedInUserQuery";
import { ContextNames, RecursivePartial, UserRoles } from "../../types";
import { DraftProposalFormData } from "../DraftProposals/DraftProposalService";
import {
  DirectDeal,
  DraftProposal,
  Proposal,
  ProposalFormSectionName,
  ProposalType,
} from "../types";
import BankDetailsSection, {
  bankDetailsSectionWatchFields,
} from "./BankDetailsSection";
import BusinessDetailsSection, {
  businessDetailsWatchFields,
} from "./BusinessDetailsSection";
import CapitalizeCustomerName from "./CapitalizeCustomerName";
import DealerNotesSection, {
  dealerNotesSectionWatchFields,
} from "./DealerNotesSection";
import DealerSection, { dealerWatchFields } from "./DealerSection";
import DirectorsSection, {
  directorsSectionWatchFields,
} from "./DirectorsSection";
import EmploymentHistorySection, {
  employmentHistorySectionWatchFields,
} from "./EmploymentHistorySection";
import FinanceSection, { financeSectionWatchFields } from "./FinanceSection";
import HomeAddressHistorySection, {
  homeAddressHistoryWatchFields,
} from "./HomeAddressHistorySection";
import "./index.scss";
import IndividualCustomerSection, {
  individualCustomerWatchFields,
} from "./IndividualCustomerSection";
import {
  EMPLOYERS_MAX_REQUIRED,
  EMPLOYERS_YEARS_REQUIRED,
  INDIVIDUAL_ADDRESS_MAX_REQUIRED,
  INDIVIDUAL_ADDRESS_YEARS_REQUIRED,
} from "./individualCustomerValidationSchema";
import PrivacySection, { privacySectionWatchFields } from "./PrivacySection";
import ProposalFormHeader from "./ProposalFormHeader";
import ProposalFormQuotationCalculator from "./ProposalFormQuotationCalculator";
import proposalValidationSchema from "./proposalValidationSchema";
import ProposalVehicleSection, {
  proposalVehicleSectionWatchFields,
} from "./ProposalVehicleSection";
import SummarySection, { summarySectionWatchFields } from "./SummarySection";

export interface ProposalFormInitialValues {
  initialValues: Proposal;
  initialSection?: ProposalFormSectionName;
  initialTouched?: FormikTouched<Proposal>;
  proposalType?: ProposalType;
}

interface ProposalFormProps extends ProposalFormInitialValues {
  showDealerSelect?: boolean;
  isIndividual?: boolean;
  proposal?: RecursivePartial<Proposal>;
  draftProposalLastSaved?: string;
  context?: ContextNames;
  saveDraft: (formData: DraftProposalFormData) => Promise<DraftProposal | void>;
  onSubmitProposal: (proposal: Proposal) => Promise<any>;
  sendDirectDeal: (directDeal: DirectDeal) => Promise<any>;
}

type ProposalFormPropsEnhanced = InjectedFormikProps<
  ProposalFormProps & LoggedInUserProps & RouteComponentProps,
  Proposal
>;

const proposalFormSections: FormSectionInfo<Proposal>[] = [
  {
    id: ProposalFormSectionName.PRIVACY,
    title: "Privacy agreement",
    component: PrivacySection,
    watchFields: privacySectionWatchFields,
  },
  {
    id: ProposalFormSectionName.DEALER,
    title: "Dealer",
    component: DealerSection,
    watchFields: dealerWatchFields,
  },
  {
    id: ProposalFormSectionName.CUSTOMER_DETAILS,
    title: "Customer details",
    component: IndividualCustomerSection,
    watchFields: individualCustomerWatchFields,
  },
  {
    id: ProposalFormSectionName.BUSINESS_DETAILS,
    title: "Business details",
    component: BusinessDetailsSection,
    watchFields: businessDetailsWatchFields,
  },
  {
    id: ProposalFormSectionName.DIRECTORS,
    title: "Directors details",
    component: DirectorsSection,
    watchFields: directorsSectionWatchFields,
  },
  {
    id: ProposalFormSectionName.HOME_ADDRESSES,
    title: "Home address",
    subtitle: `Home addresses from the last ${INDIVIDUAL_ADDRESS_YEARS_REQUIRED} years required, up to ${INDIVIDUAL_ADDRESS_MAX_REQUIRED} addresses`,
    component: HomeAddressHistorySection,
    watchFields: homeAddressHistoryWatchFields,
  },
  {
    id: ProposalFormSectionName.BANK_DETAILS,
    title: "Bank details",
    component: BankDetailsSection,
    watchFields: bankDetailsSectionWatchFields,
  },
  {
    id: ProposalFormSectionName.EMPLOYMENT_HISTORY,
    title: "Employer",
    subtitle: `Employer details from the last ${EMPLOYERS_YEARS_REQUIRED} years required, up to ${EMPLOYERS_MAX_REQUIRED} employers`,
    component: EmploymentHistorySection,
    watchFields: employmentHistorySectionWatchFields,
  },
  {
    id: ProposalFormSectionName.VEHICLE,
    title: "Vehicle",
    component: ProposalVehicleSection,
    watchFields: proposalVehicleSectionWatchFields,
  },
  {
    id: ProposalFormSectionName.FINANCE,
    title: "Finance",
    component: FinanceSection,
    watchFields: financeSectionWatchFields,
  },
  {
    id: ProposalFormSectionName.DEALERNOTES,
    title: "Dealer notes",
    component: DealerNotesSection,
    watchFields: dealerNotesSectionWatchFields,
  },
  {
    id: ProposalFormSectionName.SUMMARY,
    title: "Summary",
    component: SummarySection,
    watchFields: summarySectionWatchFields,
  },
];

class ProposalForm extends React.Component<
  ProposalFormPropsEnhanced,
  { section?: string; savingDraft: boolean }
> {
  /**
   * Field to store the formik setTouched function,
   * so it can be used to set the initial touched values in componentDidMount
   */
  private setTouched: any;

  constructor(props: ProposalFormPropsEnhanced) {
    super(props);
    this.getSections = memoizeOne(this.getSections.bind(this));
    this.handleSaveDraft = debounce(this.handleSaveDraft.bind(this), 3000, {
      leading: true,
    });
    this.handleSaveDirectDeal = debounce(
      this.handleSaveDirectDeal.bind(this),
      3000,
      {
        leading: true,
      }
    );
    this.getDraftProposalValidationMessage =
      this.getDraftProposalValidationMessage.bind(this);
    this.handleSectionChanged = this.handleSectionChanged.bind(this);
    this.getDirectDealValidationMessage =
      this.getDirectDealValidationMessage.bind(this);
    this.state = { section: this.props.initialSection, savingDraft: false };
  }

  public render() {
    const {
      loggedInUser,
      onSubmitProposal,
      showDealerSelect,
      isIndividual,
      draftProposalLastSaved,
      initialValues,
    } = this.props;

    const { savingDraft } = this.state;

    if (!loggedInUser) {
      return null;
    }

    const isDealer = loggedInUser.roles.includes(UserRoles.dealer);
    const activeSection = this.state.section;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values: Proposal, { setSubmitting }) =>
          onSubmitProposal(values).then(() => setSubmitting(false))
        }
        validateOnChange={false}
        isInitialValid={({ proposal }: any) => !!(proposal && proposal.id)}
        validationSchema={proposalValidationSchema}
      >
        {(formikProps) => {
          this.setTouched = formikProps.setTouched;

          const saveDraftProps: SaveDraftProps = {
            saveDraft: () => this.handleSaveDraft(formikProps),
            draftLastSaved: draftProposalLastSaved,
            savingDraft,
            draftValidationMessage: this.getDraftProposalValidationMessage(
              formikProps.values
            ),
          };

          const directDealProps: DirectDealProps = {
            saveDirectDeal: (directDeal: DirectDeal) =>
              this.handleSaveDirectDeal(formikProps, directDeal),
            directDealValidationMessage: this.getDirectDealValidationMessage(
              formikProps.values
            ),
          };

          return (
            <>
              <ProposalFormHeader
                loggedInUser={loggedInUser}
                formikProps={formikProps}
                context={this.props.context}
                {...saveDraftProps}
                {...directDealProps}
              />
              <CapitalizeCustomerName />
              <ProposalFormQuotationCalculator />
              <Prompt
                when={
                  !formikProps.isSubmitting && formikProps.dirty && !savingDraft
                }
                message="There are unsaved changes. Are you sure you want to navigate away from this proposal?"
              />
              <MultiSectionForm
                {...formikProps}
                createOrUpdate={CreateOrUpdateMode.CREATE}
                className="proposal-form"
                submitButtonText="Submit proposal"
                sections={this.getSections(
                  showDealerSelect !== false && !isDealer,
                  !!isIndividual
                )}
                section={activeSection}
                onSectionChanged={this.handleSectionChanged}
                {...saveDraftProps}
                {...directDealProps}
                context={this.props.context}
              />
            </>
          );
        }}
      </Formik>
    );
  }

  public componentDidMount() {
    if (this.props.initialTouched && this.setTouched) {
      this.setTouched(this.props.initialTouched);
    }
  }

  public componentWillUnmount() {
    this.setTouched = undefined;
  }

  private handleSectionChanged(section: string) {
    this.setState({ section });
  }

  /** Indicates whether the draft proposal can be saved */
  private getDraftProposalValidationMessage(
    values: Proposal
  ): string | undefined {
    const missingFields = [];
    if (!values.dealerId) {
      missingFields.push("dealer");
    }
    if (
      values.individualCustomer &&
      !values.individualCustomer.forename &&
      !values.individualCustomer.surname
    ) {
      missingFields.push("customer name");
    }
    if (values.business && !values.business.name) {
      missingFields.push("business name");
    }

    if (missingFields.length) {
      return `Requires ${missingFields.join(" and ")} to save as draft`;
    }

    return undefined;
  }

  /**
   * Save the current form state to the server as a draft.
   * Redirects to the draft proposal url if it is being created for the first time.
   */
  private handleSaveDraft({ values, errors, touched }: FormikProps<Proposal>) {
    const { saveDraft, history, initialValues } = this.props;
    const { section: currentSection } = this.state;

    const formData = {
      values,
      initialValues,
      errors,
      touched,
      currentSection,
    };

    this.setState({ savingDraft: true });

    return saveDraft(formData).then((result) => {
      if (result && this.props.context !== ContextNames.DIRECT_DEAL) {
        history.push(`/proposals/drafts/${result.id}`);
      }
      this.setState({ savingDraft: false });
    });
  }

  private handleSaveDirectDeal(
    { values, errors, touched }: FormikProps<Proposal>,
    directDeal: DirectDeal
  ) {
    const { saveDraft, sendDirectDeal, history, initialValues } = this.props;

    const formData = {
      values,
      initialValues,
      errors,
      touched,
    };

    this.setState({ savingDraft: true });

    return saveDraft(formData).then((result) => {
      if (result) {
        history.push(`/proposals/drafts/${result.id}`);
        directDeal.draftProposalId = result.id;

        sendDirectDeal(directDeal);
      }

      this.setState({ savingDraft: false });
    });
  }

  /** Get a filtered collection of form sections */
  private getSections(showDealerSelect?: boolean, isIndividual?: boolean) {
    const omitSections: ProposalFormSectionName[] = [];
    if (!showDealerSelect) {
      omitSections.push(ProposalFormSectionName.DEALER);
    }
    if (!isIndividual) {
      omitSections.push(
        ProposalFormSectionName.CUSTOMER_DETAILS,
        ProposalFormSectionName.HOME_ADDRESSES,
        ProposalFormSectionName.EMPLOYMENT_HISTORY
      );
    } else {
      omitSections.push(
        ProposalFormSectionName.BUSINESS_DETAILS,
        ProposalFormSectionName.DIRECTORS
      );
    }
    return proposalFormSections.filter(
      (section) => !omitSections.includes(section.id as ProposalFormSectionName)
    );
  }

  private getDirectDealValidationMessage(values: Proposal): string | undefined {
    const missingFields = [];
    if (!values.dealerId) {
      missingFields.push("dealer");
    }
    if (
      values.individualCustomer &&
      (!values.individualCustomer.forename ||
        !values.individualCustomer.surname)
    ) {
      missingFields.push("customer name");
    }
    if (values.individualCustomer && !values.individualCustomer.email) {
      missingFields.push("customer email");
    }
    if (
      !values.vehicle.regNo ||
      !values.vehicle.mileage ||
      !values.vehicle.maxAnnualMileage
    ) {
      missingFields.push("vehicle details");
    }
    if (
      !values.finance.cashPrice ||
      !values.finance.aprRate ||
      !values.finance.period ||
      !values.finance.productType
    ) {
      missingFields.push("finance details");
    }
    if (!values.salesPerson) {
      missingFields.push("sales person");
    }
    if (values.distanceSelling === undefined) {
      missingFields.push("type of sales transaction");
    }

    if (missingFields.length) {
      return `Requires ${missingFields.join(
        " and "
      )} before issuing direct deal`;
    }

    return undefined;
  }
}

export default compose<ProposalFormPropsEnhanced, ProposalFormProps>(
  withRouter,
  withLoggedInUser
)(ProposalForm);
