import * as React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle
} from "reactstrap";
import { FormSectionFooterProps } from "./FormSectionFooter";
import FormUpdateButtons from "./FormUpdateButtons";

export interface FormSectionHeaderProps extends FormSectionFooterProps {
  title: string;
  subtitle?: string;
}

class FormSectionWrapper extends React.Component<
  FormSectionHeaderProps & { id: string; children: React.ReactNode }
> {
  public render() {
    const { children, title, subtitle, ...footerProps } = this.props;

    return (
      <Card className="mb-3 form-section">
        <CardHeader>
          <CardTitle key="title">
            {title}
            <FormUpdateButtons {...footerProps} />
          </CardTitle>
          {subtitle ? (
            <CardSubtitle key="subtitle" className="text-info">
              {subtitle}
            </CardSubtitle>
          ) : null}
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    );
  }
}

export default FormSectionWrapper;
