import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as React from "react";
import FlipMove from "react-flip-move";
import { Badge, Button } from "reactstrap";
import { formatCurrency } from "../../../utils";
import { ProductTypeEnum } from "../../types";
import {
  QuotationListResult,
  QuotationResults,
  QuotationTargetBy,
} from "../types";
import {
  QuotationResultListItemProps,
  QuotationResultListProps,
} from "./QuotationResultList";

const MIN_PCP_TERM = 24;
const MAX_PCP_TERM = 49;

const getLowestPayment = (results?: QuotationResults[]) =>
  results &&
  results.reduce((prev: number, r: QuotationResults) => {
    return prev > 0 ? Math.min(prev, r.monthlyPayment) : r.monthlyPayment;
  }, 0);

const ValueCell = ({
  loading,
  children,
  className,
}: {
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
}) => (
  <td className={classnames({ "text-muted": loading }, className)}>
    {children}
  </td>
);

const UnableToQuoteTableRow = ({
  messages,
  showCommission,
  showProductType,
  productType,
  term,
  onSelectMoreInfo,
}: {
  messages: string[];
  showCommission: boolean;
  productType: ProductTypeEnum;
  showProductType: boolean;
  term: number;
  onSelectMoreInfo: () => void;
}) => (
  <tr className="unable-to-quote">
    <th className="product-type-cell">
      {showProductType ? productType : null}
    </th>
    <th>{term}</th>
    <td colSpan={showCommission ? 5 : 4}>
      <p key="unable-to-quote" className="small mb-0 text-muted">
        Unable to quote for {productType} at {term} months:
      </p>
      {messages.map((x) => (
        <p key={x} className="small mb-0 text-muted">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-info" /> {x}
        </p>
      ))}
    </td>
    <td style={{ textAlign: "left", verticalAlign: "middle" }}>
      <Button
        className="w-100"
        outline={true}
        color="primary"
        type="button"
        onClick={(e: any) => {
          e.preventDefault();
          onSelectMoreInfo();
        }}
        size={showCommission ? "sm" : undefined}
      >
        More info
      </Button>
    </td>
  </tr>
);

const QuotationResultTableRow = ({
  result: {
    id,
    productType,
    term,
    monthlyPayment,
    guaranteedFutureValue,
    aprRate,
    flatRate,
    commissionCode,
  },
  lowestPayment,
  lowestProductPayment,
  showCommission,
  loading,
  onSelectResult,
  targetBy,
  showProductType,
}: QuotationResultListItemProps & { showProductType: boolean }) => (
  <tr onClick={() => onSelectResult()}>
    <th className="product-type-cell">
      {showProductType ? productType : null}
    </th>
    <th>{term}</th>
    <ValueCell loading={loading}>
      {formatCurrency(monthlyPayment)}
      {monthlyPayment === lowestProductPayment &&
      targetBy !== QuotationTargetBy.MONTHLY_PAYMENT ? (
        <Badge
          color={monthlyPayment === lowestPayment ? "success" : "secondary"}
          className="ml-3"
        >
          Lowest {monthlyPayment !== lowestPayment ? productType : ""}
        </Badge>
      ) : null}
    </ValueCell>
    <ValueCell loading={loading}>
      {guaranteedFutureValue ? formatCurrency(guaranteedFutureValue) : null}
    </ValueCell>
    <ValueCell loading={loading}>
      {(Math.round(aprRate * 100) / 100).toFixed(2)}%
    </ValueCell>

    <ValueCell loading={loading}>
      {(Math.round(flatRate * 100) / 100).toFixed(2)}%
    </ValueCell>
    {showCommission ? (
      <ValueCell loading={loading} className="text-muted">
        {commissionCode}
      </ValueCell>
    ) : null}
    <td>
      <Button
        className="w-100"
        outline={true}
        color="primary"
        type="button"
        onClick={(e: any) => {
          e.preventDefault();
          onSelectResult();
        }}
        size={showCommission ? "sm" : undefined}
      >
        Show details
      </Button>
    </td>
  </tr>
);

const getProductResultsByTerm = (
  quotationListResult: QuotationListResult,
  productType: ProductTypeEnum
) => {
  const { results, unableToQuote } = quotationListResult;

  const productResultsByTerm: {
    [val: number]: { result?: QuotationResults; errors?: string[] };
  } = results.reduce((obj, val) => {
    if (val.productType === productType) {
      obj[val.term] = { result: val };
    }
    return obj;
  }, {} as any);

  unableToQuote.reduce((obj, val) => {
    if (val.productType === productType) {
      let item: any = obj[val.term];
      if (!item) {
        obj[val.term] = item = {};
      }
      item.errors = val.messages;
    }
    return obj;
  }, productResultsByTerm);

  return productResultsByTerm;
};

const QuotationResultTable: React.SFC<QuotationResultListProps> = ({
  className,
  quotationListResult,
  onSelectResult,
  onSelectMoreInfo,
  showCommission,
  targetBy,
  loading,
  headerText,
}: QuotationResultListProps) => {
  if (!quotationListResult) {
    return null;
  }

  const { unableToQuote } = quotationListResult;
  let { results } = quotationListResult;
  results = results.filter(
    (r) => r.productType !== "PCP" || r.term <= MAX_PCP_TERM
  );
  const lowestPayment = getLowestPayment(results);
  return (
    <>
      <div className="custom-header mt-3">{headerText}</div>
      <table
        className={classnames(className, "table quotation-table", "mb-4", {
          invisible: !(results.length || unableToQuote.length),
          small: showCommission,
        })}
      >
        <colgroup>
          <col style={{ width: 50 }} />
          <col style={{ width: 50 }} />
          <col style={{ width: 210 }} />
          <col />
          <col />
          <col />
          {showCommission ? <col key="commission-col" /> : null}
          <col style={{ width: 140 }} />
        </colgroup>
        <thead key={"header"}>
          <tr>
            <th scope="col" />
            <th scope="col">Term</th>
            <th scope="col">Monthly</th>
            <th scope="col">GFV / Balloon</th>
            <th scope="col">APR</th>
            <th scope="col">Flat rate</th>
            {showCommission ? (
              <th key="commission-header-col" scope="col">
                Scheme code
              </th>
            ) : null}
            <th />
          </tr>
        </thead>
        <FlipMove typeName={null}>
          {["PCP", "LP", "HP"].map((p) => {
            const productResultsByTerm = getProductResultsByTerm(
              quotationListResult,
              p as ProductTypeEnum
            );

            const lowestProductPayment = getLowestPayment(
              results.filter((x) => x.productType === p)
            );

            const terms = Object.keys(productResultsByTerm)
              .map((t) => parseInt(t, 10))
              .filter(
                (t) => p !== "PCP" || (t >= MIN_PCP_TERM && t <= MAX_PCP_TERM)
              )
              .sort();

            return terms.length ? (
              <tbody key={`${p}_body`}>
                {terms.map((term, i) => {
                  const { result, errors } = productResultsByTerm[term];

                  return result ? (
                    <QuotationResultTableRow
                      key={`${p}_${term}_results`}
                      result={result}
                      lowestProductPayment={lowestProductPayment}
                      lowestPayment={lowestPayment}
                      showCommission={showCommission}
                      showProductType={i === 0}
                      onSelectResult={() => onSelectResult(result)}
                      loading={loading}
                      targetBy={targetBy}
                    />
                  ) : errors ? (
                    <UnableToQuoteTableRow
                      key={`${p}_${term}_errors`}
                      messages={errors}
                      showCommission={!!showCommission}
                      productType={p as ProductTypeEnum}
                      term={term}
                      showProductType={i === 0 && !result}
                      onSelectMoreInfo={() => onSelectMoreInfo(errors[0])}
                    />
                  ) : null;
                })}
              </tbody>
            ) : null;
          })}
        </FlipMove>
      </table>
    </>
  );
};

export default QuotationResultTable;
