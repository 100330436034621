import { Field, FieldProps } from "formik";
import * as React from "react";
import CustomSelect from "./CustomSelect";
import FormFieldWrapper from "./FormFieldWrapper";

const getLabel = (options: { label: any; value: any }[], value: any) => {
  const option = options.find((x) => x.value === value);
  return option && option.label;
};

const SelectField = ({
  name,
  title,
  placeholder,
  colSize,
  options,
  autoFocus,
  disabled,
}: {
  name: string;
  title: string;
  placeholder?: string;
  colSize: number;
  options: { label: any; value: any }[];
  autoFocus?: boolean;
  disabled?: boolean;
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps<any>) => {
      return (
        <FormFieldWrapper
          title={title}
          colSize={colSize}
          field={field}
          form={form}
        >
          <CustomSelect
            name={field.name}
            value={
              field.value
                ? {
                    label: getLabel(options, field.value),
                    value: field.value,
                  }
                : null
            }
            onChange={(option) => {
              form.setFieldValue(
                field.name as any,
                (option as any).value,
                false
              );
              form.setFieldTouched(field.name as any, true, false);
              setTimeout(form.validateForm, 0);
            }}
            onBlur={() => form.setFieldTouched(field.name, true)}
            options={options}
            placeholder={placeholder}
            autoFocus={autoFocus}
            isDisabled={disabled}
          />
        </FormFieldWrapper>
      );
    }}
  </Field>
);

export default SelectField;
