import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

/**
 * Listener which scrolls the page to the top after a route transition
 */
class ScrollToTop extends React.Component<RouteComponentProps> {
  public componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
