import gql from "graphql-tag";
import * as React from "react";
import {
  graphql,
  Mutation,
  MutationFunction,
  MutationResult,
} from "react-apollo";
import { CompositeComponent } from "../../types";
import {
  DirectDealDraftProposalFragment,
  DraftProposalFragment,
} from "../fragments";
import { DraftProposal } from "../types";

export interface DraftProposalData {
  createOrUpdateDraftProposal: DraftProposal;
}

export interface DraftProposalVariables {
  input: DraftProposal;
}

export const CREATE_OR_UPDATE_DRAFT_PROPOSAL = gql`
  mutation CreateOrUpdateDraftProposal($input: DraftProposalInput) {
    createOrUpdateDraftProposal(input: $input) {
      ...DraftProposalFragment
    }
  }
  ${DraftProposalFragment}
`;

export const CREATE_OR_UPDATE_DRAFT_PROPOSAL_DIRECT_DEAL = gql`
  mutation CreateOrUpdateDraftProposalDirectDeal($input: DraftProposalInput) {
    createOrUpdateDraftProposal(input: $input) {
      ...DirectDealDraftProposalFragment
    }
  }
  ${DirectDealDraftProposalFragment}
`;
const CreateOrUpdateDraftProposal = ({
  children,
}: {
  children: (
    mutateFn: MutationFunction<DraftProposalData, DraftProposalVariables>,
    result: MutationResult<DraftProposalData>
  ) => JSX.Element | null;
}) => (
  <Mutation mutation={CREATE_OR_UPDATE_DRAFT_PROPOSAL}>{children}</Mutation>
);

export const CreateOrUpdateDraftProposalDirectDealMutation = ({
  children,
}: {
  children: (
    mutateFn: MutationFunction<DraftProposalData, DraftProposalVariables>,
    result: MutationResult<DraftProposalData>
  ) => JSX.Element | null;
}) => (
  <Mutation mutation={CREATE_OR_UPDATE_DRAFT_PROPOSAL_DIRECT_DEAL}>
    {children}
  </Mutation>
);

export interface CreateOrUpdateDraftProposalProps {
  createOrUpdateDraftProposal: (draftProposal: DraftProposal) => Promise<any>;
}

export const withCreateOrUpdateDraftProposal = <TProps extends {}>(
  WrappedComponent: CompositeComponent<
    TProps & CreateOrUpdateDraftProposalProps
  >
) => {
  return graphql<
    TProps,
    DraftProposalVariables,
    {},
    CreateOrUpdateDraftProposalProps
  >(CREATE_OR_UPDATE_DRAFT_PROPOSAL, {
    props: ({ mutate }) => ({
      createOrUpdateDraftProposal: (draftProposal) => {
        return mutate
          ? mutate({ variables: { input: draftProposal } })
          : Promise.reject("No mutation provided");
      },
    }),
  })(WrappedComponent);
};

export default CreateOrUpdateDraftProposal;
