import gql from "graphql-tag";

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    username
    email
    isSuspended
    forename
    surname
    identityProviderUserId
    isReadyToMigrate
    roles
    lastLoginOnOldSystem
    lastLogin
    loginCount
    auth0Roles
    dealer {
      id
      name
    }
    accountManager {
      id
      name
    }
    regionalSalesManager {
      id
      name
    }
  }
`;

export const UserShallowFragment = gql`
  fragment UserShallowFragment on User {
    id
    username
    email
    isSuspended
    forename
    surname
    identityProviderUserId
    isReadyToMigrate
    roles
    lastLoginOnOldSystem
    dealer {
      id
      name
    }
    accountManager {
      id
      name
    }
    regionalSalesManager {
      id
      name
    }
  }
`;

export const IdentityProviderUserFragment = gql`
  fragment IdentityProviderUserFragment on IdentityProviderUser {
    id
    username
    isSuspended
    forename
    surname
  }
`;
