import React from "react";
import { usePageVisibility } from "react-page-visibility";
import { DismissableAlert } from ".";
import { useActiveAlertMessages } from "./AlertMessageQueries";
import { AlertColor } from "./types";

// Refresh every five minutes
const MESSAGE_REFRESH_INTERVAL = 1000 * 60 * 5;

const AlertMessages = () => {
  const isVisible = usePageVisibility();
  const activeAlertMessages = useActiveAlertMessages(
    isVisible ? MESSAGE_REFRESH_INTERVAL : undefined
  );
  return (
    <>
      {activeAlertMessages &&
        activeAlertMessages.map((message, i) => {
          message.color = AlertColor.info;
          if (!!message.redirectPath) {
            message.onClick = () => window.open(message.redirectPath, "_blank");
          }
          return <DismissableAlert key={i} alert={message} fade={false} />;
        })}
    </>
  );
};

export default AlertMessages;
