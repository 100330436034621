import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { SearchResults, User } from "../../types";
import { QuotationFragment } from "../fragments";
import { Quotation, QuotationSearchArgs } from "../types";

export interface QuotationListData {
  loggedInUser: User & {
    quotations: SearchResults<Quotation>;
  };
}

export const QUOTATION_LIST = gql`
  query QuotationListQuery($input: QuotationSearchArgsInput) {
    loggedInUser {
      id
      username
      roles
      quotations(input: $input) {
        pageInfo {
          hasMorePages
          totalResults
          page
          pageSize
          first
          last
        }
        edges {
          node {
            ...QuotationFragment
          }
        }
      }
    }
  }
  ${QuotationFragment}
`;

const QuotationListQuery = ({
  children,
  ...input
}: QuotationSearchArgs & {
  children: (
    result: QueryResult<QuotationListData, { input: QuotationSearchArgs }>
  ) => JSX.Element | null;
}) => (
  <Query<QuotationListData, { input: QuotationSearchArgs }>
    query={QUOTATION_LIST}
    variables={{ input }}
  >
    {children}
  </Query>
);

export default QuotationListQuery;
