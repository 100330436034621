import { distanceInWords } from "date-fns";

import * as React from "react";

interface DistanceInWordsTextProps {
  date: Date;
  refreshInterval?: number;
}

class DistanceInWordsText extends React.Component<
  DistanceInWordsTextProps,
  { counter: number }
> {
  private interval?: NodeJS.Timeout;

  constructor(props: DistanceInWordsTextProps) {
    super(props);
    this.tick = this.tick.bind(this);

    this.state = {
      counter: 0
    };
  }

  public componentDidMount() {
    this.interval = setInterval(this.tick, this.props.refreshInterval || 10000);
  }

  public componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  public render() {
    return (
      <>
        {distanceInWords(new Date(), this.props.date, {
          addSuffix: true,
          includeSeconds: true
        })}
      </>
    );
  }

  private tick() {
    this.setState(s => ({ counter: s.counter + 1 }));
  }
}

export default DistanceInWordsText;
