import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import {
  Badge,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { getFullName } from "../../../utils";
import DealerSelect from "../../Dealers/DealerSelect";
import AnimatedHeightContainer from "../../Forms/AnimatedHeightContainer";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import FormInputField from "../../Forms/FormInputField";
import LoggedInUserQuery from "../../LoggedInUserQuery";
import TitleSelectField from "../../Proposals/ProposalForm/TitleSelectField";
import VehicleSection from "../../Proposals/ProposalForm/VehicleSection";
import { ContextNames, ProductTypeEnum, UserRoles } from "../../types";
import { QuotationFormValues } from "../types";
import CapitalizeQuotationCustomerName from "./CapitalizeQuotationCustomerName";

class VehicleFormSection extends React.Component<
  FormikProps<QuotationFormValues> & {
    showDealerSelect?: boolean;
    nextSection: () => void;
  },
  { isCollapsed: boolean; nextButtonClicked: boolean }
> {
  constructor(
    props: FormikProps<QuotationFormValues> & { nextSection: () => void }
  ) {
    super(props);
    this.toggleIsCollapsed = this.toggleIsCollapsed.bind(this);
    this.touchAllFields = this.touchAllFields.bind(this);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handleskipVehicle = this.handleskipVehicle.bind(this);
    this.state = {
      isCollapsed:
        !!props.values.vehicle.cAP || !!props.values.vehicle.skipVehicle,
      nextButtonClicked: false,
    };
  }

  public render() {
    const props = this.props;
    const { isCollapsed } = this.state;
    const vehicleErrors: any = (props.errors && props.errors.vehicle) || {};

    const {
      vehicle: {
        regNo,
        cAP,
        make,
        model,
        derivative: style,
        mileage,
        maxAnnualMileage,
      },
    } = this.props.values;

    const fullName = getFullName(this.props.values);

    return (
      <AnimatedHeightContainer
        className={classnames("card form-section mb-3", {
          "has-focus": !isCollapsed,
        })}
      >
        {!isCollapsed ? (
          <CardHeader className="card-header">
            <CardTitle className="mb-0">Quotation details</CardTitle>
          </CardHeader>
        ) : null}
        <CardBody>
          {isCollapsed ? (
            <Row className="mt-3">
              <Col lg={{ size: 2 }}>
                {" "}
                <Button
                  size="sm"
                  outline={true}
                  color="secondary"
                  onClick={this.toggleIsCollapsed}
                  className="mb-3"
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Edit details
                </Button>
              </Col>

              <Col lg={{ size: 10 }}>
                {fullName ? <h4 key="fullName">{fullName}</h4> : null}
                {cAP ? (
                  <>
                    <h4 key="vehicle">
                      {regNo ? (
                        <span className="vehicle-registration-static">{`${regNo.toUpperCase()}, `}</span>
                      ) : null}
                      {make} {model}
                    </h4>
                    <p className="mb-0">
                      {style}
                      {mileage && !vehicleErrors.mileage ? (
                        <>, {mileage} miles</>
                      ) : null}
                      {maxAnnualMileage && !vehicleErrors.maxAnnualMileage ? (
                        <>, Max {maxAnnualMileage} miles per year</>
                      ) : null}
                    </p>{" "}
                  </>
                ) : null}
                {vehicleErrors.length ? (
                  <>
                    <Badge color="danger" className="mb-3">
                      Vehicle details are incomplete
                    </Badge>
                    <br />
                  </>
                ) : null}
              </Col>
            </Row>
          ) : (
            <LoggedInUserQuery>
              {({ data, loading }) => {
                const isDealer =
                  data &&
                  data.loggedInUser &&
                  data.loggedInUser.roles.includes(UserRoles.dealer);
                const showDealerSelect =
                  !loading && props.showDealerSelect !== false && !isDealer;

                return (
                  <Row>
                    <CapitalizeQuotationCustomerName />
                    <Col lg={9}>
                      {showDealerSelect ? (
                        <Field name="dealerId">
                          {({
                            field,
                            form,
                          }: FieldProps<QuotationFormValues>) => (
                            <Row>
                              <Col lg={7}>
                                <FormGroupWrapper
                                  title="Dealer"
                                  field={field}
                                  form={form}
                                >
                                  <DealerSelect
                                    autoFocus={true}
                                    dealerId={field.value}
                                    onSelectDealer={(
                                      dealerId,
                                      isMannIslandDealer,
                                      isMannIslandZList,
                                      isMultiQuote
                                    ) => {
                                      props.setFieldValue(field.name, dealerId);
                                      props.setFieldValue(
                                        "isMannIslandDealer",
                                        isMannIslandDealer
                                      );
                                      props.setFieldValue(
                                        "isMannIslandZlist",
                                        isMannIslandZList
                                      );
                                      props.setFieldValue(
                                        "isMultiQuote",
                                        isMultiQuote
                                      );
                                    }}
                                  />
                                </FormGroupWrapper>
                              </Col>
                            </Row>
                          )}
                        </Field>
                      ) : null}
                      <Row>
                        <Field
                          colSize={3}
                          name="title"
                          placeholder="Title"
                          autoFocus={!showDealerSelect}
                          component={TitleSelectField}
                        />
                      </Row>
                      <Row>
                        <Field
                          colSize={4}
                          name="forename"
                          type="text"
                          placeholder="First name"
                          title="First name"
                          component={FormInputField}
                        />
                        <Field
                          colSize={4}
                          name="middleName"
                          type="text"
                          placeholder="Middle name"
                          title="Middle name"
                          component={FormInputField}
                        />
                        <Field
                          colSize={4}
                          name="surname"
                          type="text"
                          placeholder="Last name"
                          title="Last name"
                          component={FormInputField}
                        />
                      </Row>
                      <p className="text-info mb-1 small">
                        Make sure to add a middle name if the customer has one.
                      </p>
                      <p className="text-info mb-3 small">
                        The customer name should be the same as it appears on
                        their driving license or passport to successfully
                        complete e-sign.
                      </p>

                      <VehicleSection
                        {...props}
                        context={ContextNames.QUOTATION_FORM}
                      />
                      <Row className={"mt-3"}>
                        <Col md={{ size: 5 }}>
                          <button
                            className={classnames(
                              "btn wide-button mb-2 btn-lg",
                              {
                                "btn-primary": !props.values.showFinanceSection,
                                "btn-outline-primary":
                                  props.values.showFinanceSection,
                              }
                            )}
                            onClick={this.handleNextButtonClick}
                            type="button"
                          >
                            {props.values.showFinanceSection
                              ? "Update"
                              : "Next"}
                          </button>
                          {this.state.nextButtonClicked &&
                          vehicleErrors.length ? (
                            <p className="text-danger small">
                              Vehicle details are incomplete
                            </p>
                          ) : null}

                          <button
                            className="btn mb-2 btn-link wide-button"
                            onClick={this.handleskipVehicle}
                            type="button"
                          >
                            Skip vehicle details (HP loans only)
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </LoggedInUserQuery>
          )}
        </CardBody>
      </AnimatedHeightContainer>
    );
  }

  private handleskipVehicle(e: React.MouseEvent<any>) {
    e.preventDefault();

    const { setFieldValue } = this.props;

    setFieldValue("vehicle.skipVehicle" as any, true, false);
    setFieldValue("productTypes" as any, [ProductTypeEnum.HP], false);
    setFieldValue("vehicle.regNo" as any, "", false);
    setFieldValue("vehicle.dateOfRegistration" as any, null, false);
    setFieldValue("vehicle.make" as any, "", false);
    setFieldValue("vehicle.model" as any, "", false);
    setFieldValue("vehicle.derivative" as any, "", false);
    setFieldValue("vehicle.cAP" as any, "", false);
    setFieldValue("vehicle.capId" as any, "", false);
    setFieldValue("vehicle.mileage" as any, null, false);
    setFieldValue("vehicle.maxAnnualMileage" as any, null, false);

    Promise.resolve()
      .then(() => this.props.validateForm())
      .then(() => {
        this.handleNextButtonClick();
      });
  }

  private handleNextButtonClick() {
    const { errors } = this.props;
    const hasErrors = !!errors.vehicle || !!errors.dealerId;

    this.touchAllFields();
    this.setState({ nextButtonClicked: true });

    if (
      (this.props.values.vehicle.cAP ||
        this.props.values.vehicle.skipVehicle) &&
      !hasErrors
    ) {
      this.toggleIsCollapsed();
      this.props.nextSection();
    }
  }

  private toggleIsCollapsed() {
    this.touchAllFields();

    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed });

    if (isCollapsed) {
      this.props.setFieldValue("showResults", false);
      this.props.setFieldValue("vehicle.skipVehicle", false);
    }
  }

  private touchAllFields() {
    [
      "dealerId",
      "vehicle.cAP",
      "vehicle.capId",
      "vehicle.isCommercial",
      "vehicle.mileage",
      "vehicle.regNo",
      "vehicle.maxAnnualMileage",
      "vehicle.isNew",
      "vehicle.make",
      "vehicle.model",
      "vehicle.dateOfRegistration",
      "vehicle.derivative",
      "vehicle.regNoNotFound",
    ].forEach((x) => this.props.setFieldTouched(x as any, true, false));
  }
}

export default VehicleFormSection;
