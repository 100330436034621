import { FieldProps } from "formik";
import { padStart } from "lodash";
import * as React from "react";
import Select from "react-select";
import { isNumeric } from "../../../utils";
import { selectStyles, selectTheme } from "../../Forms/CustomSelect";

interface DateSelectRowState {
  day?: number;
  month?: number;
  year?: number;
}

const datePattern = /^(\d*)-(\d*)-(\d*)(.*)/g;

const monthOptions: { label: any; value: any }[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((month, i) => ({
  label: month,
  value: padStart((i + 1).toString(), 2, "0"),
}));

const dateStyle = {
  ...selectStyles,
  container: (provided: any) => ({
    ...provided,
  }),
};

class DateSelectRow extends React.Component<
  FieldProps<any> & { preventMaxYear?: boolean },
  DateSelectRowState
> {
  public constructor(props: FieldProps<any>) {
    super(props);
    this.updateDay = this.updateDay.bind(this);
    this.updateMonth = this.updateMonth.bind(this);
    this.updateYear = this.updateYear.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  public render() {
    const {
      field: { value },
      form: { errors, touched },
      preventMaxYear,
    } = this.props;

    let day = "";
    let month = "";
    let year = "";
    if (value) {
      const results = new RegExp(datePattern).exec(value);
      if (results) {
        day = results[3] || "";
        month = results[2] || "";
        year = results[1] || "";
      }
    }

    // Remove any leading digits from the day for display
    if (day && isNumeric(day)) {
      day = parseInt(day, 10).toString();
    }

    const option = monthOptions.find((x) => x.value === month);

    const path = this.props.field.name.split(".");
    const isTouched = path.reduce((o, i) => o && o[i], { ...touched } as any);
    const err =
      (isTouched && path.reduce((o, i) => o && o[i], { ...errors } as any)) ||
      "";

    return (
      <div className="form-group">
        <div key="dob-control" className="form-row align-items-center">
          <div className="col-sm-3 pb-3 pb-sm-0">
            <input
              value={day}
              type="number"
              min={1}
              max={31}
              className="form-control"
              id="dob-day-input"
              placeholder="Day"
              onChange={this.updateDay}
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-sm-5 pb-3 pb-sm-0">
            <Select
              styles={dateStyle}
              theme={selectTheme}
              id="dob-month-input"
              value={
                month
                  ? {
                      label: option && option.label,
                      value: month,
                    }
                  : null
              }
              onChange={this.updateMonth}
              onBlur={this.handleBlur}
              options={monthOptions}
              placeholder="Month"
            />
          </div>
          <div className="col-sm-4">
            <input
              value={year}
              min={1900}
              max={!!preventMaxYear ? undefined : new Date().getFullYear()}
              type="number"
              className="form-control"
              id="dob-year-input"
              placeholder="Year"
              onChange={this.updateYear}
              onBlur={this.handleBlur}
            />
          </div>
        </div>
        {err && isTouched ? <p className="text-danger small">{err}</p> : null}
      </div>
    );
  }

  private updateDay(e: any) {
    const dayText = e.target.value ? e.target.value.padStart(2, "0") : "";
    const oldValue: string = this.props.field.value;

    const dateText = oldValue && oldValue.match(datePattern) ? oldValue : "--";
    this.props.form.setFieldValue(
      this.props.field.name,
      dateText.replace(datePattern, `$1-$2-${dayText}$4`)
    );
  }

  private updateMonth(option: any) {
    const monthText = option.value;
    const oldValue: string = this.props.field.value;

    const dateText = oldValue && oldValue.match(datePattern) ? oldValue : "--";
    this.props.form.setFieldValue(
      this.props.field.name,
      dateText.replace(datePattern, `$1-${monthText}-$3$4`)
    );
  }

  private updateYear(e: any) {
    const yearText = e.target.value ? e.target.value : "";
    const oldValue: string = this.props.field.value;

    const dateText = oldValue && oldValue.match(datePattern) ? oldValue : "--";
    this.props.form.setFieldValue(
      this.props.field.name,
      dateText.replace(datePattern, `${yearText}-$2-$3$4`)
    );
  }

  private handleBlur(e: any) {
    this.props.form.setFieldTouched(this.props.field.name, true);
  }
}

export default DateSelectRow;
