import gql from "graphql-tag";
import {
  DealerFragment,
  DirectDealDealerFragment,
  ShallowDealerFragment,
} from "../Dealers/fragments";
import { SuitabilityFragment } from "./ProposalScene/SuitabilityForm/SuitabilityQueries";

export const BankDetailsFragment = gql`
  fragment BankDetailsFragment on BankDetails {
    id
    branch
    bank
    accountName
    accountNumber
    sortCode
    yearsWithBank
    monthsWithBank
  }
`;

export const VehicleFragment = gql`
  fragment VehicleFragment on Vehicle {
    id
    bodyStyle
    cAP
    capId
    colour
    derivative
    doors
    engineSize
    fuel
    insuranceGroup
    isNew
    make
    maxAnnualMileage
    mileage
    model
    regNo
    dateOfRegistration
    transmission
    vIN
    vatQualifying
    isCommercial
    vehicleType
  }
`;

export const IndividualCustomerFragment = gql`
  fragment IndividualCustomerFragment on IndividualCustomer {
    id
    title
    forename
    middleName
    surname
    telephone
    mobile
    email
    dOB
    drivingLicense
    maritalStatus
    numOfDependants
    countryOfBirthId
    countryOfBirth {
      id
      name
    }
    nationalityId
    nationality {
      id
      name
    }
    countryOfResidenceId
    countryOfResidence {
      id
      name
    }
    selfEmployedDirectorOrBoardMember
    countryOfActivityId
    countryOfActivity {
      id
      name
    }
    occupationTypeId
    occupationType {
      id
      description
    }
    homeAddresses {
      id
      line1
      line2
      line3
      town
      county
      postcode
      countryId
      ownership
      otherOwnership
      monthsAtAddress
      yearsAtAddress
      telephone
      totalMonthlyRentOrMortgage
    }
    employmentDetails {
      id
      employmentTerms
      employmentStatus
      employmentType
      occupation
      employerName
      earnings
      earningsPer
      yearsWithEmployer
      monthsWithEmployer
      address {
        id
        line1
        line2
        line3
        town
        county
        countryId
        postcode
        telephone
      }
      industry
    }
  }
`;

export const BusinessFragment = gql`
  fragment BusinessFragment on Business {
    id
    name
    natureOfBusiness
    contactName
    contactPosition
    email
    mobile
    established
    businessType
    otherBusinessType
    registrationNumber
    yearsAtAddress
    monthsAtAddress
    address {
      line1
      line2
      line3
      town
      county
      postcode
      countryId
    }
    directors {
      id
      guarantor
      title
      forename
      middleName
      surname
      dOB
      email
      mobile
      owner
      otherStatus
      drivingLicense
      maritalStatus
      numOfDependants
      countryOfBirthId
      countryOfBirth {
        id
        name
      }
      nationalityId
      nationality {
        id
        name
      }
      countryOfResidenceId
      countryOfResidence {
        id
        name
      }
      homeYears
      homeMonths
      address {
        line1
        line2
        line3
        postcode
        town
        totalMonthlyRentOrMortgage
      }
      earnings
    }
  }
`;

export const FinanceFragment = gql`
  fragment FinanceFragment on ProposalFinance {
    id
    rate
    aprRate
    period
    monthlyPayment
    balloonPayment
    partExchange
    partExchangeSettlement
    cashPrice
    cashDeposit
    rFL
    extras
    productType
    acceptanceFee
    optionFee
    totalFinance
    interestCharges
    totalCharges
    balancePayable
    totalAmountPayable
    lessRentalDeposit
    commissionCode
    dealerCommission
    paidOutDate
    requestDifferentLoanType
    lenderId
    lenderName
  }
`;

export const ProposalCustomerQuestionsFragment = gql`
  fragment ProposalCustomerQuestionsFragment on ProposalCustomerQuestions {
    id
    privacyAgreement
    customerHasHadFinanceBefore
    customerSoleSignatoryToBankAccount
    customerExpectsAffordabilityProblems
    customerAnnualMileageRealistic
    customerConfidentFinanceIsAffordable
    customerUnderstandsAgreement
    customerHappyAgreementIsGoodValue
    customerAdditionalInfo
    customerSaysDealerAnsweredQuestions
    customerPresent
    tNC
  }
`;

export const ShallowProposalFragment = gql`
  fragment ShallowProposalFragment on Proposal {
    id
    proposalRef
    status
    cancelType
    isDealSaver
    dealer {
      ...ShallowDealerFragment
    }
    finance {
      ...FinanceFragment
    }
    individualCustomer {
      id
      title
      forename
      middleName
      surname
      telephone
      mobile
      email
      homeAddresses {
        line1
        line2
        line3
        town
        county
        postcode
        countryId
      }
    }
    createdDate
    salesPerson
    proposalType
    business {
      id
      name
      contactName
      email
      mobile
      established
      businessType
      otherBusinessType
      address {
        line1
        line2
        line3
        town
        county
        postcode
        countryId
      }
      directors {
        id
        title
        forename
        surname
        dOB
        email
        mobile
        address {
          line1
          line2
          line3
          county
          postcode
          town
          countryId
        }
      }
    }
    debitBacks {
      id
    }
    vehicle {
      ...VehicleFragment
    }
    loan {
      id
      name
      lender {
        id
        name
      }
    }
    suitability {
      ...SuitabilityFragment
    }
    autoConvertReference
    autoConvertLenderName
    externalSource
    isDocsRequested
  }
  ${ShallowDealerFragment}
  ${VehicleFragment}
  ${FinanceFragment}
  ${SuitabilityFragment}
`;

export const ProposalNotesFragment = gql`
  fragment ProposalNotesFragment on ProposalNote {
    id
    proposalId
    updated
    loggedBy
    priority
    body
    isSticky
    followupDate
  }
`;

export const ProposalFragment = gql`
  fragment ProposalFragment on Proposal {
    id
    proposalRef
    status
    cancelType
    isDealSaver
    distanceSelling
    dealer {
      ...DealerFragment
    }
    finance {
      ...FinanceFragment
    }
    bankDetails {
      ...BankDetailsFragment
    }
    individualCustomer {
      ...IndividualCustomerFragment
    }
    business {
      ...BusinessFragment
    }
    createdDate
    salesPerson
    proposalType
    business {
      id
      name
    }
    vehicle {
      ...VehicleFragment
    }
    loan {
      id
      name
      lender {
        id
        name
      }
    }
    autoConvertReference
    autoConvertLenderName
    externalSource
    isDocsRequested
  }
  ${DealerFragment}
  ${VehicleFragment}
  ${IndividualCustomerFragment}
  ${BusinessFragment}
  ${FinanceFragment}
  ${BankDetailsFragment}
`;

export const DraftProposalFormDataFragment = gql`
  fragment DraftProposalFormDataFragment on DraftProposalFormData {
    id
    dealerId
    distanceSelling
    targetBy
    targetByValue
    quotationId
    finance {
      ...FinanceFragment
    }
    bankDetails {
      ...BankDetailsFragment
    }
    individualCustomer {
      ...IndividualCustomerFragment
    }
    salesPerson
    notes
    business {
      ...BusinessFragment
    }
    vehicle {
      ...VehicleFragment
    }
    proposalCustomerQuestions {
      ...ProposalCustomerQuestionsFragment
    }
  }
  ${VehicleFragment}
  ${IndividualCustomerFragment}
  ${BusinessFragment}
  ${FinanceFragment}
  ${BankDetailsFragment}
  ${ProposalCustomerQuestionsFragment}
`;

export const DraftProposalFragment = gql`
  fragment DraftProposalFragment on DraftProposal {
    id
    customerDescription
    vehicleDescription
    proposal {
      ...DraftProposalFormDataFragment
    }
    touchedFields
    currentSection
    created
    updated
    proposalType
    dealer {
      ...DealerFragment
    }
    requiresQuotation
  }
  ${DraftProposalFormDataFragment}
  ${DealerFragment}
`;

export const DraftProposalShallowFragment = gql`
  fragment DraftProposalShallowFragment on DraftProposal {
    id
    customerDescription
    vehicleDescription
    created
    updated
    proposalType
    isDirectDeal
    dealer {
      id
      name
    }
  }
`;

export const DirectDealDraftProposalFragment = gql`
  fragment DirectDealDraftProposalFragment on DraftProposal {
    id
    customerDescription
    vehicleDescription
    proposal {
      ...DraftProposalFormDataFragment
    }
    touchedFields
    currentSection
    created
    updated
    proposalType
    dealer {
      ...DirectDealDealerFragment
    }
    requiresQuotation
  }
  ${DraftProposalFormDataFragment}
  ${DirectDealDealerFragment}
`;

export const DirectDealFragment = gql`
  fragment DirectDealFragment on DirectDeal {
    id
    title
    forename
    middleName
    surname
    customerEmail
    dealerId
    dealerName
    draftProposalId
    isActive
    iddSigned
    printName
  }
`;

export const UpdateIndividualFragment = gql`
  fragment UpdateIndividualFragment on Proposal {
    id
    proposalRef
    individualCustomer {
      ...IndividualCustomerFragment
    }
  }
  ${IndividualCustomerFragment}
`;

export const UpdateBusinessFragment = gql`
  fragment UpdateBusinessFragment on Proposal {
    id
    proposalRef
    business {
      ...BusinessFragment
    }
  }
  ${BusinessFragment}
`;

export const UpdateVehicleFragment = gql`
  fragment UpdateVehicleFragment on Proposal {
    id
    proposalRef
    individualCustomer {
      ...IndividualCustomerFragment
    }
    business {
      ...BusinessFragment
    }
    vehicle {
      ...VehicleFragment
    }
  }
  ${VehicleFragment}
  ${IndividualCustomerFragment}
  ${BusinessFragment}
`;

export const UpdateFinanceFragment = gql`
  fragment UpdateFinanceFragment on Proposal {
    id
    proposalRef
    individualCustomer {
      ...IndividualCustomerFragment
    }
    business {
      ...BusinessFragment
    }
    finance {
      ...FinanceFragment
    }
  }
  ${IndividualCustomerFragment}
  ${FinanceFragment}
  ${BusinessFragment}
`;
