import { FieldProps } from "formik";
import * as React from "react";
import { Col } from "reactstrap";
import FormGroupWrapper from "./FormGroupWrapper";

const FormFieldWrapper = <TValues extends {}>({
  children,
  colSize,
  description,
  ...fieldProps
}: FieldProps<TValues> & {
  colSize?: number;
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}) => (
  <Col lg={colSize}>
    <FormGroupWrapper {...fieldProps} description={description}>
      {children}
    </FormGroupWrapper>
  </Col>
);

export default FormFieldWrapper;
