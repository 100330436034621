import { DocumentNode } from "apollo-boost";
import gql from "graphql-tag";
import memoizeOne from "memoize-one";
import * as React from "react";
import { graphql, Query, QueryResult, useQuery } from "react-apollo";
import { AccountManagerFragment } from "./AccountManagers/fragments";
import {
  PermissionActivityNames,
  PermissionModuleNames,
} from "./Permissions/types";
import { CompositeComponent, User, UserRoles } from "./types";

export interface LoggedInUserProps {
  loggedInUser?: User;
  loading?: boolean;
}

interface LoggedInUserQueryProps {
  children: (
    result: QueryResult<LoggedInUserProps> & { loggedInUser?: User }
  ) => JSX.Element | null;
}

export const LoggedInUserFragment = gql`
  fragment LoggedInUserFragment on LoggedInUser {
    id
    username
    isSuspended
    canSubmitProposals
    roles
    forename
    surname
    tawkTo {
      enabled
      hash
    }
    dealer {
      id
      name
    }
    accountManager {
      ...AccountManagerFragment
    }
    regionalSalesManager {
      id
    }
    permissions {
      id
      activities
      permissionRoleId
      permissionModuleId
    }
  }
  ${AccountManagerFragment}
`;

export const LoggedInUserShallowFragment = gql`
  fragment LoggedInUserShallowFragment on LoggedInUser {
    id
    username
    isSuspended
    canSubmitProposals
    roles
    forename
    surname
    dealer {
      id
      name
    }
    permissions {
      id
      activities
      permissionRoleId
      permissionModuleId
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query LoggedInUserQuery {
    loggedInUser {
      ...LoggedInUserFragment
    }
  }
  ${LoggedInUserFragment}
`;

const LOGGED_IN_USER_WITH_DEALER_RATES_QUERY = gql`
  query LoggedInUserWithDealerRatesQuery {
    loggedInUser {
      id
      username
      isSuspended
      roles
      dealer {
        id
        isMannIslandDealer
        isMultiQuote
        rateBands {
          minVehicleAgeInMonths
          maxVehicleAgeInMonths
          flatRate
          maxFlatRate
          volumeBonusFlatRate
        }
      }
    }
  }
`;

export const GET_LOGGED_IN_USER_SHALLOW = gql`
  query LoggedInUserShallowQuery {
    loggedInUser {
      ...LoggedInUserShallowFragment
    }
  }
  ${LoggedInUserShallowFragment}
`;

const LoggedInUserQueryCore = ({
  query,
  children,
}: LoggedInUserQueryProps & { query: DocumentNode }) => (
  <Query query={query}>
    {(result: QueryResult<LoggedInUserProps>) =>
      children({
        ...result,
        loggedInUser: result && result.data && result.data.loggedInUser,
      })
    }
  </Query>
);

const LoggedInUserQuery = (props: LoggedInUserQueryProps) => (
  <LoggedInUserQueryCore {...props} query={GET_LOGGED_IN_USER} />
);

export const LoggedInUserWithDealerRatesQuery = (
  props: LoggedInUserQueryProps
) => (
  <LoggedInUserQueryCore
    {...props}
    query={LOGGED_IN_USER_WITH_DEALER_RATES_QUERY}
  />
);

export const withLoggedInUser = <TProps extends {}>(
  WrappedComponent: CompositeComponent<TProps & LoggedInUserProps>
) => {
  return graphql<TProps, LoggedInUserProps, {}, LoggedInUserProps>(
    GET_LOGGED_IN_USER_SHALLOW,
    {
      props: (props: any) => {
        const {
          data: { loading, error, loggedInUser },
        } = props;

        return {
          loading,
          error,
          loggedInUser,
          ...props.ownProps,
        };
      },
    }
  )(WrappedComponent);
};

/** Get helper values indicating the roles the logged in user is a member of */
const isInRoles = memoizeOne((user?: User) => {
  const checkIsInRole = (role: UserRoles) =>
    user?.roles?.includes(role) === true;

  return {
    isDealer: checkIsInRole(UserRoles.dealer),
    isAccountManager: checkIsInRole(UserRoles.account_manager),
    isAdministrator: checkIsInRole(UserRoles.administrator),
    isRegionalSalesManager: checkIsInRole(UserRoles.regional_sales_manager),
    isSeniorManager: checkIsInRole(UserRoles.senior_manager),
    isCaseManagementTeam: checkIsInRole(UserRoles.case_management_team),
    isSuperUser:
      checkIsInRole(UserRoles.administrator) ||
      checkIsInRole(UserRoles.senior_manager),
    isDirectCustomer: checkIsInRole(UserRoles.direct_customer),
  };
});

/** Build an array of strings representing the users permissions as "Module:Activity" */
const getPermissionsLookup = memoizeOne((user?: User) => {
  return user?.permissions?.reduce((prev, p) => {
    p.activities.forEach((activity) => {
      const key = `${p.permissionModuleId}:${activity}`;
      if (!prev.includes(key)) {
        prev.push(key);
      }
    });

    return prev;
  }, [] as string[]);
});

/** Check that the specified user is authorized for the module and activity */
const checkIsAuthorized = (
  permissionModule: PermissionModuleNames,
  activity: PermissionActivityNames,
  user?: User
) => {
  if (!user) {
    return false;
  }

  if (user.roles.includes(UserRoles.administrator)) {
    return true;
  }

  const permissions = getPermissionsLookup(user);
  const key = `${permissionModule}:${activity}`;

  return permissions?.includes(key) === true;
};

export const useLoggedInUser = (query?: DocumentNode) => {
  const { data, loading, error } = useQuery<{ loggedInUser: User }>(
    query || GET_LOGGED_IN_USER
  );

  const loggedInUser = data?.loggedInUser;

  return {
    loading,
    loggedInUser,
    error,
    /** Check that the user is authorized for the module and activity */
    checkIsAuthorized: (
      permissionModule: PermissionModuleNames,
      activity: PermissionActivityNames
    ) => checkIsAuthorized(permissionModule, activity, loggedInUser),
    /** Check that the user is authorized to read the specified module */
    checkCanRead: (permissionModule: PermissionModuleNames) =>
      checkIsAuthorized(
        permissionModule,
        PermissionActivityNames.Read,
        loggedInUser
      ),
    /** Check that the user is authorized to read internal data in the specified module */
    checkCanReadInternal: (permissionModule: PermissionModuleNames) =>
      checkIsAuthorized(
        permissionModule,
        PermissionActivityNames.ReadInternal,
        loggedInUser
      ),
    /** Check that the user is authorized to create entities in the specified module */
    checkCanCreate: (permissionModule: PermissionModuleNames) =>
      checkIsAuthorized(
        permissionModule,
        PermissionActivityNames.Create,
        loggedInUser
      ),
    /** Check that the user is authorized to update entities in the specified module */
    checkCanUpdate: (permissionModule: PermissionModuleNames) =>
      checkIsAuthorized(
        permissionModule,
        PermissionActivityNames.Update,
        loggedInUser
      ),
    /** Check that the user is authorized to delete entities in the specified module */
    checkCanDelete: (permissionModule: PermissionModuleNames) =>
      checkIsAuthorized(
        permissionModule,
        PermissionActivityNames.Delete,
        loggedInUser
      ),
    ...isInRoles(loggedInUser),
  };
};

export default LoggedInUserQuery;
