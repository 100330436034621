import { Field, FieldProps, FormikProps } from "formik";
import React from "react";
import { Alert, Col, Row } from "reactstrap";
import RadioField from "../../Forms/RadioField";
import { Proposal } from "../types";

interface ProposalQuestionProps {
  name: string;
  text: string;
  additionalMessage?: string;
  reverseValues?: boolean;
}

type ProposalQuestionPropsEnhanced = FormikProps<Proposal> &
  ProposalQuestionProps;

class ProposalQuestion extends React.Component<ProposalQuestionPropsEnhanced> {
  public render() {
    const { name, text, additionalMessage, reverseValues } = this.props;

    return (
      <Row>
        <Col className="pt-2">
          <Field name={name}>
            {({ field, form }: FieldProps<any>) => (
              <Alert fade={false} color="secondary" className="proposal-alert">
                <p className="text-info lead">Customer question</p>
                <Row>
                  <RadioField
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false }
                    ]}
                    name={name}
                    title={text}
                  />
                </Row>
                {additionalMessage && field.value === !!reverseValues ? (
                  <p className="text-info mb-0">{additionalMessage}</p>
                ) : null}
              </Alert>
            )}
          </Field>
        </Col>
      </Row>
    );
  }
}

export default ProposalQuestion;
