import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { Address } from "../types";

interface AddressAutocompleteRetrieveArgs {
  input: {
    id?: string;
    query?: string;
  };
}

export interface AddressAutocompleteRetrieveData {
  addressAutocomplete: {
    retrieve: Address;
  };
}

export const ADDRESS_AUTOCOMPLETE_RETRIEVE = gql`
  query AddressAutocompleteRetrieveQuery(
    $input: AddressAutocompleteRetrieveArgsInput
  ) {
    addressAutocomplete {
      retrieve(input: $input) {
        line1
        line2
        line3
        town
        county
        postcode
        countryId
      }
    }
  }
`;

const AddressAutocompleteRetrieveQuery = ({
  children,
  input: { id, query }
}: AddressAutocompleteRetrieveArgs & {
  children: (
    result: QueryResult<
      AddressAutocompleteRetrieveData,
      AddressAutocompleteRetrieveArgs
    >
  ) => JSX.Element | null;
}) => (
  <Query<AddressAutocompleteRetrieveData, AddressAutocompleteRetrieveArgs>
    query={ADDRESS_AUTOCOMPLETE_RETRIEVE}
    variables={{ input: { id, query } }}
  >
    {children}
  </Query>
);

export default AddressAutocompleteRetrieveQuery;
