import { ApolloQueryResult, gql } from "apollo-boost";
import { debounce, isEqual } from "lodash";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import quotationRequestValidator from "./quotationRequestValidator";
import { QuotationListResult, QuotationRequest } from "./types";

interface CalculateQuotationServiceProps {
  input?: QuotationRequest;
  isMannIslandDealer?: boolean;
  isMulti?: boolean;
  children: (props: {
    loading: boolean;
    calculateQuotationList?: QuotationListResult;
  }) => JSX.Element;
}

export interface CalculateQuotationData {
  calculateQuotationList: QuotationListResult;
}

export interface CalculateAlpheraQuotationData {
  calculateAlpheraQuotationList: QuotationListResult;
}

export interface CalculateMannIslandQuotationData {
  calculateMannIslandQuotationList: QuotationListResult;
}

interface CalculateQuotationServiceState {
  loading: boolean;
  calculateQuotationList?: QuotationListResult;
}

export const CALCULATE_QUOTATION = gql`
  query CalculateQuotationQuery($input: QuotationRequestInput) {
    calculateQuotationList(input: $input) {
      results {
        id
        term
        productType
        monthlyPayment
        cashPrice
        deposit
        finance
        arrangementFee
        completionFee
        finalPayment
        aprRate
        flatRate
        guaranteedFutureValue
        guaranteed
        lenderId
        lenderName
        loanId
        commissionCode
        commission
        interestCharges
      }
      unableToQuote {
        term
        productType
        messages
        lenderName
      }
    }
  }
`;

export const CALCULATE_ALPHERA_QUOTATION = gql`
  query CalculateQuotationQuery($input: AlpheraQuotationRequestInput) {
    calculateAlpheraQuotationList(input: $input) {
      results {
        id
        term
        productType
        monthlyPayment
        cashPrice
        deposit
        finance
        arrangementFee
        completionFee
        finalPayment
        aprRate
        flatRate
        guaranteedFutureValue
        guaranteed
        loanId
        commissionCode
        commission
        interestCharges
      }
      unableToQuote {
        term
        productType
        messages
        lenderName
      }
    }
  }
`;
export const CALCULATE_MANN_ISLAND_QUOTATION = gql`
  query CalculateQuotationQuery($input: MannIslandQuotationRequestInput) {
    calculateMannIslandQuotationList(input: $input) {
      results {
        id
        term
        productType
        monthlyPayment
        cashPrice
        deposit
        finance
        arrangementFee
        completionFee
        finalPayment
        aprRate
        flatRate
        guaranteedFutureValue
        guaranteed
        loanId
        commissionCode
        commission
        interestCharges
        lenderId
      }
      unableToQuote {
        term
        productType
        messages
        lenderName
      }
    }
  }
`;

/** Helper class to get quotation results */
class CalculateQuotationService extends React.Component<
  WithApolloClient<CalculateQuotationServiceProps>,
  CalculateQuotationServiceState
> {
  private requestId = 0;

  public constructor(props: WithApolloClient<CalculateQuotationServiceProps>) {
    super(props);
    this.state = { loading: false };
    this.calculateQuotations = debounce(
      this.calculateQuotations.bind(this),
      200,
      { leading: false, trailing: true }
    );
  }

  public render() {
    const { loading, calculateQuotationList } = this.state;
    const { children } = this.props;
    return children({ loading, calculateQuotationList });
  }

  public componentDidUpdate(
    prevProps: WithApolloClient<CalculateQuotationServiceProps>
  ) {
    const { input: prevInput } = prevProps;
    const { input: nextInput } = this.props;

    if (nextInput && quotationRequestValidator.isValidSync(nextInput)) {
      if (!isEqual(prevInput, nextInput)) {
        // Recalculate the results if the next props have changed and are valid
        this.setState({ loading: true });
        this.calculateQuotations(nextInput);
      }
    } else {
      // Clear the results if the next props are not valid
      this.state.calculateQuotationList &&
        this.setState({ calculateQuotationList: undefined });
    }
  }

  /** Get quotation results for the provided request */
  private calculateQuotations(input: QuotationRequest) {
    const { client, isMannIslandDealer, isMulti } = this.props;

    this.setState({ loading: true });

    this.requestId += 1;
    const currentRequestId = this.requestId;

    if (isMulti) {
      return client
        .query({
          query: CALCULATE_QUOTATION,
          variables: { input },
        })
        .then((result: ApolloQueryResult<CalculateQuotationData>) => {
          if (this.requestId === currentRequestId) {
            this.setState({
              loading: false,
              calculateQuotationList:
                result.data && result.data.calculateQuotationList
                  ? result.data.calculateQuotationList
                  : undefined,
            });
          }
        })
        .catch(() => this.setState({ loading: false }));
    } else if (isMannIslandDealer) {
      return client
        .query({
          query: CALCULATE_MANN_ISLAND_QUOTATION,
          variables: { input },
        })
        .then((result: ApolloQueryResult<CalculateMannIslandQuotationData>) => {
          if (this.requestId === currentRequestId) {
            this.setState({
              loading: false,
              calculateQuotationList:
                result.data && result.data.calculateMannIslandQuotationList
                  ? result.data.calculateMannIslandQuotationList
                  : undefined,
            });
          }
        })
        .catch(() => this.setState({ loading: false }));
    } else {
      return client
        .query({
          query: CALCULATE_ALPHERA_QUOTATION,
          variables: { input },
        })
        .then((result: ApolloQueryResult<CalculateAlpheraQuotationData>) => {
          if (this.requestId === currentRequestId) {
            this.setState({
              loading: false,
              calculateQuotationList:
                result.data && result.data.calculateAlpheraQuotationList
                  ? result.data.calculateAlpheraQuotationList
                  : undefined,
            });
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
  }
}

export default withApollo<CalculateQuotationServiceProps>(
  CalculateQuotationService
);
