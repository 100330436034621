import { differenceInCalendarMonths, parse, subMonths } from "date-fns";
import { FieldProps } from "formik";
import * as React from "react";
import { Col, InputGroup, InputGroupAddon } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";

const getDateOfRegistration = (ageInMonths: number) => {
  if (ageInMonths || ageInMonths === 0) {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    return subMonths(firstDayOfMonth, ageInMonths);
  }
  return null;
};

const getAgeInMonths = (dateOfRegistration: string) => {
  if (dateOfRegistration) {
    const date = parse(dateOfRegistration);
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    return differenceInCalendarMonths(firstDayOfMonth, date);
  }
  return null;
};

const AgeInMonthsField = ({
  field,
  form,
  title,
  colSize
}: FieldProps<any> & { title: string; colSize?: number }) => {
  const ageInMonths = getAgeInMonths(field.value);

  const handleChange = (e: any) => {
    const num = e.target.value ? parseFloat(e.target.value) : null;
    let date: Date | null = null;
    if (num || num === 0) {
      date = getDateOfRegistration(num);
    }
    form.setFieldValue(field.name, date ? date.toISOString() : null);
    form.setFieldTouched(field.name, true);
  };

  return (
    <Col lg={colSize}>
      <FormGroupWrapper title={title} form={form} field={field}>
        <InputGroup>
          <input
            className="form-control"
            type="number"
            name={field.name}
            value={!!ageInMonths || ageInMonths === 0 ? ageInMonths : ""}
            onChange={handleChange}
            onBlur={handleChange}
            placeholder="Age in months"
          />
          <InputGroupAddon addonType="append">Months</InputGroupAddon>
        </InputGroup>
      </FormGroupWrapper>
    </Col>
  );
};

export default AgeInMonthsField;
