import * as React from "react";
import { Container } from "reactstrap";
import LoadingSpinner from "./LoadingSpinner";

const SceneLoadingSpinner: React.SFC = () => (
  <Container className="scene-loading">
    <LoadingSpinner />
  </Container>
);

export default SceneLoadingSpinner;
