import * as React from "react";
import { Motion, spring } from "react-motion";
import { SizeMeProps, withSize } from "react-sizeme";

interface AnimatedHeightContainerProps {
  className?: string;
  children: React.ReactNode;
}

class InnerContainer extends React.Component<
  AnimatedHeightContainerProps & SizeMeProps
> {
  public render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

const SizeAwareContainer = withSize({
  monitorHeight: true,
  monitorWidth: false,
})(InnerContainer);

// tslint:disable-next-line:max-classes-per-file
class AnimatedHeightContainer extends React.Component<
  AnimatedHeightContainerProps,
  { height?: number; isInitialHeight: boolean }
> {
  constructor(props: AnimatedHeightContainerProps) {
    super(props);
    this.state = { isInitialHeight: true };
    this.handleSize = this.handleSize.bind(this);
  }

  public componentDidMount() {
    this.setState({ isInitialHeight: true });
  }

  public render() {
    const { height, isInitialHeight } = this.state;

    return (
      <Motion
        defaultStyle={{ height: height || 0 }}
        style={{
          height:
            height && !isInitialHeight
              ? spring(height, { stiffness: 200, damping: 25, precision: 0.2 })
              : height || 0,
        }}
      >
        {(value) => {
          return (
            <div
              style={{
                height: value.height ? value.height : "auto",
              }}
              className={this.props.className}
            >
              <SizeAwareContainer {...this.props} onSize={this.handleSize} />
            </div>
          );
        }}
      </Motion>
    );
  }

  private handleSize(size: any) {
    size.height &&
      this.setState((s) => ({
        height: size.height,
        isInitialHeight: !s.height,
      }));
  }
}

export default AnimatedHeightContainer;
