import gql from "graphql-tag";
import * as React from "react";
import { Mutation, MutationFunction, MutationResult } from "react-apollo";
import { ProposalFragment } from "./fragments";
import { Proposal } from "./types";

interface ProposalData {
  createProposal: Proposal;
}

interface ProposalVariables {
  input: Proposal;
}

export const CREATE_PROPOSAL = gql`
  mutation CreateProposal($input: ProposalInput) {
    createProposal(input: $input) {
      ...ProposalFragment
    }
  }
  ${ProposalFragment}
`;

const CreateProposal = ({
  children,
}: {
  children: (
    mutationFunction: MutationFunction<ProposalData, ProposalVariables>,
    result: MutationResult<ProposalData>
  ) => JSX.Element | null;
}) => <Mutation mutation={CREATE_PROPOSAL}>{children}</Mutation>;

export default CreateProposal;
