import { useHistory } from "react-router";
import { passwordlessConfig } from "./Auth0PasswordlessProvider";

const PasswordlessAuth0Callback = () => {
  const history = useHistory();

  passwordlessConfig.parseHash(
    { hash: window.location.hash },
    (err, authResult) => {
      if (err) {
        return null;
      }

      const data = localStorage.getItem("login_redirect");
      let returnTo: string | undefined;

      if (data) {
        const redirectInfo = JSON.parse(data);
        returnTo = redirectInfo.path;
      }

      history.push(returnTo || "/forbidden");
      return null;
    }
  );

  return null;
};

export default PasswordlessAuth0Callback;
