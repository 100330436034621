import * as React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { DirectDeal } from "../types";
import { formatCurrency, getFullName } from "../../../utils";
import SummaryRow from "../../shared/SummarySection/SummaryRow";
import { DirectDealProps } from "../../Forms/MultiSectionForm";

export interface DirectDealModalProps extends DirectDealProps {
  toggle: () => void;
  toggleAlert: () => void;
  display: boolean;
  values: DirectDeal;
  disabledConfirmButton: boolean;
}

const DirectDealModal = ({
  display,
  toggle,
  toggleAlert,
  saveDirectDeal,
  values,
  disabledConfirmButton,
}: DirectDealModalProps) => {
  const {
    dealerName,
    title,
    forename,
    middleName,
    surname,
    customerEmail,
    vehicle,
    totalMileage,
    annualMileage,
    period,
    monthlyPayment,
    productType,
    salesPerson,
    distanceSelling,
  } = values;

  return (
    <>
      <Modal toggle={toggle} isOpen={display}>
        <ModalHeader toggle={toggle}>
          Direct Deal - Individual Customer
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>
                You are about to start the direct deal process for an individual
                customer. To proceed, confirm the details below are correct.
              </p>
              <p className="font-weight-bold">This action cannot be undone.</p>
            </Col>
          </Row>
          <SummaryRow title="Dealer" value={dealerName} />
          <SummaryRow
            title="Customer name"
            value={getFullName({ title, forename, middleName, surname })}
          />
          <SummaryRow title="Email" value={customerEmail} />
          <SummaryRow title="Vehicle" value={vehicle} />
          <SummaryRow title="Total miles" value={totalMileage} />
          <SummaryRow title="Annual miles" value={annualMileage} />
          <SummaryRow title="Term" value={period} />
          <SummaryRow title="Monthly" value={formatCurrency(monthlyPayment)} />
          <SummaryRow title="Product" value={productType} />
          <SummaryRow title="Sales person" value={salesPerson} />
          <SummaryRow
            title="Type of sales transaction"
            value={
              distanceSelling !== undefined
                ? distanceSelling
                  ? "Distance selling"
                  : "Sale on premise"
                : distanceSelling
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline={true} onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="warning"
            type="submit"
            disabled={!!disabledConfirmButton}
            onClick={() => {
              toggle();
              toggleAlert();

              delete values.vehicle;
              delete values.totalMileage;
              delete values.annualMileage;
              delete values.period;
              delete values.monthlyPayment;
              delete values.productType;
              delete values.salesPerson;
              delete values.distanceSelling;

              saveDirectDeal && saveDirectDeal(values);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DirectDealModal;
