import { Field, FormikProps } from "formik";
import * as React from "react";
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import { roundNumber } from "../../../utils";
import { Dealer } from "../../Dealers/types";
import CheckboxField from "../../Forms/CheckboxField";
import FormInputField from "../../Forms/FormInputField";
import SelectField from "../../Forms/SelectField";
import { ProductTypeEnum } from "../../types";
import { QuotationFormValues, QuotationTargetBy } from "../types";
import TargetByField from "./TargetByField";

export const termOptions: { label: string; value: number }[] = [
  { label: "12 months", value: 12 },
  { label: "24 months", value: 24 },
  { label: "36 months", value: 36 },
  { label: "48 months", value: 48 },
  { label: "60 months", value: 60 },
];

class FinanceFormSection extends React.Component<
  FormikProps<QuotationFormValues> & {
    nextSection: () => void;
    refresh: (props?: FormikProps<QuotationFormValues>) => void;
    className?: string;
    dealer?: Dealer;
    isMulti?: boolean;
  },
  {
    isCollapsed: boolean;
    productTypes?: ProductTypeEnum[];
    targetBy?: QuotationTargetBy;
    targetByValue?: number;
  }
> {
  constructor(
    props: FormikProps<QuotationFormValues> & {
      nextSection: () => void;
      refresh: () => void;
    }
  ) {
    super(props);
    this.toggleIsCollapsed = this.toggleIsCollapsed.bind(this);
    this.touchAllFields = this.touchAllFields.bind(this);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.state = { isCollapsed: !!props.values.id };

    this.props.setFieldValue(
      "targetByValue",
      this.props.dealer?.agreedApr
        ? roundNumber(this.props.dealer?.agreedApr)
        : ""
    );
  }

  public render() {
    const props = this.props;
    return props.isMulti ? (
      <>
        <Row>
          <Field
            colSize={2}
            name="finance.cashPrice"
            type="number"
            title="Price of vehicle"
            component={FormInputField}
            units="£"
            min={0}
            autoFocus={true}
          />

          <Field
            colSize={2}
            name="finance.deposit"
            type="number"
            title="Cash deposit(This field should NOT include any part exchange)"
            component={FormInputField}
            units="£"
            min={0}
          />

          <Field
            colSize={2}
            name="finance.partExchangeValue"
            type="number"
            title="Part exchange value"
            component={FormInputField}
            units="£"
            step={10}
            min={0}
          />

          <Field
            colSize={2}
            name="finance.partExchangeSettlement"
            type="number"
            title="Part ex. settlement"
            component={FormInputField}
            units="£"
            min={0}
          />

          <Col sm={6} lg={2}>
            <FormGroup>
              <Label>Total finance value</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">£</InputGroupAddon>
                <input
                  className="form-control"
                  type="number"
                  value={this.getFinanceValue(props.values).toFixed(2) || ""}
                  readOnly={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col sm={6} lg={3}>
            <Field
              name="targetByValue"
              type="number"
              min={0}
              disabled={!!this.props.dealer?.agreedApr}
              component={TargetByField}
            />
          </Col>

          <SelectField
            colSize={3}
            name="finance.term"
            title="Term"
            options={termOptions}
          />

          <Field
            colSize={3}
            name="vehicle.maxAnnualMileage"
            type="number"
            placeholder="Max mileage p/a"
            min={0}
            title="Max annual mileage"
            component={FormInputField}
            units="Miles"
            unitsPosition="after"
          />

          <CheckboxField
            colSize={3}
            name="productTypes"
            title="Loan types"
            options={Object.keys(ProductTypeEnum).map((x) => ({
              label: x,
              value: x,
            }))}
          />
        </Row>
      </>
    ) : (
      <>
        <Row>
          <Field
            colSize={12}
            name="finance.cashPrice"
            type="number"
            placeholder="Price of vehicle"
            title="Price of vehicle"
            component={FormInputField}
            units="£"
            min={0}
            autoFocus={true}
          />
        </Row>
        <Row>
          <Field
            colSize={12}
            name="finance.deposit"
            type="number"
            title="Cash deposit(This field should NOT include any part exchange)"
            component={FormInputField}
            units="£"
            min={0}
          />
        </Row>
        <Row>
          <Field
            colSize={12}
            name="finance.partExchangeValue"
            type="number"
            title="Part exchange value"
            component={FormInputField}
            units="£"
            step={10}
            min={0}
          />
        </Row>
        <Row>
          <Field
            colSize={12}
            name="finance.partExchangeSettlement"
            type="number"
            title="Part ex. settlement"
            component={FormInputField}
            units="£"
            min={0}
          />
        </Row>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label>Total finance value</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">£</InputGroupAddon>
                <input
                  className="form-control"
                  type="number"
                  value={this.getFinanceValue(props.values).toFixed(2) || ""}
                  readOnly={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 12 }}>
            <Field
              name="targetByValue"
              type="number"
              min={0}
              disabled={!!this.props.dealer?.agreedApr}
              component={TargetByField}
            />
          </Col>
        </Row>
        <Row>
          <CheckboxField
            colSize={12}
            name="productTypes"
            title="Loan types"
            options={Object.keys(ProductTypeEnum).map((x) => ({
              label: x,
              value: x,
            }))}
          />
        </Row>
      </>
    );
  }

  private handleNextButtonClick() {
    const financeErrors = Object.keys(this.props.errors).filter(
      (x) => !x.startsWith("vehicle")
    );
    this.touchAllFields();
    if (!financeErrors.length) {
      this.toggleIsCollapsed();
      this.props.nextSection();
    }
  }

  private getFinanceValue(values: QuotationFormValues) {
    const {
      finance: {
        cashPrice,
        deposit,
        partExchangeValue,
        partExchangeSettlement,
      },
    } = values;

    return (
      (cashPrice || 0) +
      (partExchangeSettlement || 0) -
      ((deposit || 0) + (partExchangeValue || 0))
    );
  }

  private toggleIsCollapsed() {
    this.touchAllFields();
    this.setState((s) => ({ isCollapsed: !s.isCollapsed }));
  }

  private touchAllFields() {
    this.props.setTouched({
      finance: {
        cashPrice: true,
        deposit: true,
        partExchangeSettlement: true,
        partExchangeValue: true,
      },
      targetByValue: true,
    });
  }
}

export default FinanceFormSection;
