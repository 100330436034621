import gql from "graphql-tag";
import { VehicleFragment } from "../Proposals/fragments";

export const QuotationFragment = gql`
  fragment QuotationFragment on Quotation {
    id
    title
    forename
    middleName
    surname
    dealerId
    dealer {
      id
      name
      isMannIslandDealer
      isMultiQuote
    }
    submittedDate
    skipVehicle
    finance {
      id
      productType
      term
      cashPrice
      deposit
      partExchangeSettlement
      partExchangeValue
      arrangementFee
      completionFee
      guaranteedFutureValue
      monthlyPayment
      commission
      commissionCode
      aprRate
      flatRate
      totalFinance
      interestCharges
      totalCharges
      balancePayable
      totalAmountPayable
    }
    lender {
      id
      name
    }
    vehicle {
      ...VehicleFragment
    }
    targetBy
    targetByValue
  }
  ${VehicleFragment}
`;
