import { onError } from "apollo-link-error";

import config from "../config";

const cleanMessage = (message: string) =>
  message ? message.replace("GraphQL.ExecutionError: ", "") : "";

// This uses a hack to access the error provider from outside of React.
const postErrorAlert = (message: string) => {
  const alertsProvider = (window as any).alertsProvider;
  if (alertsProvider) {
    alertsProvider.addAlert({ message });
  }
};

export default () =>
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      // Post an error to notify the user
      postErrorAlert(
        `Network error when connecting to the ${config.TITLE} server`
      );
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        // Post an error to notify the user
        postErrorAlert(cleanMessage(message));
      });
    }
  });
