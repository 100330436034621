import * as React from "react";
import FlipMove from "react-flip-move";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { formatCurrency } from "../../../utils";
import {
  QuotationListResult,
  QuotationResults,
  QuotationTargetBy,
} from "../types";

export interface QuotationResultListProps {
  className?: string;
  quotationListResult?: QuotationListResult;
  targetBy?: QuotationTargetBy;
  showCommission?: boolean;
  loading?: boolean;
  onSelectResult: (result: QuotationResults | null) => void;
  onSelectMoreInfo: (errorMessage: string | null) => void;
  headerText?: string;
}
export interface QuotationResultListItemProps {
  result: QuotationResults;
  lowestPayment?: number;
  lowestProductPayment?: number;
  targetBy?: QuotationTargetBy;
  showCommission?: boolean;
  loading?: boolean;
  onSelectResult: () => void;
}

const MAX_PCP_TERM = 49;

const getLowestPayment = (results?: QuotationResults[]) =>
  results &&
  results.reduce((prev: number, r: QuotationResults) => {
    return prev > 0 ? Math.min(prev, r.monthlyPayment) : r.monthlyPayment;
  }, 0);

const QuotationResultCard = ({
  result: {
    id,
    productType,
    term,
    monthlyPayment,
    guaranteedFutureValue,
    aprRate,
    flatRate,
    commissionCode,
  },
  showCommission,
  onSelectResult,
  lowestPayment,
  lowestProductPayment,
  targetBy,
}: QuotationResultListItemProps) => {
  return (
    <Card
      className="mb-3 quotation-card"
      key={`${id}_${productType}_${term}`}
      onClick={onSelectResult}
    >
      <CardHeader>
        <CardTitle className="small mb-0">
          {productType} finance, {term} months
        </CardTitle>
      </CardHeader>
      <CardBody>
        <p className="mb-1">
          {term} monthly payments: <b>{formatCurrency(monthlyPayment)}</b>{" "}
          {monthlyPayment === lowestProductPayment &&
          targetBy !== QuotationTargetBy.MONTHLY_PAYMENT ? (
            <Badge
              color={monthlyPayment === lowestPayment ? "success" : "secondary"}
              className="ml-1"
            >
              Lowest {monthlyPayment !== lowestPayment ? productType : ""}
            </Badge>
          ) : null}
        </p>
        <p className="mb-1">
          Balloon payment: <b>{formatCurrency(guaranteedFutureValue)}</b>
        </p>
        <p className="mb-1">
          APR: <b>{(Math.round(aprRate * 100) / 100).toFixed(2)}%</b>
        </p>
        <p className="mb-1">
          Flat rate: <b>{(Math.round(flatRate * 100) / 100).toFixed(2)}%</b>
        </p>
        {showCommission ? (
          <p className="text-muted mb-1">Scheme code: {commissionCode}</p>
        ) : null}{" "}
        <Button
          outline={true}
          color="primary"
          type="button"
          onClick={(e: any) => {
            e.preventDefault();
            onSelectResult();
          }}
        >
          Show details
        </Button>
      </CardBody>
    </Card>
  );
};

const QuotationResultList: React.SFC<QuotationResultListProps> = ({
  className,
  quotationListResult,
  onSelectResult,
  onSelectMoreInfo,
  targetBy,
  showCommission,
  loading,
  headerText,
}: QuotationResultListProps) => {
  if (!quotationListResult) {
    return null;
  }

  let termResults = quotationListResult.results;
  termResults = termResults.filter(
    (r) => r.productType !== "PCP" || r.term <= MAX_PCP_TERM
  );

  const lowestPayment = getLowestPayment(termResults) || 0;
  return (
    <div className="mb-4">
      <div className="custom-header mt-3 text-center">{headerText}</div>
      <FlipMove className={className}>
        {["PCP", "LP", "HP"].map((productType) => {
          const results =
            termResults.filter((x) => x.productType === productType) || [];

          const lowestProductPayment = getLowestPayment(results);

          const unableToQuoteList =
            quotationListResult.unableToQuote.filter(
              (x) => x.productType === productType
            ) || [];

          return results.length || unableToQuoteList.length ? (
            <div key={productType} className="mt-3">
              {results.map((result) => (
                <QuotationResultCard
                  key={`${productType}_${result.term}_results`}
                  result={result}
                  lowestProductPayment={lowestProductPayment}
                  lowestPayment={lowestPayment}
                  showCommission={showCommission}
                  onSelectResult={() => onSelectResult(result)}
                  loading={loading}
                  targetBy={targetBy}
                />
              ))}
              {unableToQuoteList.length ? (
                <Card className="mt-3 unable-to-quote">
                  <CardBody>
                    <p className="text-info">
                      Could not quote for some {productType} products
                    </p>
                    <ul className="small mt-2 mb-0 text-muted">
                      {unableToQuoteList.map(({ term, messages }) => (
                        <li key={`${productType}_${term}`}>
                          <p>
                            {productType} finance, {term} months
                          </p>
                          {messages.map((message) => (
                            <p key={message}>{message}</p>
                          ))}
                          <Button
                            outline={true}
                            color="primary"
                            type="button"
                            onClick={(e: any) => {
                              e.preventDefault();
                              onSelectMoreInfo(messages[0]);
                            }}
                          >
                            Show details
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              ) : null}
            </div>
          ) : null;
        })}
      </FlipMove>
    </div>
  );
};

export default QuotationResultList;
