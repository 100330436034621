import * as React from "react";
import { QuotationResults } from "../../Quotations/types";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import InformationTable, {
  CurrencyRow,
  InformationTableRow,
} from "../../shared/InformationTable";

export interface ProposalMultiQuoteResultModalProps {
  result?: QuotationResults | null;
  toggle: () => void;
  selectResult: (result: QuotationResults) => void;
}

const ProposalMultiQuoteResultModal = ({
  result,
  toggle,
  selectResult,
}: ProposalMultiQuoteResultModalProps) => {
  return (
    <Modal
      isOpen={!!result}
      toggle={toggle}
      centered={true}
      modalTransition={{ timeout: 100 }}
    >
      {result ? (
        <>
          <ModalHeader toggle={toggle} className="pt-4 pb-2" tag="div">
            <div className="d-sm-inline-block d-block product-lender">
              <div className="modal-product-type">{result.productType}</div>
            </div>
            <div className="pl-0 d-sm-inline-block d-block product-lender">
              <div className="ml-4">
                <h5 className="modal-lender-name">{result.lenderName}</h5>
                <div className="font-weight-bold">Representative Example</div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="pb-3">
            <Row>
              <Col sm={{ size: 12 }} className="text-justify">
                <InformationTable>
                  <CurrencyRow title="Cash price" value={result.cashPrice} />
                  <CurrencyRow title="Deposit" value={result.deposit} />
                  <CurrencyRow
                    title="Amount of credit"
                    value={result.finance}
                    highlighted={true}
                  />
                  <CurrencyRow
                    title={`${result.term} monthly rentals`}
                    value={result.monthlyPayment}
                    highlighted={true}
                  />
                  <CurrencyRow
                    title="Arrangement fee"
                    value={result.arrangementFee}
                  />
                  <CurrencyRow
                    title="Completion fee"
                    value={result.completionFee}
                  />
                  <CurrencyRow
                    title={`Final rental`}
                    value={result.guaranteedFutureValue}
                  />
                  <CurrencyRow
                    title="Interest charges"
                    value={result.interestCharges}
                  />
                  <CurrencyRow
                    title="Total amount payable"
                    value={
                      result.finance +
                      result.arrangementFee +
                      result.completionFee +
                      result.interestCharges
                    }
                  />
                  <InformationTableRow
                    title="APR"
                    value={`${(Math.round(result.aprRate * 100) / 100).toFixed(
                      2
                    )}%`}
                    valueAlignRight={true}
                    valueHighlighted={true}
                  />
                </InformationTable>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    selectResult(result);
                    toggle();
                  }}
                >
                  Save this loan
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </>
      ) : null}
    </Modal>
  );
};

export default ProposalMultiQuoteResultModal;
