import { useEffect } from "react";

const addBodyClass = (className: string) =>
  document.documentElement.classList.add(className);
const removeBodyClass = (className: string) =>
  document.documentElement.classList.remove(className);

export default function useHtmlElementClass(className: string) {
  useEffect(() => {
    // Set up
    addBodyClass(className);

    // Clean up
    return () => removeBodyClass(className);
  }, [className]);
}
