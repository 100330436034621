import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";
import { User } from "../../types";
import {
  DirectDealDraftProposalFragment,
  DraftProposalFragment,
  DraftProposalShallowFragment
} from "../fragments";
import { DraftProposal } from "../types";

export interface DraftProposalProps {
  loggedInUser: User;
  draftProposal: DraftProposal;
}

interface DraftProposalData {
  loggedInUser: User;
  draftProposal: DraftProposal;
}

interface DraftProposalArgs {
  draftProposalId?: number;
}

export const GET_DRAFT_PROPOSAL = gql`
  query DraftProposalQuery($draftProposalId: Int!) {
    loggedInUser {
      id
      username
      roles
    }
    draftProposal(id: $draftProposalId) {
      ...DraftProposalFragment
    }
  }
  ${DraftProposalFragment}
`;

export const GET_DRAFT_PROPOSAL_SHALLOW = gql`
  query DraftProposalQuery($draftProposalId: Int!) {
    loggedInUser {
      id
      username
      roles
    }
    draftProposal(id: $draftProposalId) {
      ...DraftProposalShallowFragment
    }
  }
  ${DraftProposalShallowFragment}
`;

export const GET_DRAFT_PROPOSAL_DIRECT_DEAL = gql`
  query DraftProposalDirectDealQuery($draftProposalId: Int!) {
    loggedInUser {
      id
      username
      roles
    }
    draftProposal(id: $draftProposalId) {
      ...DirectDealDraftProposalFragment
    }
  }
  ${DirectDealDraftProposalFragment}
`;

const DraftProposalQuery = ({
  children,
  draftProposalId,
  query
}: DraftProposalArgs & {
  children: (
    result: QueryResult<DraftProposalData, DraftProposalArgs> & {
      draftProposal?: DraftProposal;
    }
  ) => JSX.Element | null;
  query?: any;
}) => (
  <Query<DraftProposalData, DraftProposalArgs>
    query={query || GET_DRAFT_PROPOSAL}
    variables={{ draftProposalId }}
    skip={!draftProposalId}
  >
    {result =>
      children({
        ...result,
        draftProposal: result?.data?.draftProposal
      })
    }
  </Query>
);

export const useDraftProposal = (id?: number) => {
  const { loading, data } = useQuery<DraftProposalData, DraftProposalArgs>(
    GET_DRAFT_PROPOSAL,
    {
      variables: { draftProposalId: id },
      skip: !id
    }
  );
  return { loading, draftProposal: data?.draftProposal };
};

export default DraftProposalQuery;
