import { faCarSide, faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import moment from "moment";
import * as React from "react";
import { useApolloClient } from "react-apollo";
import Helmet from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Jumbotron,
  Row
} from "reactstrap";
import config from "../../config";
import { USER_DEALER } from "../Dealers/UserDealerQuery";
import FileDownloadLink from "../FileDownloadLink";
import { GET_LOGGED_IN_USER, useLoggedInUser } from "../LoggedInUserQuery";
import { PROPOSAL_LIST } from "../Proposals/ProposalList/ProposalListQuery";
import { ActiveProposalStatus, ProposalSearchDate } from "../Proposals/types";
import { QUOTATION_LIST } from "../Quotations/QuotationList/QuotationListQuery";
import SceneLoadingSpinner from "../SceneLoadingSpinner";
import Image_1 from "./Blog_1.jpg";
import Image_2 from "./Blog_2.jpg";
import Image_3 from "./Blog_3.jpg";
import HomeSceneNotifications from "./HomeSceneNotifications";
import "./index.scss";

const dashboardPromise = import(
  "../AccountManagers/AccountManagerScene/UserAccountManagerTargetsTabContent"
);
const Dashboard = React.lazy(() => dashboardPromise);

const usePreloadedData = () => {
  const client = useApolloClient();

  React.useEffect(() => {
    // Preload the logged in user
    client.query({ query: GET_LOGGED_IN_USER });
    // Preload the user's dealer
    client.query({ query: USER_DEALER });
    // Preload the first page of proposals
    client.query({
      query: PROPOSAL_LIST,
      variables: {
        input: {
          fundedDealersOnly: false,
          hasDebitBackOnly: false,
          page: 1,
          pageSize: 10,
          status: ActiveProposalStatus,
          statisticsFilterByDate: true,
          filterByDate: ProposalSearchDate.PAID_OUT_DATE
        }
      }
    });
    // Preload the first page of quotations
    client.query({
      query: QUOTATION_LIST,
      variables: { input: { page: 1, pageSize: 10 } }
    });
  });
};

const HomeScene = () => {
  usePreloadedData();
  const {
    loggedInUser,
    isAccountManager,
    isRegionalSalesManager
  } = useLoggedInUser();

  if (!loggedInUser) {
    return null;
  }

  const isSales = isAccountManager || isRegionalSalesManager;

  return (
    <>
      <Helmet title="Home | Eurodrive" />
      <Container className="home-scene">
        {moment().isSameOrBefore(moment("2020-01-02", "YYYY-MM-DD")) ? (
          <Alert color="info">
            <h2>
              <FontAwesomeIcon icon={faGifts} className="mr-3" />
              Eurodrive festive opening times
            </h2>
            <ul className="mt-3 mb-0">
              <li>Christmas Eve: 9am – 1pm</li>
              <li>
                Christmas Day: <b>Closed</b>
              </li>
              <li>
                Boxing Day: <b>Closed</b>
              </li>
              <li>New Year's Eve: 9am – 3pm</li>
              <li>
                New Year's Day: <b>Closed</b>
              </li>
            </ul>
          </Alert>
        ) : null}

        {isSales && (
          <Alert className="d-block d-md-none" fade={false} color="warning">
            <FontAwesomeIcon icon={faCarSide} className="mr-2" />
            Do not use this site while driving
          </Alert>
        )}
        <Jumbotron
          className={classnames("home-jumbotron mb-3", {
            "d-none": isSales,
            "d-md-block": isSales
          })}
        >
          <Row>
            <Col md="8">
              <div className="homepage-text">
                <h1>Eurodrive motor finance</h1>
                <h2>Dealer portal</h2>
                <div className="lead mt-5 d-flex flex-column flex-sm-row">
                  <RouterLink
                    to="quotations/create"
                    className="btn btn-primary btn-lg mr-sm-3 mb-3"
                  >
                    New finance quotation
                  </RouterLink>
                  <RouterLink
                    to="proposals"
                    className="btn btn-secondary btn-lg mb-3"
                  >
                    Find a proposal
                  </RouterLink>
                </div>
              </div>
            </Col>
          </Row>
        </Jumbotron>
        {isSales ? (
          <React.Suspense fallback={<SceneLoadingSpinner />}>
            <Row className="mb-3">
              <Col lg={4}>
                <h3 style={{ color: "#ff6b3a" }} className="mb-3">
                  Today's activity
                </h3>
                <HomeSceneNotifications />
              </Col>
              <Col lg={8}>
                <Dashboard />
              </Col>
            </Row>
          </React.Suspense>
        ) : null}
        <Row className="mb-3">
          <Col md={4}>
            <Card>
              <CardImg top={true} src={Image_1} />
              <CardBody>
                <CardTitle>FCA compliant</CardTitle>
                <CardText>
                  As one of the first finance companies to be fully authorised
                  by the FCA, Eurodrive Finance has blazed a trail in helping
                  motor dealers understand the effects and implications of new
                  regulation.
                </CardText>
                <FileDownloadLink
                  href={`${config.API_URL}/api/files/salesprocessguide`}
                  target="_blank"
                >
                  Download sales and compliance guide
                </FileDownloadLink>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardImg top={true} src={Image_2} />
              <CardBody>
                <CardTitle>Growing fast</CardTitle>
                <CardText>
                  Nominated as one of the 'Fastest 50' growing companies in the
                  North East and owned by Maxxia UK, Eurodrive Motor Finance is
                  an emerging force within the motor finance sector.
                </CardText>
                <a href="https://www.eurodrivefinance.co.uk/about">Read more</a>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardImg top={true} src={Image_3} />
              <CardBody>
                <CardTitle>Technology first</CardTitle>
                <CardText>
                  We put technology at the forefront of everything we do. From
                  automated responses to e-sign documents, we are constantly
                  striving to deliver the service our dealers demand in record
                  time.
                </CardText>
                <a href="https://www.eurodrivefinance.co.uk/dealer-services/online-decisions">
                  Read more
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          <small className="text-muted">&copy; Eurodrive Motor Finance </small>
          <small className="float-right">
            <RouterLink to="/releasenotes">Release notes</RouterLink>
          </small>
        </div>
      </Container>
    </>
  );
};

export default HomeScene;
