import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { DropdownItem } from "reactstrap";
import { useAuth0 } from "../Auth0Login/Auth0Provider";

interface LogoutDropdownItemProps {
  children: React.ReactNode;
}

const LogoutDropdownItem = ({ children }: LogoutDropdownItemProps) => {
  const { logout, loading } = useAuth0();

  if (loading || !logout) {
    return null;
  }

  return (
    <DropdownItem onClick={logout}>
      <FontAwesomeIcon icon={faSignOutAlt} /> {children}
    </DropdownItem>
  );
};

export default LogoutDropdownItem;
