import { isArray, uniq } from "lodash";
import { Proposal } from "../Proposals/types";
import { cleanFormData } from "../../utils";

export function findWatchedFieldsNotInData(allWatched: any, allValues: any) {
  const inner = (watched: any, values: any, path?: string): string[] => {
    if (!watched || typeof watched !== "object") {
      return [];
    }

    if (isArray(watched)) {
      if (!values) {
        return [path || ""];
      }
      if (isArray(values) && watched.length) {
        return values.reduce((prev: string[], val: any, i: number) => {
          return [...prev, ...inner(watched[0], val, `${path}[${i}]`)];
        }, [] as string[]);
      }
      return [];
    }

    return Object.keys(watched).reduce((prev, k) => {
      if (k === "id") {
        return prev;
      }
      const childPath = path ? `${path}.${k}` : k;
      if (!values?.hasOwnProperty(k)) {
        return [...prev, path ? `${path}.${k}` : k];
      }
      return [...prev, ...inner(watched[k], values[k], childPath)];
    }, [] as string[]);
  };

  return inner(allWatched, allValues);
}

export function findDataValuesNotWatched(allWatched: any, allValues: any) {
  const inner = (watched: any, values: any, path?: string): string[] => {
    if (!values || typeof values !== "object") {
      return [];
    }

    if (isArray(values)) {
      if (watched === true) {
        return [];
      }
      if (!watched || !isArray(watched) || !watched.length) {
        return [path || ""];
      }
      if (values.length) {
        return values.reduce((prev: string[], val: any) => {
          return uniq([...prev, ...inner(watched[0], val, `${path}[0]`)]);
        }, [] as string[]);
      }
      return [];
    }

    return Object.keys(values).reduce((prev, k) => {
      if (k === "id") {
        return prev;
      }
      const childPath = path ? `${path}.${k}` : k;
      if (!watched.hasOwnProperty(k)) {
        return [...prev, path ? `${path}.${k}` : k];
      }
      return [...prev, ...inner(watched[k], values[k], childPath)];
    }, [] as string[]);
  };

  return inner(allWatched, allValues);
}

/** Removes client-only fields from the proposal object before submitting it to the server */
export function cleanProposalBeforeSubmit(values: Proposal) {
  const isMannIslandDealer = values.dealer.isMannIslandDealer;
  const proposal = cleanFormData(values) as Proposal;

  // Remove questions
  // delete proposal.customerPresent;

  // delete proposal.proposalCustomerQuestions.tNC;
  delete proposal.externalSource;
  // delete proposal.cancelType;

  // Remove client-only fields
  delete proposal.autoConvertLenderName;
  delete proposal.vehicle.skipVehicle;
  delete proposal.vehicle.isRegUnknown;
  delete proposal.vehicle.regNoNotFound;
  delete proposal.vehicle.LCV;
  delete proposal.finance.totalFinance;
  delete proposal.finance.commissionCode;
  delete proposal.finance.interestCharges;
  delete proposal.finance.totalCharges;
  delete proposal.finance.balancePayable;
  delete proposal.finance.totalAmountPayable;
  delete proposal.finance.lessRentalDeposit;
  delete proposal.finance.paidOutDate;
  delete proposal.finance.dealerCommission;
  delete proposal.targetBy;
  delete proposal.targetByValue;
  delete proposal.quotationListResult;

  if (isMannIslandDealer) {
    proposal.finance.rate = 0.1;
  }
  if (proposal.isMannIslandDealer != null) {
    delete proposal.isMannIslandDealer;
  }

  delete proposal.dealer;
  delete proposal.createdDate;
  // delete submittedProp.finance;
  delete proposal.proposalType;
  delete proposal.autoConvertReference;

  if (proposal.individualCustomer) {
    delete proposal.individualCustomer.countryOfBirth;
    delete proposal.individualCustomer.nationality;
    delete proposal.individualCustomer.countryOfResidence;
    delete proposal.individualCustomer.countryOfActivity;
    delete proposal.individualCustomer.occupationType;
  }

  if (proposal.business) {
    proposal.business.directors.forEach((director) => {
      delete director.countryOfBirth;
      delete director.nationality;
      delete director.countryOfResidence;
    });
  }

  return proposal;
}
