import gql from "graphql-tag";
import {
  AccountManagerFragment,
  AccountManagerShallowFragment,
} from "../AccountManagers/fragments";

export const DealerFragment = gql`
  fragment DealerFragment on Dealer {
    id
    name
    email
    isSuspended
    url
    mobile
    staticMapUrl
    googleMapsUrl
    hasRecentActivity
    accountManager {
      ...AccountManagerShallowFragment
    }
    groups {
      id
      name
    }
    address {
      id
      line1
      line2
      line3
      town
      county
      postcode
      countryId
      telephone
    }
    location {
      latitude
      longitude
    }
    countOfActiveProposals
    contractSigned
    approvedDate
    autoConvert
    isOnICORegister
    icoNumber
    agreedApr
    previousApr
    franchised
    isMannIslandDealer
    isMannIslandZList
    isRateForRisk
    autoConvertReference
    isMultiQuote
    setupStatus
    documentStorageRef
  }
  ${AccountManagerShallowFragment}
`;

export const SingleDealerFragment = gql`
  fragment SingleDealerFragment on Dealer {
    id
    name
    email
    franchised
    isMannIslandDealer
    isMannIslandZList
    isMultiQuote
    accountManager {
      ...AccountManagerShallowFragment
    }
    isSuspended
  }
  ${AccountManagerShallowFragment}
`;

export const DirectDealDealerFragment = gql`
  fragment DirectDealDealerFragment on Dealer {
    id
    name
  }
`;

export const DealerContactFragment = gql`
  fragment DealerContactFragment on DealerContact {
    id
    dealerId
    title
    forename
    surname
    position
    telephone
    mobile
    fax
    email
    notes
    sMS
    emailInclude
    hasLogin
    isDirector
    directorDateOfBirth
    isFCARegulatedPerson
    isMainContact
    requestsDealerPortalUserAccount
    isSAFTested
    homeAddress {
      id
      line1
      line2
      line3
      town
      county
      postcode
      countryId
    }
  }
`;

export const DealerLocationFragment = gql`
  fragment DealerLocationFragment on Dealer {
    id
    name
    location {
      latitude
      longitude
    }
    address {
      id
      line1
      line2
      line3
      town
      county
      postcode
      countryId
    }
  }
`;

export const ShallowDealerFragment = gql`
  fragment ShallowDealerFragment on Dealer {
    id
    name
    email
    isSuspended
    url
    staticMapUrl
    googleMapsUrl
    accountManager {
      ...AccountManagerShallowFragment
    }
    groups {
      id
      name
    }
    address {
      id
      line1
      line2
      line3
      town
      county
      postcode
      telephone
      countryId
    }
    location {
      latitude
      longitude
    }
    contractSigned
    approvedDate
    autoConvert
  }
  ${AccountManagerShallowFragment}
`;

export const DealerQuestionnaireFragment = gql`
  fragment DealerQuestionnaireFragment on DealerSetupQuestionnaire {
    id
    hasPreviousRelationship
    datePreviousRelationshipClosed
    reasonPreviousRelationshipClosed
    issuesWithDealingAgain
    highWriteOffsOrArrears
    isOnLenderZList
    employeeRelationshipWithEVC
    employeeRelationshipPreviousDealer
    employeeRelationshipPreviousDealerRelationship
    relationshipAdditionalInformation
    longTermLeaseOnPremises
    longTermLeaseOnPremisesDetails
    numberOfVehiclesInStock
    stockReview
    isStockFinanceable
    reasonNotFinanceable
    isMileageWithinLenderPolicy
    highestVehicleMileage
    isAgeWithinUnderwritingPolicy
    highestVehicleAge
    sorStock
    smartAndReasonable
    hasForecourtAndSignage
    reasonNoForecourtOrSignage
    vehiclesPhotographedConsistently
    advertisesSubprimeFinance
    customersFreeToVisit
    hasComplaintsProcess
    complaintsProcessDetails
    reputationConcerns
    negativeReviewsOnWeb
    negativeReviewsOnWebDetails
    negativeReviewsOnSocialMedia
    negativeReviewsOnSocialMediaDetails
    negativeNews
    negativeNewsDetails
    hasGoodReputation
    wasReferred
    referredBy
    positiveAndAppropriate
    directorsAssociatedWithOtherCompanies
    directorOtherCompanyName
    directorOtherCompanyUrl
    directorOtherCompanyRegNumber
    ultimateBeneficialOwners
    approvedPersons
    investors
    otherInterestedParties
    financeProviders
    financeProvidersDuration
    hasVisualShowroomFinance
    whyDoesTheDealerWantToWorkWithEVC
    productSetupPCP
    productSetupLTVLoans
    productSetupSubprime
    productSetupVATAssist
    productSetupZeroPercentSubsidy
    productSetupLenders
  }
`;

export const FullDealerFragment = gql`
  fragment FullDealerFragment on Dealer {
    ...DealerFragment
    accountManagerId
    ccaNumber
    ccaExpiry
    vatNumber
    established
    companyType
    companiesHouseUrl
    companyRegNumber
    tradingStyle
    assetClass
    fCAStatus
    fCAUrl
    netWorthFromCompaniesHouse
    bank {
      id
      bank
      accountNumber
      accountName
      sortCode
      bankAddress
    }
    questionnaire {
      ...DealerQuestionnaireFragment
    }
  }
  ${DealerFragment}
  ${DealerQuestionnaireFragment}
`;

export const DraftDealerFragment = gql`
  fragment DraftDealerFragment on DraftDealer {
    id
    name
    address
    dealerJson
    touchedFields
    currentSection
    created
    updated
    accountManager {
      ...AccountManagerFragment
    }
  }
  ${AccountManagerFragment}
`;

export const DraftDealerShallowFragment = gql`
  fragment DraftDealerShallowFragment on DraftDealer {
    id
    name
    address
    created
    updated
    accountManager {
      ...AccountManagerShallowFragment
    }
  }
  ${AccountManagerShallowFragment}
`;

export const DealerBonafideeEsignFragment = gql`
  fragment DealerBonafideeEsignFragment on DealerBonafideeEsign {
    rowGuid
    autoConvertReference
    created
    esignatureSessionReference
    payload
    status
    sharepointLink
    active
  }
`;

export const LenderRatesForDealersFragment = gql`
  fragment LenderRatesForDealersFragment on LenderRatesForDealers {
    id
    dealerId
    lenderId
    dateFrom
    dateTo
    rate
    commission
    asMoney
    lender {
      id
      name
    }
  }
`;
