import { FormikProps } from "formik";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { cleanFormData } from "../../../utils";
import quotationValidationSchema from "../../Quotations/QuotationForm/quotationValidationSchema";
import {
  TRY_CREATE_QUOTATION,
  TRY_CREATE_INCOMPLETE_QUOTATION,
  TryCreateQuotationData,
  TRY_CREATE_MANN_ISLAND_QUOTATION,
  TryCreateMannIslandQuotationData,
} from "../../Quotations/TryCreateQuotationMutation";
import {
  Quotation,
  QuotationTargetBy,
  QuotationVariables,
} from "../../Quotations/types";
import { Proposal, ProposalFinance } from "../types";

interface ProposalFormQuotationCalculatorProps {
  children: (props: {
    canCreateQuotation: boolean;
    createQuotation: () => void;
    createIncompleteQuotation: () => void;
    loading: boolean;
  }) => JSX.Element | null;
}

const financeFields: (keyof ProposalFinance)[] = [
  "monthlyPayment",
  "rate",
  "aprRate",
  "balloonPayment",
  "acceptanceFee",
  "optionFee",
];

class ProposalFormQuotationCalculator extends React.Component<
  WithApolloClient<
    ProposalFormQuotationCalculatorProps & FormikProps<Proposal>
  >,
  { loading: boolean }
> {
  public constructor(props: any) {
    super(props);
    this.getQuotation = this.getQuotation.bind(this);
    this.cleanQuotation = this.cleanQuotation.bind(this);
    this.state = { loading: false };
  }

  public render() {
    const { children, ...formikProps } = this.props;
    const { loading } = this.state;

    const quotation = this.getQuotation(formikProps.values);
    const { finance, quotationId } = formikProps.values;
    const isMannIslandDealer = !!formikProps.values.dealer?.isMannIslandDealer;

    const hasFinanceValues = financeFields.every(
      (x) => !!finance[x] || finance[x] === 0
    );

    const canCreateQuotation =
      !quotationId &&
      hasFinanceValues &&
      quotationValidationSchema.isValidSync(quotation);

    return children({
      loading,
      canCreateQuotation,
      createQuotation: () =>
        canCreateQuotation
          ? this.createQuotation(quotation, formikProps, isMannIslandDealer)
          : undefined,
      createIncompleteQuotation: () =>
        !canCreateQuotation
          ? this.createIncompleteQuotation(
              quotation,
              formikProps,
              isMannIslandDealer
            )
          : undefined,
    });
  }

  /** Get the variables required for getting a quotation from the server */
  private getQuotation(proposal: Proposal): Quotation {
    let { dealerId, targetBy, individualCustomer, dealer = null } = proposal;

    dealerId == null && (dealerId = dealer?.id);
    targetBy == null && (targetBy = QuotationTargetBy.APR_RATE);
    let targetByValue = this.getTargetValue(proposal);

    const {
      period,
      productType,
      cashPrice,
      cashDeposit,
      partExchange,
      partExchangeSettlement,
    } = proposal.finance;

    const vehicle = cleanFormData(proposal.vehicle);

    const quotation = {
      dealerId,
      title: individualCustomer ? individualCustomer.title : undefined,
      forename: individualCustomer ? individualCustomer.forename : undefined,
      surname: individualCustomer ? individualCustomer.surname : undefined,
      finance: {
        productType,
        term: period,
        cashPrice,
        deposit: cashDeposit,
        partExchangeSettlement,
        partExchangeValue: partExchange,
      },
      skipVehicle: false,
      vehicle,
      targetBy,
      targetByValue,
      isHidden: true,
    };

    return quotation;
  }

  private getTargetValue(proposal: Proposal): number | undefined {
    var targetByValue = proposal.targetByValue;
    targetByValue == null && (targetByValue = proposal?.dealer?.agreedApr);
    targetByValue == null && (targetByValue = proposal?.finance?.aprRate);

    return targetByValue;
  }

  /** Get finance values from the server in response to changes in the form */
  private createQuotation(
    quotation: Quotation,
    formikProps: FormikProps<Proposal>,
    isMannIslandDealer: boolean | undefined
  ) {
    const { client } = this.props;
    const { touched, setFieldValue, setFieldTouched } = formikProps;

    const updateField = (field: string, value?: number | null) => {
      setFieldValue(field as any, value, false);
      setFieldTouched(field as any, true, false);
    };

    const clearFields = () => {
      financeFields.forEach((x) => updateField(`finance.${x}`, null));
      updateField("quotationId", null);
    };

    const isValid = quotationValidationSchema.isValidSync(quotation);

    if (isValid) {
      this.setState({ loading: true });

      const callClient = async (isMannIslandDealer: boolean | undefined) => {
        if (isMannIslandDealer) {
          return client.mutate<
            TryCreateMannIslandQuotationData,
            QuotationVariables
          >({
            mutation: TRY_CREATE_MANN_ISLAND_QUOTATION,
            variables: {
              input: this.cleanQuotation(quotation, isMannIslandDealer),
            },
          });
        } else {
          return client.mutate<TryCreateQuotationData, QuotationVariables>({
            mutation: TRY_CREATE_QUOTATION,
            variables: { input: this.cleanQuotation(quotation) },
          });
        }
      };

      callClient(isMannIslandDealer)
        .then(({ data }: any) => {
          let result = data && data.tryCreateQuotation;
          if (isMannIslandDealer) {
            result = data && data.tryCreateMannIslandQuotation;
          }
          const created = result && result.quotation;
          const unableToQuote = result && result.unableToQuote;

          if (created) {
            updateField(
              "finance.monthlyPayment",
              created.finance.monthlyPayment
            );
            updateField("finance.rate", created.finance.flatRate);
            updateField("finance.aprRate", created.finance.aprRate);
            updateField(
              "finance.balloonPayment",
              created.finance.guaranteedFutureValue
            );
            updateField(
              "finance.acceptanceFee",
              created.finance.arrangementFee
            );
            updateField("finance.optionFee", created.finance.completionFee);
            updateField("quotationId", created.id);
          } else {
            clearFields();
          }

          setFieldValue("FORMSTATE_requiresQuotation" as any, true, false);
          setFieldValue("FORMSTATE_noQuotationResults", !created, false);

          setFieldValue(
            "FORMSTATE_noQuotationResultsReasons",
            unableToQuote ? unableToQuote.messages : null,
            false
          );

          this.setState({ loading: false });
        })
        .then(() => formikProps.validateForm());
    } else {
      setFieldValue("FORMSTATE_noQuotationResults", false, false);
      setFieldValue("FORMSTATE_noQuotationResultsReasons", null, false);
      if (touched && touched.finance) {
        clearFields();
      }
    }
  }

  private createIncompleteQuotation(
    quotation: Quotation,
    formikProps: FormikProps<Proposal>,
    isMannIslandDealer: boolean | undefined
  ) {
    const { client } = this.props;
    const { touched, setFieldValue, setFieldTouched } = formikProps;

    const updateField = (field: string, value?: number | null) => {
      setFieldValue(field as any, value, false);
      setFieldTouched(field as any, true, false);
    };

    const clearFields = () => {
      financeFields.forEach((x) => updateField(`finance.${x}`, null));
      updateField("quotationId", null);
    };

    const isValid = quotationValidationSchema.isValidSync(quotation);

    if (isValid) {
      this.setState({ loading: true });

      const callClient = async (isMannIslandDealer: boolean | undefined) => {
        return client.mutate<TryCreateQuotationData, QuotationVariables>({
          mutation: TRY_CREATE_INCOMPLETE_QUOTATION,
          variables: { input: this.cleanQuotation(quotation) },
        });
      };

      callClient(isMannIslandDealer)
        .then(({ data }: any) => {
          let result = data && data.tryCreateIncompleteQuotation;
          const created = result && result.quotation;
          const unableToQuote = result && result.unableToQuote;

          if (created) {
            updateField(
              "finance.monthlyPayment",
              created.finance.monthlyPayment
            );
            updateField("finance.rate", created.finance.flatRate);
            updateField("finance.aprRate", created.finance.aprRate);
            updateField(
              "finance.balloonPayment",
              created.finance.guaranteedFutureValue
            );
            updateField(
              "finance.acceptanceFee",
              created.finance.arrangementFee
            );
            updateField("finance.optionFee", created.finance.completionFee);
            updateField("quotationId", created.id);
          } else {
            clearFields();
          }

          setFieldValue("FORMSTATE_requiresQuotation" as any, true, false);
          setFieldValue("FORMSTATE_noQuotationResults", !created, false);

          setFieldValue(
            "FORMSTATE_noQuotationResultsReasons",
            unableToQuote ? unableToQuote.messages : null,
            false
          );

          this.setState({ loading: false });
        })
        .then(() => formikProps.validateForm());
    } else {
      setFieldValue("FORMSTATE_noQuotationResults", false, false);
      setFieldValue("FORMSTATE_noQuotationResultsReasons", null, false);
      if (touched && touched.finance) {
        clearFields();
      }
    }
  }

  private cleanQuotation(
    formData: Quotation,
    isMannIslandDealer: boolean | undefined = undefined
  ) {
    const quotation: Quotation = cleanFormData(formData);

    if (quotation.vehicle) {
      delete quotation.vehicle.isRegUnknown;
      delete quotation.vehicle.skipVehicle;
      delete quotation.vehicle.regNoNotFound;
      delete quotation.vehicle.LCV;
      if (!isMannIslandDealer) {
        delete quotation.vehicle.imported;
      }
    }

    return quotation;
  }
}

export default withApollo<
  ProposalFormQuotationCalculatorProps & FormikProps<Proposal>
>(ProposalFormQuotationCalculator);
