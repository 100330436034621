import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { SearchResults, User } from "../../types";
import { ShallowProposalFragment } from "../fragments";
import { Proposal, ProposalSearchArgs } from "../types";

export interface ProposalListData {
  loggedInUser: User & {
    proposals: SearchResults<Proposal>;
  };
}

export const PROPOSAL_LIST = gql`
  query ProposalListQuery(
    $input: ProposalSearchArgsInput
    $countOnly: Boolean = false
  ) {
    loggedInUser {
      id
      username
      roles
      proposals(input: $input, countOnly: $countOnly) {
        pageInfo {
          hasMorePages
          totalResults
          page
          pageSize
          first
          last
        }
        edges {
          node {
            ...ShallowProposalFragment
          }
        }
      }
    }
  }
  ${ShallowProposalFragment}
`;

export const useProposalListCount = (input: ProposalSearchArgs) => {
  const { loading, data } = useQuery<
    ProposalListData,
    { input: ProposalSearchArgs; countOnly?: boolean }
  >(PROPOSAL_LIST, {
    variables: { input, countOnly: true }
  });

  const count = data?.loggedInUser?.proposals?.pageInfo?.totalResults || 0;

  return { loading, count };
};

export const useProposalList = (
  input: ProposalSearchArgs,
  pollInterval?: number
) => {
  const { loading, data } = useQuery<
    ProposalListData,
    { input: ProposalSearchArgs }
  >(PROPOSAL_LIST, {
    variables: { input },
    pollInterval: pollInterval || 0,
    fetchPolicy: "cache-and-network"
  });

  return { loading, proposals: data?.loggedInUser?.proposals };
};
