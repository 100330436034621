import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { User } from "../types";
import { DealerFragment, FullDealerFragment } from "./fragments";
import { Dealer } from "./types";

export interface UserDealerProps {
  loggedInUser: User;
}

export const USER_DEALER = gql`
  query UserDealerQuery {
    loggedInUser {
      id
      username
      isSuspended
      roles
      dealer {
        ...DealerFragment
      }
    }
  }
  ${DealerFragment}
`;

export const USER_DEALER_AND_QUESTIONNAIRE = gql`
  query UserDealerAndQuestionnaireQuery {
    loggedInUser {
      id
      username
      isSuspended
      roles
      dealer {
        ...FullDealerFragment
      }
    }
  }
  ${FullDealerFragment}
`;

const UserDealerQuery = ({
  query,
  children
}: {
  query?: object;
  children: (
    result: QueryResult<UserDealerProps> & {
      dealer?: Dealer;
      loggedInUser?: User;
    }
  ) => JSX.Element | null;
}) => (
  <Query<UserDealerProps> query={query || USER_DEALER}>
    {(result: QueryResult<UserDealerProps>) =>
      children({
        ...result,
        dealer: result?.data?.loggedInUser?.dealer,
        loggedInUser: result?.data?.loggedInUser
      })
    }
  </Query>
);

export default UserDealerQuery;
