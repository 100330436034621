import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "reactstrap";
import { FormSectionFooterProps } from "./FormSectionFooter";
import { CreateOrUpdateMode } from "./MultiSectionForm";

export const FormUpdateButtons = ({
  isSubmitting,
  createOrUpdate,
  isEditing,
  onIsEditingChanged,
  isSectionValid,
  canUpdate,
}: FormSectionFooterProps) => {
  if (createOrUpdate === CreateOrUpdateMode.CREATE) {
    return null;
  }

  return isEditing ? (
    <div className="float-right">
      <Button className="mr-2" outline={true} color="primary" type="submit">
        {isSubmitting && (
          <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
        )}
        Save changes
      </Button>
      <Button color="link" onClick={(e) => onIsEditingChanged(false)}>
        Cancel
      </Button>
      {!isSectionValid && (
        <p className="form-error text-danger small mt-2 mb-0">
          Error in this section
        </p>
      )}
    </div>
  ) : canUpdate !== false ? (
    <Button
      className="float-right"
      color="secondary"
      outline={true}
      onClick={(e) => onIsEditingChanged(true)}
    >
      <FontAwesomeIcon icon={faEdit} className="mr-2" />
      Edit
    </Button>
  ) : null;
};

export default FormUpdateButtons;
