import classnames from "classnames";
import { distanceInWords } from "date-fns";
import moment from "moment";
import * as React from "react";
import { Badge } from "reactstrap";
import {
  convertToTitleCase,
  formatEnumValue,
  getShortDate,
  getShortDateAndTime
} from "../../utils";
import { useLoggedInUser } from "../LoggedInUserQuery";
import { ContextNames } from "../types";
import {
  ProposalProps,
  ProposalStatusEnum,
  ProposalStatusLookup,
  ProposalType
} from "./types";

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const ProposalBadges = ({
  proposal: {
    isDealSaver,
    createdDate,
    status,
    cancelType,
    salesPerson,
    dealer,
    debitBacks,
    proposalType,
    loan,
    autoConvertReference,
    autoConvertLenderName,
    finance: { paidOutDate }
  },
  compact,
  context,
  className
}: ProposalProps & { className?: string; compact?: boolean }) => {
  const { loggedInUser, isDealer, isAccountManager } = useLoggedInUser();
  if (!loggedInUser) {
    return null;
  }

  const isCancelled = !!cancelType || status === ProposalStatusEnum.CANCELLED;

  return (
    <div className={classnames("status-list", className)}>
      {isCancelled && (
        <Badge key="cancelled" color="danger">
          {formatEnumValue(cancelType) || "Cancelled"}
        </Badge>
      )}
      {!isCancelled && ProposalStatusLookup[status] && (
        <Badge key="status" color={ProposalStatusLookup[status].color}>
          {ProposalStatusLookup[status].description}
          {paidOutDate && status === ProposalStatusEnum.PAID_OUT
            ? ` ${getShortDate(paidOutDate)}`
            : null}
        </Badge>
      )}
      {debitBacks && debitBacks.length && !compact ? (
        <Badge color="danger" key="debit-back">
          Debit back
        </Badge>
      ) : null}{" "}
      {!autoConvertReference && !isDealer && !compact ? (
        <Badge key="eurodealer" color="info" className="info-muted">
          EuroDealer
        </Badge>
      ) : null}
      {(loan?.lender || autoConvertLenderName) &&
        status !== ProposalStatusEnum.UNDERWRITING &&
        !compact && (
          <Badge key="lender" color="info" className="info-muted">
            {autoConvertLenderName || loan?.lender?.name}
          </Badge>
        )}
      {isDealSaver && !compact ? (
        <Badge key="deal-saver">Dealsaver</Badge>
      ) : null}
      {proposalType === ProposalType.BUSINESS && (
        <Badge key="proposal-type">Business</Badge>
      )}
      {salesPerson && !compact ? (
        <Badge key="sales-person">
          {convertToTitleCase(salesPerson)} (sales)
        </Badge>
      ) : null}
      {dealer?.accountManager &&
      context !== ContextNames.ACCOUNT_MANAGER &&
      !isAccountManager &&
      !isDealer &&
      !compact ? (
        <Badge key="account-manager">
          {convertToTitleCase(dealer.accountManager.name)} (AM)
        </Badge>
      ) : null}
      {createdDate ? <Badge>{getShortDateAndTime(createdDate)}</Badge> : null}
      {createdDate && moment(createdDate).isSame(moment(), "day") ? (
        <Badge key="created-date">{`${capitalizeFirstLetter(
          distanceInWords(new Date(), createdDate)
        )} ago`}</Badge>
      ) : null}
    </div>
  );
};

export default ProposalBadges;
