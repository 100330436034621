import { Field, FormikProps } from "formik";
import * as React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { getSingleLineAddress } from "../../utils";
import FormInputField from "../Forms/FormInputField";
import CountrySelect from "../Settings/Countries/CountrySelect";
import { Address } from "../types";
import AddressLookup from "./";

interface AddressFormSectionProps extends FormikProps<any> {
  pathToAddress?: string;
  address: Address;
  title?: string;
  autoFocus?: boolean;
  colSize?: number;
  readOnly?: boolean;
}

interface AddressFormSectionState {
  edit?: boolean;
}

class AddressFormSection extends React.Component<
  AddressFormSectionProps,
  AddressFormSectionState
> {
  constructor(props: AddressFormSectionProps) {
    super(props);
    this.clearAddress = this.clearAddress.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.hasAddress = this.hasAddress.bind(this);
    this.state = {};
  }

  public render() {
    const { pathToAddress, autoFocus, colSize, readOnly } = this.props;
    const edit = this.state.edit && !readOnly;
    const path = pathToAddress ? `${pathToAddress}.` : "";
    const hasAddress = this.hasAddress();
    const hasError = this.hasError();
    const title = this.props.title;

    return (
      <>
        <Row key="address-lookup" hidden={!(!hasAddress && !edit)}>
          <Col lg={colSize || 8}>
            <FormGroup className={hasError ? "is-invalid" : ""}>
              {title ? <Label>{title}</Label> : null}
              <AddressLookup
                key="address-lookup"
                onSelectAddress={this.handleSelectAddress}
                autoFocus={autoFocus}
                readOnly={readOnly}
              />

              <div className="invalid-feedback">
                {hasError ? "Address required" : null}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <div hidden={!edit}>
          <Row>
            <Field
              colSize={5}
              name={`${path}line1`}
              type="text"
              title={title}
              placeholder="Line 1"
              component={FormInputField}
            />
          </Row>
          <Row>
            <Field
              colSize={5}
              name={`${path}line2`}
              type="text"
              placeholder="Line 2"
              component={FormInputField}
            />
          </Row>
          <Row>
            <Field
              colSize={5}
              name={`${path}line3`}
              type="text"
              placeholder="Line 3"
              component={FormInputField}
            />
          </Row>
          <Row>
            <Field
              colSize={5}
              name={`${path}town`}
              type="text"
              placeholder="Town"
              component={FormInputField}
            />
          </Row>
          <Row>
            <Field
              colSize={5}
              name={`${path}county`}
              type="text"
              placeholder="County"
              component={FormInputField}
            />
          </Row>
          <Row>
            <Field
              colSize={5}
              name={`${path}postcode`}
              type="text"
              placeholder="Postcode"
              component={FormInputField}
            />
          </Row>
          <Row>
            <CountrySelect
              className="col-lg-5 mb-3"
              countryId={this.props.address.countryId}
              onSelectCountry={countryId => {
                this.props.setFieldValue(`${path}countryId`, countryId);
                this.props.setFieldTouched(`${path}countryId`, true);
              }}
            />
          </Row>
        </div>

        <FormGroup hidden={!hasAddress}>
          {title ? <Label>{title}</Label> : null}
          <p className="form-control-static">
            {getSingleLineAddress(this.props.address)}
          </p>
        </FormGroup>

        <Row className="mb-3" style={{ marginTop: "-0.5rem" }}>
          <Col lg={5}>
            {hasAddress || edit ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-link pl-0"
                  onClick={this.clearAddress}
                >
                  Clear address
                </button>
                {!edit ? "| " : null}
              </>
            ) : null}
            {!edit && !readOnly ? (
              <button
                type="button"
                className="btn btn-sm btn-link pl-0"
                onClick={this.toggleEditMode}
              >
                Enter address manually
              </button>
            ) : null}
          </Col>
        </Row>
      </>
    );
  }

  private hasAddress() {
    const { address } = this.props;
    return (
      address &&
      (address.line1 ||
        address.line2 ||
        address.line3 ||
        address.town ||
        address.county ||
        address.postcode)
    );
  }

  private hasError() {
    const { pathToAddress, touched, errors } = this.props;
    const path = pathToAddress ? `${pathToAddress}.` : "";

    const propNames = ["line1", "line2", "line3", "town", "postcode"];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < propNames.length; i += 1) {
      const pathArray = `${path}${propNames[i]}`.split(".");
      const isTouched = pathArray.reduce((o, n) => o && o[n], {
        ...touched
      } as any);
      const error =
        (isTouched &&
          pathArray.reduce((o, n) => o && o[n], { ...errors } as any)) ||
        "";
      if (error) {
        return true;
      }
    }
    return false;
  }

  private clearAddress(e?: any) {
    const { setFieldValue, pathToAddress } = this.props;
    const path = pathToAddress ? `${pathToAddress}.` : "";

    e && e.preventDefault();

    this.setState({ edit: false });

    [
      "line1",
      "line2",
      "line3",
      "county",
      "town",
      "postcode",
      "countryId"
    ].map(x => setFieldValue(`${path}${x}`, ""));
  }

  private toggleEditMode(e?: any) {
    e && e.preventDefault();
    this.setState(s => ({ edit: !s.edit }));
  }

  private handleSelectAddress(selectedAddress?: Address) {
    const { setFieldValue, pathToAddress, setFieldTouched } = this.props;
    const path = pathToAddress ? `${pathToAddress}.` : "";
    if (selectedAddress) {
      [
        "line1",
        "line2",
        "line3",
        "town",
        "county",
        "postcode",
        "countryId"
      ].forEach(x => {
        setFieldValue(`${path}${x}`, (selectedAddress as any)[x] || "");
        setFieldTouched(`${path}${x}`, true);
      });
    } else {
      this.clearAddress();
    }
  }
}

export default AddressFormSection;
