import classnames from "classnames";
import { Field, FieldProps } from "formik";
import * as React from "react";
import { Col } from "reactstrap";
import FormGroupWrapper from "./FormGroupWrapper";
import "./formstyles.scss";

const CheckboxField = <TValues extends {}>({
  name,
  description,
  options,
  title,
  colSize,
  vertical,
  readOnly
}: {
  name: string;
  description?: string;
  title?: string;
  colSize?: number;
  options: { label: any; value: any }[];
  vertical?: boolean;
  readOnly?: boolean;
}) => (
  <Field name={name}>
    {({ form, field }: FieldProps<TValues>) => (
      <>
        <Col lg={colSize}>
          <FormGroupWrapper
            form={form}
            field={field}
            title={title}
            description={description}
            className={classnames("checkbox-field", {
              vertical,
              horizontal: !vertical
            })}
          >
            <div>
              {options.map(opt => (
                <div
                  key={opt.value}
                  className={classnames("custom-control custom-checkbox", {
                    "custom-control-inline": !vertical
                  })}
                >
                  <input
                    id={`${name}_${opt.value}`}
                    type="checkbox"
                    className="custom-control-input"
                    checked={field.value.includes(opt.value)}
                    value={opt.value}
                    name={name}
                    readOnly={!!readOnly}
                    onChange={e => {
                      let nextValue: string[];
                      if (field.value.includes(opt.value)) {
                        nextValue = (field.value as string[]).filter(
                          value => value !== opt.value
                        );
                        form.setFieldValue(field.name, nextValue);
                      } else {
                        nextValue = field.value.concat(opt.value);
                        form.setFieldValue(name, nextValue);
                      }
                      form.setFieldTouched(field.name, true);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${name}_${opt.value}`}
                  >
                    {opt.label}
                  </label>
                </div>
              ))}
            </div>
          </FormGroupWrapper>
        </Col>
      </>
    )}
  </Field>
);

export default CheckboxField;
