import * as Yup from "yup";

export const mobileValidationSchema = Yup.string()
  .default(undefined)
  .min(11, "Mobile number must be at least 11 characters")
  .max(11)
  .nullable(true)
  .matches(
    /^07\d*$/,
    "Mobile number has to start with '07' and contain numbers only"
  )
  .transform((value) => (value || value === 0 ? value : null));

export default mobileValidationSchema;
