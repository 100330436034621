import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApolloClient } from "apollo-boost";
import * as React from "react";
// tslint:disable-next-line:no-submodule-imports
import { ValueType } from "react-select/src/types";
import CustomSelect from "../Forms/CustomSelect";
import { Address } from "../types";
import AddressAutocompleteFindQuery from "./AddressAutocompleteFindQuery";
import {
  ADDRESS_AUTOCOMPLETE_RETRIEVE,
  AddressAutocompleteRetrieveData
} from "./AddressAutocompleteRetrieveQuery";

interface AddressLookupProps {
  selectedAddress?: Address;
  onSelectAddress: (address?: Address) => void;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
}

interface AddressLookupState {
  query?: string;
  pathFilter?: { label: string; value: string }[] | null;
  menuIsOpen?: boolean;
  retrieving?: boolean;
}

interface AddressLookupOption {
  label: string;
  shortLabel: string;
  value: string;
  type: string;
}

// tslint:disable-next-line:max-classes-per-file
class AddressLookup extends React.Component<
  AddressLookupProps,
  AddressLookupState
> {
  private select: any;

  constructor(props: AddressLookupProps) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    const { placeholder, autoFocus, readOnly } = this.props;
    const { query, pathFilter, menuIsOpen } = this.state;

    const lastPathFilter =
      pathFilter && pathFilter.length
        ? pathFilter[pathFilter.length - 1].value
        : undefined;

    return (
      <AddressAutocompleteFindQuery
        input={{ query, pathFilter: lastPathFilter }}
      >
        {({ data, client, loading }) => {
          const options =
            data && data.addressAutocomplete && data.addressAutocomplete.find
              ? data.addressAutocomplete.find.map(x => ({
                  label: `${x.summaryLine}${x.locationSummary ? ", " : ""}${
                    x.locationSummary
                  }${
                    x.count > 1
                      ? ` (${x.count > 100 ? "100+" : x.count} addresses)`
                      : ""
                  }`,
                  shortLabel: `${x.summaryLine}${
                    x.locationSummary ? ", " : ""
                  }${x.locationSummary}`,
                  value: x.id,
                  type: x.type
                }))
              : [];

          if (pathFilter && pathFilter.length) {
            const lastItem = pathFilter[pathFilter.length - 1];
            const removeFilterOption = {
              label: `< Remove filter: ${lastItem.label}`,
              shortLabel: "",
              value: "",
              type: "REMOVE_FILTER"
            };
            options.unshift(removeFilterOption);
          }

          if (this.state.retrieving) {
            return (
              <p className="form-control-static">
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </p>
            );
          }

          return (
            <CustomSelect
              isDisabled={readOnly}
              ref={(ref: any) => {
                this.select = ref;
              }}
              menuIsOpen={menuIsOpen || false}
              key="address-lookup"
              options={options}
              inputValue={query}
              onInputChange={(value: any, meta: any) => {
                if (value && !menuIsOpen) {
                  this.toggleMenuIsOpen();
                }

                if (meta.action !== "set-value") {
                  this.setState({ query: value });
                }
              }}
              blurInputOnSelect={false}
              onChange={(value: any) => this.handleChange(value, client)}
              filterOption={() => true}
              closeMenuOnSelect={false}
              onBlur={() => menuIsOpen && this.toggleMenuIsOpen()}
              isClearable={false}
              placeholder={placeholder || "Start typing to find an address"}
              noOptionsMessage={() =>
                loading ? "Loading..." : "No addresses found"
              }
              autoFocus={autoFocus}
            />
          );
        }}
      </AddressAutocompleteFindQuery>
    );
  }

  private toggleMenuIsOpen() {
    this.setState(s => ({
      menuIsOpen: !s.menuIsOpen,
      pathFilter: s.menuIsOpen ? s.pathFilter : undefined
    }));
    if (this.select) {
      !this.state.menuIsOpen ? this.select.focus() : this.select.blur();
    }
  }

  private handleChange(
    value: ValueType<AddressLookupOption>,
    client: ApolloClient<any>
  ) {
    const option = value as AddressLookupOption;

    if (option && option.type === "REMOVE_FILTER") {
      this.setState(s => {
        const f =
          s.pathFilter && s.pathFilter.length ? [...(s.pathFilter || [])] : [];
        f.pop();
        return { pathFilter: f };
      });
    } else if (option && option.type !== "ADD") {
      if (option.value) {
        this.setState(s => ({
          pathFilter: [
            ...(s.pathFilter || []),
            { label: option.shortLabel, value: option.value }
          ]
        }));
      }
    } else {
      if (option && option.value) {
        this.setState({ retrieving: true });
        client
          .query<AddressAutocompleteRetrieveData>({
            query: ADDRESS_AUTOCOMPLETE_RETRIEVE,
            variables: { input: { id: option.value, query: this.state.query } }
          })
          .then(({ data }) => {
            if (
              data &&
              data.addressAutocomplete &&
              data.addressAutocomplete.retrieve
            ) {
              this.props.onSelectAddress(data.addressAutocomplete.retrieve);
            }
          })
          .finally(() => this.setState({ retrieving: false }));
      }
      if (this.select) {
        this.select.blur();
      }
      this.setState({
        menuIsOpen: false,
        pathFilter: null,
        query: ""
      });
    }
  }
}

export default AddressLookup;
