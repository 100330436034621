import {
  faChevronDown,
  faChevronUp,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray } from "formik";
import React from "react";
import { Button } from "reactstrap";
import {
  formatEnumValue,
  getMonthsAndYearsText,
  getSingleLineAddress,
  hasAddress,
} from "../../../utils";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import { BusinessDirector, DirectorOwnerStatus, Proposal } from "../types";
import DirectorsDetailsSection from "./DirectorsDetailsSection";

export const directorsSectionWatchFields: WatchFields<Proposal> = {
  business: {
    directors: [
      {
        title: true,
        forename: true,
        middleName: true,
        surname: true,
        dOB: true,
        email: true,
        earnings: true,
        guarantor: true,
        mobile: true,
        owner: true,
        otherStatus: true,
        homeYears: true,
        homeMonths: true,
        countryOfBirthId: true,
        nationalityId: true,
        countryOfResidenceId: true,
        maritalStatus: true,
        numOfDependants: true,
        drivingLicense: true,
        address: {
          line1: true,
          line2: true,
          line3: true,
          town: true,
          county: true,
          postcode: true,
          countryId: true,
          totalMonthlyRentOrMortgage: true,
        },
      },
    ],
  },
};

const ValidateAddressDates = (director: BusinessDirector) => {
  return (
    (director.homeMonths || director.homeMonths === 0) &&
    (director.homeYears || director.homeYears === 0)
  );
};

const validateDirectorDetails = (director: BusinessDirector) => {
  return !!(
    director.title &&
    director.forename &&
    director.surname &&
    director.dOB &&
    director.email &&
    hasAddress(director.address) &&
    director.owner &&
    ValidateAddressDates
  );
};

const ShortDirectorSummary = ({ director }: { director: BusinessDirector }) => {
  return (
    <>
      <p className="mb-1 text-muted">
        {director.title && director.forename && director.surname ? (
          <span className="mr-3">
            Name: {`${director.title} ${director.forename} ${director.surname}`}
          </span>
        ) : null}
        {director.dOB ? <span>DOB: {director.dOB}</span> : null}
      </p>
      <p className="mb-1 text-muted">
        {director.email ? (
          <span className="mr-3">Email: {director.email}</span>
        ) : null}
        {director.mobile ? <span>Mobile: {director.mobile}</span> : null}
      </p>
      {hasAddress(director.address) ? (
        <p className="mb-1 text-muted">
          Address: {getSingleLineAddress(director.address)}
        </p>
      ) : null}
      <p className="mb-1 text-muted">
        {director.owner && director.owner !== DirectorOwnerStatus.OTHER ? (
          <span className="mr-3">
            Home ownership: {formatEnumValue(director.owner)}
          </span>
        ) : director.owner === DirectorOwnerStatus.OTHER ? (
          director.otherStatus ? (
            <span className="mr-3">Home ownership: {director.otherStatus}</span>
          ) : null
        ) : null}
        {(director.homeMonths || director.homeMonths === 0) &&
        (director.homeYears || director.homeYears === 0) ? (
          <span>
            {getMonthsAndYearsText(
              director.homeMonths + director.homeYears * 12
            )}
          </span>
        ) : null}
      </p>
    </>
  );
};
class DirectorsSection extends React.Component<
  FormSectionProps<Proposal>,
  { collapsedItems: number[] }
> {
  constructor(props: FormSectionProps<Proposal>) {
    super(props);
    this.collapseDirector = this.collapseDirector.bind(this);
    this.expandDirector = this.expandDirector.bind(this);

    this.state = { collapsedItems: [] };
    const { business } = this.props.values;
    if (business.directors.length > 1) {
      business.directors.forEach((_, i) => {
        this.state.collapsedItems.push(i);
      });
    }
  }

  public render() {
    const { collapsedItems } = this.state;
    const { business } = this.props.values;

    const formikProps = this.props;
    return (
      <>
        <FieldArray name="business.directors">
          {(arrayHelpers) => (
            <div>
              {business.directors &&
                business.directors.map((_, i) => {
                  const isOpen = collapsedItems.indexOf(i) === -1;
                  return (
                    <div key={i}>
                      <h5
                        className="my-3 section-title pointer"
                        onClick={
                          isOpen
                            ? () => this.collapseDirector(i)
                            : () => this.expandDirector(i)
                        }
                      >
                        Director / Partner - {i + 1}
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={isOpen ? faChevronUp : faChevronDown}
                        />
                      </h5>
                      <div className="mb-4">
                        {isOpen ? (
                          <>
                            <DirectorsDetailsSection
                              {...formikProps}
                              index={i}
                              business={business}
                            />
                            {i > 0 ? (
                              <Button
                                type="button"
                                color="link"
                                className="pl-0 small"
                                onClick={() => arrayHelpers.remove(i)}
                              >
                                Remove this director
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          <div
                            className="pointer"
                            onClick={() => this.expandDirector(i)}
                          >
                            <ShortDirectorSummary
                              director={business.directors[i]}
                            />
                            {!validateDirectorDetails(business.directors[i]) ? (
                              <p className="text-danger small mt-2 pb-3">
                                Director details section is incomplete
                              </p>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {business.directors.length === 1 ? (
                <Button
                  className="mb-2"
                  type="button"
                  onClick={() => {
                    arrayHelpers.push({ address: {} });
                    this.collapseDirector(business.directors.length - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                  Add another director
                </Button>
              ) : null}
            </div>
          )}
        </FieldArray>
      </>
    );
  }

  private collapseDirector(i: number) {
    const array = this.state.collapsedItems.slice();
    if (array.indexOf(i) === -1) {
      array.push(i);

      this.setState({
        collapsedItems: array,
      });
    }
  }

  private expandDirector(i: number) {
    const array = this.state.collapsedItems.slice();
    array.splice(array.indexOf(i), 1);

    this.setState({
      collapsedItems: array,
    });
  }
}

export default DirectorsSection;
