import * as React from "react";

const FollowUpQuestions = ({
  children,
  className,
  show
}: {
  children: React.ReactNode;
  show?: boolean;
  className?: string;
}) =>
  show ? (
    <div className={`${className ? className : ""} follow-up-questions`}>
      {children}
    </div>
  ) : null;

export default FollowUpQuestions;
