import gql from "graphql-tag";
import { useQuery } from "react-apollo";

const GET_COUNTRY = gql`
  query GetCountry($id: ID!) {
    country(id: $id) {
      id
      name
    }
  }
`;

const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      id
      name
    }
  }
`;

interface Country {
  id: string;
  name: string;
}

export const useCountry = (id?: string) => {
  const { data } = useQuery<{ country: Country }, { id?: string }>(
    GET_COUNTRY,
    {
      variables: { id },
      skip: !id
    }
  );

  return data?.country;
};

export const useCountries = () => {
  const { data } = useQuery<{ countries: Country[] }>(GET_COUNTRIES);

  return data?.countries;
};
