import gql from "graphql-tag";
import { UserShallowFragment } from "../Settings/Users/fragments";

export const AnnouncementFragment = gql`
  fragment AnnouncementFragment on Announcement {
    id
    title
    messageMarkdown
    buttonText
    createdDate
    maxDismissedCount
  }
`;

export const ShallowAnnouncementWebUserFragment = gql`
  fragment ShallowAnnouncementWebUserFragment on AnnouncementWebUser {
    id
    announcementId
    userId
    confirmed
    confirmedDate
    dismissedCount
    announcement {
      ...AnnouncementFragment
    }
  }
  ${AnnouncementFragment}
`;

export const AnnouncementWebUserFragment = gql`
  fragment AnnouncementWebUserFragment on AnnouncementWebUser {
    id
    announcementId
    userId
    confirmed
    confirmedDate
    dismissedCount
    user {
      ...UserShallowFragment
    }
    announcement {
      ...AnnouncementFragment
    }
  }
  ${UserShallowFragment}
  ${AnnouncementFragment}
`;
