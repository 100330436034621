import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";

export interface IndustryListData {
  industryList: string[];
}

export const INDUSTRY_LIST = gql`
  query IndustryListQuery {
    industryList
  }
`;

const IndustryListQuery = ({
  children,
}: {
  children: (result: QueryResult<IndustryListData>) => JSX.Element | null;
}) => <Query<IndustryListData> query={INDUSTRY_LIST}>{children}</Query>;

export const useIndustryList = () => {
  const { loading, data } = useQuery<IndustryListData>(INDUSTRY_LIST);

  return { loading, industryList: data };
};

export default IndustryListQuery;
