import { SearchResults } from "../types";

export interface PermissionRole {
  id: string;
  description: string;
  permissions: SearchResults<Permission>;
}

export interface PermissionModule {
  id: string;
  description: string;
  permissions: SearchResults<Permission>;
}

export interface Permission {
  id: string;
  permissionModuleId: string;
  permissionRoleId: string;
  role: PermissionRole;
  module: PermissionModule;
  activities: string[];
  availableActivities: string[];
}

export enum PermissionModuleNames {
  AccountManagers = "AccountManagers",
  AccountManagerTargets = "AccountManagerTargets",
  RegionalSalesManagers = "RegionalSalesManagers",
  Proposals = "Proposals",
  AutoConvertProposalNotes = "AutoConvertProposalNotes",
  DraftProposals = "DraftProposals",
  ProposalFiles = "ProposalFiles",
  Dealers = "Dealers",
  DraftDealers = "DraftDealers",
  DealerContacts = "DealerContacts",
  Quotations = "Quotations",
  Lenders = "Lenders",
  Loans = "Loans",
  Users = "Users",
  Alerts = "Alerts",
  Announcements = "Announcements",
  Permissions = "Permissions",
  AccountManagerActivity = "AccountManagerActivity",
  DirectDeal = "DirectDeal",
}

export enum PermissionActivityNames {
  Create = "Create",
  Read = "Read",
  Update = "Update",
  Delete = "Delete",
  Unlock = "Unlock",
  UpdateDraft = "UpdateDraft",
  ReadInternal = "ReadInternal",
  ReadSensitive = "ReadSensitive",
  UpdateInternal = "UpdateInternal",
  ReadReport = "ReadReport",
  ReadListReport = "ReadListReport",
  ReadManagementReport = "ReadManagementReport",
  SignIDDForm = "SignIDDForm",
}
