import classnames from "classnames";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { debounce, isArray, isEqual, merge } from "lodash";
import memoizeOne from "memoize-one";
import * as React from "react";
import { Col, Form, Row } from "reactstrap";
import { ContextNames } from "../types";
import AutoGeneratedFormSectionSummary from "./AutoGeneratedFormSectionSummary";
import FormNav, { FormNavItemProps } from "./FormNav";
import FormSectionFooter, { FormSectionFooterProps } from "./FormSectionFooter";
import FormSectionWrapper from "./FormSectionWrapper";
import { findDataValuesNotWatched, findWatchedFieldsNotInData } from "./utils";
import { DirectDeal } from "../Proposals/types";

interface MultiSectionFormProps<Values>
  extends UncontrolledMultiSectionFormProps<Values> {
  section?: string;
  onSectionChanged: (section: string) => void;
  canUpdate?: boolean;
  context?: ContextNames;
}

export enum CreateOrUpdateMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

interface UncontrolledMultiSectionFormProps<Values> extends SaveDraftProps {
  submitButtonText?: string;
  sections: FormSectionInfo<Values>[];
  className?: string;
  createOrUpdate: CreateOrUpdateMode;
  populateModal?: (dealerList: Array<any>) => void | null;
}

export interface SaveDraftProps {
  savingDraft?: boolean;
  draftLastSaved?: string;
  draftValidationMessage?: string;
  saveDraft?: () => Promise<any>;
}

export interface DirectDealProps {
  saveDirectDeal?: (directDeal: DirectDeal) => Promise<any>;
  directDealValidationMessage?: string;
}

interface MultiSectionFormState {
  section?: string;
}

export type FormSectionProps<Values> = {
  section: FormSectionInfo<Values>;
  navigateToSection: (section: string) => void;
  isSectionTouched: boolean;
  context?: ContextNames;
  populateModal?: (dealerList: Array<any>) => void | null;
} & FormikProps<Values>;

export interface FormSectionSummaryProps<Values> {
  section: FormSectionInfo<Values>;
  values: Values;
}

export interface FormSectionInfo<Values> {
  id: string;
  title: string;
  subtitle?: string;
  watchFields: WatchFields<Values>;
  component: React.ComponentType<FormSectionProps<Values>>;
  summaryComponent?: React.ComponentType<FormSectionSummaryProps<Values>>;
}

export type WatchFields<Values> = {
  [K in keyof Values]?: Values[K] extends object
    ? WatchFields<Values[K]>
    : true;
};

/** Compare two arrays of arguments for deep equality */
function argsEqual(newArgs: any[], lastArgs: any[]) {
  return lastArgs.every((x, i) => isEqual(x, newArgs[i]));
}

class MultiSectionForm<TFormValues> extends React.Component<
  FormikProps<TFormValues> & MultiSectionFormProps<TFormValues>,
  { isEditing: boolean }
> {
  constructor(
    props: FormikProps<TFormValues> & MultiSectionFormProps<TFormValues>
  ) {
    super(props);
    this.navigateToSection = this.navigateToSection.bind(this);
    this.navigateToNextSection = this.navigateToNextSection.bind(this);
    this.isSectionValid = this.isSectionValid.bind(this);
    this.isSectionTouched = this.isSectionTouched.bind(this);
    this.getIsSectionTouchedLookup = memoizeOne(
      this.getIsSectionTouchedLookup.bind(this),
      argsEqual
    );
    this.getIsSectionValidLookup = memoizeOne(
      this.getIsSectionValidLookup.bind(this),
      argsEqual
    );
    this.touchSection = this.touchSection.bind(this);
    this.touchAllSections = this.touchAllSections.bind(this);
    this.getSectionTouched = this.getSectionTouched.bind(this);
    this.handleIsEditingChanged = this.handleIsEditingChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Use a long debounce for warning about the sections
    this.validateSections = debounce(this.validateSections.bind(this), 10000, {
      leading: true,
      trailing: false,
    });

    this.state = {
      isEditing: props.createOrUpdate === CreateOrUpdateMode.CREATE,
    };
  }

  public componentDidMount() {
    const { createOrUpdate: editMode, validateForm } = this.props;
    if (editMode === CreateOrUpdateMode.UPDATE) {
      this.touchAllSections();
      setTimeout(validateForm, 0);
    }
  }

  public componentDidUpdate(
    prevProps: FormikProps<TFormValues> & MultiSectionFormProps<TFormValues>
  ) {
    const { section, sections } = this.props;
    if (
      sections.length &&
      section &&
      !sections.find(({ id }) => id === section)
    ) {
      this.navigateToSection(sections[0].id);
    }
  }

  public render() {
    const {
      sections,
      className,
      submitButtonText,
      section,
      onSectionChanged,
      createOrUpdate,
      savingDraft,
      draftLastSaved,
      draftValidationMessage,
      saveDraft,
      canUpdate,
      populateModal,
      ...formikProps
    } = this.props;

    const { errors, touched } = formikProps;

    const currentSectionId = section || sections[0].id;
    const sectionInfo = sections.find((s) => s.id === currentSectionId);

    // Show a warning message if there are problems in the section watched fields
    if (process && process.env && process.env.NODE_ENV === "development") {
      this.validateSections();
    }

    if (!sectionInfo || !currentSectionId) {
      return null;
    }

    const isLastSection = sections.indexOf(sectionInfo) === sections.length - 1;
    const isSectionTouchedLookup: {
      [val: string]: boolean;
    } = this.getIsSectionTouchedLookup(sections, touched);

    const isSectionValidLookup: {
      [val: string]: boolean;
    } = this.getIsSectionValidLookup(sections, touched, errors);

    const sectionHeaders: FormNavItemProps[] = sections.map(
      ({ id, title }) => ({
        section: id,
        activeSection: currentSectionId,
        title,
        onClick: this.navigateToSection,
        isSectionValid: isSectionValidLookup[id],
        isSectionTouched: isSectionTouchedLookup[id],
      })
    );

    const SectionComponent = sectionInfo.component;
    const { isEditing } = this.state;

    const footerProps: FormSectionFooterProps = {
      isLastSection,
      onSubmitForm: () => {
        formikProps.submitForm();
        return new Promise((resolve) => setTimeout(resolve, 0));
      },
      isSubmitting: formikProps.isSubmitting,
      submitButtonText,
      dirty: formikProps.dirty,
      isValid: formikProps.isValid,
      isSectionValid: isSectionValidLookup[sectionInfo.id],
      createOrUpdate,
      isEditing,
      onIsEditingChanged: this.handleIsEditingChanged,
      onCompleteSection: this.navigateToNextSection,
      savingDraft,
      draftLastSaved,
      draftValidationMessage,
      saveDraft,
      canUpdate,
    };

    const sectionProps: FormSectionProps<TFormValues> = {
      ...formikProps,
      section: sectionInfo,
      navigateToSection: this.navigateToSection,
      isSectionTouched: isSectionTouchedLookup[sectionInfo.id],
    };

    const Summary =
      sectionInfo.summaryComponent || AutoGeneratedFormSectionSummary;

    return (
      <Form
        onSubmit={this.handleSubmit}
        className={classnames("multi-section-form", className)}
        autoComplete="off"
      >
        <Row>
          <Col lg={3}>
            <FormNav
              sections={sectionHeaders}
              currentSection={currentSectionId}
              isEditing={isEditing}
            />
          </Col>
          <Col lg={9}>
            <FormSectionWrapper
              id={sectionInfo.id}
              title={sectionInfo.title}
              subtitle={sectionInfo.subtitle}
              {...footerProps}
            >
              <>
                {isEditing ? (
                  <>
                    <SectionComponent
                      populateModal={populateModal}
                      context={
                        this.props.context
                          ? this.props.context
                          : ContextNames.UPDATE_FORM
                      }
                      {...sectionProps}
                    />
                    <FormSectionFooter {...footerProps} />
                  </>
                ) : (
                  <Summary
                    section={sectionInfo}
                    values={this.props.initialValues}
                  />
                )}
              </>
            </FormSectionWrapper>
          </Col>
        </Row>
      </Form>
    );
  }

  private handleIsEditingChanged(isEditing: boolean) {
    this.setState({ isEditing });
    if (isEditing) {
      this.touchAllSections();
    } else {
      setTimeout(this.props.handleReset, 0);
    }
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    const { isValid, handleSubmit, createOrUpdate, resetForm, values } =
      this.props;
    const { isEditing } = this.state;

    if (isValid && isEditing) {
      handleSubmit(e);

      if (createOrUpdate === CreateOrUpdateMode.UPDATE) {
        this.setState({ isEditing: false });
        // Reset the form initial values to the current values
        resetForm(values);
      }
    } else {
      e.preventDefault();
    }
  }

  private validateSections() {
    const { sections, values: allValues } = this.props;

    const allWatched = sections.reduce((prev, section) => {
      return merge(prev, section.watchFields);
    }, {});

    const missingValues = findWatchedFieldsNotInData(allWatched, allValues);
    if (missingValues.length) {
      // tslint:disable-next-line:no-console
      console.warn(
        `Some watched fields are not present in the data: ${missingValues.join(
          ", "
        )}`
      );
    }
    const missingWatched = findDataValuesNotWatched(allWatched, allValues);
    if (missingWatched.length) {
      // tslint:disable-next-line:no-console
      console.warn(
        `Some data values are not being watched in a form section: ${missingWatched.join(
          ", "
        )}`
      );
    }
  }

  private navigateToSection(nextSection: string) {
    const { section, sections, onSectionChanged } = this.props;
    const { isEditing } = this.state;

    const currentSection = section
      ? sections.find((x) => x.id === section)
      : sections[0];

    if (currentSection && isEditing) {
      this.touchSection(currentSection);
    }

    onSectionChanged(nextSection);
  }

  private getIsSectionTouchedLookup(
    sections: FormSectionInfo<TFormValues>[],
    touched: FormikTouched<TFormValues>
  ): { [val: string]: boolean } {
    return sections.reduce(
      (obj, val) => ({
        ...obj,
        [val.id]: this.isSectionTouched(val, touched),
      }),
      {}
    );
  }

  private getIsSectionValidLookup(
    sections: FormSectionInfo<TFormValues>[],
    touched: FormikTouched<TFormValues>,
    errors: FormikErrors<TFormValues>
  ): { [val: string]: boolean } {
    return sections.reduce(
      (obj, val) => ({
        ...obj,
        [val.id]: this.isSectionValid(val, errors, touched),
      }),
      {}
    );
  }

  private isSectionTouched(
    section: FormSectionInfo<TFormValues>,
    touched: FormikTouched<TFormValues>
  ) {
    const findTouched = (innerFields: any, innerTouched: any): boolean => {
      const keys = Object.keys(innerFields);
      return (
        keys.length > 0 &&
        keys.every((k) => {
          const t = innerTouched && innerTouched[k];
          if (t === true || (isArray(t) && t.every((x) => x === true))) {
            return true;
          }
          if (t) {
            return findTouched(innerFields[k], t);
          }
          return false;
        })
      );
    };

    return findTouched(section.watchFields, touched);
  }

  private isSectionValid(
    section: FormSectionInfo<TFormValues>,
    errors: FormikErrors<TFormValues>,
    touched: FormikTouched<TFormValues>
  ) {
    const findErrors = (
      innerFields: any,
      innerErrors: any,
      innerTouched: any
    ): boolean =>
      Object.keys(innerFields)
        .filter(
          (k) =>
            Object.keys(innerErrors).includes(k) &&
            (!innerTouched || Object.keys(innerTouched).includes(k))
        )
        .some((k) => {
          const field = innerFields[k];
          const err = innerErrors[k];
          const t = innerTouched && innerTouched[k];
          // Error found!
          if ((!innerTouched || t === true) && typeof err === "string") {
            return true;
          }

          if (
            err &&
            field &&
            Array.isArray(field) &&
            field.length &&
            Array.isArray(err)
          ) {
            // Find any child elements with errors
            return err.some(
              (e, i) => e && findErrors(field[0], e, t ? t[i] : undefined)
            );
          }
          if (field && err && t) {
            return findErrors(field, err, t);
          }
          return false;
        });

    return !findErrors(section.watchFields || {}, errors, touched);
  }

  private getSectionTouched(
    watchFields: WatchFields<any>,
    values: TFormValues
  ) {
    return Object.keys(watchFields).reduce((obj, key) => {
      const watch = (watchFields as any)[key];
      const value = values ? (values as any)[key] : undefined;

      if (watch === true) {
        obj[key] = true;
      } else if (Array.isArray(watch) && watch.length) {
        obj[key] = value.map((x: any) => this.getSectionTouched(watch[0], x));
      } else {
        obj[key] = this.getSectionTouched(watch, value);
      }
      return obj;
    }, {} as any);
  }

  private touchSection(section: FormSectionInfo<TFormValues>) {
    const { values, touched, setTouched } = this.props;
    const watchFields = section.watchFields || {};
    const touchedFields = merge(
      {},
      this.getSectionTouched(watchFields, values),
      touched
    );

    setTouched(touchedFields as FormikTouched<TFormValues>);
  }

  private touchAllSections() {
    const { values, setTouched, touched } = this.props;
    const allTouched: FormikTouched<TFormValues> = this.props.sections.reduce(
      (prev: any, section) =>
        merge(prev, this.getSectionTouched(section.watchFields, values)),
      {}
    );

    merge(allTouched, touched);

    setTouched(allTouched);
  }

  private navigateToNextSection() {
    const { submitForm, sections, section } = this.props;

    const currentSection = section
      ? sections.find((x) => x.id === section)
      : sections[0];

    if (!currentSection) {
      return Promise.resolve();
    }

    const index = sections.indexOf(currentSection);
    const isLastSection = index === sections.length - 1;

    this.touchSection(currentSection);

    return Promise.resolve()
      .then(() => {
        if (!this.props.dirty) {
          return this.props.validateForm() as Promise<any>;
        }
        return Promise.resolve();
      })
      .then(() => {
        const watchFields = currentSection.watchFields || {};
        if (
          this.isSectionValid(currentSection, this.props.errors, watchFields)
        ) {
          if (isLastSection) {
            !this.props.isSubmitting && submitForm();
          } else {
            this.navigateToSection(sections[index + 1].id);
          }
        }
      });
  }
}

// tslint:disable-next-line:max-classes-per-file
export class UncontrolledMultiSectionForm<Values> extends React.Component<
  FormikProps<Values> & UncontrolledMultiSectionFormProps<Values>,
  MultiSectionFormState
> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <MultiSectionForm
        {...this.props}
        onSectionChanged={(section) => this.setState({ section })}
        section={this.state.section}
      />
    );
  }
}

export default MultiSectionForm;
