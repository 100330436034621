import {
  faExclamationTriangle,
  faSave,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikProps } from "formik";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { getFullName, getSingleLineVehicle } from "../../../utils";
import { GET_DIRECT_DEAL_DEALER, useDealer } from "../../Dealers/DealerQuery";
import { DirectDealProps, SaveDraftProps } from "../../Forms/MultiSectionForm";
import {
  GET_LOGGED_IN_USER_SHALLOW,
  useLoggedInUser,
} from "../../LoggedInUserQuery";
import EmailButton from "../../shared/EmailButton";
import { ContextNames, User } from "../../types";
import { Proposal } from "../types";
import { PermissionModuleNames } from "../../Permissions/types";
import DirectDealModal from "../DirectDeals/DirectDealModal";
import { useState } from "react";

interface DirectDealModalState {
  showDirectDealModal: boolean;
}

interface ProposalFormHeaderProps extends SaveDraftProps, DirectDealProps {
  formikProps: FormikProps<Proposal>;
  loggedInUser: User;
  draftProposalId?: number;
  context?: ContextNames;
  directDealValidationMessage?: string;
}

const ProposalFormHeader = ({
  formikProps,
  saveDraft,
  saveDirectDeal,
  savingDraft,
  draftValidationMessage,
  context,
  directDealValidationMessage,
}: ProposalFormHeaderProps) => {
  const {
    isDealer,
    isAccountManager,
    loading: loadingUser,
    loggedInUser,
    checkCanUpdate,
  } = useLoggedInUser(
    context === ContextNames.DIRECT_DEAL ? GET_LOGGED_IN_USER_SHALLOW : null
  );

  const { values } = formikProps;
  const { dealer } = useDealer(
    values.dealerId,
    context === ContextNames.DIRECT_DEAL ? GET_DIRECT_DEAL_DEALER : null
  );

  const [modalState, setModalState] = useState<DirectDealModalState>({
    showDirectDealModal: false,
  });

  const { showDirectDealModal } = modalState;

  const toggleDirectDealModal = () => {
    setModalState({ showDirectDealModal: !showDirectDealModal });
  };

  const [showAlert, setShowAlert] = useState(false);
  const toggleAlert = () => setShowAlert(!showAlert);

  if (loadingUser) {
    return null;
  }

  if (dealer) {
    values.dealer = dealer;
  }

  const customerName = values.individualCustomer
    ? getFullName(values.individualCustomer)
    : values.business.name;

  const accountManagerEmail = dealer?.accountManager?.email;

  return (
    <>
      <div>
        <h1 className="mb-3">
          New proposal
          {customerName ? (
            <span className="text-secondary"> - {customerName}</span>
          ) : null}
        </h1>
      </div>
      {context !== ContextNames.DIRECT_DEAL && dealer && !isDealer && (
        <p className="mb-2" style={{ position: "relative", display: "block" }}>
          <RouterLink to={`/dealers/${dealer.id}/proposals`}>
            {dealer.name}
          </RouterLink>
        </p>
      )}
      {dealer?.isSuspended && (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Dealer {dealer.name} is suspended, so new proposals cannot be
          submitted
        </Alert>
      )}
      {dealer?.isOnICORegister === false && (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Dealer {dealer.name} is not on the Information Commissioner's Office
          (ICO) register, so proposals cannot be submitted.{" "}
          {isDealer ? "Please contact your Eurodrive account manager." : null}
        </Alert>
      )}
      {loggedInUser?.canSubmitProposals === false && (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Eurodrive is not currently accepting finance proposals. Please contact
          your account manager for more information.
        </Alert>
      )}
      {context !== ContextNames.DIRECT_DEAL && (
        <div className="action-buttons">
          <Button
            outline={true}
            id="saveDraft"
            onClick={saveDraft}
            disabled={!!draftValidationMessage}
          >
            <FontAwesomeIcon
              icon={savingDraft ? faSpinner : faSave}
              fixedWidth={true}
              spin={savingDraft}
              className="mr-2"
            />
            Save as draft
          </Button>
          {!isDealer && (
            <EmailButton
              to={dealer?.email || ""}
              cc={`salessupport@eurodrivefinance.co.uk${
                !isAccountManager && accountManagerEmail
                  ? `;${accountManagerEmail}`
                  : ""
              }`}
              subject={`Draft proposal${
                customerName ? ` for ${customerName}` : ""
              }`}
              buttonText="Contact dealer"
              outline={true}
            />
          )}
          {!isAccountManager && (
            <EmailButton
              to={accountManagerEmail ? accountManagerEmail : ""}
              cc="salessupport@eurodrivefinance.co.uk"
              subject={`Draft proposal${
                customerName ? ` for ${customerName}` : ""
              }${dealer ? `, ${dealer.name}` : ""}`}
              buttonText="Contact account manager"
              outline={true}
            />
          )}
          {checkCanUpdate(PermissionModuleNames.DirectDeal) &&
          values.individualCustomer ? (
            <>
              <Button
                className="btn btn-info"
                onClick={() => setModalState({ showDirectDealModal: true })}
              >
                Direct Deal
              </Button>
              <DirectDealModal
                key="dd-modal"
                toggle={toggleDirectDealModal}
                display={showDirectDealModal}
                toggleAlert={toggleAlert}
                values={{
                  title: values.individualCustomer?.title || "",
                  forename: values.individualCustomer?.forename || "",
                  middleName: values.individualCustomer?.middleName || "",
                  surname: values.individualCustomer?.surname || "",
                  customerEmail: values.individualCustomer?.email || "",
                  dealerId: values.dealer?.id,
                  dealerName: values.dealer?.name,
                  vehicle: getSingleLineVehicle(values.vehicle),
                  totalMileage: values.vehicle?.mileage,
                  annualMileage: values.vehicle?.maxAnnualMileage,
                  period: values.finance.period,
                  monthlyPayment: values.finance.monthlyPayment,
                  productType: values.finance.productType,
                  salesPerson: values.salesPerson,
                  distanceSelling: values.distanceSelling,
                }}
                saveDirectDeal={saveDirectDeal}
                disabledConfirmButton={!!directDealValidationMessage}
              />
              {showAlert && (
                <Alert
                  color="success"
                  fade={true}
                  toggle={() => setShowAlert(false)}
                >
                  The direct deal has been initiated
                </Alert>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ProposalFormHeader;
