import { FormikProps } from "formik";
import * as React from "react";
import { Proposal } from "../types";
import {
  QuotationListResult,
  QuotationResults,
  QuotationTargetBy,
} from "../../Quotations/types";
import { QuotationResultListItemProps } from "../../Quotations/QuotationForm/QuotationResultList";
import { getLowestPayment } from "../../Quotations/QuotationForm/MultiQuoteResultTable";
import { useEffect, useState } from "react";
import { Badge, Col, Row } from "reactstrap";
import FlipMove from "react-flip-move";
import { formatCurrency } from "../../../utils";

interface ProposalMultiQuoteResultProps {
  loading?: boolean;
  multiQuoteResult?: QuotationListResult;
  targetBy?: QuotationTargetBy;
  onSelectResult: (result: QuotationResults | null) => void;
}

const ProposalMultiQuoteRow = ({
  result: {
    finance,
    arrangementFee,
    completionFee,
    interestCharges,
    term,
    monthlyPayment,
    aprRate,
    commissionCode,
    lenderName,
  },
  lowestProductPayment,
  showCommission,
  targetBy,
}: QuotationResultListItemProps) => (
  <>
    <Col lg={7} className="d-flex align-items-center product-lender">
      <div className="custom-header">{lenderName}</div>
    </Col>
    <Col lg={5} className="text-left py-3">
      <div className="font-weight-normal">
        Monthly Payment: {formatCurrency(monthlyPayment)}
      </div>

      <p>
        Total Amount Payable:{" "}
        {formatCurrency(
          finance + arrangementFee + completionFee + interestCharges
        )}
      </p>
      <p>Term {term} months</p>
      <div className="d-inline">
        APR {(Math.round(aprRate * 100) / 100).toFixed(2)}%
      </div>
      <div className="d-inline">
        {monthlyPayment === lowestProductPayment &&
        targetBy !== QuotationTargetBy.MONTHLY_PAYMENT ? (
          <Badge color="success" className="ml-3">
            {" "}
            Lowest
          </Badge>
        ) : null}
      </div>
      {showCommission && (
        <p className="text-muted">
          <small>{commissionCode}</small>
        </p>
      )}
    </Col>
  </>
);

const ProposalMultiQuoteResult = ({
  loading,
  multiQuoteResult,
  targetBy,
  props,
  onSelectResult,
}: ProposalMultiQuoteResultProps & { props: FormikProps<Proposal> }) => {
  const { values } = props;
  const results = multiQuoteResult?.results;

  let filteredByTermResults = results?.filter(
    (x) => x.term === values.finance.period
  );

  const NoItemsPlaceholder = () => (
    <div className="no-items-header">No results found</div>
  );

  const productTypes = ["LP", "HP", "PCP"];

  const lowestPayment = productTypes.map((p) => {
    return getLowestPayment(
      filteredByTermResults?.filter((r) => r.productType === p)
    );
  });

  const sortByNameDesc = (a: QuotationResults, b: QuotationResults) => {
    let textA = a.lenderName?.toUpperCase() || "";
    let textB = b.lenderName?.toUpperCase() || "";
    return textA < textB ? 1 : textA > textB ? -1 : 0;
  };

  const sortByLowest = (a: QuotationResults, b: QuotationResults) => {
    return (
      lowestPayment.indexOf(b.monthlyPayment) -
      lowestPayment.indexOf(a.monthlyPayment)
    );
  };

  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    setActiveIndex(-1);
    onSelectResult(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.finance, values.finance.productType]);

  return results ? (
    <>
      <Col>
        {!filteredByTermResults?.length ? (
          <>{NoItemsPlaceholder()}</>
        ) : (
          <div>
            <Row key="multi-header">
              <Col className="custom-header mt-3 mb-3">
                Select Your Lender Quote
              </Col>
            </Row>

            <Col>
              <FlipMove typeName={null}>
                {filteredByTermResults
                  .sort((a, b) => {
                    return sortByLowest(a, b) || sortByNameDesc(a, b);
                  })
                  .map((r, i) => {
                    const lowest = getLowestPayment(
                      filteredByTermResults?.filter(
                        (p) => p.productType === r.productType
                      )
                    );

                    return (
                      <div
                        key={`${r.productType}_${r.term}_${r.lenderId}_proposal_row`}
                        onClick={() => {
                          setActiveIndex(i);
                          onSelectResult(r);
                        }}
                        className={
                          "row custom-row" +
                          (activeIndex === i ? " selected" : "")
                        }
                      >
                        <ProposalMultiQuoteRow
                          result={r}
                          lowestProductPayment={lowest}
                          onSelectResult={() => onSelectResult(r)}
                          loading={loading}
                        />
                      </div>
                    );
                  })}
              </FlipMove>
            </Col>
          </div>
        )}
      </Col>
    </>
  ) : null;
};

export default ProposalMultiQuoteResult;
