import classnames from "classnames";
import { Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { compose, onlyUpdateForKeys } from "recompose";
import { getSingleLineVehicle } from "../../../utils";
import FormFieldWrapper from "../../Forms/FormFieldWrapper";
import FormInputField from "../../Forms/FormInputField";
import RadioField from "../../Forms/RadioField";
import SelectField from "../../Forms/SelectField";
import VehicleLookupField from "../../Quotations/QuotationForm/VehicleLookupField";
import VehicleMakeField from "../../Quotations/QuotationForm/VehicleMakeField";
import VehicleModelField from "../../Quotations/QuotationForm/VehicleModelField";
import VehicleStyleField from "../../Quotations/QuotationForm/VehicleStyleField";
import { ContextNames } from "../../types";
import { Proposal, Vehicle, VehicleTypeEnum } from "../types";
import AgeInMonthsField from "./AgeInMonthsField";

const vehicleTypeOptions: { label: any; value: any }[] = Object.values(
  VehicleTypeEnum
).map((x) => ({
  label: x,
  value: x,
}));

type VehicleSectionProps = FormikProps<{ vehicle: Vehicle }> & {
  autoFocus?: boolean;
  context?: ContextNames;
};

const VehicleRegistrationField = (fieldProps: FieldProps<Proposal>) => (
  <FormFieldWrapper {...fieldProps} colSize={5}>
    <VehicleLookupField {...fieldProps} />
  </FormFieldWrapper>
);

class VehicleSection extends React.Component<VehicleSectionProps> {
  public render() {
    const { setFieldValue, autoFocus, values, context } = this.props;
    const isRegKnown = !values.vehicle.isRegUnknown;

    const {
      vehicle: { regNo, cAP, make, model, vatQualifying, vehicleType, isNew },
    } = this.props.values;

    return (
      <>
        <Row>
          <SelectField
            colSize={5}
            name={`vehicle.vehicleType`}
            title="Vehicle type"
            options={vehicleTypeOptions}
          />
        </Row>
        <Row>
          <RadioField
            title="Used or new"
            name="vehicle.isNew"
            options={[
              { label: "Used", value: false },
              { label: "New", value: true },
            ]}
          />
        </Row>
        {isRegKnown ? (
          <>
            <Row className={classnames({ "d-none": !!cAP })}>
              <Field
                name="vehicle.regNo"
                title="Vehicle registration"
                colSize={5}
                component={VehicleRegistrationField}
                autoFocus={autoFocus}
              />
            </Row>
            <Row className={classnames({ "d-none": !cAP })}>
              <Col md={8}>
                <FormGroup className="mb-1">
                  <h4 className="vehicle-registration-static my-2">
                    {(regNo || "").toUpperCase()}
                  </h4>
                  <p className="mb-0">{getSingleLineVehicle(values.vehicle)}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={9}>
                {regNo && cAP ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-sm btn-link pl-0"
                      onClick={(e) => {
                        e.preventDefault();

                        [
                          "vehicle.regNo",
                          "vehicle.make",
                          "vehicle.model",
                          "vehicle.derivative",
                          "vehicle.cAP",
                          "vehicle.capId",
                        ].map((x) => setFieldValue(x, null));

                        setFieldValue("vehicle.dateOfRegistration", null);
                      }}
                    >
                      Clear registration
                    </button>
                    {!!isNew && " | "}
                  </>
                ) : null}

                {!!isNew && context !== ContextNames.QUOTATION_FORM && (
                  <button
                    type="button"
                    className="btn btn-sm btn-link pl-0"
                    onClick={(e) => {
                      e.preventDefault();

                      [
                        "vehicle.regNo",
                        "vehicle.make",
                        "vehicle.model",
                        "vehicle.derivative",
                        "vehicle.cAP",
                        "vehicle.capId",
                      ].map((x) => setFieldValue(x, null));

                      setFieldValue("vehicle.dateOfRegistration", null);
                      setFieldValue("vehicle.isRegUnknown", true);
                    }}
                  >
                    Enter vehicle details manually...
                  </button>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            {vehicleType !== VehicleTypeEnum.Motorhome ? (
              <>
                <Row>
                  <RadioField
                    name="vehicle.isCommercial"
                    title="Is it a commercial vehicle?"
                    colSize={6}
                    options={[
                      {
                        label: "Non commercial",
                        value: false,
                      },
                      { label: "Commercial", value: true },
                    ]}
                    autoFocus={autoFocus}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.make"
                    placeholder="Vehicle make"
                    component={(fieldProps: any) => (
                      <FormFieldWrapper title="Vehicle details" {...fieldProps}>
                        <VehicleMakeField {...fieldProps} />
                      </FormFieldWrapper>
                    )}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.model"
                    placeholder="Vehicle model"
                    component={(fieldProps: any) => (
                      <FormFieldWrapper {...fieldProps}>
                        <VehicleModelField {...fieldProps} />
                      </FormFieldWrapper>
                    )}
                    make={make}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.derivative"
                    placeholder="Vehicle style"
                    component={(fieldProps: any) => (
                      <FormFieldWrapper {...fieldProps}>
                        <VehicleStyleField {...fieldProps} />
                      </FormFieldWrapper>
                    )}
                    make={make}
                    model={model}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={4}
                    title="Age in months"
                    component={AgeInMonthsField}
                    name="vehicle.dateOfRegistration"
                  />
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.make"
                    placeholder="Vehicle make"
                    component={FormInputField}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.model"
                    placeholder="Vehicle model"
                    component={FormInputField}
                    make={make}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={6}
                    name="vehicle.derivative"
                    placeholder="Vehicle variant"
                    component={FormInputField}
                    make={make}
                  />
                </Row>
                <Row>
                  <Field
                    colSize={4}
                    title="Age in months"
                    component={AgeInMonthsField}
                    name="vehicle.dateOfRegistration"
                  />
                </Row>
              </>
            )}
            <Row className="mb-3">
              <Col md={6}>
                <button
                  className="btn btn-sm btn-link pl-0"
                  onClick={(e) => {
                    e.preventDefault();
                    [
                      "vehicle.make",
                      "vehicle.model",
                      "vehicle.derivative",
                      "vehicle.cAP",
                      "vehicle.capId",
                    ].map((x) => setFieldValue(x, ""));
                    setFieldValue("vehicle.isRegUnknown", false);
                  }}
                >
                  Find vehicle by registration...
                </button>
              </Col>
            </Row>
          </>
        )}
        <>
          <Row>
            <Col>
              <div className="custom-control custom-checkbox mb-3">
                <input
                  id="vatQualifying"
                  name="vehicle.vatQualifying"
                  type="checkbox"
                  className="custom-control-input pointer"
                  checked={!!vatQualifying}
                  onChange={(e) => {
                    this.props.setFieldValue(
                      "vehicle.vatQualifying",
                      e.target.checked
                    );
                  }}
                />
                <label
                  htmlFor="vatQualifying"
                  className="custom-control-label pointer"
                >
                  VAT Qualifying
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Field
              name="vehicle.mileage"
              type="number"
              min={0}
              placeholder="Total mileage"
              title="Mileage"
              component={FormInputField}
              units="Miles"
              unitsPosition="after"
              colSize={5}
            />
          </Row>
          <Row>
            <Field
              name="vehicle.maxAnnualMileage"
              type="number"
              placeholder="Max mileage p/a"
              min={0}
              title="Max annual mileage"
              component={FormInputField}
              units="Miles"
              unitsPosition="after"
              colSize={5}
            />
          </Row>
        </>
      </>
    );
  }
}

export default compose<VehicleSectionProps, VehicleSectionProps>(
  onlyUpdateForKeys(["values"])
)(VehicleSection);
