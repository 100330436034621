import gql from "graphql-tag";
import { useMutation } from "react-apollo";
interface SendSuitabilityData {
  success: boolean;
}

interface SendSuitabilityVariables {
  id: number;
}

export const SuitabilityFragment = gql`
  fragment SuitabilityFragment on Suitability {
    id
    dealerId
    proposalId
    title
    forename
    middleName
    surname
    email
    address {
      line1
      line2
      line3
      town
      county
      postcode
      countryId
    }
    pxAnotherVehicle
    outstandingFinance
    outstandingMonthlyPayment
    existingFinanceCompany
    settlementFigure
    pxValuation
    voluntaryAgreement
    voluntaryAgreementConfirm
    halfAmountMade
    financialAdvantage
    equityFunding
    customerType
    vehicleAge
    vehicleChange
    deferLoanConfirmation
    financeType
    financeCompany
    financeCompanyReason
    financeCompanySelectConfirmation
    alternativeDeclines
    topUpLoanRequired
    agreedPrice
    agreedDeposit
    lowDepositConfirmation
    keepVehicleMonths
    agreementOverMonths
    acceptanceFee
    optionToPurchaseFee
    initialPayment
    monthlyPayments
    finalPayment
    annualApr
    agreedMaximumMileage
    excessMileageCharge
    leftoverFinance
    isCustomerHappyWithFinance
    hasCustomerBeenDeclinedBefore
  }
`;

export const CREATE_OR_UPDATE_SUITABILITY = gql`
  mutation CreateOrUpdateSuitability($input: SuitabilityInput) {
    createOrUpdateSuitability(input: $input) {
      ...SuitabilityFragment
    }
  }
  ${SuitabilityFragment}
`;

export const SEND_SUITABILITY_FORM = gql`
  mutation SendSuitabilityForm($id: ID!) {
    sendSuitabilityForm(id: $id) {
      success
    }
  }
`;

export const useSendSuitabilityForm = () => {
  const [sendSuitabilityForm] = useMutation<
    SendSuitabilityData,
    SendSuitabilityVariables
  >(SEND_SUITABILITY_FORM);

  return sendSuitabilityForm;
};

export default () => ({});
