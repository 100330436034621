import classnames from "classnames";
import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { FormGroup, Label } from "reactstrap";

const FormGroupWrapper = ({
  className,
  field,
  form,
  title,
  description,
  children
}: FieldProps<any> & {
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  const { errors, touched } = form;
  const isTouched = get(touched, field.name);
  const error = isTouched ? get(errors, field.name) : null;
  const descriptionNode =
    description && typeof description === "string" ? (
      <small className="form-text text-info">{description}</small>
    ) : (
      description
    );

  return (
    <FormGroup
      className={classnames(className, {
        "is-invalid": !!error
      })}
    >
      {title ? <Label for={field.name}>{title}</Label> : null}
      {children}
      <div className="invalid-feedback">{error}</div>
      {descriptionNode || null}
    </FormGroup>
  );
};

export default FormGroupWrapper;
