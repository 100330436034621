import axios from "axios";
import * as React from "react";
import config from "../config";
import { withAlerts } from "./Alerts/AlertsProvider";
import { AlertColor, AlertsProps } from "./Alerts/types";

const VERSION_CHECK_INTERVAL = config.VERSION_CHECK_INTERVAL_MINS * 1000 * 60;

class VersionChecker extends React.Component<
  AlertsProps,
  { version?: string }
> {
  private interval?: any;

  public constructor(props: AlertsProps) {
    super(props);
    this.checkVersion = this.checkVersion.bind(this);
    this.state = {};
  }

  public componentDidMount() {
    this.checkVersion();
    this.interval = setInterval(this.checkVersion, VERSION_CHECK_INTERVAL);
  }

  public componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  public render() {
    return null;
  }

  private checkVersion() {
    axios
      .get("/version.json", { headers: { "Cache-Control": "no-cache" } })
      .then(r => {
        const { version } = this.state;
        if (r.data && r.data.version) {
          if (version && r.data.version !== version) {
            this.props.addAlert({
              color: AlertColor.warning,
              message:
                "A new version of the dealer portal has been released.  Make sure your work is saved, then click here to refresh your browser.",
              onClick: () => (window as any).location.reload(true)
            });
          }
        }
        this.setState({ version: r.data.version });
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.error("Error finding current app version");
      });
  }
}

export default withAlerts<{}>(VersionChecker);
