import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import querystring from "query-string";
import React from "react";
import { Button } from "reactstrap";

interface MailtoProps {
  to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}

interface EmailButtonProps extends MailtoProps {
  buttonText: string;
  className?: string;
  outline?: boolean;
  disabled?: boolean;
}

const buildMailtoUrl = ({ to, body, ...querystringProps }: MailtoProps) => {
  let url: string | undefined;
  if (to) {
    url = `mailto:${encodeURIComponent(to)}`;

    let qs = querystring.stringify(querystringProps);

    // Special case for the body, because url encoding mangles line breaks
    if (body) {
      let safeBody = encodeURIComponent(body);
      // Replace url encoded /n with %0D%0A, to make line breaks work
      safeBody = safeBody.replace(/%2Fn/g, "%0D%0A");
      qs = qs ? `${qs}&body=${safeBody}` : `body=${safeBody}`;
    }

    if (qs) {
      url = `${url}?${qs}`;
    }
  }
  return url;
};

export const EmailButton = ({
  buttonText,
  className,
  outline,
  disabled,
  ...mailtoProps
}: EmailButtonProps) => {
  const url = buildMailtoUrl(mailtoProps);

  return (
    <Button
      href={url}
      outline={!!outline}
      className={className}
      disabled={!url}
    >
      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
      {buttonText}
    </Button>
  );
};

export default EmailButton;
