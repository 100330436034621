import moment from "moment";
import * as React from "react";
import { usePageVisibility } from "react-page-visibility";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { getFullName } from "../../utils";
import { useLoggedInUser } from "../LoggedInUserQuery";
import ProposalBadges from "../Proposals/ProposalBadges";
import { useProposalList } from "../Proposals/ProposalList/ProposalListQuery";
import { Proposal, ProposalSearchArgs } from "../Proposals/types";

const getName = (proposal: Proposal) =>
  proposal.individualCustomer
    ? getFullName(proposal.individualCustomer)
    : proposal.business.name;

const HomeSceneNotifications = () => {
  const startDate =
    process && process.env && process.env.NODE_ENV === "development"
      ? moment("2019-04-01")
      : moment();

  const searchArgs: ProposalSearchArgs = {
    startDate: startDate.startOf("day").toISOString(true),
    pageSize: 5
  };
  const isVisible = usePageVisibility();
  const { loggedInUser } = useLoggedInUser();
  const { proposals } = useProposalList(
    searchArgs,
    isVisible ? 30000 : undefined
  );
  const history = useHistory();

  if (!proposals || !loggedInUser) {
    return null;
  }

  return (
    <>
      <div className="mb-2">
        {proposals.edges.map(({ node: item }) => (
          <div
            className="entity-list-item"
            key={item.id}
            onClick={() => history.push(`/proposals/${item.proposalRef}`)}
          >
            <div className="">
              <p className="">
                <b>{getName(item) || "[no name]"}</b>{" "}
                <span className="small text-muted">
                  {item.vehicle.regNo
                    ? `${item.vehicle.regNo.toUpperCase()}, `
                    : null}
                  {item.vehicle.make || item.vehicle.model
                    ? `${item.vehicle.make} ${item.vehicle.model}`
                    : "[vehicle unknown]"}
                </span>
              </p>
              <p className="small">{item.dealer?.name}</p>
            </div>
            <ProposalBadges
              proposal={item}
              loggedInUser={loggedInUser}
              compact={true}
            />
          </div>
        ))}
      </div>
      {proposals.pageInfo.hasMorePages ? (
        <RouterLink
          className="btn btn-sm btn-outline-secondary mb-3"
          to="/proposals"
        >
          {(proposals.pageInfo.totalResults || 0) -
            (proposals.pageInfo.pageSize || 0)}{" "}
          more proposals today
        </RouterLink>
      ) : null}
    </>
  );
};

export default HomeSceneNotifications;
