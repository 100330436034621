import { ApolloClient } from "apollo-boost";
import * as React from "react";
import Helmet from "react-helmet";
import AlertsProvider from "./Alerts/AlertsProvider";
import AppRouter from "./AppRouter";
import Auth0Provider from "./Auth0Login/Auth0Provider";
import DealerPortalApolloProvider from "./DealerPortalApolloProvider";
import ConfirmDialogProvider from "./Forms/ConfirmDialogProvider";
import TawkToLoader from "./TawkToLoader";
import VersionChecker from "./VersionChecker";

import lightFont from "../fonts/open-sans-v15-latin-300.woff2";
import normalFont from "../fonts/open-sans-v15-latin-regular.woff2";
import boldFont from "../fonts/open-sans-v17-latin-700.woff2";

export interface AppProps {
  apolloClient: ApolloClient<any>;
}

const App = () => (
  <>
    <Helmet>
      <link
        rel="preload"
        href={lightFont}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={normalFont}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={boldFont}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
    <AlertsProvider>
      <Auth0Provider>
        <DealerPortalApolloProvider>
          <VersionChecker />
          <TawkToLoader key="tawk-to" />
          <ConfirmDialogProvider>
            <AppRouter />
          </ConfirmDialogProvider>
        </DealerPortalApolloProvider>
      </Auth0Provider>
    </AlertsProvider>
  </>
);

export default App;
