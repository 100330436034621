import classnames from "classnames";
import * as React from "react";
import { formatCurrency } from "../../utils";
import "./InformationTable.scss";

export const InformationTableRow = (props: {
  title: string;
  value?: any;
  valueHighlighted?: boolean;
  valueAlignRight?: boolean;
}) =>
  props.value || props.value === 0 ? (
    <>
      <tr
        className={classnames({ highlighted: props.valueHighlighted }, "mt-3")}
      >
        <th>{props.title}</th>
        <td
          className={classnames({
            "font-weight-bold": props.valueHighlighted,
            "text-right": props.valueAlignRight
          })}
        >
          {props.value}
        </td>
      </tr>
    </>
  ) : null;

export const CurrencyRow = ({
  title,
  value,
  highlighted
}: {
  title: string;
  value?: number;
  highlighted?: boolean;
}) => (
  <InformationTableRow
    title={title}
    value={formatCurrency(value)}
    valueAlignRight={true}
    valueHighlighted={highlighted}
  />
);

const InformationTable = ({
  title,
  children
}: {
  title?: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div>
        <table
          className={classnames("mw-100 information-table", {
            "has-caption": !!title
          })}
        >
          {title ? <caption>{title}</caption> : null}
          <tbody>{children}</tbody>
        </table>
      </div>
    </>
  );
};

export default InformationTable;
