// tslint:disable:no-submodule-imports
import "core-js/features/array";
import "core-js/features/map";
import "core-js/features/number";
import "core-js/features/promise";
import "core-js/features/string";
import "react-app-polyfill/ie11";
// tslint:enable:no-submodule-imports

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import "./components/PagedList/index.scss";
import "./styles/styles.scss";

const rootElement = document.getElementById("root");
const rootComponent = ReactDOM.render(<App />, rootElement);

// Save the root component globally so it can be
// refreshed outside of react, during the login process
(window as any).rootComponent = rootComponent;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
