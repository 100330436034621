import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Formik, FormikProps } from "formik";
import { merge } from "lodash";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { SizeMeProps, withSize } from "react-sizeme";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { compose } from "recompose";
import DealerQuery from "../../Dealers/DealerQuery";
import { LoggedInUserWithDealerRatesQuery } from "../../LoggedInUserQuery";
import { ProductTypeEnum, RecursivePartial, User } from "../../types";
import CalculateQuotationService from "../CalculateQuotationService";
import {
  Quotation,
  QuotationFormValues,
  QuotationProps,
  QuotationRequest,
  QuotationResults,
} from "../types";
import FinanceFormSection from "./FinanceFormSection";
import "./index.scss";
import QuotationResultList, {
  QuotationResultListProps,
} from "./QuotationResultList";
import QuotationResultModal from "./QuotationResultModal";
import QuotationErrorModal from "./QuotationErrorModal";
import QuotationResultTable from "./QuotationResultTable";
import quotationValidationSchema from "./quotationValidationSchema";
import VehicleFormSection from "./VehicleFormSection";
import MultiQuoteResultSmallTable from "./MultiQuoteResultSmallTable";
import MultiQuoteResultTable from "./MultiQuoteResultTable";
import MultiQuoteResultCard from "./MultiQuoteResultCard";
import MultiQuoteResultModal from "./MultiQuoteResultModal";
import { Dealer } from "../../Dealers/types";

const mapQuotationToFormValues = (
  loggedInUser: User,
  quotation?: RecursivePartial<Quotation>,
  dealer?: Dealer
): QuotationFormValues => {
  const defaults: QuotationFormValues =
    quotationValidationSchema.default() as any;
  const quotationHasId = !!(quotation && quotation.id);

  if (quotation) {
    // tslint:disable-next-line:prefer-object-spread
    const finance: any = { ...quotation.finance };

    delete finance.totalFinance;
    delete finance.interestCharges;
    delete finance.totalCharges;
    delete finance.balancePayable;
    delete finance.totalAmountPayable;

    quotation.finance = finance;

    const skipVehicle = quotation.skipVehicle || false;

    if (skipVehicle) {
      defaults.productTypes = [ProductTypeEnum.HP];
    }

    defaults.isMannIslandDealer = dealer
      ? dealer.isMannIslandDealer
      : quotation.dealer?.isMannIslandDealer;
    defaults.isMannIslandZlist = dealer
      ? dealer.isMannIslandZList
      : quotation.dealer?.isMannIslandZList;
    defaults.isMultiQuote = dealer
      ? dealer.isMultiQuote
      : quotation.dealer?.isMultiQuote;

    delete quotation.id;
    quotation.finance && delete quotation.finance.id;
    delete quotation.skipVehicle;
    delete quotation.dealer;
    if (quotation.vehicle) {
      delete quotation.vehicle.id;
      quotation.vehicle.isRegUnknown = !(
        skipVehicle || quotation.vehicle.regNo
      );
      quotation.vehicle.skipVehicle = skipVehicle;
    }
  }

  const { dealer: loggedInDealer } = loggedInUser;
  if (loggedInDealer) {
    defaults.dealerId = loggedInDealer.id;
  }

  const result = merge(
    defaults,
    quotation,
    quotation && quotationHasId
      ? {
          showFinanceSection: true,
          showLoanDetailsSection: true,
          showResults: false,
          showCommission: false,
        }
      : undefined
  );

  return result;
};

const mapFormValues = (values: QuotationFormValues): Quotation => {
  delete values.vehicle.LCV;

  const {
    vehicle: {
      __typename: vehicleType,
      skipVehicle,
      isRegUnknown,
      regNoNotFound,
      ...vehicle
    },
    showFinanceSection,
    showLoanDetailsSection,
    showResults,
    showCommission,
    productTypes,
    __typename: quotationType,
    finance: { __typename: financeType, ...finance },
    isMannIslandZlist,
    ...rest
  } = values;

  const result = {
    skipVehicle,
    vehicle: skipVehicle ? undefined : vehicle,
    finance,
    ...rest,
  };

  return result;
};

const getQuotationRequest = (
  values: QuotationFormValues,
  isMulti: boolean = false,
  isMannIslandDealer: boolean | undefined = undefined,
  isMannIslandZList: boolean | undefined = undefined
): QuotationRequest => {
  const {
    dealerId,
    productTypes,
    vehicle: {
      cAP,
      mileage,
      maxAnnualMileage,
      isNew,
      dateOfRegistration,
      capId,
      imported,
      regNo,
      vehicleType,
      isCommercial,
      vatQualifying,
    },
    finance: { cashPrice, deposit, partExchangeValue, partExchangeSettlement },
    targetBy,
    targetByValue,
  } = values;

  let returnValues = {
    minTerm: 12,
    maxTerm: 60,
    dealerId,
    productTypes,
    cAP,
    mileage,
    maxAnnualMileage,
    regNo,
    targetBy,
    targetByValue,
    isNew,
    dateOfRegistration,
    cashPrice,
    cashDeposit: deposit,
    partExchange: partExchangeValue,
    partExchangeSettlement,
    extras: 0,
    rFL: 0,
  };

  if (!isMulti && isMannIslandDealer && !isMannIslandZList) {
    return {
      ...returnValues,
      capId: capId ? +capId : undefined,
      import: imported,
      regNo,
      vehicleType,
      usage: isCommercial ? "commercial" : "Social",
      vatQualify: vatQualifying,
      minTerm: 36,
    };
  }
  return returnValues;
};

/** Get a collection of paths to errors in a formik errors object */
export const getErrorPaths = (errObj: any, path?: string): string[] =>
  Object.keys(errObj).reduce((prev, k) => {
    const val = errObj[k];
    const nextPath = path ? `${path}.${k}` : k;
    if (typeof val === "object") {
      return [...prev, ...getErrorPaths(val, nextPath)];
    }
    return [...prev, nextPath];
  }, [] as string[]);

type QuotationFormProps = WithApolloClient<RecursivePartial<QuotationProps>> & {
  onSubmitQuotation: (quotation: Quotation) => Promise<any>;
};

class QuotationForm extends React.Component<
  QuotationFormProps,
  {
    modalResult: QuotationResults | null;
    cardResult: QuotationResults | null;
    unsavedResults: QuotationResults[] | null;
    loadingResults: boolean;
    showErrorModal: boolean;
    errorModalMessage: string | null;
    quotationLoading: boolean;
    size: any;
  }
> {
  constructor(props: QuotationFormProps) {
    super(props);
    this.vehicleSectionCompleted = this.vehicleSectionCompleted.bind(this);
    this.financeSectionCompleted = this.financeSectionCompleted.bind(this);
    this.handleCalculateRepayments = this.handleCalculateRepayments.bind(this);
    this.loanDetailsSectionCompleted =
      this.loanDetailsSectionCompleted.bind(this);
    this.selectResult = this.selectResult.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideCard = this.hideCard.bind(this);
    this.showCard = this.showCard.bind(this);
    this.showErrorModal = this.showErrorModal.bind(this);
    this.hideErrorModal = this.hideErrorModal.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.getWindowDimensions = this.getWindowDimensions.bind(this);
    this.setWindowDimensions = this.setWindowDimensions.bind(this);

    this.state = {
      modalResult: null,
      cardResult: null,
      unsavedResults: null,
      loadingResults: false,
      showErrorModal: false,
      errorModalMessage: null,
      quotationLoading: false,
      size: this.getWindowDimensions(),
    };

    window.addEventListener("resize", this.setWindowDimensions);
  }

  public render() {
    const { quotation, onSubmitQuotation } = this.props;
    return (
      <LoggedInUserWithDealerRatesQuery>
        {({ loggedInUser }) => {
          if (!loggedInUser) {
            return null;
          }

          return (
            <DealerQuery dealerId={quotation?.dealerId}>
              {({ dealer, loading }) => {
                if (loading) {
                  return null;
                }
                return (
                  <Formik
                    initialValues={mapQuotationToFormValues(
                      loggedInUser,
                      quotation,
                      dealer
                    )}
                    isInitialValid={!!(quotation && quotation.id)}
                    onSubmit={(values, { setSubmitting }) => {
                      onSubmitQuotation(mapFormValues(values)).then(() => {
                        this.setState({ unsavedResults: null });
                        setSubmitting(false);
                      });
                    }}
                    validationSchema={quotationValidationSchema}
                  >
                    {(formikProps) => {
                      const {
                        handleSubmit,
                        values: { showResults, showCommission },
                      } = formikProps;

                      const isMannIslandDealer =
                        formikProps.values.isMannIslandDealer;
                      const isMannIslandZList =
                        formikProps.values.isMannIslandZlist;

                      let forMannIslandDealer = isMannIslandDealer;
                      let forMannIslandZList = isMannIslandZList;

                      if (
                        isMannIslandDealer === undefined &&
                        loggedInUser.dealer
                      ) {
                        forMannIslandDealer =
                          loggedInUser?.dealer?.isMannIslandDealer;
                      } else if (loggedInUser && loggedInUser.dealer === null) {
                        forMannIslandDealer = isMannIslandDealer;
                      }

                      const isMulti =
                        (loggedInUser &&
                          loggedInUser.dealer &&
                          loggedInUser.dealer.isMultiQuote) ||
                        formikProps.values.isMultiQuote;

                      const quotationRequest = showResults
                        ? getQuotationRequest(
                            formikProps.values,
                            isMulti,
                            forMannIslandDealer,
                            forMannIslandZList
                          )
                        : undefined;

                      return (
                        <Form
                          onSubmit={handleSubmit}
                          className="quotation-form"
                          autoComplete="off"
                        >
                          <VehicleFormSection
                            showDealerSelect={this.props.showDealerSelect}
                            key="vehicle-section"
                            {...formikProps}
                            nextSection={() =>
                              this.vehicleSectionCompleted(formikProps)
                            }
                          />
                          {formikProps.values.showFinanceSection ? (
                            isMulti ? (
                              <>
                                <CalculateQuotationService
                                  input={quotationRequest}
                                  isMulti={true}
                                >
                                  {({ calculateQuotationList, loading }) => {
                                    return (
                                      <>
                                        <Row>
                                          <Col lg={12}>
                                            <Card className="mb-3">
                                              <CardHeader>
                                                <CardTitle className="mb-0">
                                                  Finance calculator
                                                </CardTitle>
                                              </CardHeader>
                                              <CardBody className="pb-1">
                                                <DealerQuery
                                                  dealerId={
                                                    formikProps.values?.dealerId
                                                  }
                                                >
                                                  {({
                                                    data,
                                                    loading: dealerLoading,
                                                  }) => {
                                                    return !dealerLoading ? (
                                                      <FinanceFormSection
                                                        key="finance-section"
                                                        {...formikProps}
                                                        nextSection={() =>
                                                          this.financeSectionCompleted(
                                                            formikProps
                                                          )
                                                        }
                                                        refresh={(props) =>
                                                          this.handleCalculateRepayments(
                                                            props || formikProps
                                                          )
                                                        }
                                                        dealer={data?.dealer}
                                                        isMulti={true}
                                                      />
                                                    ) : null;
                                                  }}
                                                </DealerQuery>
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    caret={true}
                                                    color="link"
                                                    size="sm"
                                                    className="pl-0 mb-0 text-muted"
                                                  >
                                                    Display options
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        formikProps.setFieldValue(
                                                          "showCommission",
                                                          !showCommission
                                                        )
                                                      }
                                                    >
                                                      {showCommission
                                                        ? "Hide"
                                                        : "Show"}{" "}
                                                      scheme codes
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </CardBody>
                                            </Card>
                                            <button
                                              key="show-loans"
                                              onClick={() =>
                                                this.handleCalculateRepayments(
                                                  formikProps
                                                )
                                              }
                                              className={classnames(
                                                "btn wide-button btn-lg",
                                                {
                                                  "btn-primary": !showResults,
                                                  "btn-outline-secondary":
                                                    showResults,
                                                }
                                              )}
                                              type="button"
                                              disabled={showResults}
                                            >
                                              {(this.state.quotationLoading ||
                                                loading) && (
                                                <FontAwesomeIcon
                                                  icon={faSpinner}
                                                  spin={true}
                                                  className="mr-2"
                                                />
                                              )}{" "}
                                              Show loans
                                            </button>
                                          </Col>
                                          <Col lg={12}>
                                            {!!formikProps.values
                                              .targetByValue &&
                                              formikProps.values.targetByValue >
                                                13.9 && (
                                                <Row>
                                                  <Col>
                                                    <Alert color="warning">
                                                      <b>Warning:</b> Some
                                                      lenders only lend up to
                                                      13.9% APR
                                                    </Alert>
                                                  </Col>
                                                </Row>
                                              )}
                                            <Row>
                                              <ResponsiveMultiQuoteResultList
                                                headerText="Select Your Lender Quote"
                                                loading={
                                                  this.state.quotationLoading
                                                }
                                                quotationListResult={
                                                  calculateQuotationList
                                                }
                                                targetBy={
                                                  formikProps.values.targetBy
                                                }
                                                onSelectResult={(x) =>
                                                  this.showCard(x)
                                                }
                                                onSelectMoreInfo={(x) =>
                                                  this.showErrorModal(x)
                                                }
                                                showCommission={showCommission}
                                                props={formikProps}
                                              />
                                              {this.state.size &&
                                              this.state.size.width < 992 ? (
                                                <MultiQuoteResultModal
                                                  result={this.state.cardResult}
                                                  toggle={this.hideCard}
                                                  showCommission={
                                                    showCommission
                                                  }
                                                  selectResult={(result) =>
                                                    this.selectResult(
                                                      result,
                                                      formikProps
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <MultiQuoteResultCard
                                                  result={this.state.cardResult}
                                                  toggle={this.hideCard}
                                                  showCommission={
                                                    showCommission
                                                  }
                                                  selectResult={(result) =>
                                                    this.selectResult(
                                                      result,
                                                      formikProps
                                                    )
                                                  }
                                                />
                                              )}
                                            </Row>
                                          </Col>
                                        </Row>
                                      </>
                                    );
                                  }}
                                </CalculateQuotationService>
                              </>
                            ) : (
                              <>
                                <CalculateQuotationService
                                  input={quotationRequest}
                                  isMannIslandDealer={forMannIslandDealer}
                                >
                                  {({ calculateQuotationList, loading }) => {
                                    const quotationRequest2 = showResults
                                      ? getQuotationRequest(
                                          formikProps.values,
                                          false,
                                          !forMannIslandDealer,
                                          forMannIslandZList
                                        )
                                      : undefined;
                                    return (
                                      <>
                                        <Row>
                                          <Col lg={4}>
                                            <Card className="mb-3">
                                              <CardHeader>
                                                <CardTitle className="mb-0">
                                                  Finance calculator
                                                </CardTitle>
                                              </CardHeader>
                                              <CardBody className="pb-1">
                                                <DealerQuery
                                                  dealerId={
                                                    formikProps.values?.dealerId
                                                  }
                                                >
                                                  {({
                                                    data,
                                                    loading: dealerLoading,
                                                  }) => {
                                                    return !dealerLoading ? (
                                                      <FinanceFormSection
                                                        key="finance-section"
                                                        {...formikProps}
                                                        nextSection={() =>
                                                          this.financeSectionCompleted(
                                                            formikProps
                                                          )
                                                        }
                                                        refresh={(props) =>
                                                          this.handleCalculateRepayments(
                                                            props || formikProps
                                                          )
                                                        }
                                                        dealer={data?.dealer}
                                                        isMulti={false}
                                                      />
                                                    ) : null;
                                                  }}
                                                </DealerQuery>
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    caret={true}
                                                    color="link"
                                                    size="sm"
                                                    className="pl-0 mb-0 text-muted"
                                                  >
                                                    Display options
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        formikProps.setFieldValue(
                                                          "showCommission",
                                                          !showCommission
                                                        )
                                                      }
                                                    >
                                                      {showCommission
                                                        ? "Hide"
                                                        : "Show"}{" "}
                                                      scheme codes
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </CardBody>
                                            </Card>
                                            <button
                                              key="show-loans"
                                              onClick={() =>
                                                this.handleCalculateRepayments(
                                                  formikProps
                                                )
                                              }
                                              className={classnames(
                                                "btn wide-button btn-lg",
                                                {
                                                  "btn-primary": !showResults,
                                                  "btn-outline-secondary":
                                                    showResults,
                                                }
                                              )}
                                              type="button"
                                              disabled={showResults}
                                            >
                                              {(this.state.quotationLoading ||
                                                loading) && (
                                                <FontAwesomeIcon
                                                  icon={faSpinner}
                                                  spin={true}
                                                  className="mr-2"
                                                />
                                              )}{" "}
                                              Show loans
                                            </button>
                                          </Col>
                                          <Col lg={{ size: 8 }}>
                                            {!!formikProps.values
                                              .targetByValue &&
                                              formikProps.values.targetByValue >
                                                13.9 && (
                                                <Row>
                                                  <Col>
                                                    <Alert color="warning">
                                                      <b>Warning:</b> Some
                                                      lenders only lend up to
                                                      13.9% APR
                                                    </Alert>
                                                  </Col>
                                                </Row>
                                              )}
                                            <ResponsiveQuotationResultList
                                              headerText={
                                                forMannIslandDealer
                                                  ? "Mann Island"
                                                  : "Alphera"
                                              }
                                              loading={
                                                this.state.quotationLoading
                                              }
                                              quotationListResult={
                                                calculateQuotationList
                                              }
                                              targetBy={
                                                formikProps.values.targetBy
                                              }
                                              onSelectResult={(x) =>
                                                this.showModal(x)
                                              }
                                              onSelectMoreInfo={(x) =>
                                                this.showErrorModal(x)
                                              }
                                              showCommission={showCommission}
                                            />
                                            {!forMannIslandDealer &&
                                            !forMannIslandZList ? (
                                              <CalculateQuotationService
                                                input={quotationRequest2}
                                                isMannIslandDealer={
                                                  !forMannIslandDealer
                                                }
                                              >
                                                {({
                                                  calculateQuotationList:
                                                    calculateQuotationList2,
                                                  loading,
                                                }) => {
                                                  this.setLoading(loading);
                                                  return (
                                                    <ResponsiveQuotationResultList
                                                      headerText={
                                                        !forMannIslandDealer
                                                          ? "Mann Island"
                                                          : "Alphera"
                                                      }
                                                      loading={
                                                        this.state
                                                          .quotationLoading
                                                      }
                                                      quotationListResult={
                                                        calculateQuotationList2
                                                      }
                                                      targetBy={
                                                        formikProps.values
                                                          .targetBy
                                                      }
                                                      onSelectResult={(x) =>
                                                        this.showModal(x)
                                                      }
                                                      onSelectMoreInfo={(x) =>
                                                        this.showErrorModal(x)
                                                      }
                                                      showCommission={
                                                        showCommission
                                                      }
                                                    />
                                                  );
                                                }}
                                              </CalculateQuotationService>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </>
                                    );
                                  }}
                                </CalculateQuotationService>
                              </>
                            )
                          ) : null}
                          <QuotationErrorModal
                            key="modal-error"
                            errorMessage={this.state.errorModalMessage}
                            showErrorModal={this.state.showErrorModal}
                            toggle={this.hideErrorModal}
                          />
                          <QuotationResultModal
                            key="modal-result"
                            result={this.state.modalResult}
                            toggle={this.hideModal}
                            showCommission={showCommission}
                            selectResult={(result) =>
                              this.selectResult(result, formikProps)
                            }
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                );
              }}
            </DealerQuery>
          );
        }}
      </LoggedInUserWithDealerRatesQuery>
    );
  }

  private selectResult(
    result: QuotationResults,
    {
      isSubmitting,
      setFieldValue,
      handleSubmit,
    }: FormikProps<QuotationFormValues>
  ) {
    if (!isSubmitting) {
      setFieldValue("finance.productType" as any, result.productType, false);
      setFieldValue("finance.term" as any, result.term, false);
      setFieldValue(
        "finance.monthlyPayment" as any,
        result.monthlyPayment,
        false
      );
      setFieldValue("lenderId" as any, result.lenderId, false);

      setTimeout(handleSubmit, 0);
    }
  }

  private vehicleSectionCompleted(
    formikProps: FormikProps<QuotationFormValues>
  ) {
    formikProps.setFieldValue("showFinanceSection", true);
  }

  private async financeSectionCompleted(
    formikProps: FormikProps<QuotationFormValues>
  ) {
    formikProps.setFieldValue("showLoanDetailsSection", true);
  }

  private async loanDetailsSectionCompleted(
    formikProps: FormikProps<QuotationFormValues>
  ) {
    await this.handleCalculateRepayments(formikProps);
  }

  private async handleCalculateRepayments(
    formikProps: FormikProps<QuotationFormValues>
  ) {
    [
      "finance.cashPrice",
      "finance.deposit",
      "finance.partExchangeValue",
      "finance.partExchangeSettlement",
      "targetBy",
      "targetByValue",
      "productTypes",
    ].forEach((x) => formikProps.setFieldTouched(x as any, true, false));

    formikProps.validateForm().then((err) => {
      if (
        !getErrorPaths(err).filter(
          (x) => x !== "finance.term" && x !== "finance.productType"
        ).length
      ) {
        formikProps.setFieldValue("showResults", true, false);
      }
    });
  }

  private showModal(results: QuotationResults | null) {
    this.setState({ modalResult: results });
  }

  private hideModal() {
    this.setState({ modalResult: null });
  }

  private showCard(results: QuotationResults | null) {
    this.setState({ cardResult: results });
  }

  private hideCard() {
    this.setState({ cardResult: null });
  }

  private showErrorModal(errorModalMessage: string | null) {
    this.setState({ showErrorModal: true, errorModalMessage });
  }
  private hideErrorModal() {
    this.setState({ showErrorModal: false });
  }
  private setLoading(value: boolean) {
    if (value !== this.state.quotationLoading)
      this.setState({ quotationLoading: value });
  }

  private getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }

  private setWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    this.setState({
      size: {
        width,
        height,
      },
    });
  }
}

export default compose<
  QuotationFormProps,
  RecursivePartial<QuotationProps> & {
    onSubmitQuotation: (quotation: Quotation) => Promise<any>;
  }
>(withApollo)(QuotationForm);

const ResponsiveQuotationResultListInner = ({
  size,
  ...rest
}: SizeMeProps & QuotationResultListProps) =>
  size.width && size.width < 600 ? (
    <QuotationResultList key="narrow" {...rest} />
  ) : (
    <QuotationResultTable key="wide" {...rest} />
  );

const ResponsiveQuotationResultList = withSize({
  monitorHeight: false,
  monitorWidth: true,
})(ResponsiveQuotationResultListInner);

const ResponsiveMultiQuoteResultListInner = ({
  size,
  ...rest
}: SizeMeProps &
  QuotationResultListProps & { props: FormikProps<QuotationFormValues> }) =>
  size.width && size.width < 600 ? (
    <MultiQuoteResultSmallTable key="narrow-multi" {...rest} />
  ) : (
    <MultiQuoteResultTable key="wide-multi" {...rest} />
  );

const ResponsiveMultiQuoteResultList = withSize({
  monitorHeight: false,
  monitorWidth: true,
})(ResponsiveMultiQuoteResultListInner);
