import { FormikTouched } from "formik";
import { merge } from "lodash";
import * as React from "react";
import Helmet from "react-helmet";
import { Route, RouteComponentProps, withRouter } from "react-router";
import { Container } from "reactstrap";
import { compose } from "recompose";
import { convertNumber } from "../../utils";
import { cleanProposalBeforeSubmit } from "../Forms/utils";
import { LoggedInUserProps, withLoggedInUser } from "../LoggedInUserQuery";
import PageNotFound from "../PageNotFound";
import ProposalQuotationQuery from "../Quotations/ProposalQuotationQuery";
import SceneLoadingSpinner from "../SceneLoadingSpinner";
import { ContextNames, UserRoles } from "../types";
import CreateProposalMutation from "./CreateProposalMutation";
import DraftProposalService from "./DraftProposals/DraftProposalService";
import { ProposalFragment } from "./fragments";
import ProposalBreadcrumbs from "./ProposalBreadcrumbs";
import ProposalForm from "./ProposalForm";
import businessCustomerValidationSchema from "./ProposalForm/businessCustomerValidationSchema";
import individualCustomerValidationSchema from "./ProposalForm/individualCustomerValidationSchema";
import proposalValidationSchema from "./ProposalForm/proposalValidationSchema";
import { PROPOSAL_LIST } from "./ProposalList/ProposalListQuery";
import {
  EarningsFrequency,
  EmploymentTypesRequiringEmployerName,
  Proposal,
  ProposalFormSectionName,
  ProposalType,
} from "./types";
import DirectDealService from "./DirectDeals/DirectDealService";
import IDDForm from "./DirectDeals/IDDForm";

type CreateProposalSceneProps = RouteComponentProps<{
  dealerId?: string;
  proposalType?: string;
  draftProposalId?: string;
  quotationId?: string;
  directDealId?: string;
}> &
  LoggedInUserProps;

const CreateProposalScene = ({
  history,
  match,
  loggedInUser,
}: CreateProposalSceneProps) => {
  if (!loggedInUser) {
    return null;
  }

  let draftProposalId = match.params.draftProposalId
    ? parseInt(match.params.draftProposalId, 10)
    : undefined;

  const loggedInDealerId =
    loggedInUser &&
    loggedInUser.dealer &&
    loggedInUser.roles.includes(UserRoles.dealer)
      ? loggedInUser.dealer.id
      : undefined;

  const dealerId =
    loggedInDealerId ||
    (match.params.dealerId ? convertNumber(match.params.dealerId) : undefined);

  const accountManagerId =
    loggedInUser &&
    loggedInUser.accountManager &&
    loggedInUser.accountManager.id;

  const quotationId = match.params.quotationId
    ? parseInt(match.params.quotationId, 10)
    : undefined;

  const directDealId = match.params.directDealId;

  const context = directDealId ? ContextNames.DIRECT_DEAL : undefined;

  return (
    <Container>
      <Helmet title="New proposal | Eurodrive" />
      {context !== ContextNames.DIRECT_DEAL && <ProposalBreadcrumbs />}
      <DirectDealService directDealId={directDealId}>
        {({ directDeal, loading: directDealLoading, sendDirectDeal }) => {
          if (directDeal) {
            if (!directDeal.iddSigned) {
              return <IDDForm directDeal={directDeal}></IDDForm>;
            }
            draftProposalId = directDeal.draftProposalId;
          } else if (
            !directDeal &&
            context === ContextNames.DIRECT_DEAL &&
            !directDealLoading
          ) {
            return <Route path="/" component={PageNotFound} />;
          }

          return (
            <DraftProposalService
              draftProposalId={draftProposalId}
              context={context}
            >
              {({
                draftProposal,
                draftProposalInitialValues,
                loading: draftProposalLoading,
                saveDraft,
              }) => {
                if (directDealLoading || draftProposalLoading) {
                  return <SceneLoadingSpinner />;
                }

                // Get the proposal type from the draft proposal, or from the
                // route values if there is no draft proposal
                const proposalType =
                  draftProposalInitialValues &&
                  draftProposalInitialValues.proposalType
                    ? draftProposalInitialValues.proposalType
                    : match.params.proposalType
                    ? (match.params.proposalType.toUpperCase() as ProposalType)
                    : (ProposalType.BUSINESS as ProposalType);
                return (
                  <ProposalQuotationQuery
                    quotationId={quotationId}
                    proposalType={proposalType}
                  >
                    {({
                      quotationInitialValues,
                      loading: quotationLoading,
                    }) => {
                      if (draftProposalLoading || quotationLoading) {
                        return <SceneLoadingSpinner />;
                      }

                      if (
                        (quotationId && !quotationInitialValues) ||
                        (draftProposalId && !draftProposalInitialValues)
                      ) {
                        return <PageNotFound />;
                      }

                      // Create a set of default values for all proposals
                      let initialValues = merge(
                        {},
                        proposalValidationSchema.default() as Proposal,
                        {
                          individualCustomer:
                            proposalType === ProposalType.INDIVIDUAL
                              ? individualCustomerValidationSchema.default()
                              : undefined,
                          business:
                            proposalType === ProposalType.INDIVIDUAL
                              ? undefined
                              : businessCustomerValidationSchema.default(),
                        },
                        {
                          dealerId,
                          accountManagerId,
                          directDealId:
                            context === ContextNames.DIRECT_DEAL &&
                            proposalType === ProposalType.INDIVIDUAL
                              ? directDealId
                              : null,
                        }
                      );

                      let initialSection: ProposalFormSectionName | undefined;
                      let initialTouched: FormikTouched<Proposal> | undefined;

                      if (draftProposalInitialValues) {
                        // Merge in draft proposal data
                        initialValues = merge(
                          {},
                          initialValues,
                          draftProposalInitialValues.initialValues
                        );
                        initialSection =
                          context !== ContextNames.DIRECT_DEAL
                            ? draftProposalInitialValues.initialSection
                            : undefined;
                        initialTouched =
                          context !== ContextNames.DIRECT_DEAL
                            ? draftProposalInitialValues.initialTouched
                            : undefined;
                      } else if (quotationInitialValues) {
                        // Merge in quotation data
                        initialValues = merge(
                          {},
                          initialValues,
                          quotationInitialValues.initialValues
                        );
                      }

                      // Set the initial value for isRegUnknown
                      if (initialValues.vehicle) {
                        initialValues.vehicle.isRegUnknown =
                          !initialValues.vehicle.regNo &&
                          !!initialValues.vehicle.cAP;
                      }

                      initialValues.individualCustomer?.employmentDetails?.forEach(
                        (employment) => {
                          // Manually add the "EarningsPer" field to employers if necessary
                          employment.earningsPer =
                            employment.earningsPer || EarningsFrequency.YEARLY;

                          // Remove the employer address if it is not required,
                          // so it does not fail validation
                          const requiresEmployerAddress =
                            !employment.employmentType ||
                            EmploymentTypesRequiringEmployerName.some(
                              (x) => x === employment.employmentType
                            );

                          if (!requiresEmployerAddress) {
                            (employment.address as any) = null;
                          }
                        }
                      );

                      return (
                        <CreateProposalMutation>
                          {(mutation, { client }) => (
                            <>
                              <Helmet
                                title={`New ${
                                  proposalType === ProposalType.INDIVIDUAL
                                    ? "individual"
                                    : "business"
                                } proposal | Eurodrive`}
                              />

                              <ProposalForm
                                showDealerSelect={dealerId ? false : undefined}
                                initialValues={initialValues}
                                initialSection={initialSection}
                                initialTouched={initialTouched}
                                context={context}
                                isIndividual={
                                  proposalType === ProposalType.INDIVIDUAL
                                }
                                saveDraft={saveDraft}
                                sendDirectDeal={(directDeal) => {
                                  return sendDirectDeal(directDeal);
                                }}
                                draftProposalLastSaved={
                                  draftProposal && draftProposal.updated
                                }
                                onSubmitProposal={(submitted) => {
                                  const variables = {
                                    input: cleanProposalBeforeSubmit(submitted),
                                  };
                                  return context !== ContextNames.DIRECT_DEAL
                                    ? mutation({
                                        variables,
                                        refetchQueries: [
                                          {
                                            query: PROPOSAL_LIST,
                                            variables: {
                                              input: { page: 1, pageSize: 10 },
                                            },
                                          },
                                        ],
                                      }).then((result) => {
                                        if (result && result.data) {
                                          const created =
                                            result.data.createProposal;
                                          if (created) {
                                            client?.writeFragment({
                                              id: (
                                                created.proposalRef || ""
                                              ).toString(),
                                              fragment: ProposalFragment,
                                              data: created,
                                              fragmentName: "ProposalFragment",
                                            });
                                            history.push(
                                              `/proposals/${created.proposalRef}`
                                            );
                                          }
                                        }
                                      })
                                    : mutation({
                                        variables,
                                      }).then((result) => {
                                        if (result && result.data) {
                                          const created =
                                            result.data.createProposal;
                                          if (created) {
                                            history.push(
                                              `/directdeal/completed`
                                            );
                                          }
                                        }
                                      });
                                }}
                              />
                            </>
                          )}
                        </CreateProposalMutation>
                      );
                    }}
                  </ProposalQuotationQuery>
                );
              }}
            </DraftProposalService>
          );
        }}
      </DirectDealService>
    </Container>
  );
};

export default compose<CreateProposalSceneProps, {}>(
  withLoggedInUser,
  withRouter
)(CreateProposalScene);
