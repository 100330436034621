import { Field, FormikProps } from "formik";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { formatEnumValue } from "../../../utils";
import AddressFormSection from "../../AddressLookup/AddressFormSection";
import FormInputField from "../../Forms/FormInputField";
import RadioField from "../../Forms/RadioField";
import { HomeAddress, HomeOwnership, Proposal } from "../types";

interface HomeAddressSectionProps {
  index: number;
  remove: () => void;
  address: HomeAddress;
}

const homeOwnershipOptions = Object.keys(HomeOwnership).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const HomeAddressSection = ({
  index,
  address,
  remove,
  ...rest
}: HomeAddressSectionProps & FormikProps<Proposal>) => (
  <div>
    <AddressFormSection
      {...rest}
      address={address}
      pathToAddress={`individualCustomer.homeAddresses.${index}`}
      autoFocus={true}
    />
    <Row>
      <RadioField
        colSize={5}
        name={`individualCustomer.homeAddresses.${index}.ownership`}
        title="Home ownership"
        options={homeOwnershipOptions}
        vertical={true}
      />
    </Row>
    {address.ownership === HomeOwnership.OTHER ? (
      <Row>
        <Field
          colSize={5}
          name={`individualCustomer.homeAddresses.${index}.otherOwnership`}
          type="text"
          placeholder="Ownership details"
          component={FormInputField}
        />
      </Row>
    ) : null}
    {index === 0 && (
      <Row>
        <Field
          colSize={5}
          name={`individualCustomer.homeAddresses.${index}.totalMonthlyRentOrMortgage`}
          type="number"
          min={0}
          title="Monthly rent or mortgage cost"
          units="£"
          component={FormInputField}
        />
      </Row>
    )}
    <Row>
      <Col lg={5}>
        <Row>
          <Field
            colSize={6}
            name={`individualCustomer.homeAddresses.${index}.yearsAtAddress`}
            type="number"
            min={0}
            title="Years at address"
            component={FormInputField}
          />
          <Field
            colSize={6}
            name={`individualCustomer.homeAddresses.${index}.monthsAtAddress`}
            type="number"
            min={0}
            max={11}
            title="Months at address"
            component={FormInputField}
          />
        </Row>
      </Col>
    </Row>
    {index === 0 && (
      <Row>
        <Field
          colSize={5}
          name={`individualCustomer.homeAddresses.${index}.telephone`}
          type="text"
          title="Home telephone"
          component={FormInputField}
        />
      </Row>
    )}
  </div>
);

export default HomeAddressSection;
