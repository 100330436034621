import gql from "graphql-tag";
import {
  Mutation,
  MutationFunction,
  MutationResult,
  useMutation,
} from "react-apollo";
import { DirectDeal } from "../types";
import * as React from "react";

export interface DirectDealData {
  success: boolean;
}

export interface DirectDealVariables {
  input: DirectDeal;
}

export const CREATE_OR_UPDATE_DIRECT_DEAL = gql`
  mutation CreateOrUpdateDirectDeal($input: DirectDealInput) {
    createOrUpdateDirectDeal(input: $input) {
      success
    }
  }
`;

const CreateOrUpdateDirectDeal = ({
  children,
}: {
  children: (
    mutationFunction: MutationFunction<DirectDealData, DirectDealVariables>,
    result: MutationResult<DirectDealData>
  ) => JSX.Element | null;
}) => <Mutation mutation={CREATE_OR_UPDATE_DIRECT_DEAL}>{children}</Mutation>;

export const useCreateOrUpdateDirectDeal = () => {
  const [createOrUpdateDirectDeal] = useMutation<
    DirectDealData,
    DirectDealVariables
  >(CREATE_OR_UPDATE_DIRECT_DEAL);
  return createOrUpdateDirectDeal;
};

export default CreateOrUpdateDirectDeal;
