import { FormikProps } from "formik";
import { Proposal } from "../types";

const ProposalFormClearQuotation = ({
  children,
  setFieldValue,
  setFieldTouched,
  validateForm,
}: {
  children: (props: { clearQuotation: () => void }) => JSX.Element;
} & FormikProps<Proposal>) =>
  children({
    clearQuotation: () => {
      [
        "quotationId",
        "finance.monthlyPayment",
        "finance.rate",
        "finance.aprRate",
        "finance.balloonPayment",
        "finance.acceptanceFee",
        "finance.optionFee",
        "finance.cashPrice",
        "finance.lenderId",
        "finance.lenderName"
      ].forEach((field) => {
        setFieldValue(field as any, undefined, false);
        setFieldTouched(field as any, true, false);
      });
      return setTimeout(validateForm, 0);
    },
  });

export default ProposalFormClearQuotation;
