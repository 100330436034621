import * as React from "react";
import Select from "react-select";
// tslint:disable-next-line:no-submodule-imports
import { Props } from "react-select/src/Select";
// tslint:disable-next-line:no-submodule-imports
import { Styles } from "react-select/src/styles";
// tslint:disable-next-line:no-submodule-imports
import { Theme } from "react-select/src/types";

export const selectStyles: Partial<Styles> = {
  container: provided => ({
    ...provided,
    minWidth: 150
  }),
  menu: provided => ({
    ...provided,
    zIndex: 10000
  }),
  input: provided => ({
    ...provided,
    "@media (max-width: 768px)": {
      fontSize: "1rem"
    }
  }),
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      boxShadow: isFocused
        ? `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(117, 48, 207, 0.25)`
        : "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
      borderColor: isFocused ? "#ba98e7" : "#ced4da",
      "&:hover": {
        borderColor: isFocused ? "#ba98e7" : "#ced4da"
      },
      height: "35.6px",
      minHeight: "35.6px"
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    top: -1,
    left: 3
  })
};

export const selectTheme: (theme: Theme) => Theme = theme => ({
  ...theme,
  borderRadius: 4.8,
  colors: {
    ...theme.colors,
    primary: "#7530cf",
    primary75: "#7a3dc9",
    primary50: "#9872ca",
    primary25: "#dbc1fc"
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 36
  }
});

const CustomSelect: React.ComponentType<
  Props<any> & React.RefAttributes<any>
> = React.forwardRef<{}, Props<any>>((props: Props<any>, ref: any) => (
  <Select ref={ref} {...props} styles={selectStyles} theme={selectTheme} />
));

export default CustomSelect;
