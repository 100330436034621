import * as React from "react";
import gql from "graphql-tag";
import { DirectDeal } from "../types";
import { DirectDealFragment } from "../fragments";
import { Query, QueryResult } from "react-apollo";

interface DirectDealData {
  directDeal: DirectDeal;
}

interface DirectDealVariables {
  directDealId?: string;
}

export const GET_DIRECT_DEAL = gql`
  query DirectDealQuery($directDealId: String!) {
    directDeal(id: $directDealId) {
      ...DirectDealFragment
    }
  }
  ${DirectDealFragment}
`;

const DirectDealQuery = ({
  children,
  directDealId,
  query,
}: DirectDealVariables & {
  children: (
    result: QueryResult<DirectDealData, DirectDealVariables> & {
      directDeal?: DirectDeal;
    }
  ) => JSX.Element | null;
  query?: any;
}) => (
  <Query<DirectDealData, DirectDealVariables>
    query={query || GET_DIRECT_DEAL}
    variables={{ directDealId }}
    skip={!directDealId}
  >
    {(result) =>
      children({
        ...result,
        directDeal: result?.data?.directDeal,
      })
    }
  </Query>
);

export default DirectDealQuery;
