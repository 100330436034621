import { FormikProps } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import FlipMove from "react-flip-move";
import { Badge, Col, Row } from "reactstrap";
import { formatCurrency } from "../../../utils";
import {
  QuotationFormValues,
  QuotationResults,
  QuotationTargetBy,
} from "../types";
import {
  QuotationResultListItemProps,
  QuotationResultListProps,
} from "./QuotationResultList";
import { ProductTypeEnum } from "../../types";

export const getLowestPayment = (results?: QuotationResults[]) =>
  results &&
  results.reduce((prev: number, r: QuotationResults) => {
    return prev > 0 ? Math.min(prev, r.monthlyPayment) : r.monthlyPayment;
  }, 0);

const MultiQuoteResultTableRow = ({
  result: {
    productType,
    finance,
    arrangementFee,
    completionFee,
    interestCharges,
    guaranteedFutureValue,
    term,
    monthlyPayment,
    aprRate,
    commissionCode,
    lenderName,
  },
  lowestProductPayment,
  showCommission,
  targetBy,
}: QuotationResultListItemProps) => (
  <>
    <Col lg={7} className="d-flex align-items-center product-lender">
      <div className="custom-product-type">{productType}</div>
      <div className="custom-header ml-5 pb-0">{lenderName}</div>
    </Col>
    <Col lg={5} className="text-left py-3">
      <h5 className="font-weight-normal">
        Monthly Payment: {formatCurrency(monthlyPayment)}
      </h5>

      {productType === ProductTypeEnum.PCP && (
        <p>Final rental {formatCurrency(guaranteedFutureValue)}</p>
      )}
      {false && (
        <p>
          Total Amount Payable:{" "}
          {formatCurrency(
            finance + arrangementFee + completionFee + interestCharges
          )}
        </p>
      )}
      <p>Term {term} months</p>
      <div className="d-inline">
        APR {(Math.round(aprRate * 100) / 100).toFixed(2)}%
      </div>
      <div className="d-inline">
        {monthlyPayment === lowestProductPayment &&
        targetBy !== QuotationTargetBy.MONTHLY_PAYMENT ? (
          <Badge color="success" className="ml-3">
            {" "}
            Lowest {productType}
          </Badge>
        ) : null}
      </div>
      {showCommission && (
        <p className="text-muted">
          <small>{commissionCode}</small>
        </p>
      )}
    </Col>
  </>
);

const MultiQuoteResultTable = ({
  quotationListResult,
  onSelectResult,
  loading,
  headerText,
  props,
}: QuotationResultListProps & { props: FormikProps<QuotationFormValues> }) => {
  const { values } = props;
  const results = quotationListResult?.results;

  let filteredByTermResults = results?.filter(
    (x) => x.term === values.finance.term
  );

  const NoItemsPlaceholder = () => (
    <div className="no-items-header">No results found</div>
  );

  const productTypeArray = ["LP", "HP", "PCP"];

  const lowestByProduct = productTypeArray.map((r) => {
    return getLowestPayment(
      filteredByTermResults?.filter((p) => p.productType === r)
    );
  });

  const sortByProductType = (a: QuotationResults, b: QuotationResults) => {
    return (
      productTypeArray.indexOf(b.productType.toUpperCase()) -
      productTypeArray.indexOf(a.productType.toUpperCase())
    );
  };

  const sortByNameDesc = (a: QuotationResults, b: QuotationResults) => {
    let textA = a.lenderName?.toUpperCase() || "";
    let textB = b.lenderName?.toUpperCase() || "";
    return textA < textB ? 1 : textA > textB ? -1 : 0;
  };

  const sortByLowest = (a: QuotationResults, b: QuotationResults) => {
    return (
      lowestByProduct.indexOf(b.monthlyPayment) -
      lowestByProduct.indexOf(a.monthlyPayment)
    );
  };

  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    setActiveIndex(-1);
    onSelectResult(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.finance, values.productTypes]);

  return results ? (
    <>
      <Col lg={8}>
        {!filteredByTermResults?.length ? (
          <>{NoItemsPlaceholder()}</>
        ) : (
          <div className="table table-hover">
            <Col>
              <Row key="multi-header">
                <Col className="custom-header mt-3 mb-3">{headerText}</Col>
              </Row>

              <FlipMove typeName={null}>
                {filteredByTermResults
                  .sort((a, b) => {
                    return (
                      sortByProductType(a, b) ||
                      sortByLowest(a, b) ||
                      sortByNameDesc(a, b)
                    );
                  })
                  .map((r, i) => {
                    const lowestProductPayment = getLowestPayment(
                      filteredByTermResults?.filter(
                        (p) => p.productType === r.productType
                      )
                    );

                    return (
                      <div
                        key={`${r.productType}_${r.term}_${r.lenderId}_row`}
                        onClick={() => {
                          setActiveIndex(i);
                          onSelectResult(r);
                        }}
                        className={
                          "row custom-row" +
                          (activeIndex === i ? " selected" : "")
                        }
                      >
                        <MultiQuoteResultTableRow
                          result={r}
                          lowestProductPayment={lowestProductPayment}
                          onSelectResult={() => onSelectResult(r)}
                          loading={loading}
                        />
                      </div>
                    );
                  })}
              </FlipMove>
            </Col>
          </div>
        )}
      </Col>
    </>
  ) : null;
};

export default MultiQuoteResultTable;
