import { Dealer } from "../Dealers/types";
import { Vehicle } from "../Proposals/types";
import { ContextNames, ProductTypeEnum, SearchArgs, User } from "../types";

export interface VehicleLookup {
  bodyStyle?: string;
  capCode?: string;
  capDer?: string;
  capId?: string;
  capMan?: string;
  capMod?: string;
  colour?: string;
  doors?: number;
  engineSize?: string;
  exported?: boolean;
  fuel?: string;
  imported?: boolean;
  lCV?: boolean;
  make?: string;
  model?: string;
  regDate?: string;
  regNo?: string;
  scrapped?: boolean;
  transmission?: string;
  vIN?: string;
  variant?: string;
}

export interface QuotationSearchArgs extends SearchArgs {
  accountManagerId?: string;
  dealerId?: number;
}

export interface QuotationFormValues {
  id?: number;
  dealerId?: number;
  lenderId?: number;
  title?: string;
  forename?: string;
  middleName?: string;
  surname?: string;
  mobile?: string;
  email?: string;
  targetBy?: QuotationTargetBy;
  targetByValue?: number;
  productTypes?: ProductTypeEnum[];
  showFinanceSection: boolean;
  showLoanDetailsSection: boolean;
  showResults: boolean;
  showCommission: boolean;
  vehicle: Vehicle;
  finance: QuotationFinance;
  isMannIslandDealer?: boolean;
  isMannIslandZlist?: boolean;
  isMultiQuote?: boolean;
  __typename?: string;
}

export interface QuotationFinance {
  id?: string;
  productType?: ProductTypeEnum;
  term?: number;
  cashPrice?: number;
  deposit?: number;
  financeValue?: number;
  partExchangeSettlement?: number;
  partExchangeValue?: number;
  arrangementFee?: number;
  completionFee?: number;
  guaranteedFutureValue?: number;
  monthlyPayment?: number;
  commission?: number;
  commissionCode?: string;
  aprRate?: number;
  flatRate?: number;
  __typename?: string;
}
export interface Quotation {
  id?: number;
  dealerId?: number;
  lenderId?: number;
  dealer?: Dealer;
  title?: string;
  forename?: string;
  middleName?: string;
  surname?: string;
  mobile?: string;
  email?: string;
  submittedDate?: Date;
  targetBy?: QuotationTargetBy;
  targetByValue?: number;
  skipVehicle?: boolean;
  finance: {
    id?: string;
    productType?: ProductTypeEnum;
    term?: number;
    cashPrice?: number;
    deposit?: number;
    financeValue?: number;
    partExchangeSettlement?: number;
    partExchangeValue?: number;
    arrangementFee?: number;
    completionFee?: number;
    guaranteedFutureValue?: number;
    monthlyPayment?: number;
    commission?: number;
    commissionCode?: string;
    aprRate?: number;
    flatRate?: number;
    __typename?: string;
    totalFinance?: number;
    interestCharges?: number;
    totalCharges?: number;
    balancePayable?: number;
    totalAmountPayable?: number;
  };
  lender?: {
    id?: number;
    name?: string;
  };
  vehicle?: Vehicle;
  isHidden?: boolean;
  isMannIslandDealer?: boolean;
  isMannIslandZList?: boolean;
  isMultiQuote?: boolean;
}

export interface QuotationRequest {
  minTerm?: number;
  maxTerm?: number;
  dealerId?: number;
  productTypes?: ProductTypeEnum[];
  cAP?: string;
  mileage?: number;
  maxAnnualMileage?: number;
  targetBy?: QuotationTargetBy;
  targetByValue?: number;
  isNew?: boolean;
  dateOfRegistration?: string;
  cashPrice?: number;
  cashDeposit?: number;
  partExchange?: number;
  partExchangeSettlement?: number;
  extras?: number;
  rFL?: number;
  capId?: number;
  import?: boolean;
  regNo?: string;
  vehicleType?: string;
  usage?: string;
  vatQualify?: boolean;
}

export interface QuotationListResult {
  results: QuotationResults[];
  unableToQuote: UnableToQuote[];
}

export interface UnableToQuote {
  term: number;
  productType: ProductTypeEnum;
  messages: string[];
  lenderName: string;
}

export interface QuotationResults {
  id?: number;
  term: number;
  productType: ProductTypeEnum;
  monthlyPayment: number;
  cashPrice: number;
  deposit: number;
  finance: number;
  arrangementFee: number;
  completionFee: number;
  finalPayment: number;
  aprRate: number;
  flatRate: number;
  guaranteedFutureValue: number;
  guaranteed: boolean;
  lenderId?: number;
  lenderName?: string;
  loanId: number;
  commissionCode: string;
  commission: number;
  interestCharges: number;
}

export interface QuotationProps {
  showDealerSelect?: boolean;
  loggedInUser: User;
  quotation: Quotation;
  context?: ContextNames;
}

export interface QuotationData {
  quotation: Quotation;
}

export interface QuotationVariables {
  input: Quotation;
}

export enum QuotationTargetBy {
  FLAT_RATE = "FLAT_RATE",
  APR_RATE = "APR_RATE",
  COMMISSION_VALUE = "COMMISSION_VALUE",
  MONTHLY_PAYMENT = "MONTHLY_PAYMENT",
}

export const QuotationTargetByLookup: {
  [key in QuotationTargetBy]: {
    description: string;
    shortDescription?: string;
  };
} = {
  FLAT_RATE: { description: "Flat rate" },
  APR_RATE: { description: "APR rate" },
  COMMISSION_VALUE: {
    description: "Commission",
    shortDescription: "Comm.",
  },
  MONTHLY_PAYMENT: {
    description: "Monthly repayment",
    shortDescription: "Repayment",
  },
};

export default () => ({});
