import * as queryString from "query-string";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import { CompositeComponent } from "../utils/types";

export interface ToggleStateProps {
  /** Toggleable property */
  active: boolean;
  /** Toggles the "active" property between true and false */
  toggle: () => void;
}

/**
 * Provides a toggleable "active" property to child components like dropdowns
 * @param initialActive Indicates what the initial state of the "active" property should be
 */
export default <TProps extends {}>(initialActive = false) => (
  InnerComponent: CompositeComponent<ToggleStateProps & TProps>
): React.ComponentClass<TProps> => {
  return class ToggleStateComponent extends React.Component<
    TProps,
    { active: boolean }
  > {
    constructor(props: TProps) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { active: initialActive };
    }

    public render() {
      const { active } = this.state;

      return (
        <InnerComponent active={active} toggle={this.toggle} {...this.props} />
      );
    }

    private toggle() {
      this.setState(({ active }) => ({
        active: !active
      }));
    }
  };
};

export const useToggle = (initialActive = false) => {
  const [active, setActive] = React.useState(initialActive);
  const toggle = React.useCallback(() => setActive(!active), [
    active,
    setActive
  ]);

  return { active, toggle };
};

/** Provides a toggleable "active" property by setting or unsetting a query string parameter */
export const withQuerystringToggleState = <TProps extends {}>(
  paramName: string
) =>
  compose<ToggleStateProps & TProps, TProps>(
    withRouter,
    (InnerComponent: CompositeComponent<ToggleStateProps & TProps>) => {
      // tslint:disable-next-line:max-classes-per-file
      return class QueryStringToggleStateComponent extends React.Component<
        RouteComponentProps<any> & TProps
      > {
        constructor(props: RouteComponentProps<any> & TProps) {
          super(props);

          this.getIsActive = this.getIsActive.bind(this);
          this.toggle = this.toggle.bind(this);
        }

        public toggle() {
          const { location, history } = this.props;
          const previousQuery = queryString.parse(location.search);
          const active = !(previousQuery[paramName] === "true");

          // Copy the previous query string
          // to make sure we don't wipe any other parameters
          const query = { ...previousQuery, [paramName]: active };

          // Remove the menu parameter if it is false
          if (!active) {
            delete query[paramName];
          }

          // Set the new querystring by replacing the current history location
          history.replace({
            pathname: location.pathname,
            search: queryString.stringify(query)
          });
        }

        /** Get the menu state from the query string */
        public getIsActive() {
          const { location } = this.props;
          const query = queryString.parse(location.search);
          return query[paramName] === "true";
        }

        public render() {
          // Strip out the router props
          const {
            history,
            location,
            match,
            staticContext,
            ...rest
          } = this.props;

          return (
            <InnerComponent
              active={this.getIsActive()}
              toggle={this.toggle}
              {...(rest as TProps)}
            />
          );
        }
      };
    }
  );
