import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import gql from "graphql-tag";
import { assign } from "lodash";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { Button } from "reactstrap";
import { compose, onlyUpdateForKeys } from "recompose";
import { isNumeric } from "../../../utils";
import { Vehicle, VehicleTypeEnum } from "../../Proposals/types";
import { VehicleLookup } from "../types";

const GET_VEHICLE_LOOKUP = gql`
  query GetVehicleLookup($input: VehicleLookupArgsInput) {
    vehicles {
      vehicleLookup(input: $input) {
        make
        model
        capCode
        capId
        capDer
        regDate
        vIN
        colour
        doors
        engineSize
        fuel
        transmission
        lCV
        imported
      }
    }
  }
`;

type VehicleLookupFieldProps = FieldProps<{ vehicle: Vehicle }> & {
  autoFocus?: boolean;
};

class VehicleLookupField extends React.Component<
  WithApolloClient<VehicleLookupFieldProps>,
  { loading: boolean }
> {
  constructor(props: WithApolloClient<VehicleLookupFieldProps>) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.updateVehicle = this.updateVehicle.bind(this);
    this.state = { loading: false };
  }

  public render() {
    const { field, form, autoFocus } = this.props;
    const errors = form.errors as any;
    const { loading } = this.state;
    const notFound =
      form.values.vehicle.regNo &&
      form.values.vehicle.regNo === form.values.vehicle.regNoNotFound;

    return (
      <>
        <input
          type="text"
          {...field}
          value={field.value || ""}
          className="form-control vehicle-registration"
          placeholder="Find reg"
          spellCheck={false}
          autoFocus={autoFocus}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              this.handleClick();
            }
          }}
        />
        <Button
          className="mt-3 wide-button"
          disabled={!field.value || !!(form.errors as any)[field.name]}
          type="button"
          color="secondary"
          onClick={this.handleClick}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
          ) : null}
          Find vehicle
        </Button>
        {!loading &&
        !notFound &&
        !errors[field.name] &&
        errors.vehicle &&
        errors.vehicle.cAP ? (
          <div
            key="vehicle-feedback"
            className={
              (form.touched as any)[field.name]
                ? "invalid-feedback"
                : "form-text text-muted small"
            }
            style={{ display: "block" }}
          >
            {'Click "Find vehicle" to get the details'}
          </div>
        ) : null}
      </>
    );
  }

  private handleClick() {
    const { field, form, client } = this.props;
    const regNo = field.value;

    this.setState({ loading: true });

    client
      .query<{
        vehicles: { vehicleLookup: VehicleLookup };
      }>({
        query: GET_VEHICLE_LOOKUP,
        variables: {
          input: {
            regNo,
          },
        },
      })
      .then(({ data }) => {
        const vehicle = data && data.vehicles.vehicleLookup;
        this.updateVehicle(
          assign({}, vehicle, { regNoNotFound: vehicle ? "" : regNo })
        );
        form.setFieldTouched("vehicle.regNo", true);
        form.setFieldTouched("vehicle.cAP", true);
      })
      .finally(() => this.setState({ loading: false }));
  }

  private updateVehicle({
    capCode,
    capId,
    make,
    model,
    capDer,
    vIN,
    doors,
    engineSize,
    fuel,
    transmission,
    regDate,
    lCV,
    imported,
  }: Partial<VehicleLookup>) {
    const { form } = this.props;

    const vehicle = {
      regNo: form.values.vehicle.regNo,
      cAP: capCode || undefined,
      capId: capId || undefined,
      make: make || undefined,
      model: model || undefined,
      derivative: capDer || undefined,
      vIN: vIN || undefined,
      doors: doors || null,
      engineSize: isNumeric(engineSize) ? parseFloat(engineSize || "") : null,
      fuel: fuel || undefined,
      transmission:
        transmission && ["A", "M", "O"].includes(transmission)
          ? transmission
          : undefined,
      dateOfRegistration: regDate || undefined,
      regNoNotFound: capCode ? undefined : form.values.vehicle.regNo,
      LCV: !!lCV,
      vehicleType: !!lCV ? VehicleTypeEnum.Van : VehicleTypeEnum.Car,
      imported,
    };

    const nextValues = {
      ...form.values,
      vehicle: assign({}, form.values.vehicle, vehicle),
    };

    form.setValues(nextValues);
  }
}

export default compose<
  WithApolloClient<VehicleLookupFieldProps>,
  VehicleLookupFieldProps
>(
  onlyUpdateForKeys(["field"]),
  withApollo
)(VehicleLookupField);
