import * as React from "react";
import useHtmlElementClass from "../../useHtmlElementClass";
import "./SummarySection.scss";

const SummaryDisplaySection = ({
  title,
  subTitle,
  children
}: {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
}) => {
  useHtmlElementClass("smooth-scroll");
  return (
    <div className="mb-4">
      {title ? <h4 className="mb-4 summary-section-title">{title}</h4> : null}
      {subTitle ? (
        <h5 className="mb-4 summary-section-title">{subTitle}</h5>
      ) : null}
      {children}
    </div>
  );
};

export default SummaryDisplaySection;
