import * as React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ConfirmDialogProviderProps {
  show: (props: ConfirmDialogWithCallback) => void;
  cancel: () => void;
}

interface ConfirmDialogProps {
  title: string;
  message: string | React.ReactElement;
  confirmButtonText: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
}

interface ConfirmDialogWithCallback extends ConfirmDialogProps {
  confirmCallback: () => void;
}

const dummyContext: ConfirmDialogProviderProps = {
  show: () => {
    return;
  },
  cancel: () => {
    return;
  }
};

interface ConfirmDialogProviderState {
  dialog?: ConfirmDialogWithCallback;
  isDisplayed?: boolean;
}

const ConfirmDialogContext = React.createContext(dummyContext);

class ConfirmDialogProvider extends React.Component<
  { children: React.ReactNode },
  ConfirmDialogProviderState
> {
  constructor(props: any) {
    super(props);

    this.show = this.show.bind(this);
    this.cancel = this.cancel.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {};
  }

  public render() {
    const { children } = this.props;

    const dialog: Partial<ConfirmDialogProps & {
      confirmCallback: () => void;
    }> = this.state.dialog || {};

    return (
      <ConfirmDialogContext.Provider
        value={{
          show: this.show,
          cancel: this.cancel
        }}
      >
        <>
          {children}
          <Modal
            isOpen={!!this.state.isDisplayed}
            toggle={this.hide}
            onClosed={this.cancel}
            centered={true}
            modalTransition={{ timeout: 100 }}
          >
            <ModalHeader>{dialog.title}</ModalHeader>
            <ModalBody>{dialog.message}</ModalBody>
            <ModalFooter>
              <Button
                color={dialog.cancelButtonColor || "secondary"}
                onClick={this.hide}
                outline={true}
              >
                {dialog.cancelButtonText || "Cancel"}
              </Button>
              <Button
                color={dialog.confirmButtonColor || "primary"}
                onClick={() => {
                  dialog.confirmCallback && dialog.confirmCallback();
                  this.hide();
                }}
              >
                {dialog.confirmButtonText}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </ConfirmDialogContext.Provider>
    );
  }

  private show(dialog: ConfirmDialogWithCallback) {
    this.setState({ dialog, isDisplayed: true });
  }

  private hide() {
    this.setState({ isDisplayed: false });
  }

  private cancel() {
    this.setState({ dialog: undefined });
  }
}

export const ConfirmDialog = ({
  children,
  ...confirmProps
}: ConfirmDialogProps & {
  children: (confirm: (callback: () => void) => void) => JSX.Element | null;
}) => (
  <ConfirmDialogContext.Consumer>
    {({ show }) =>
      children(callback =>
        show({
          ...confirmProps,
          confirmCallback: callback
        })
      )
    }
  </ConfirmDialogContext.Consumer>
);

export default ConfirmDialogProvider;
