import * as React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Container } from "reactstrap";
import Alerts from "./Alerts";
import AlertMessages from "./Alerts/AlertMessages";
import Announcements from "./Announcements/Announcements";
import Auth0Login from "./Auth0Login";
import Auth0Callback from "./Auth0Login/Auth0Callback";
import PasswordlessAuth0Callback from "./Auth0Login/PasswordlessAuth0Callback";
import { useAuth0 } from "./Auth0Login/Auth0Provider";
import Forbidden from "./Forbidden";
import HomeScene from "./HomeScene";
import {
  GET_LOGGED_IN_USER_SHALLOW,
  useLoggedInUser,
} from "./LoggedInUserQuery";
import PageNotFound from "./PageNotFound";
import SceneLoadingSpinner from "./SceneLoadingSpinner";
import ScrollToTop from "./ScrollToTop";
import ServerError from "./ServerError";
import TopNavbar from "./TopNavbar";
import CreateProposalScene from "./Proposals/CreateProposalScene";
import { ContextNames, UserRoles } from "./types";
import DirectDealCompleteScene from "./Proposals/DirectDeals/DirectDealCompleteScene";
import DirectDealLogin from "./Proposals/DirectDeals/DirectDealLogin";

const quotationRouterPromise = import("./Quotations/QuotationRouter");
const QuotationRouter = React.lazy(() => quotationRouterPromise);

const proposalRouterPromise = import("./Proposals/ProposalRouter");
const ProposalRouter = React.lazy(() => proposalRouterPromise);

const dealerRouterPromise = import("./Dealers/DealerRouter");
const DealerRouter = React.lazy(() => dealerRouterPromise);

const accountManagerRouterPromise = import(
  "./AccountManagers/AccountManagerRouter"
);
const AccountManagerRouter = React.lazy(() => accountManagerRouterPromise);

const regionalSalesManagerRouterPromise = import(
  "./RegionalSalesManagers/RegionalSalesManagerRouter"
);
const RegionalSalesManagerRouter = React.lazy(
  () => regionalSalesManagerRouterPromise
);

const SettingsRouter = React.lazy(() => import("./Settings/SettingsRouter"));
const MigrateRouter = React.lazy(() => import("./Migrate/MigrateRouter"));

const releaseNotesPromise = import("./ReleaseNotes");
const ReleaseNotesPromiseScene = React.lazy(() => releaseNotesPromise);

const TermsAndConditions = React.lazy(() => import("./TermsAndConditions"));

/** Get the path to the login component, including the current path in the query string */
const getLoginPath = () => {
  const returnTo = window.location.pathname + window.location.search;
  // Add the current path to the querystring, to redirect after login
  const qs =
    returnTo && returnTo !== "/" && !returnTo.startsWith("/login")
      ? `?returnTo=${encodeURIComponent(returnTo)}`
      : "";

  return `/login${qs}`;
};

const useLoggedInStatus = (context: string | undefined) => {
  const {
    loading: userLoading,
    loggedInUser,
    error,
  } = useLoggedInUser(
    context === ContextNames.DIRECT_DEAL ? GET_LOGGED_IN_USER_SHALLOW : null
  );
  const { loading: auth0Loading, isAuthenticated } = useAuth0();

  const isLoggedIn = isAuthenticated && (!!loggedInUser || userLoading);

  const statusCode = (error?.networkError as any)?.statusCode;
  const isServerError = statusCode && statusCode >= 500 && statusCode < 600;

  const isDirectCustomer =
    loggedInUser && loggedInUser.roles.includes(UserRoles.direct_customer);

  const isForbidden =
    (!isServerError &&
      (!!(isAuthenticated && !loggedInUser) ||
        !!(isLoggedIn && loggedInUser && loggedInUser.isSuspended))) ||
    (isLoggedIn &&
      loggedInUser &&
      isDirectCustomer &&
      context !== ContextNames.DIRECT_DEAL);

  return {
    loading: userLoading || auth0Loading,
    isLoggedIn,
    isAuthenticated,
    isServerError,
    isForbidden,
    isDirectCustomer,
  };
};

const AppRouter = () => {
  const context =
    window.location.pathname.startsWith("/directdeal") ||
    window.location.pathname.startsWith("/callback-otp")
      ? ContextNames.DIRECT_DEAL
      : undefined;

  const {
    loading,
    isLoggedIn,
    isAuthenticated,
    isServerError,
    isForbidden,
    isDirectCustomer,
  } = useLoggedInStatus(context);

  return (
    <Router>
      <ScrollToTop>
        {isLoggedIn && !isForbidden && !isDirectCustomer && (
          <Announcements key="announcements" />
        )}
        <TopNavbar key="navbar" context={context} />
        {!loading && !isDirectCustomer && (
          <Container>
            <AlertMessages />
          </Container>
        )}
        {isAuthenticated &&
          !isForbidden &&
          !isServerError &&
          !isDirectCustomer && (
            <Container>
              <Alerts />
            </Container>
          )}
        {loading ? (
          <SceneLoadingSpinner />
        ) : (
          <React.Suspense fallback={<SceneLoadingSpinner />}>
            <Switch>
              <Route path="/tnc" component={TermsAndConditions} />
              {isForbidden && (
                <>
                  <Route
                    key="forbidden"
                    path="/forbidden"
                    component={Forbidden}
                    exact={true}
                  />
                  <Redirect key="forbidden-redirect" to="/forbidden" />
                </>
              )}
              {isServerError && (
                <Route
                  key="error"
                  path="/error"
                  component={ServerError}
                  exact={true}
                />
              )}
              {isServerError && <Redirect key="error-redirect" to="/error" />}
              <Route
                key="callback"
                path="/callback"
                component={Auth0Callback}
                exact={true}
              />

              <Route
                key="callback-otp"
                path="/callback-otp"
                component={PasswordlessAuth0Callback}
                exact={true}
              />

              {!isLoggedIn && (
                <Route
                  key="directdeal-login"
                  path="/directdeal-login"
                  component={DirectDealLogin}
                  exact={true}
                />
              )}
              {!isLoggedIn && (
                <Route
                  key="migrate"
                  path="/migrate"
                  component={MigrateRouter}
                />
              )}
              {!isLoggedIn && (
                <Route
                  key="login"
                  path="/login"
                  component={Auth0Login}
                  exact={true}
                />
              )}
              {!isLoggedIn && (
                <Redirect key="login-redirect" to={getLoginPath()} />
              )}
              <Route key="home" path="/" exact={true} component={HomeScene} />
              <Route path="/quotations" component={QuotationRouter} />
              <Route path="/proposals" component={ProposalRouter} />
              <Route path="/dealers" component={DealerRouter} />
              <Route path="/dealer" component={DealerRouter} />
              <Route path="/accountmanagers" component={AccountManagerRouter} />
              <Route path="/accountmanager" component={AccountManagerRouter} />
              <Route
                path="/regionalsalesmanagers"
                component={RegionalSalesManagerRouter}
              />
              <Route
                path="/regionalsalesmanager"
                component={RegionalSalesManagerRouter}
              />
              <Route
                path="/changelog"
                exact={true}
                component={ReleaseNotesPromiseScene}
              />
              <Route
                path="/releasenotes"
                exact={true}
                component={ReleaseNotesPromiseScene}
              />
              <Route
                path="/directdeal/completed"
                component={DirectDealCompleteScene}
              />
              <Route
                path="/directdeal/:directDealId"
                component={CreateProposalScene}
              />
              <Route path="/settings" component={SettingsRouter} />
              <Redirect from="/login" to="/" exact={true} />
              <Redirect from="/forbidden" to="/" exact={true} />
              <Redirect from="/error" to="/" exact={true} />
              <Redirect from="/migrate" to="/" />
              <Route path="/" component={PageNotFound} />
            </Switch>
          </React.Suspense>
        )}
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
