import { FieldProps } from "formik";
import * as React from "react";
import CustomSelect from "../../Forms/CustomSelect";
import { QuotationFormValues } from "../types";
import "./index.scss";
import VehicleMakesQuery from "./VehicleMakesQuery";

const VehicleMakeField = ({
  placeholder,
  ...props
}: FieldProps<QuotationFormValues> & {
  placeholder?: string;
}) => {
  const { form, field } = props;

  return (
    <VehicleMakesQuery isCommercial={!!form.values.vehicle.isCommercial}>
      {({ loading, error, data }) => {
        const options =
          loading || !data
            ? []
            : data.vehicles.vehicleMakes.map((x) => ({
                value: x.id,
                label: x.name,
              }));

        return (
          <CustomSelect
            placeholder={
              loading ? "Loading..." : placeholder || "Select make..."
            }
            isDisabled={loading || !data}
            name={field.name}
            value={
              field.value ? { label: field.value, value: field.value } : null
            }
            onChange={(option: any) => {
              form.setFieldValue(field.name, (option as any).value);
              form.setFieldValue("vehicle.model", undefined);
            }}
            onBlur={() => form.setFieldTouched(field.name, true)}
            options={options}
          />
        );
      }}
    </VehicleMakesQuery>
  );
};

export default VehicleMakeField;
