import * as React from "react";
import { ProposalFormInitialValues } from "../Proposals/ProposalForm";
import { ProposalType, Vehicle } from "../Proposals/types";
import QuotationQuery from "./QuotationQuery";
import { Quotation } from "./types";

interface ProposalQuotationQueryInnerProps {
  quotation?: Quotation;
  quotationInitialValues?: ProposalFormInitialValues;
  loading?: boolean;
}

interface ProposalQuotationQueryProps {
  quotationId?: number;
  proposalType?: ProposalType;
  children: (props: ProposalQuotationQueryInnerProps) => JSX.Element | null;
}

/** Map a quotation to the initial values required for a proposal */
const getProposalInitialValues = (
  quotation?: Quotation,
  proposalType?: ProposalType
): ProposalFormInitialValues | undefined => {
  if (!quotation) {
    return undefined;
  }

  const isIndividual = proposalType === ProposalType.INDIVIDUAL;

  const {
    id: quotationId,
    title,
    forename,
    middleName,
    surname,
    dealerId,
    vehicle: quotationVehicle,
    targetBy,
    targetByValue,
  } = quotation;

  const {
    flatRate,
    aprRate,
    term,
    monthlyPayment,
    guaranteedFutureValue,
    cashPrice,
    deposit,
    productType,
    partExchangeValue,
    partExchangeSettlement,
    arrangementFee,
    completionFee,
  } = quotation.finance;

  const vehicle: Partial<Vehicle> = quotationVehicle
    ? {
        ...quotationVehicle,
        isRegUnknown: !!quotationVehicle.cAP && !quotationVehicle.regNo,
      }
    : {};

  delete vehicle.__typename;

  const result: any = {
    quotationId,
    dealerId,
    targetBy,
    targetByValue,
    individualCustomer: isIndividual
      ? { title, forename, middleName, surname }
      : undefined,
    vehicle: vehicle
      ? {
          isRegUnknown: !vehicle.regNo,
          isCommercial: vehicle.isCommercial || false,
          ...vehicle,
        }
      : {},
    finance: {
      rate: flatRate,
      aprRate,
      period: term,
      monthlyPayment,
      balloonPayment: guaranteedFutureValue,
      partExchange: partExchangeValue,
      partExchangeSettlement,
      cashPrice,
      cashDeposit: deposit,
      productType,
      acceptanceFee: arrangementFee,
      optionFee: completionFee,
      lenderId: quotation.lender?.id,
      lenderName: quotation.lender?.name,
    },
  };

  return {
    initialValues: result,
    proposalType,
  };
};

/** Gets a quotation and maps it to the initial values required for a proposal */
const ProposalQuotationQuery = ({
  quotationId,
  proposalType,
  children,
}: ProposalQuotationQueryProps) => {
  return (
    <QuotationQuery quotationId={quotationId}>
      {({ quotation, loading }) =>
        children({
          quotation,
          loading,
          quotationInitialValues: getProposalInitialValues(
            quotation,
            proposalType
          ),
        })
      }
    </QuotationQuery>
  );
};

export default ProposalQuotationQuery;
