import * as React from "react";
import { useParams } from "react-router";
import { getFullName } from "../../utils";
import Breadcrumbs from "../Breadcrumbs";
import { useDealer, useLoggedInDealer } from "../Dealers/DealerQuery";
import { Dealer } from "../Dealers/types";
import { useQuotation } from "../Quotations/QuotationQuery";
import { Quotation } from "../Quotations/types";
import { useDraftProposal } from "./DraftProposals/DraftProposalQuery";

const getBreadcrumbData = (
  dealer?: Dealer,
  proposalType?: string,
  quotation?: Quotation,
  draftProposalId?: number
) => {
  if (dealer) {
    return [
      { to: "/", title: "Home" },
      { to: "/dealers", title: "Dealers" },
      { to: `/dealers/${dealer.id}/proposals`, title: dealer.name },
      {
        to: `/dealers/${dealer.id}/proposals/create`,
        title: "Proposal type"
      },
      {
        to: `/dealers/${dealer.id}/proposals/create/${proposalType}`,
        title: `New ${proposalType} proposal`,
        active: true
      }
    ];
  }
  if (quotation) {
    const quotationName = getFullName(quotation);
    return [
      { to: "/", title: "Home" },
      { to: "/quotations", title: "Quotations" },
      {
        to: `/quotations/${quotation && quotation.id}`,
        title: quotation && quotationName ? quotationName : "Untitled quotation"
      },
      {
        to: `/quotations/${quotation && quotation.id}/convert`,
        title: "Proposal type"
      },
      {
        to: `/quotations/${quotation && quotation.id}/convert/${proposalType}`,
        title: "Convert quotation to new proposal",
        active: true
      }
    ];
  }
  if (draftProposalId) {
    if (!proposalType) {
      return [];
    }

    return [
      { to: "/", title: "Home" },
      { to: "/proposals", title: "Proposals" },
      { to: "/proposals/drafts", title: "Draft proposals" },
      {
        to: `/proposals/drafts/${draftProposalId}`,
        title: `New ${proposalType} proposal`,
        active: true
      }
    ];
  }
  return [
    { to: "/", title: "Home" },
    { to: "/proposals", title: "Proposals" },
    { to: "/proposals/create", title: "Proposal type" },
    {
      to: `/proposals/create/${proposalType}`,
      title: `New ${proposalType} proposal`,
      active: true
    }
  ];
};

const ProposalBreadcrumbs = () => {
  const params = useParams<{
    dealerId?: string;
    proposalType?: string;
    quotationId?: string;
    draftProposalId?: string;
  }>();

  const dealerId = params.dealerId ? parseInt(params.dealerId, 10) : undefined;
  const quotationId = params.quotationId
    ? parseInt(params.quotationId, 10)
    : undefined;
  const draftProposalId = params.draftProposalId
    ? parseInt(params.draftProposalId, 10)
    : undefined;

  const {
    dealer: loggedInDealer,
    loading: loggedInDealerLoading
  } = useLoggedInDealer();
  const { draftProposal, loading: draftProposalLoading } = useDraftProposal(
    draftProposalId
  );
  const { quotation, loading: quotationLoading } = useQuotation(quotationId);
  const { dealer, loading: dealerLoading } = useDealer(dealerId);

  if (
    loggedInDealerLoading ||
    draftProposalLoading ||
    quotationLoading ||
    dealerLoading
  ) {
    return null;
  }

  const proposalType = (
    params.proposalType || draftProposal?.proposalType
  )?.toLowerCase();

  return (
    <Breadcrumbs
      breadcrumbs={getBreadcrumbData(
        loggedInDealer || dealer,
        proposalType,
        quotation,
        draftProposalId
      )}
    />
  );
};

export default ProposalBreadcrumbs;
