import { cloneDeepWith, defaults, isArray } from "lodash";
import moment from "moment";
import {
  Business,
  BusinessDirector,
  EmploymentDetails,
  Vehicle,
  ProposalStatusEnum,
} from "../components/Proposals/types";
import { Address, User, UserRoles } from "../components/types";

/** Get the last two lines of an address for display */
export const getShortAddress = ({ line1, line2, line3, town }: Address) => {
  return [town, line2, line3, line1]
    .map((line) => (line ? line.trim() : ""))
    .reduce((prev: string[], line: string) => {
      if (line && prev.length < 2) {
        prev.push(line);
      }
      return prev;
    }, [])
    .reverse()
    .join(", ");
};

/** Join the title, forename and surname */
export const getFullName = ({
  title,
  forename,
  middleName,
  surname,
}: {
  title?: string;
  forename?: string;
  middleName?: string;
  surname?: string;
}) =>
  [title !== "Other" ? title : null, forename, middleName, surname]
    .filter((x) => !!x)
    .join(" ");

/** Postal address in a single line */
export const getSingleLineAddress = ({
  line1,
  line2,
  line3,
  town,
  postcode,
}: Address) => {
  return [line1, line2, line3, town, postcode]
    .map((line) => (line ? line.trim() : ""))
    .filter((x) => !!x)
    .join(", ");
};

export const hasAddress = ({ line1, line2, line3, town, postcode }: any) => {
  return line1 && (line2 || line3 || town || postcode);
};

export const formatCurrency = (value?: number) =>
  value || value === 0
    ? new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(value)
    : "-";

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const convertNumber = (n: string | undefined) =>
  n && isNumeric(n) ? parseFloat(n) : 0;

export const cleanNumber = (number: any) => {
  if (typeof number === "number") {
    return number;
  }
  if (number && typeof number === "string" && isNumeric(number)) {
    return convertNumber(number);
  }
  return undefined;
};

export const roundNumber = (rate?: number) => {
  return isNumeric(rate)
    ? (Math.round((rate || 0) * 100) / 100).toFixed(2)
    : undefined;
};

export const shortenNumberForDisplay = (value?: number) => {
  if (!value && value !== 0) {
    return "";
  }

  if (value / 1000000 > 1) {
    return `${Math.round(value / 1000000)}m`;
  }
  if (value / 1000 > 1) {
    return `${Math.round(value / 1000)}k`;
  }
  return Math.round(value).toString();
};

export const formatEnumValue = (enumValue?: string) => {
  const result = enumValue ? enumValue.replace(/_/g, " ") : "";
  return capitalizeFirstLetter(result.toLowerCase());
};

export const formatToPercentage = (value?: number) => {
  return `${Math.round(value ? value : 0)}%`;
};

export const getProportion = (numerator?: number, denominator?: number) => {
  const result = denominator ? ((numerator || 0) / denominator) * 100 : 0;
  return formatToPercentage(result);
};

export const capitalizeFirstLettersOnly = (value: string) =>
  value
    .split(" ")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const convertToTitleCase = (value: string) =>
  value
    .split(" ")
    .map((x) => capitalizeFirstLetter(x))
    .join(" ");

/** Get vehicle details in a single line */
export const getSingleLineVehicle = ({ make, model, derivative }: Vehicle) => {
  return [make, model, derivative]
    .map((line) => (line ? line.trim() : ""))
    .filter((x) => !!x)
    .join(", ");
};

export const getMonthsAndYearsText = (totalMonths: number) => {
  const months = totalMonths % 12;
  const years = (totalMonths - months) / 12;
  const monthsText = months
    ? `${months} ${months > 1 ? "months" : "month"}`
    : "";
  const yearsText = years ? `${years} ${years > 1 ? "years" : "year"}` : "";

  return totalMonths ? `${yearsText}${monthsText ? ` ${monthsText}` : ""}` : "";
};

export const getMonthDateRange = (date: moment.Moment) => ({
  label: date.format("MMMM YYYY"),
  value: {
    start: date.startOf("month").toISOString(true),
    end: date
      .startOf("month")
      .add(1, "month")
      .subtract(1, "day")
      .endOf("day")
      .toISOString(true),
  },
});

export const getYearDateRange = (date: moment.Moment) => ({
  label: date.format("YYYY"),
  value: {
    start: date.startOf("year").toISOString(true),
    end: date
      .startOf("year")
      .add(1, "year")
      .subtract(1, "day")
      .endOf("day")
      .toISOString(true),
  },
});

export const getFinancialYearDateRange = (date: moment.Moment) => {
  const adjustedDate =
    date.month() >= 3 ? date.clone().subtract(1, "year") : date.clone();

  const startDate = adjustedDate.month("April").startOf("month");
  const endDate = adjustedDate
    .clone()
    .add(1, "year")
    .subtract(1, "day")
    .endOf("day");

  return {
    label: `FY ${startDate.format("YYYY")}-${endDate.format("YYYY")}`,
    value: {
      start: startDate.toISOString(true),
      end: endDate.toISOString(true),
    },
  };
};

export const getCurrentQuarter = (date: moment.Moment) => {
  const startDate = date.clone().startOf("quarter");
  const endDate = date.clone().endOf("quarter");

  return {
    value: {
      start: startDate.toISOString(true),
      end: endDate.toISOString(true),
    },
  };
};

export const validatePassword = (
  password: string,
  minScore: number = 3,
  minLength: number = 8
) => {
  // Fail early if the password isn't long enough
  if (!password || password.length < minLength) {
    return false;
  }

  const containsLowerCase = new RegExp("^(?=.*[a-z])").test(password);
  const containsUpperCase = new RegExp("^(?=.*[A-Z])").test(password);
  const containsNumbers = new RegExp("^(?=.*[0-9])").test(password);
  const containsSymbols = new RegExp('^(?=.*[!@#$%^&*(),.?":{}|<>])').test(
    password
  );

  const score =
    (containsLowerCase ? 1 : 0) +
    (containsUpperCase ? 1 : 0) +
    (containsNumbers ? 1 : 0) +
    (containsSymbols ? 1 : 0);

  return score >= minScore;
};

const shouldRemoveFormField = (
  field: string,
  value: any,
  keepNulls: boolean,
  removeIdFields: boolean
) => {
  // Remove special fields which should not be sent to the server
  if (field === "__typename" || field.startsWith("FORMSTATE_")) {
    return true;
  }
  // Remove empty fields
  if (!keepNulls) {
    if (value === null || value === undefined || value === "") {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
  }
  // Optionally remove id fields
  if (removeIdFields && field === "id") {
    return true;
  }
  return false;
};

const cleanFormDataCore = (
  formData: any,
  shouldRemoveField: (field: string, value: any) => boolean
) =>
  cloneDeepWith(formData, (value) => {
    if (value && !isArray(value)) {
      if (typeof value === "object") {
        return Object.keys(value)
          .filter((k) => !shouldRemoveField(k, value[k]))
          .reduce((clone, k) => {
            clone[k] = cleanFormDataCore(value[k], shouldRemoveField);
            return clone;
          }, {} as any);
      }

      if (typeof value === "string") {
        return value.trim();
      }
    }
  });

/**
 * Deep clones and cleans a form data object, ready to submit to the server.
 * Removes special fields "__typename" and "FORMSTATE_*"".
 * Removes empty fields.
 * Optionally removes "id" fields
 * @param formData Form data to clone and clean
 * @param options Options for fields to remove
 */
export const cleanFormData = (
  formData: any,
  options?: {
    keepNulls?: boolean;
    removeIdFields?: boolean;
  }
) => {
  const defaultOptions = {
    keepNulls: false,
    removeIdFields: false,
  };
  const mergedOptions = defaults({}, options, defaultOptions);
  const shouldRemoveField = (field: string, value: any) =>
    shouldRemoveFormField(
      field,
      value,
      mergedOptions.keepNulls,
      mergedOptions.removeIdFields
    );

  return cleanFormDataCore(formData, shouldRemoveField);
};

export const hasEmploymentDetails = (employer: EmploymentDetails) => {
  return (
    employer &&
    (employer.employmentTerms ||
      employer.employmentStatus ||
      employer.employmentType ||
      employer.occupation ||
      employer.employerName ||
      employer.earnings ||
      employer.yearsWithEmployer ||
      employer.monthsWithEmployer ||
      hasAddress(employer.address) ||
      employer.industry)
  );
};

export const hasBusinessDetails = (business: Business) => {
  return !!(
    business &&
    (business.name ||
      business.contactName ||
      business.contactPosition ||
      business.email ||
      business.mobile ||
      business.registrationNumber ||
      business.natureOfBusiness ||
      business.businessType ||
      business.otherBusinessType ||
      business.established ||
      hasAddress(business.address) ||
      (business.monthsAtAddress && business.yearsAtAddress))
  );
};

export const hasDirectorDetails = (director: BusinessDirector) => {
  return !!(director && director.forename && director.surname);
};

/** Test function to validate a date string.  Accepts empty strings. */
export const testDateStringIsValid = (value: string) => {
  // Ignore empty values
  if (!value) {
    return true;
  }

  return new RegExp(DATE_PATTERN).test(value) && moment(value).isValid();
};

export const isAdministrator = (user: User) =>
  user.roles.includes(UserRoles.administrator);

export const isDealer = (user: User) => user.roles.includes(UserRoles.dealer);

export const isAccountManager = (user: User) =>
  user.roles.includes(UserRoles.account_manager);

export const isSuperUser = (user: User) =>
  [UserRoles.senior_manager, UserRoles.administrator].some((r) =>
    user.roles.includes(r)
  );

export const canManageContacts = isSuperUser;

export const canManageUsers = isSuperUser;

export const canManageAccountManagers = isSuperUser;

export const canManageRegionalSalesManagers = isSuperUser;

export const canManageTargets = (user: User) =>
  isSuperUser(user) || user.roles.includes(UserRoles.regional_sales_manager);

export const canViewNetProfit = canManageTargets;

export const canManageProposalFiles = (user: User) =>
  isSuperUser(user) ||
  user.roles.includes(UserRoles.case_management_team) ||
  user.roles.includes(UserRoles.account_manager) ||
  user.roles.includes(UserRoles.regional_sales_manager);

export const canEditProposal = (
  user: User,
  proposalStatus: ProposalStatusEnum
) => {
  if (user.roles.includes(UserRoles.administrator)) {
    return true;
  }

  const hasPermissionToEdit = user.roles.includes(UserRoles.dealer);
  const proposalCanBeEdited =
    proposalStatus === ProposalStatusEnum.UNDERWRITING ||
    proposalStatus === ProposalStatusEnum.ACCEPTED;

  return hasPermissionToEdit && proposalCanBeEdited;
};

export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const DATE_PATTERN = /^\d{4}-\d{1,2}-\d{1,2}$/;

export const GUID_PATTERN =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const SPECIAL_CHARACTER_SELECT = /[&/\\#,+()$~%.'£":*^-_=?!<>{}]/g;

/** Adds the http protocol to a url string */
export const addHttp = (url: string) => {
  if (url.search(/^http[s]?:\/\//) === -1) {
    return `http://${url}`;
  }
  return url;
};

/**
 * Gets a formatted date, including the time if it is recent.
 * Behaviour is roughly the same as dates in Outlook.
 */
export const getShortDateAndTime = (date?: string | Date) => {
  if (!date) {
    return null;
  }

  const now = moment();
  const then = moment(date);

  if (then.isSame(now, "day")) {
    return moment(date).format("HH:mm");
  }

  if (then.isSame(now, "week")) {
    return moment(date).format("ddd HH:mm");
  }

  if (
    then.isSame(now, "year") &&
    then.isSameOrAfter(moment().subtract(1, "month"))
  ) {
    return moment(date).format("ddd DD/MM");
  }

  return then.format("DD/MM/YYYY");
};

/**
 * Gets a formatted date, including the time if it is recent.
 * Behaviour is roughly the same as dates in Outlook.
 */
export const getShortDate = (date?: string | Date) => {
  if (!date) {
    return null;
  }

  const now = moment();
  const then = moment(date);

  if (then.isSame(now, "day")) {
    return "Today";
  }

  if (then.isSame(moment().subtract(1, "day"))) {
    return "Yesterday";
  }

  if (then.isSame(now, "week")) {
    return moment(date).format("ddd");
  }

  if (then.isSame(now, "year")) {
    return moment(date).format("ddd DD/MM");
  }

  return then.format("DD/MM/YYYY");
};
