import classnames from "classnames";
import { Field, FieldProps } from "formik";
import * as React from "react";
import { Col } from "reactstrap";
import FormGroupWrapper from "./FormGroupWrapper";
import "./formstyles.scss";

interface RadioFieldProps {
  name: string;
  description?: string;
  title?: string;
  colSize?: number;
  options: { label: any; value: any }[];
  vertical?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  className?: string;
}

const RadioField = <TValues extends {}>({
  name,
  description,
  options,
  title,
  colSize,
  vertical,
  readOnly,
  autoFocus,
  className
}: RadioFieldProps) => (
  <Field name={name}>
    {({ form, field }: FieldProps<TValues>) => (
      <Col lg={colSize}>
        <FormGroupWrapper
          form={form}
          field={field}
          title={title}
          description={description}
          className={classnames("radio-field", className, {
            vertical,
            horizontal: !vertical
          })}
        >
          <div>
            {options.map((opt, i) => (
              <div
                key={opt.value || 0}
                className={classnames("custom-control custom-radio", {
                  "custom-control-inline": !vertical
                })}
              >
                <input
                  id={`${name}_${opt.value}`}
                  type="radio"
                  className="custom-control-input"
                  checked={
                    field.value === opt.value ||
                    (field.value === null && opt.value === undefined)
                  }
                  value={opt.value || opt.value === 0 ? opt.value : undefined}
                  name={name}
                  readOnly={!!readOnly}
                  autoFocus={i === 0 && autoFocus}
                  onChange={() => {
                    if (!readOnly) {
                      form.setFieldValue(name, opt.value);
                      form.setFieldTouched(name, true);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${name}_${opt.value}`}
                >
                  {opt.label}
                </label>
              </div>
            ))}
          </div>
        </FormGroupWrapper>
      </Col>
    )}
  </Field>
);

export default RadioField;
