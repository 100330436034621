import {
  faChevronDown,
  faChevronUp,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray } from "formik";
import * as React from "react";
import { Button } from "reactstrap";
import {
  formatEnumValue,
  getMonthsAndYearsText,
  getSingleLineAddress,
  hasAddress
} from "../../../utils";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import { HomeAddress, Proposal } from "../types";
import HomeAddressSection from "./HomeAddressSection";
import { homeAddressSchema } from "./individualCustomerValidationSchema";

const ValidateAddressDates = (address: HomeAddress) => {
  return (
    (address.monthsAtAddress || address.monthsAtAddress === 0) &&
    (address.yearsAtAddress || address.yearsAtAddress === 0)
  );
};

const validateAddress = (address: HomeAddress) => {
  return (
    hasAddress(address) && address.ownership && ValidateAddressDates(address)
  );
};

export const homeAddressHistoryWatchFields: WatchFields<Proposal> = {
  individualCustomer: {
    homeAddresses: [
      {
        line1: true,
        line2: true,
        line3: true,
        town: true,
        county: true,
        postcode: true,
        countryId: true,
        telephone: true,
        yearsAtAddress: true,
        monthsAtAddress: true,
        ownership: true,
        otherOwnership: true,
        totalMonthlyRentOrMortgage: true
      }
    ],
    homeAddressesError: true
  }
};

class HomeAddressHistorySection extends React.Component<
  FormSectionProps<Proposal>,
  { isValidated: boolean; collapsedItems: number[] }
> {
  constructor(props: FormSectionProps<Proposal>) {
    super(props);
    this.collapseAddress = this.collapseAddress.bind(this);
    this.expandAddress = this.expandAddress.bind(this);

    this.state = {
      isValidated: false,
      collapsedItems: []
    };

    const { individualCustomer } = this.props.values;
    if (individualCustomer.homeAddresses.length > 1) {
      individualCustomer.homeAddresses.forEach((_, i) => {
        this.state.collapsedItems.push(i);
      });
    }
  }

  public render() {
    const { collapsedItems } = this.state;
    const errors = this.props.errors;

    const { individualCustomer } = this.props.values;
    const requiresHomeAddress =
      !!(
        !individualCustomer.homeAddresses ||
        !individualCustomer.homeAddresses.length
      ) ||
      !!(
        errors.individualCustomer &&
        (errors.individualCustomer as any).homeAddressesError
      );

    return (
      <FieldArray name="individualCustomer.homeAddresses">
        {arrayHelpers => (
          <div>
            {individualCustomer.homeAddresses &&
              individualCustomer.homeAddresses.map((address, i) => {
                const isOpen = collapsedItems.indexOf(i) === -1;
                return (
                  <div key={i}>
                    <h5
                      className="my-3 section-title pointer"
                      onClick={
                        isOpen
                          ? () => this.collapseAddress(i)
                          : () => this.expandAddress(i)
                      }
                    >
                      {i > 0 ? <>Previous address {i}</> : <>Current address</>}
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={isOpen ? faChevronUp : faChevronDown}
                      />
                    </h5>
                    <div>
                      {isOpen ? (
                        <>
                          <HomeAddressSection
                            index={i}
                            address={address}
                            remove={() => arrayHelpers.remove(i)}
                            // isAddressValid={this.validateAddress(i)}
                            {...this.props}
                          />
                          {i > 0 ? (
                            <Button
                              type="button"
                              color="link"
                              className="pl-0 small"
                              onClick={() => arrayHelpers.remove(i)}
                            >
                              Remove this address
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            this.expandAddress(i);
                          }}
                          className="pointer pb-3"
                        >
                          <p className="mb-1">
                            {hasAddress(address) ? (
                              getSingleLineAddress(address)
                            ) : i > 0 ? (
                              <>No previous address provided</>
                            ) : (
                              <>No current address provided</>
                            )}
                          </p>
                          {address.ownership ? (
                            <p className="mb-1 text-muted">
                              Home ownership:{" "}
                              {formatEnumValue(address.ownership)}
                            </p>
                          ) : null}
                          {ValidateAddressDates(address) ? (
                            <p className="mb-1 text-muted">
                              {getMonthsAndYearsText(
                                (address.yearsAtAddress
                                  ? address.yearsAtAddress
                                  : 0) *
                                  12 +
                                  (address.monthsAtAddress
                                    ? address.monthsAtAddress
                                    : 0)
                              )}
                            </p>
                          ) : null}
                          {!validateAddress(address) ? (
                            <p className="text-danger small mt-2">
                              Home address section is incomplete
                            </p>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            {requiresHomeAddress ? (
              <>
                <Button
                  type="button"
                  onClick={() => {
                    arrayHelpers.push(homeAddressSchema.default());
                    this.collapseAddress(
                      individualCustomer.homeAddresses.length - 1
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add previous address
                </Button>
                {errors.individualCustomer &&
                (errors.individualCustomer as any).homeAddressesError ? (
                  <p className="text-danger small mt-2 mb-3">
                    {(errors.individualCustomer as any).homeAddressesError}
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
        )}
      </FieldArray>
    );
  }

  private collapseAddress(i: number) {
    const array = this.state.collapsedItems.slice();
    if (array.indexOf(i) === -1) {
      array.push(i);

      this.setState({
        collapsedItems: array
      });
    }
  }

  private expandAddress(i: number) {
    const array = this.state.collapsedItems.slice();
    array.splice(array.indexOf(i), 1);

    this.setState({
      collapsedItems: array
    });
  }
}

export default HomeAddressHistorySection;
