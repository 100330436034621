export interface AlertMessage {
  id: string;
  title?: string;
  message: string;
  redirectPath?: string;
  color: AlertColor;
  count: number;
  startDate?: Date;
  endDate?: Date;
  priority?: number;
  onClick?: () => void;
}

export interface AlertsProps {
  alerts: AlertMessage[];
  addAlert: (alert: {
    message: string;
    color?: AlertColor;
    onClick?: () => void;
  }) => void;
  removeAlert: (id: string) => void;
  clearAlerts: () => void;
}

export enum AlertColor {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark"
}
