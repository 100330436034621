import { Field, FieldProps } from "formik";
import * as React from "react";
import { Col } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import CountrySelect from "./CountrySelect";

const CountryField = ({
  name,
  label,
  colSize,
}: {
  name: string;
  label: string;
  colSize?: number;
}) => {
  return (
    <Col lg={colSize}>
      <Field name={name}>
        {(fieldProps: FieldProps<any>) => (
          <CountryFieldInner {...fieldProps} title={label} />
        )}
      </Field>
    </Col>
  );
};

const CountryFieldInner = ({
  title,
  ...fieldProps
}: FieldProps<any> & { title: string }) => {
  const country = fieldProps.field.value;
  const otherCountryInitial = !!country ? country !== "GBR" : undefined;
  const [otherCountry, setOtherCountry] = React.useState(otherCountryInitial);

  const {
    form: { setFieldValue, setFieldTouched },
    field: { name },
  } = fieldProps;

  const onRadioChanged = (value: boolean) => {
    const countryId = value ? null : "GBR";
    setFieldValue(name as any, countryId);
    setFieldTouched(name as any, true);
    setOtherCountry(value);
  };

  return (
    <FormGroupWrapper
      {...fieldProps}
      title={title}
      className="radio-field horizontal country-field"
    >
      <div className="mb-3">
        {[
          { label: "United Kingdom", value: false },
          { label: "Other", value: true },
        ].map(({ label, value }) => {
          const key = `${name}_${value}`;
          return (
            <div
              key={key}
              className="custom-control custom-radio custom-control-inline"
            >
              <input
                id={key}
                type="radio"
                className="custom-control-input"
                checked={otherCountry === value}
                name={key}
                onChange={() => onRadioChanged(value)}
              />
              <label className="custom-control-label" htmlFor={key}>
                {label}
              </label>
            </div>
          );
        })}
      </div>
      {otherCountry && (
        <CountrySelect
          className="proposal-country-select"
          key={`${name}_country`}
          countryId={fieldProps.field.value}
          onSelectCountry={(countryId) => {
            setFieldValue(name, countryId);
            setFieldTouched(name, true);
          }}
        />
      )}
    </FormGroupWrapper>
  );
};

export default CountryField;
