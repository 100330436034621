import * as React from "react";
import { capitalizeFirstLettersOnly } from "../../../utils";
import FormikEffects from "../../Forms/FormikEffects";

/**
 * Automatically capitalizes the customer name on the proposal form
 */
const CapitalizeCustomerName = () => (
  <FormikEffects
    onChange={(prevValues, nextValues, { setFieldValue }) => {
      // Automatically capitalize the first letters of the customer name
      if (nextValues.individualCustomer) {
        ["title", "forename", "middleName", "surname"].forEach((field) => {
          const prevVal = prevValues.individualCustomer[field];
          const nextVal = nextValues.individualCustomer[field];
          if (nextVal && prevVal !== nextVal) {
            const titleCase = capitalizeFirstLettersOnly(nextVal);
            if (titleCase !== nextVal) {
              setFieldValue(`individualCustomer.${field}`, titleCase, false);
            }
          }
        });
      }
    }}
  />
);

export default CapitalizeCustomerName;
