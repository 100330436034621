import { AccountManager } from "./AccountManagers/types";
import { Dealer } from "./Dealers/types";
import { Permission } from "./Permissions/types";

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export enum UserRoles {
  dealer = "dealer",
  account_manager = "account_manager",
  regional_sales_manager = "regional_sales_manager",
  case_management_team = "case_management_team",
  senior_manager = "senior_manager",
  administrator = "administrator",
  partnership = "partnership",
  agent = "agent",
  group_manager = "group_manager",
  direct_customer = "direct_customer",
}

export enum DebounceKeys {
  VEHICLE_MAKES = "VEHICLE_MAKES",
  VEHICLE_MODELS = "VEHICLE_MODELS",
  VEHICLE_STYLES = "VEHICLE_STYLES",
  SEARCH_IDENTITY_PROVIDER_USERS = "SEARCH_IDENTITY_PROVIDER_USERS",
  DEALER_LIST = "DEALER_LIST",
  ACCOUNT_MANAGER_LIST = "ACCOUNT_MANAGER_LIST",
  ACCOUNT_MANAGER_TARGETS_LIST = "ACCOUNT_MANAGER_TARGETS_LIST",
}

export enum ContextNames {
  DEALER = "DEALER",
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER",
  REGIONAL_SALES_MANAGER = "REGIONAL_SALES_MANAGER",
  UPDATE_FORM = "UPDATE_FORM",
  QUOTATION_FORM = "QUOTATION_FORM",
  DIRECT_DEAL = "DIRECT_DEAL",
}

export enum FormSize {
  NARROW = "NARROW",
  WIDE = "WIDE",
}

export interface User {
  id?: string;
  applicationUserId: string;
  username: string;
  isSuspended: boolean;
  canSubmitProposals: boolean;
  roles: UserRoles[];
  dealer?: Dealer;
  forename?: string;
  surname?: string;
  tawkTo?: {
    enabled?: boolean;
    hash?: string;
  };
  accountManager?: AccountManager;
  regionalSalesManager?: {
    id: string;
  };
  permissions?: Permission[];
}

export interface Address {
  id?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  town?: string;
  county?: string;
  postcode?: string;
  countryId?: string;
  telephone?: string;
  extension?: string;
  fax?: string;
  totalMonthlyRentOrMortgage?: number;
}

export interface PagedArgs {
  page?: number;
  pageSize?: number;
}

export interface SearchArgs extends PagedArgs {
  q?: string;
}

export interface PageInfo {
  hasMorePages: boolean;
  page: number;
  pageSize: number;
  totalResults?: number;
  first: number;
  last: number;
}

export interface SearchResults<T> {
  pageInfo: PageInfo;
  edges: {
    node: T;
  }[];
}

export enum ProductTypeEnum {
  PCP = "PCP",
  HP = "HP",
  LP = "LP",
}

export type CompositeComponent<P> =
  | React.StatelessComponent<P>
  | React.ComponentClass<P>;

export default () => ({});
