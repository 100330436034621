import createAuth0Client from "@auth0/auth0-spa-js";

// tslint:disable-next-line:no-submodule-imports
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import * as React from "react";
import uuid from "uuid";
import config from "../../config";

interface Auth0ContextValues {
  loginWithRedirect?: ({
    returnTo,
    email,
  }: {
    returnTo?: string;
    email?: string;
  }) => Promise<void>;
  getToken?: () => Promise<any>;
  logout?: () => void;
  handleRedirectCallback?: () => Promise<{ returnTo: string }>;
  loading: boolean;
  isAuthenticated: boolean;
  handlePasswordlessRedirectCallback?: () => Promise<{ returnTo: string }>;
}

export const getRedirectUri = () => {
  const url =
    config.PUBLIC_URL ||
    [window.location.protocol, "//", window.location.host].join("");
  return `${url}/callback`;
};

export const getPasswordlessRedirectUri = () => {
  const url =
    config.PUBLIC_URL ||
    [window.location.protocol, "//", window.location.host].join("");
  return `${url}/callback-otp`;
};

const auth0Config: Auth0ClientOptions = {
  domain: config.AUTH0_DOMAIN,
  client_id: config.AUTH0_CLIENT_ID,
  redirect_uri: getRedirectUri(),
  scope: "openid email profile",
  audience: config.AUTH0_AUDIENCE,
};

const Auth0Context = React.createContext<Auth0ContextValues>({
  loading: false,
  isAuthenticated: false,
});

const getReturnToUrl = (appState?: string) => {
  let url: string | undefined;

  if (appState) {
    const data = localStorage.getItem("login_redirect");

    if (data) {
      const redirectInfo = JSON.parse(data);
      if (redirectInfo?.id === appState) {
        url = redirectInfo.path;
      }
    }
  }

  return url;
};

const setReturnToUrl = (returnTo: string) => {
  const appState = uuid.v4();
  localStorage.setItem(
    "login_redirect",
    JSON.stringify({ id: appState, path: returnTo })
  );

  return { appState };
};

const Auth0Provider = ({ children }: { children: React.ReactElement }) => {
  const [state, setState] = React.useState<{
    auth0Client?: Auth0Client;
    loading: boolean;
    isAuthenticated: boolean;
  }>({ loading: true, isAuthenticated: false });

  // Create the Auth0 client once on load
  React.useEffect(() => {
    (async () => {
      const client = await createAuth0Client(auth0Config);
      const isAuthenticated = await client.isAuthenticated();
      setState({ auth0Client: client, loading: false, isAuthenticated });
    })();
  }, []);

  const { auth0Client } = state;

  // Build the values to be passed through the context
  const authenticationMethods = React.useMemo(
    () => ({
      loginWithRedirect: async ({
        returnTo,
        email,
      }: {
        returnTo?: string;
        email?: string;
      }) => {
        const appState = returnTo
          ? setReturnToUrl(returnTo)?.appState
          : undefined;

        await auth0Client?.loginWithRedirect({ appState, login_hint: email });
      },
      getToken: async () => {
        let token: any;
        try {
          token = await auth0Client?.getTokenSilently();
        } catch (error) {
          // tslint:disable-next-line:no-console
          console.error("Error getting auth token", error);
        }

        // Re-evaluate isAuthenticated
        const isAuthenticated = (await auth0Client?.isAuthenticated()) || false;
        setState((s) => ({ ...s, isAuthenticated }));

        return token;
      },
      logout: () => {
        auth0Client?.logout({ localOnly: true });
        // Redirect to the api logout endpoint, which will delete cookies and reset the Auth0 session
        window.location.href = `${config.API_URL}/logout`;
      },
      handleRedirectCallback: async () => {
        const result = await auth0Client?.handleRedirectCallback();
        const returnTo = getReturnToUrl(result?.appState) || "/";

        // Re-evaluate isAuthenticated
        const isAuthenticated = (await auth0Client?.isAuthenticated()) || false;

        setState((s) => ({ ...s, isAuthenticated }));

        return { returnTo };
      },
    }),
    [auth0Client]
  );

  const contextValues = React.useMemo<Auth0ContextValues>(
    () => ({
      ...authenticationMethods,
      isAuthenticated: state.isAuthenticated,
      loading: state.loading,
    }),
    [authenticationMethods, state]
  );

  return (
    <Auth0Context.Provider value={contextValues}>
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => React.useContext(Auth0Context);

export default Auth0Provider;
