import * as Yup from "yup";
import { ProductTypeEnum } from "../types";
import { QuotationRequest } from "./types";

const isProductTypeWithBalloon = (p?: ProductTypeEnum[]) =>
  !!p && (p.includes(ProductTypeEnum.LP) || p.includes(ProductTypeEnum.PCP));

const quotationRequestValidator = Yup.object<QuotationRequest>().shape({
  dealerId: Yup.number()
    .required()
    .nullable(true)
    .default(undefined),
  minTerm: Yup.number()
    .required()
    .moreThan(0)
    .max(120),
  maxTerm: Yup.number()
    .required()
    .moreThan(0)
    .max(120),
  productTypes: Yup.array()
    .ensure()
    .compact()
    .min(1, "Product type is required")
    .required("Product types are required") as any,
  cAP: Yup.string()
    .nullable(true)
    .label("CAP")
    .when("productTypes", {
      is: isProductTypeWithBalloon,
      then: Yup.string().required()
    }),
  mileage: Yup.number()
    .nullable(true)
    .label("Mileage")
    .when("productTypes", {
      is: isProductTypeWithBalloon,
      then: Yup.number()
        .min(0)
        .required()
    }),
  maxAnnualMileage: Yup.number()
    .nullable(true)
    .label("Max annual mileage")
    .when("productTypes", {
      is: isProductTypeWithBalloon,
      then: Yup.number()
        .moreThan(0)
        .required()
    }),
  isNew: Yup.boolean()
    .nullable(true)
    .when("productTypes", {
      is: isProductTypeWithBalloon,
      then: Yup.boolean().required()
    }),
  dateOfRegistration: Yup.date()
    .nullable(true)
    .when("productTypes", {
      is: isProductTypeWithBalloon,
      then: Yup.date().required()
    }) as any,
  targetBy: Yup.string().required() as any,
  targetByValue: Yup.number()
    .moreThan(0)
    .required(),
  cashPrice: Yup.number()
    .moreThan(0)
    .required(),
  cashDeposit: Yup.number()
    .min(0)
    .required(),
  partExchange: Yup.number()
    .min(0)
    .required(),
  partExchangeSettlement: Yup.number()
    .min(0)
    .required(),
  extras: Yup.number()
    .min(0)
    .required(),
  rFL: Yup.number()
    .min(0)
    .nullable(true)
});

export default quotationRequestValidator;
