import * as React from "react";
import * as uuid from "uuid";
import { AlertColor, AlertMessage, AlertsProps } from "./types";

const dummyContext: AlertsProps = {
  alerts: [],
  addAlert: alert => {
    return;
  },
  removeAlert: id => {
    return;
  },
  clearAlerts: () => {
    return;
  }
};

const AlertsContext = React.createContext(dummyContext);

class AlertsProvider extends React.Component<
  { children: React.ReactNode },
  { alerts: AlertMessage[] }
> {
  constructor(props: any) {
    super(props);

    this.addAlert = this.addAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.clearAlerts = this.clearAlerts.bind(this);

    this.state = { alerts: [] };
  }

  public componentDidMount() {
    // Hack to allow access to the alerts provider from outside of React,
    // so the Apollo error link can post errors.
    (window as any).alertsProvider = this;
  }

  public componentWillUnmount() {
    delete (window as any).alertsProvider;
  }

  public render() {
    const { children } = this.props;
    const { alerts } = this.state;

    return (
      <AlertsContext.Provider
        value={{
          alerts,
          addAlert: this.addAlert,
          removeAlert: this.removeAlert,
          clearAlerts: this.clearAlerts
        }}
      >
        {children}
      </AlertsContext.Provider>
    );
  }

  private addAlert({
    message,
    color,
    onClick
  }: {
    message: string;
    color?: AlertColor;
    onClick?: () => void;
  }) {
    this.setState(s => {
      const existing = s.alerts.find(a => a.message === message);

      // Merge alerts with the same message
      const alert = existing
        ? { ...existing, onClick, count: existing.count + 1 }
        : {
            id: uuid.v4(),
            count: 1,
            message,
            onClick,
            color: color || AlertColor.danger
          };

      if (!existing && (!color || color === AlertColor.danger)) {
        // tslint:disable-next-line:no-console
        console.log(message);
      }

      return {
        alerts: [
          ...s.alerts.filter(a => !existing || a.id !== existing.id),
          alert
        ]
      };
    });
  }

  private removeAlert(id: string) {
    this.setState(s => {
      return { alerts: s.alerts.filter(a => a.id !== id) };
    });
  }

  private clearAlerts() {
    this.setState({ alerts: [] });
  }
}

export function withAlerts<TProps>(
  Component: React.ComponentType<TProps & AlertsProps>
) {
  return (props: TProps) => (
    <AlertsContext.Consumer>
      {alertProps => <Component {...props} {...alertProps} />}
    </AlertsContext.Consumer>
  );
}

export const AlertsConsumer = AlertsContext.Consumer;

export default AlertsProvider;
