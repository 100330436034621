import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { DebounceKeys } from "../../types";

interface VehicleMakesData {
  vehicles: {
    vehicleMakes: {
      id: string;
      name: string;
    }[];
  };
}

interface VehicleMakeArgs {
  isCommercial?: boolean;
}

interface VehicleMakeVariables {
  input: VehicleMakeArgs;
}

const VEHICLE_MAKES = gql`
  query VehicleMakesQuery($input: VehicleMakeArgsInput) {
    vehicles {
      vehicleMakes(input: $input) {
        id
        name
      }
    }
  }
`;

const VehicleMakesQuery = ({
  children,
  isCommercial
}: VehicleMakeArgs & {
  children: (
    result: QueryResult<VehicleMakesData, VehicleMakeVariables>
  ) => JSX.Element | null;
}) => {
  const variables: VehicleMakeVariables = {
    input: { isCommercial: !!isCommercial }
  };
  return (
    <Query<VehicleMakesData, VehicleMakeVariables>
      variables={variables}
      query={VEHICLE_MAKES}
      context={{
        debounceKey: DebounceKeys.VEHICLE_MAKES,
        debounceTimeout: 300
      }}
    >
      {children}
    </Query>
  );
};

export default VehicleMakesQuery;
