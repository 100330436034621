import { Dealer } from "../Dealers/types";
import { QuotationListResult, QuotationTargetBy } from "../Quotations/types";

import {
  Address,
  ContextNames,
  ProductTypeEnum,
  SearchArgs,
  User,
} from "../types";

export interface FormSectionProps {
  title: string;
  subtitle?: string;
  buttonTitle?: string;
  sectionCompleted: () => void;
  isSectionValid: boolean;
  showSaveDraftProposalButton?: boolean;
  savingDraft?: boolean;
  savedDraft?: boolean;
  draftProposalLastSaved?: Date;
  draftProposalIsValid?: boolean;
  isSubmitting?: boolean;
  saveDraft: () => Promise<any>;
}

export interface ProposalProps {
  loggedInUser: User;
  proposal: Proposal;
  context?: ContextNames;
}

export interface Proposal {
  id: number;
  quotationId?: number;
  targetBy?: QuotationTargetBy;
  targetByValue?: number;
  proposalRef: string;
  salesPerson: string;
  isDealSaver: boolean;
  distanceSelling?: boolean;
  dealer: Dealer;
  dealerId?: number;
  accountManagerId?: string;
  finance: ProposalFinance;
  individualCustomer: Individual;
  business: Business;
  vehicle: Vehicle;
  status: ProposalStatusEnum;
  cancelType: ProposalCancelTypeEnum;
  createdDate: Date;
  bankDetails: BankDetails;
  notes?: string;
  debitBacks?: {
    id: number;
  }[];
  proposalType?: ProposalType;
  FORMSTATE_noQuotationResults?: boolean;
  FORMSTATE_noQuotationResultsReasons?: string[];
  FORMSTATE_requiresQuotation?: boolean;
  loan?: {
    id: string;
    name: string;
    lender?: {
      id: string;
      name: string;
    };
  };
  autoConvertReference?: string;
  autoConvertLenderName?: string;
  externalSource?: string;
  suitability: Suitability;
  isMannIslandDealer?: boolean;
  isDocsRequested?: boolean;
  quotationListResult?: QuotationListResult;
  proposalCustomerQuestions: ProposalCustomerQuestions;
  directDealId?: string;
}

export interface DraftProposal {
  id?: number;
  dealerId: number;
  customerDescription?: string;
  vehicleDescription?: string;
  proposal: Proposal;
  touchedFields: string[];
  currentSection?: string;
  created?: string;
  updated?: string;
  proposalType?: ProposalType;
  dealer?: Dealer;
  quotationId?: number;
  requiresQuotation?: boolean;
  isDirectDeal?: boolean;
}

export interface DraftProposalProps {
  loggedInUser: User;
  draftProposal: DraftProposal;
  context?: ContextNames;
}

export interface DraftProposalSearchArgs extends SearchArgs {
  accountManagerId?: string;
  dealerId?: number;
}

export interface ProposalStatistics {
  id: string;
  totalCreatedCount: number;
  totalPaidOutCount: number;
  totalAcceptedCount: number;
  totalCancelledCount: number;
  totalFinance: number;
  averagePaidOutFinance: number;
  averageApr: number;
  averagePeriod: number;
  totalDebitBackAmount: number;
  totalDealerCommission: number;
  averageAcceptedFinance: number;
  acceptance: number;
  dealerCreated: number;
  dealerPaidOut: number;
  totalNetProfit?: number;
  holdbackInternal: number;
}

export interface ProposalInternalStatistics extends ProposalStatistics {
  netProfit100: number;
  netProfit: number;
  dealerSetup: number;
}

export enum ProposalFormSectionName {
  PRIVACY = "PRIVACY",
  DEALER = "DEALER",
  CUSTOMER_DETAILS = "CUSTOMER_DETAILS",
  BUSINESS_DETAILS = "BUSINESS_DETAILS",
  HOME_ADDRESSES = "HOME_ADDRESSES",
  EMPLOYMENT_HISTORY = "EMPLOYMENT_HISTORY",
  DIRECTORS = "DIRECTORS",
  BANK_DETAILS = "BANK_DETAILS",
  VEHICLE = "VEHICLE",
  FINANCE = "FINANCE",
  DEALERNOTES = "DEALERNOTES",
  SUMMARY = "SUMMARY",
}

export interface BankDetails {
  id?: string;
  branch?: string;
  bank?: string;
  accountName?: string;
  accountNumber?: string;
  sortCode?: string;
  yearsWithBank?: number;
  monthsWithBank?: number;
  FORMSTATE_invalidBankAccount?: { accountNumber: string; sortCode: string };
}

export interface ProposalFormValues {
  vehicle: Vehicle;
  individual: Individual;
}

export interface Vehicle {
  id?: number;
  regNo?: string;
  dateOfRegistration?: string;
  isNew?: boolean;
  cAP?: string;
  capId?: string;
  make?: string;
  model?: string;
  derivative?: string;
  bodyStyle?: string;
  colour?: string;
  vIN?: string;
  doors?: number | null;
  engineSize?: number | null;
  fuel?: string;
  transmission?: string;
  insuranceGroup?: string;
  mileage?: number;
  maxAnnualMileage?: number;
  isCommercial?: boolean;
  regNoNotFound?: string;
  skipVehicle?: boolean;
  isRegUnknown?: boolean;
  vatQualifying?: boolean;
  vehicleType?: string;
  LCV?: boolean;
  imported?: boolean;
  __typename?: string;
}

export interface HomeAddress extends Address {
  id?: string;
  yearsAtAddress?: number;
  monthsAtAddress?: number;
  ownership?: HomeOwnership;
  otherOwnership?: string;
}

export interface EmploymentDetails {
  id?: string;
  employmentTerms?: EmploymentTerms;
  employmentStatus?: EmploymentStatus;
  employmentType?: EmploymentType;
  occupation?: string;
  employerName?: string;
  earnings?: number;
  earningsPer?: EarningsFrequency;
  yearsWithEmployer?: number;
  monthsWithEmployer?: number;
  address: Address;
  industry?: string;
}

export enum EmploymentTerms {
  PERMANENT = "PERMANENT",
  TEMPORARY = "TEMPORARY",
}

export enum EmploymentStatus {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
}

export enum EmploymentType {
  EMPLOYED = "EMPLOYED",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  UNEMPLOYED = "UNEMPLOYED",
  STUDENT = "STUDENT",
  RETIRED = "RETIRED",
  MILITARY = "MILITARY",
  HOUSEWIFE = "HOUSEWIFE",
  GOVERNMENT = "GOVERNMENT",
  DISABLED = "DISABLED",
  UNKNOWN = "UNKNOWN",
}

export const EmploymentTypesRequiringEmployerName = [
  EmploymentType.EMPLOYED,
  EmploymentType.SELF_EMPLOYED,
  EmploymentType.GOVERNMENT,
  EmploymentType.MILITARY,
  EmploymentType.STUDENT,
];

export enum EarningsFrequency {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum ProposalType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

export interface ProposalFinance {
  id?: string;
  rate?: number;
  aprRate?: number;
  period: number;
  monthlyPayment: number;
  balloonPayment?: number;
  partExchange?: number;
  partExchangeSettlement?: number;
  cashPrice?: number;
  cashDeposit?: number;
  rFL?: number;
  extras?: number;
  productType?: ProductTypeEnum;
  acceptanceFee?: number;
  optionFee?: number;
  totalFinance?: number;
  interestCharges?: number;
  totalCharges?: number;
  balancePayable?: number;
  totalAmountPayable?: number;
  lessRentalDeposit?: number;
  commissionCode?: string;
  dealerCommission?: number;
  paidOutDate?: string;
  requestDifferentLoanType?: string;
  lenderId?: number;
  lenderName?: string;
}

export interface ProposalCustomerQuestions {
  id?: number;
  privacyAgreement?: boolean;
  customerHasHadFinanceBefore?: boolean;
  customerSoleSignatoryToBankAccount?: boolean;
  customerExpectsAffordabilityProblems?: boolean;
  customerAnnualMileageRealistic?: boolean;
  customerConfidentFinanceIsAffordable?: boolean;
  customerUnderstandsAgreement?: boolean;
  customerHappyAgreementIsGoodValue?: boolean;
  customerAdditionalInfo?: boolean;
  customerSaysDealerAnsweredQuestions?: boolean;
  customerPresent?: boolean;
  tNC?: boolean;
}

interface Country {
  id: string;
  name: string;
}

export interface Individual {
  id?: string;
  proposalId?: number;
  title?: string;
  forename?: string;
  middleName?: string;
  surname?: string;
  dOB?: string;
  maritalStatus?: MaritalStatus;
  numOfDependants?: number;
  drivingLicense?: DrivingLicense;
  mobile: string;
  email: string;
  homeAddresses: HomeAddress[];
  homeAddressesError?: string;
  employmentDetails: EmploymentDetails[];
  employmentDetailsError?: string;
  countryOfBirthId?: string;
  countryOfBirth?: Country;
  nationalityId?: string;
  nationality?: Country;
  countryOfResidenceId?: string;
  countryOfResidence?: Country;
  occupationType?: { id: number; description: string };
  selfEmployedDirectorOrBoardMember?: boolean;
  countryOfActivityId?: string;
  countryOfActivity?: Country;
  occupationTypeId?: number;
}

export enum MaritalStatus {
  MARRIED = "MARRIED",
  SINGLE = "SINGLE",
  DIVORCED_OR_SEPARATED = "DIVORCED_OR_SEPARATED",
  WIDOWED = "WIDOWED",
}

export enum DrivingLicense {
  OTHER = "OTHER",
  NO_LICENSE = "NO_LICENSE",
  EU_LICENSE = "EU_LICENSE",
  UK_PROVISIONAL = "UK_PROVISIONAL",
  UK_FULL = "UK_FULL",
}

export const DrivingLicenseLookup: {
  [key in DrivingLicense]: { description: string };
} = {
  UK_FULL: { description: "UK full" },
  UK_PROVISIONAL: { description: "UK provisional" },
  EU_LICENSE: { description: "EU license" },
  NO_LICENSE: { description: "No license" },
  OTHER: { description: "Other" },
};

export enum HomeOwnership {
  OWNER = "OWNER",
  TENANT = "TENANT",
  WITH_PARENTS = "WITH_PARENTS",
  WITH_PARTNER = "WITH_PARTNER",
  OTHER = "OTHER",
}
export interface Business {
  id?: string;
  name?: string;
  natureOfBusiness?: string;
  contactName?: string;
  contactPosition?: string;
  email?: string;
  mobile?: string;
  businessType?: BusinessType;
  otherBusinessType?: string;
  registrationNumber?: string;
  established?: string;
  yearsAtAddress?: number;
  monthsAtAddress?: number;
  address: Address;
  directors: BusinessDirector[];
}

export enum BusinessType {
  LTD = "LTD",
  LLP = "LLP",
  // OTHER = "OTHER",
}

export const BusinessTypeLookup: { [key in BusinessType]: string } = {
  LTD: "Limited",
  LLP: "Partnership (LLP)",
  // OTHER: "Other",
};

export enum DirectorOwnerStatus {
  OWNER = "OWNER",
  TENANT = "TENANT",
  WITH_PARENTS = "WITH_PARENTS",
  WITH_PARTNER = "WITH_PARTNER",
  OTHER = "OTHER",
}

export interface BusinessDirector {
  id?: string;
  title?: string;
  forename?: string;
  middleName?: string;
  surname?: string;
  dOB?: string;
  email?: string;
  earnings?: number;
  guarantor?: boolean;
  mobile?: string;
  owner?: DirectorOwnerStatus;
  otherStatus?: string;
  homeYears?: number;
  homeMonths?: number;
  address: Address;
  maritalStatus?: MaritalStatus;
  numOfDependants?: number;
  drivingLicense?: DrivingLicense;
  countryOfBirthId?: string;
  countryOfBirth?: Country;
  nationalityId?: string;
  nationality?: Country;
  countryOfResidenceId?: string;
  countryOfResidence?: Country;
}

export interface ProposalSearchArgs extends SearchArgs {
  dealerId?: number;
  accountManagerId?: string;
  regionalSalesManagerId?: string;
  status?: string[];
  fundedDealersOnly?: boolean;
  startDate?: Date | string;
  endDate?: Date | string;
  filterByDate?: ProposalSearchDate;
  statisticsFilterByDate?: boolean;
  editDates?: boolean;
  hasDebitBackOnly?: boolean;
  autoConvertOnly?: boolean;
}

export interface ProposalDealerCommissionSearchArgs extends SearchArgs {
  dealerId?: number;
  accountManagerId?: string;
  regionalSalesManagerId?: string;
  fundedDealersOnly?: boolean;
  startDate?: Date | string;
  endDate?: Date | string;
}

export interface ProposalNoteProps {
  note: ProposalNote;
  loggedInUser: User;
}

export interface ProposalNoteSearchArgs extends SearchArgs {
  proposalId?: number;
  proposalRef?: string;
}

export interface ProposalNote {
  id?: number;
  proposalId: number;
  updated?: Date;
  loggedBy: string;
  priority: number;
  body: string;
  isSticky?: boolean;
  followupDate?: Date;
}

export interface AutoConvertNotes {
  id?: string;
  text?: string;
  dateCreated?: string;
  visibleToDealer?: boolean;
  proposalId?: number;
  createWarningFromNote?: boolean;
  userName?: string;
}

export interface DealerDocs {
  id: string;
  dealerId: number;
  proposalRef: string;
  received: Date;
  subject: string;
  filename: string;
  extension: string;
}

export enum ProposalStatusEnum {
  UNDERWRITING = "UNDERWRITING",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
  PAID_OUT = "PAID_OUT",
  NOT_TAKEN_UP = "NOT_TAKEN_UP",
  NO_FURTHER_ACTION = "NO_FURTHER_ACTION",
  SENT_TO_PAYOUTS = "SENT_TO_PAYOUTS",
  INCOMPLETE = "INCOMPLETE",
  AWAITING_DOCS = "AWAITING_DOCS",
  PARTIAL_DOCS = "PARTIAL_DOCS",
  SENT_TO_LENDER = "SENT_TO_LENDER",
  CANCELLED = "CANCELLED",
}

export enum ProposalCancelTypeEnum {
  CANCELLED = "CANCELLED",
  FRAUD = "FRAUD",
  UNWOUND = "UNWOUND",
}

export enum ProposalSearchDate {
  CREATED_DATE = "CREATED_DATE",
  PAID_OUT_DATE = "PAID_OUT_DATE",
  ACCEPTED_DATE = "ACCEPTED_DATE",
  CANCEL_DATE = "CANCEL_DATE",
  ALL_DATES = "ALL_DATES",
}

export const ProposalSearchDateLookup: {
  [key in ProposalSearchDate]: { description: string };
} = {
  CREATED_DATE: { description: "Created date" },
  PAID_OUT_DATE: { description: "Paid out date" },
  ACCEPTED_DATE: { description: "Accepted date" },
  CANCEL_DATE: { description: "Cancelled date" },
  ALL_DATES: { description: "All dates" },
};

export const ProposalStatusLookup: {
  [key in ProposalStatusEnum]: { description: string; color: string };
} = {
  UNDERWRITING: { description: "Underwriting", color: "info" },
  SENT_TO_LENDER: { description: "Sent to lender", color: "info" },
  DECLINED: { description: "Declined", color: "danger" },
  ACCEPTED: { description: "Accepted", color: "success" },
  PAID_OUT: { description: "Paid out", color: "success" },
  NOT_TAKEN_UP: { description: "Not taken up", color: "danger" },
  NO_FURTHER_ACTION: { description: "No further action", color: "danger" },
  SENT_TO_PAYOUTS: { description: "Payouts processing", color: "warning" },
  INCOMPLETE: { description: "Incomplete", color: "warning" },
  AWAITING_DOCS: { description: "Awaiting docs", color: "warning" },
  PARTIAL_DOCS: { description: "Partial docs", color: "danger" },
  CANCELLED: { description: "Cancelled", color: "danger" },
};

export const ActiveProposalStatus = [
  ProposalStatusEnum.UNDERWRITING,
  ProposalStatusEnum.INCOMPLETE,
  ProposalStatusEnum.SENT_TO_LENDER,
  ProposalStatusEnum.ACCEPTED,
  ProposalStatusEnum.AWAITING_DOCS,
  ProposalStatusEnum.PARTIAL_DOCS,
  ProposalStatusEnum.SENT_TO_PAYOUTS,
];

export enum VehicleTypeEnum {
  Car = "Car",
  Van = "Van",
  Motorbike = "Motorbike",
  Motorhome = "Motorhome",
  HeavyGoodsVehicle = "Heavy goods vehicle",
  StaticCaravan = "Static caravan",
  HorseBox = "Horse box",
}

export enum SuitabilityFormSectionName {
  CUSTOMER_DETAILS = "CUSTOMER_DETAILS",
  PART_EXCHANGE = "PART_EXCHANGE",
  NEGATIVE_EQUITY = "NEGATIVE_EQUITY",
  ABOUT_YOU = "ABOUT_YOU",
  SUITABILITY = "SUITABILITY",
  AFFORDABILITY = "AFFORDABILITY",
}

export interface Suitability {
  id: number;
  dealerId: number;
  proposalId: number;
  title: string;
  middleName: string;
  forename: string;
  surname: string;
  email: string;
  address: Address;
  pxAnotherVehicle: boolean;
  outstandingFinance: boolean;
  outstandingMonthlyPayment: number;
  existingFinanceCompany: string;
  settlementFigure: number;
  pxValuation: number;
  voluntaryAgreement: boolean;
  voluntaryAgreementConfirm: boolean;
  halfAmountMade: boolean;
  financialAdvantage: boolean;
  equityFunding: string;
  customerType: string;
  vehicleAge: string;
  vehicleChange: string;
  deferLoanConfirmation: boolean;
  financeType: string;
  financeCompany: string;
  financeCompanyReason: string;
  financeCompanySelectConfirmation: boolean;
  alternativeDeclines: boolean;
  topUpLoanRequired: boolean;
  agreedPrice: number;
  agreedDeposit: number;
  lowDepositConfirmation: boolean;
  keepVehicleMonths: number;
  agreementOverMonths: number;
  acceptanceFee: number;
  optionToPurchaseFee: number;
  initialPayment: number;
  monthlyPayments: number;
  finalPayment: number;
  annualApr: number;
  agreedMaximumMileage: number;
  excessMileageCharge: number;
  leftoverFinance: number;
  isCustomerHappyWithFinance: boolean;
  hasCustomerBeenDeclinedBefore: boolean;
}

export enum EquityFundingOptions {
  CASH = "CASH",
  TOP_UP_LOAN = "TOP_UP_LOAN",
}

export interface DirectDeal {
  id?: string;
  title: string;
  forename: string;
  middleName?: string;
  surname: string;
  customerEmail: string;
  dealerId: number;
  dealerName: string;
  dealerDetailsNotes?: string;
  vehicle?: string;
  totalMileage?: number;
  annualMileage?: number;
  period?: number;
  monthlyPayment?: number;
  productType?: ProductTypeEnum;
  draftProposalId?: number;
  isActive?: boolean;
  iddSigned?: boolean;
  printName?: string;
  salesPerson?: string;
  distanceSelling?: boolean;
}

export default () => ({});
