import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";
import { LoggedInUserFragment } from "../LoggedInUserQuery";
import { User } from "../types";
import {
  DealerBonafideeEsignFragment,
  DealerContactFragment,
  DealerFragment,
  DirectDealDealerFragment,
  FullDealerFragment,
  SingleDealerFragment,
} from "./fragments";
import { Dealer } from "./types";
import { DocumentNode } from "apollo-boost";

export interface LoggedInUserProps {
  loggedInUser: User;
}

export interface DealerProps {
  loggedInUser: User;
  dealer: Dealer;
}

interface DealerData {
  loggedInUser: User;
  dealer: Dealer;
}

interface DealerArgs {
  query?: object;
  dealerId?: number;
}

export const GET_DEALER = gql`
  query DealerQuery($dealerId: Int!) {
    loggedInUser {
      id
      username
      roles
    }
    dealer(id: $dealerId) {
      ...DealerFragment
      mainContact {
        ...DealerContactFragment
      }
      bonafidee {
        ...DealerBonafideeEsignFragment
      }
    }
  }
  ${DealerFragment}
  ${DealerContactFragment}
  ${DealerBonafideeEsignFragment}
`;

export const GET_DEALER_SHALLOW = gql`
  query DealerShallowQuery($dealerId: Int!) {
    dealer(id: $dealerId) {
      ...SingleDealerFragment
    }
  }
  ${SingleDealerFragment}
`;

export const GET_DIRECT_DEAL_DEALER = gql`
  query DirectDealDealerQuery($dealerId: Int!) {
    dealer(id: $dealerId) {
      ...DirectDealDealerFragment
    }
  }
  ${DirectDealDealerFragment}
`;

export const GET_LOGGED_IN_DEALER = gql`
  query LoggedInDealerQuery {
    loggedInUser {
      ...LoggedInUserFragment
      dealer {
        ...DealerFragment
      }
    }
  }
  ${DealerFragment}
  ${LoggedInUserFragment}
`;

export const GET_LOGGED_IN_DEALER_AND_QUESTIONNAIRE = gql`
  query LoggedInDealerAndQuestionnaireQuery {
    loggedInUser {
      ...LoggedInUserFragment
      dealer {
        ...FullDealerFragment
      }
    }
  }
  ${FullDealerFragment}
  ${LoggedInUserFragment}
`;

export const GET_DEALER_AND_QUESTIONNAIRE = gql`
  query DealerAndQuestionnaireQuery($dealerId: Int!) {
    loggedInUser {
      id
      username
      roles
    }
    dealer(id: $dealerId) {
      ...FullDealerFragment
    }
  }
  ${FullDealerFragment}
`;

const DealerQuery = ({
  children,
  dealerId,
  query,
}: DealerArgs & {
  children: (
    result: QueryResult<DealerData, DealerArgs> & {
      dealer?: Dealer;
      loggedInUser?: User;
    }
  ) => JSX.Element | null;
}) => (
  <Query<DealerData, DealerArgs>
    query={query || GET_DEALER}
    variables={{ dealerId }}
    skip={!dealerId}
  >
    {(result) =>
      children({
        ...result,
        dealer: result?.data?.dealer,
        loggedInUser: result?.data?.loggedInUser,
      })
    }
  </Query>
);

export const useDealer = (id?: number, query?: DocumentNode) => {
  const { loading, data } = useQuery<DealerData>(query || GET_DEALER, {
    variables: { dealerId: id },
    skip: !id,
  });
  return { loading, dealer: data?.dealer };
};

export const useDealerShallow = (id?: number) => {
  const { loading, data } = useQuery<DealerData>(GET_DEALER_SHALLOW, {
    variables: { dealerId: id },
    skip: !id,
  });
  return { loading, dealer: data?.dealer };
};

export const useDealerWithQuestionnaire = (id?: number) => {
  const { loading, data } = useQuery<DealerData>(GET_DEALER_AND_QUESTIONNAIRE, {
    variables: { dealerId: id },
    skip: !id,
  });
  return { loading, dealer: data?.dealer };
};

export const useLoggedInDealer = () => {
  const { loading, data } = useQuery<{ loggedInUser?: User }>(
    GET_LOGGED_IN_DEALER
  );
  return { loading, dealer: data?.loggedInUser?.dealer };
};

export const useLoggedInDealerWithQuestionnaire = () => {
  const { loading, data } = useQuery<{ loggedInUser?: User }>(
    GET_LOGGED_IN_DEALER_AND_QUESTIONNAIRE
  );
  return { loading, dealer: data?.loggedInUser?.dealer };
};

export const useDirectDealDealer = (id?: number) => {
  const { loading, data } = useQuery<DealerData>(GET_DIRECT_DEAL_DEALER, {
    variables: { dealerId: id },
    skip: !id,
  });
  return { loading, dealer: data?.dealer };
};

export default DealerQuery;
