import { Field } from "formik";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import AddressFormSection from "../../AddressLookup/AddressFormSection";
import FormInputField from "../../Forms/FormInputField";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import SelectField from "../../Forms/SelectField";
import IndustryListQuery from "../IndustryListQuery";
import { BusinessType, BusinessTypeLookup, Proposal } from "../types";
import DateSelectRow from "./DateSelectRow";

const businessTypeOptions = Object.keys(BusinessType).map((x) => ({
  label: BusinessTypeLookup[x as BusinessType],
  value: x,
}));

export const businessDetailsWatchFields: WatchFields<Proposal> = {
  business: {
    name: true,
    natureOfBusiness: true,
    contactName: true,
    contactPosition: true,
    businessType: true,
    otherBusinessType: true,
    registrationNumber: true,
    established: true,
    address: {
      line1: true,
      line2: true,
      line3: true,
      town: true,
      county: true,
      postcode: true,
      countryId: true,
    },
    yearsAtAddress: true,
    monthsAtAddress: true,
  },
};

class BusinessDetailsSection extends React.Component<
  FormSectionProps<Proposal>
> {
  public render() {
    const { navigateToSection, ...formikProps } = this.props;
    const { business } = this.props.values;

    return (
      <>
        <Row>
          <Field
            colSize={5}
            name="business.name"
            type="text"
            title="Business name"
            autoFocus={true}
            component={FormInputField}
          />
        </Row>
        <IndustryListQuery>
          {({ data }) => {
            const industryOptions =
              data && data.industryList
                ? data.industryList.map((i) => ({
                    label: i,
                    value: i,
                  }))
                : [];
            return (
              <Row>
                <SelectField
                  colSize={5}
                  name="business.natureOfBusiness"
                  title="Industry"
                  options={industryOptions}
                />
              </Row>
            );
          }}
        </IndustryListQuery>

        <Row>
          <Field
            colSize={5}
            name="business.contactName"
            type="text"
            title="Contact name"
            component={FormInputField}
          />
        </Row>
        <Row>
          <Field
            colSize={5}
            name="business.contactPosition"
            type="text"
            title="Contact position"
            component={FormInputField}
          />
        </Row>
        <Row>
          <Field
            colSize={5}
            name="business.email"
            type="text"
            title="Email"
            component={FormInputField}
          />
        </Row>
        <Row>
          <Field
            colSize={5}
            name="business.mobile"
            type="text"
            title="Mobile"
            component={FormInputField}
          />
        </Row>
        <Row>
          <RadioField
            colSize={5}
            name="business.businessType"
            title="Business type"
            options={businessTypeOptions}
            vertical={true}
          />
        </Row>
        {business.businessType !== BusinessType.LLP && (
          <Row>
            <Field
              colSize={5}
              name="business.registrationNumber"
              type="text"
              title="Registration number"
              component={FormInputField}
            />
          </Row>
        )}
        <Row>
          <Col lg={5}>
            <Label>Date established</Label>
            <Field
              key="dob"
              name="business.established"
              component={DateSelectRow}
            />
          </Col>
        </Row>
        <AddressFormSection
          {...formikProps}
          title="Business address"
          address={business.address}
          pathToAddress={"business.address"}
        />
        <Row>
          <Col lg={5}>
            <Row>
              <Field
                colSize={6}
                name={"business.yearsAtAddress"}
                type="number"
                min={0}
                title="Years at address"
                component={FormInputField}
              />
              <Field
                colSize={6}
                name={"business.monthsAtAddress"}
                type="number"
                min={0}
                max={11}
                title="Months at address"
                component={FormInputField}
              />
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default BusinessDetailsSection;
