import * as Yup from "yup";
import { QuotationTargetBy } from "../../Quotations/types";
import { BankDetails, Proposal, ProposalStatusEnum } from "../types";
import businessCustomerValidationSchema from "./businessCustomerValidationSchema";
import financeValidationSchema from "./financeValidationSchema";
import individualCustomerValidationSchema from "./individualCustomerValidationSchema";
import vehicleValidationSchema from "./vehicleValidationSchema";
import { proposalCustomerQuestionsValidationSchema } from "./proposalCustomerQuestionsValidationSchema";

export const proposalCustomerOverviewValidationSchema =
  Yup.object<Proposal>().shape({
    id: Yup.number(),
    proposalRef: Yup.string().max(23),
    individualCustomer: individualCustomerValidationSchema
      .default(undefined)
      .nullable(true),
  });

export const proposalBusinessOverviewValidationSchema =
  Yup.object<Proposal>().shape({
    id: Yup.number(),
    proposalRef: Yup.string().max(23),
    business: businessCustomerValidationSchema
      .default(undefined)
      .nullable(true),
  });

export const proposalVehicleOverviewValidationSchema =
  Yup.object<Proposal>().shape({
    id: Yup.number(),
    proposalRef: Yup.string().max(23),
    vehicle: vehicleValidationSchema.default(undefined).nullable(true),
  });

export const proposalFinanceOverviewValidationSchema =
  Yup.object<Proposal>().shape({
    id: Yup.number(),
    proposalRef: Yup.string().max(23),
    finance: financeValidationSchema.default(undefined).nullable(true),
    quotationId: Yup.string().required(),
  });

const proposalValidationSchema = Yup.object<Proposal>().shape({
  id: Yup.number(),
  dealerId: Yup.number()
    .label("Dealer")
    .nullable(true)
    .default(undefined)
    .required(),
  quotationId: Yup.number()
    .label("Quotation")
    .nullable(true)
    .default(undefined)
    .when("FORMSTATE_requiresQuotation", {
      is: true,
      then: Yup.number().required("Quotation must be saved"),
    }),
  FORMSTATE_requiresQuotation: Yup.boolean().default(true),
  distanceSelling: Yup.boolean()
    .label("Type of sales transaction")
    .nullable(true)
    .default(undefined)
    .required(),
  targetBy: Yup.string()
    .label("Target by")
    .nullable(true)
    .default(QuotationTargetBy.APR_RATE) as Yup.Ref | Yup.Schema<string>,
  targetByValue: Yup.number().nullable(true).default(undefined),
  proposalRef: Yup.string().max(23),
  isDealSaver: Yup.boolean(),
  salesPerson: Yup.string()
    .label("Sales person")
    .max(50)
    .nullable(true)
    .default(undefined)
    .required(),
  notes: Yup.string().label("Notes").max(200).default(undefined).nullable(true),
  dealer: Yup.object().notRequired() as Yup.Ref | Yup.Schema<any>,
  accountManagerId: Yup.string().nullable(true).label("Account manager"),
  finance: financeValidationSchema
    .default(financeValidationSchema.default())
    .nullable(true),
  business: businessCustomerValidationSchema.default(undefined),
  status: Yup.string() as Yup.Ref | Yup.Schema<ProposalStatusEnum>,
  createdDate: Yup.date(),
  vehicle: vehicleValidationSchema,
  bankDetails: Yup.object<BankDetails>().shape({
    id: Yup.string().nullable(true),
    branch: Yup.string().label("Branch").required().nullable(true).max(100),
    bank: Yup.string().label("Bank").required().nullable(true).max(100),
    sortCode: Yup.string()
      .label("Sort code")
      .required()
      .nullable(true)
      .matches(/^\d\d-\d\d-\d\d$/, 'Sort code must be in the format "##-##-##"')
      .max(8),
    accountNumber: Yup.string()
      .label("Account number")
      .required()
      .nullable(true)
      .matches(/^\d{8}$/, "Bank account number must be 8 digits"),
    accountName: Yup.string()
      .label("Account name")
      .default(undefined)
      .nullable(true),
    yearsWithBank: Yup.number()
      .label("Years with bank")
      .required()
      .nullable(true)
      .min(0),
    monthsWithBank: Yup.number()
      .label("Months with bank")
      .required()
      .nullable(true)
      .min(0)
      .lessThan(12),
    FORMSTATE_invalidBankAccount: Yup.object()
      .shape({
        accountNumber: Yup.string().nullable(true),
        sortCode: Yup.string().nullable(true),
      })
      .nullable(true)
      .default(undefined),
  }),
  individualCustomer: individualCustomerValidationSchema
    .default(undefined)
    .nullable(true),
  proposalCustomerQuestions: proposalCustomerQuestionsValidationSchema
    .default(proposalCustomerQuestionsValidationSchema.default())
    .nullable(true)
    .test(
      "previous-finance-required",
      "Customer needs to answer this question",
      function (value) {
        if (
          this.parent.proposalCustomerQuestions.customerPresent &&
          value.customerHasHadFinanceBefore === undefined &&
          (this.parent.business === null || this.parent.business === undefined)
        ) {
          return this.createError({
            path: "proposalCustomerQuestions.customerHasHadFinanceBefore",
            message: "Customer needs to answer this question",
          });
        }
        return true;
      }
    )
    .test(
      "affordability-required",
      "Customer needs to answer this question",
      function (value) {
        if (
          this.parent.proposalCustomerQuestions.customerPresent &&
          value.customerExpectsAffordabilityProblems === undefined &&
          (this.parent.business === null || this.parent.business === undefined)
        ) {
          return this.createError({
            path: "proposalCustomerQuestions.customerExpectsAffordabilityProblems",
            message: "Customer needs to answer this question",
          });
        }
        return true;
      }
    ),
  FORMSTATE_noQuotationResults: Yup.boolean().default(undefined).nullable(true),
  FORMSTATE_noQuotationResultsReasons: Yup.array()
    .of(Yup.string())
    .default(undefined)
    .nullable(true),
});

export default proposalValidationSchema;
