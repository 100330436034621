import {
  faChevronDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray } from "formik";
import * as React from "react";
import { Button } from "reactstrap";
import {
  formatCurrency,
  formatEnumValue,
  getMonthsAndYearsText,
  getSingleLineAddress,
  hasAddress,
} from "../../../utils";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import { EmploymentDetails, Proposal } from "../types";
import EmploymentDetailsSection from "./EmploymentDetailsSection";
import { employmentDetailsSchema } from "./individualCustomerValidationSchema";
import ProposalQuestion from "./ProposalQuestion";

export const employmentHistorySectionWatchFields: WatchFields<Proposal> = {
  proposalCustomerQuestions: {
    customerExpectsAffordabilityProblems: true,
  },
  individualCustomer: {
    countryOfActivityId: true,
    occupationTypeId: true,
    selfEmployedDirectorOrBoardMember: true,
    employmentDetails: [
      {
        employmentTerms: true,
        employmentStatus: true,
        employmentType: true,
        occupation: true,
        employerName: true,
        earnings: true,
        earningsPer: true,
        yearsWithEmployer: true,
        monthsWithEmployer: true,
        address: {
          line1: true,
          line2: true,
          line3: true,
          town: true,
          county: true,
          postcode: true,
          countryId: true,
          telephone: true,
          extension: true,
          fax: true,
        },
        industry: true,
      },
    ],
    employmentDetailsError: true,
  },
};

const EmploymentCollapsedDetails = ({
  employmentDetails,
}: {
  employmentDetails: EmploymentDetails;
}) => {
  return (
    <>
      <p className="mb-1">
        {employmentDetails.employerName ? (
          <span className="mr-3">
            Employer: {employmentDetails.employerName}
          </span>
        ) : null}
        {employmentDetails.occupation ? (
          <>Occupation: {employmentDetails.occupation}</>
        ) : null}
      </p>
      <p className="mb-1 text-muted">
        {employmentDetails.employmentType
          ? formatEnumValue(employmentDetails.employmentType)
          : null}
        {employmentDetails.employmentStatus ? (
          <>
            {employmentDetails.employmentType ? ", " : null}
            {formatEnumValue(employmentDetails.employmentStatus)}
          </>
        ) : null}
        {employmentDetails.employmentTerms ? (
          <>
            {employmentDetails.employmentType ||
            employmentDetails.employmentStatus
              ? ", "
              : null}
            {formatEnumValue(employmentDetails.employmentTerms)}
          </>
        ) : null}

        {employmentDetails.yearsWithEmployer &&
        employmentDetails.monthsWithEmployer ? (
          <>
            {employmentDetails.employmentType ||
            employmentDetails.employmentStatus ||
            employmentDetails.employmentTerms
              ? ", "
              : null}
            {getMonthsAndYearsText(
              employmentDetails.yearsWithEmployer * 12 +
                employmentDetails.monthsWithEmployer
            )}
          </>
        ) : null}
      </p>
      {employmentDetails.earnings ? (
        <p className="mb-1 text-muted">
          Earnings{" "}
          {employmentDetails.earningsPer ? (
            <>({formatEnumValue(employmentDetails.earningsPer)})</>
          ) : null}
          : {formatCurrency(employmentDetails.earnings)}{" "}
        </p>
      ) : null}
      {employmentDetails.address ? (
        <p className="mb-1 text-muted">
          {hasAddress(employmentDetails.address) ? <>Address: </> : null}
          {getSingleLineAddress(
            employmentDetails.address ? employmentDetails.address : {}
          )}
        </p>
      ) : null}
    </>
  );
};

class EmploymentHistorySection extends React.Component<
  FormSectionProps<Proposal>,
  { isValidated: boolean; collapsedItems: number[] }
> {
  constructor(props: any) {
    super(props);

    this.collapseItem = this.collapseItem.bind(this);
    this.expandItem = this.expandItem.bind(this);

    this.state = {
      isValidated: false,
      collapsedItems: [],
    };

    const { individualCustomer } = this.props.values;
    if (individualCustomer.employmentDetails.length > 1) {
      individualCustomer.employmentDetails.forEach((_, i) => {
        this.state.collapsedItems.push(i);
      });
    }
  }

  public render() {
    const errors = this.props.errors;
    const {
      values: { individualCustomer },
    } = this.props;

    const requiresEmploymentDetails =
      !!(
        !individualCustomer.employmentDetails ||
        !individualCustomer.employmentDetails.length
      ) ||
      !!(
        errors.individualCustomer &&
        (errors.individualCustomer as any).employmentDetailsError
      );

    return (
      <>
        <FieldArray name="individualCustomer.employmentDetails">
          {(arrayHelpers: any) => (
            <div>
              {individualCustomer.employmentDetails &&
                individualCustomer.employmentDetails.map(
                  (employmentDetails: any, i: number) => {
                    const isOpen = this.state.collapsedItems.indexOf(i) === -1;
                    return (
                      <div key={i}>
                        <h5
                          className="my-3 section-title pointer"
                          onClick={
                            isOpen
                              ? () => this.collapseItem(i)
                              : () => this.expandItem(i)
                          }
                        >
                          {i > 0 ? <>Previous employer {i}</> : <>Employer</>}
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={isOpen ? faChevronUp : faChevronDown}
                          />
                        </h5>
                        <div className="mb-3">
                          {isOpen ? (
                            <>
                              <EmploymentDetailsSection
                                index={i}
                                employmentDetails={employmentDetails}
                                remove={() => arrayHelpers.remove(i)}
                                {...this.props}
                              />

                              {i > 0 ? (
                                <Button
                                  type="button"
                                  color="link"
                                  className="pl-0 small"
                                  onClick={() => arrayHelpers.remove(i)}
                                >
                                  Remove this employer
                                </Button>
                              ) : null}
                            </>
                          ) : (
                            <div
                              onClick={() => {
                                this.expandItem(i);
                              }}
                              className="pointer pb-4"
                            >
                              <EmploymentCollapsedDetails
                                employmentDetails={employmentDetails}
                              />
                              {this.validateEmployer(
                                employmentDetails
                              ) ? null : (
                                <p className="text-danger small mt-2 pb-3">
                                  Employer section is incomplete
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              {requiresEmploymentDetails ? (
                <>
                  <Button
                    type="button"
                    className="mt-3"
                    onClick={() => {
                      arrayHelpers.push(employmentDetailsSchema.default());
                      this.collapseItem(
                        individualCustomer.employmentDetails.length - 1
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add previous employer
                  </Button>
                  {errors.individualCustomer &&
                  (errors.individualCustomer as any).employmentDetailsError ? (
                    <p className="text-danger small mt-2">
                      {
                        (errors.individualCustomer as any)
                          .employmentDetailsError
                      }
                    </p>
                  ) : null}
                </>
              ) : null}
            </div>
          )}
        </FieldArray>
        {this.props.values.proposalCustomerQuestions.customerPresent && (
          <ProposalQuestion
            {...this.props}
            name="proposalCustomerQuestions.customerExpectsAffordabilityProblems"
            text="Are you expecting any changes to your income or
          expenditure that might make it more difficult to meet the finance payments?"
            additionalMessage="Please ensure you explain the responsibilities
          of a secured finance agreement to the customer"
            reverseValues={true}
          />
        )}
      </>
    );
  }

  private collapseItem(i: number) {
    const array = this.state.collapsedItems.slice();
    if (array.indexOf(i) === -1) {
      array.push(i);

      this.setState({
        collapsedItems: array,
      });
    }
  }

  private expandItem(i: number) {
    const array = this.state.collapsedItems.slice();
    array.splice(array.indexOf(i), 1);

    this.setState({
      collapsedItems: array,
    });
  }

  private validateEmployer(emp: EmploymentDetails) {
    return employmentDetailsSchema.isValidSync(emp);
  }
}

export default EmploymentHistorySection;
