import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import { Alert, Col, Label, Row } from "reactstrap";
import {
  capitalizeFirstLettersOnly,
  formatEnumValue,
  getFullName,
} from "../../../utils";
import FormInputField from "../../Forms/FormInputField";
import { WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import CountryField from "../../Settings/Countries/CountryField";
import {
  DrivingLicense,
  DrivingLicenseLookup,
  MaritalStatus,
  Proposal,
} from "../types";
import DateSelectRow from "./DateSelectRow";
import TitleSelectField from "./TitleSelectField";
import { ContextNames } from "../../types";

const maritalStatusOptions = Object.keys(MaritalStatus).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const drivingLicenseOptions = Object.keys(DrivingLicenseLookup).map((x) => ({
  label: DrivingLicenseLookup[x as DrivingLicense].description,
  value: x,
}));

export const individualCustomerWatchFields: WatchFields<Proposal> = {
  individualCustomer: {
    title: true,
    forename: true,
    middleName: true,
    surname: true,
    dOB: true,
    maritalStatus: true,
    numOfDependants: true,
    drivingLicense: true,
    mobile: true,
    email: true,
    countryOfBirthId: true,
    nationalityId: true,
    countryOfResidenceId: true,
  },
  proposalCustomerQuestions: {
    customerHasHadFinanceBefore: true,
  },
};

const containsAllCaps = (props: any) =>
  ["title", "forename", "middleName", "surname"].some(
    (x) =>
      props[x] && props[x].length > 1 && props[x].toUpperCase() === props[x]
  );

const shouldShowCustomerNameWarning = (proposal: Proposal) => {
  const fullName = getFullName(proposal.individualCustomer);
  const fullNameProperCase = fullName
    ? capitalizeFirstLettersOnly(fullName)
    : undefined;
  const allCaps = containsAllCaps(proposal.individualCustomer);

  return fullName && (fullNameProperCase !== fullName || allCaps);
};

const IndividualCustomerSection = (
  props: FormikProps<Proposal> & { context?: ContextNames }
) => {
  return (
    <>
      <Row>
        <Field
          colSize={3}
          name="individualCustomer.title"
          component={TitleSelectField}
          autoFocus={true}
        />
      </Row>
      <Row>
        <Field
          colSize={4}
          name="individualCustomer.forename"
          type="text"
          title="First name(s)"
          component={FormInputField}
        />
        <Field
          colSize={4}
          name="individualCustomer.middleName"
          type="text"
          title="Middle name(s)"
          component={FormInputField}
        />
        <Field
          colSize={4}
          name="individualCustomer.surname"
          type="text"
          title="Last name"
          component={FormInputField}
        />
      </Row>
      {props.context === ContextNames.DIRECT_DEAL ? (
        <p className="text-info mb-1 small">
          Make sure to add a middle name if you have one.
        </p>
      ) : (
        <p className="text-info mb-1 small">
          Make sure to add a middle name if the customer has one.
        </p>
      )}

      <p className="text-info mb-3 small">
        The customer name should be the same as it appears on the driving
        license or passport to successfully complete e-sign.
      </p>

      {shouldShowCustomerNameWarning(props.values) ? (
        <p className="small text-info">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          The name should use Title Case, with capital letters only at the start
          of each name.
        </p>
      ) : null}

      <Row>
        <Field
          colSize={5}
          name="individualCustomer.mobile"
          type="text"
          title="Mobile telephone number"
          component={FormInputField}
        />
      </Row>
      <Row>
        <Field
          colSize={5}
          name="individualCustomer.email"
          type="email"
          placeholder="example@example.com"
          title="Email address"
          component={FormInputField}
        />
      </Row>
      <Row>
        <Col lg={5}>
          <Label>Date of birth</Label>
          <Field
            key="dob"
            name="individualCustomer.dOB"
            component={DateSelectRow}
          />
        </Col>
      </Row>

      <Row>
        <RadioField
          colSize={5}
          name="individualCustomer.maritalStatus"
          title="Marital status"
          options={maritalStatusOptions}
          vertical={true}
        />
      </Row>
      <Row>
        <Field
          colSize={3}
          name="individualCustomer.numOfDependants"
          title="Number of dependants"
          type="number"
          min={0}
          component={FormInputField}
        />
      </Row>
      <Row>
        <RadioField
          colSize={5}
          name="individualCustomer.drivingLicense"
          title="Driving license"
          options={drivingLicenseOptions}
          vertical={true}
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name="individualCustomer.countryOfBirthId"
          label="Country of birth"
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name="individualCustomer.nationalityId"
          label="Nationality"
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name="individualCustomer.countryOfResidenceId"
          label="Country of residence"
        />
      </Row>
      {props.values.proposalCustomerQuestions.customerPresent && (
        <Row>
          <Col className="pt-2">
            <Field name="proposalCustomerQuestions.customerHasHadFinanceBefore">
              {({ field, form }: FieldProps<any>) => (
                <Alert
                  fade={false}
                  color="secondary"
                  className="proposal-alert"
                >
                  <p className="text-info lead">Customer question</p>
                  <Row>
                    <Col xs={12}>
                      <Label for={field.name}>
                        Have you had a{" "}
                        <a
                          href="https://www.eurodrivefinance.co.uk/products"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          car finance agreement
                        </a>{" "}
                        before?
                      </Label>
                    </Col>
                    <RadioField
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name="proposalCustomerQuestions.customerHasHadFinanceBefore"
                    />
                  </Row>
                  {field.value === false ? (
                    <p className="text-info mb-0">
                      Please ensure you explain the responsibilities of a
                      secured finance agreement to the customer
                    </p>
                  ) : null}
                </Alert>
              )}
            </Field>
          </Col>
        </Row>
      )}
    </>
  );
};

export default IndividualCustomerSection;
