import { Field, FormikProps } from "formik";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import { formatEnumValue } from "../../../utils";
import AddressFormSection from "../../AddressLookup/AddressFormSection";
import FormInputField from "../../Forms/FormInputField";
import RadioField from "../../Forms/RadioField";
import CountryField from "../../Settings/Countries/CountryField";
import {
  Business,
  DirectorOwnerStatus,
  DrivingLicense,
  DrivingLicenseLookup,
  MaritalStatus,
} from "../types";
import DateSelectRow from "./DateSelectRow";
import TitleSelectField from "./TitleSelectField";

interface DirectorsDetailsSectionProps {
  index: number;
  business: Business;
}

const maritalStatusOptions = Object.keys(MaritalStatus).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const drivingLicenseOptions = Object.keys(DrivingLicenseLookup).map((x) => ({
  label: DrivingLicenseLookup[x as DrivingLicense].description,
  value: x,
}));

const homeOwnershipOptions = Object.keys(DirectorOwnerStatus).map((x) => ({
  label: formatEnumValue(x),
  value: x,
}));

const DirectorsDetailsSection = ({
  index,
  business,
  ...rest
}: DirectorsDetailsSectionProps & FormikProps<any>) => {
  return (
    <>
      <Row>
        <Field
          colSize={3}
          name={`business.directors.${index}.title`}
          component={TitleSelectField}
          autoFocus={true}
        />
      </Row>
      <Row>
        <Field
          colSize={4}
          name={`business.directors.${index}.forename`}
          type="text"
          title="Name"
          component={FormInputField}
        />
        <Field
          colSize={4}
          name={`business.directors.${index}.middleName`}
          type="text"
          title="Middle name(s)"
          component={FormInputField}
        />
        <Field
          colSize={4}
          name={`business.directors.${index}.surname`}
          type="text"
          title="Surname"
          component={FormInputField}
        />
      </Row>
      <p className="text-info mb-1 small">
        Make sure to add a middle name if the customer has one.
      </p>
      <p className="text-info mb-3 small">
        The customer name should be the same as it appears on their driving
        license or passport to successfully complete e-sign.
      </p>
      <Row>
        <Field
          colSize={5}
          name={`business.directors.${index}.mobile`}
          type="text"
          title="Mobile"
          component={FormInputField}
        />
      </Row>
      <Row>
        <Field
          colSize={5}
          name={`business.directors.${index}.email`}
          type="text"
          title="Email"
          component={FormInputField}
        />
      </Row>
      <Row>
        <Col lg={5}>
          <Label>Date of birth</Label>
          <Field
            key="dob"
            name={`business.directors.${index}.dOB`}
            component={DateSelectRow}
          />
        </Col>
      </Row>
      <Row>
        <RadioField
          colSize={5}
          name={`business.directors.${index}.maritalStatus`}
          title="Marital status"
          options={maritalStatusOptions}
          vertical={true}
        />
      </Row>
      <Row>
        <Field
          colSize={3}
          name={`business.directors.${index}.numOfDependants`}
          title="Number of dependants"
          type="number"
          min={0}
          component={FormInputField}
        />
      </Row>
      <Row>
        <RadioField
          colSize={5}
          name={`business.directors.${index}.drivingLicense`}
          title="Driving license"
          options={drivingLicenseOptions}
          vertical={true}
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name={`business.directors.${index}.countryOfBirthId`}
          label="Country of birth"
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name={`business.directors.${index}.nationalityId`}
          label="Nationality"
        />
      </Row>
      <Row>
        <CountryField
          colSize={7}
          name={`business.directors.${index}.countryOfResidenceId`}
          label="Country of residence"
        />
      </Row>
      <AddressFormSection
        {...rest}
        address={business.directors[index].address}
        title="Address"
        pathToAddress={`business.directors.${index}.address`}
      />
      <Row>
        <RadioField
          colSize={5}
          name={`business.directors.${index}.owner`}
          title="Home ownership"
          options={homeOwnershipOptions}
          vertical={true}
        />
      </Row>
      {business.directors[index].owner === DirectorOwnerStatus.OTHER ? (
        <Row>
          <Field
            colSize={5}
            name={`business.directors.${index}.otherStatus`}
            type="text"
            placeholder="Ownership details"
            component={FormInputField}
          />
        </Row>
      ) : null}
      {index === 0 ? (
        <Row>
          <Field
            colSize={5}
            name={`business.directors.${index}.address.totalMonthlyRentOrMortgage`}
            type="number"
            min={0}
            title="Monthly rent or mortgage cost"
            units="£"
            component={FormInputField}
          />
        </Row>
      ) : null}
      <Row>
        <Col lg={5}>
          <Row>
            <Field
              colSize={6}
              name={`business.directors.${index}.homeYears`}
              type="number"
              min={0}
              title="Years at address"
              component={FormInputField}
            />
            <Field
              colSize={6}
              name={`business.directors.${index}.homeMonths`}
              type="number"
              min={0}
              max={11}
              title="Months at address"
              component={FormInputField}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Field
          colSize={5}
          name={`business.directors.${index}.earnings`}
          type="number"
          min={0}
          title="Gross annual income"
          units="£"
          component={FormInputField}
        ></Field>
      </Row>
      <Row>
        <Col>
          <div className="custom-control custom-checkbox">
            <input
              id={`guarantor_${index}`}
              name={`business.directors.${index}.guarantor`}
              type="checkbox"
              className="custom-control-input pointer"
              checked={!!business.directors[index].guarantor}
              onChange={(e) => {
                rest.setFieldValue(
                  `business.directors.${index}.guarantor`,
                  e.target.checked
                );
              }}
            />
            <label
              htmlFor={`guarantor_${index}`}
              className="custom-control-label pointer"
            >
              Guarantor
            </label>
            <small className="form-text text-info">
              It may be required to provide evidence that this individual is a
              guarantor
            </small>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DirectorsDetailsSection;
