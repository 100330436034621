import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { DebounceKeys } from "../../types";

interface VehicleStylesData {
  vehicles: {
    vehicleStyles: {
      id: string;
      name: string;
    }[];
  };
}

interface VehicleStyleArgs {
  isCommercial?: boolean;
  make: string;
  model: string;
}

interface VehicleStyleVariables {
  input: VehicleStyleArgs;
}

const VEHICLE_STYLES = gql`
  query VehicleStylesQuery($input: VehicleStyleArgsInput) {
    vehicles {
      vehicleStyles(input: $input) {
        id
        name
      }
    }
  }
`;

const VehicleStylesQuery = ({
  children,
  isCommercial,
  make,
  model
}: VehicleStyleArgs & {
  children: (
    result: QueryResult<VehicleStylesData, VehicleStyleVariables>
  ) => JSX.Element | null;
}) => (
  <Query<VehicleStylesData, VehicleStyleVariables>
    query={VEHICLE_STYLES}
    variables={{ input: { isCommercial: !!isCommercial, make, model } }}
    context={{ debounceKey: DebounceKeys.VEHICLE_STYLES, debounceTimeout: 300 }}
  >
    {children}
  </Query>
);

export default VehicleStylesQuery;
