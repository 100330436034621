import * as Yup from "yup";
import vehicleValidationSchema from "../../Proposals/ProposalForm/vehicleValidationSchema";
import { ProductTypeEnum } from "../../types";
import {
  QuotationFinance,
  QuotationFormValues,
  QuotationTargetBy,
} from "../types";

const financeValidationSchema = Yup.object<QuotationFinance>()
  .shape({
    cashPrice: Yup.number()
      .default(undefined)
      .typeError("Price of vehicle required")
      .required("Price of vehicle required")
      .moreThan(0, "Price must be more than zero"),
    deposit: Yup.number()
      .default(0)
      .typeError("Deposit must be a number")
      .required("Deposit not specified")
      .lessThan(Yup.ref("cashPrice"), "Deposit must be less than the price")
      .min(0, "Deposit must be zero or more"),
    partExchangeValue: Yup.number()
      .default(0)
      .typeError("Part exchange value must be a number")
      .required("Part exchange value not specified")
      .min(0, "Part exchange value must be zero or more"),
    partExchangeSettlement: Yup.number()
      .default(0)
      .typeError("Part exchange settlement must be a number")
      .required("Part exchange settlement not specified")
      .min(0, "Part exchange settlement must be zero or more"),
    arrangementFee: Yup.number().nullable(true).default(undefined),
    completionFee: Yup.number().nullable(true).default(undefined),
    guaranteedFutureValue: Yup.number().nullable(true).default(undefined),
    commission: Yup.number().nullable(true).default(undefined),
    commissionCode: Yup.string().nullable(true).default(undefined),
    aprRate: Yup.number().nullable(true).default(undefined),
    flatRate: Yup.number().nullable(true).default(undefined),
    productType: Yup.string()
      .nullable(true)
      .label("Product type")
      .required()
      .default(undefined),
    term: Yup.number()
      .label("Term")
      .nullable(true)
      .integer("Period must be a whole number")
      .default(36)
      .required("Term is required"),
    monthlyPayment: Yup.number().nullable(true).default(undefined),
  })
  .test(
    "quotation-negative-finance-value",
    "message",
    // tslint:disable:ter-prefer-arrow-callback
    // tslint:disable:only-arrow-functions
    function (value: QuotationFinance) {
      const { cashPrice, deposit, partExchangeValue, partExchangeSettlement } =
        value;
      const t =
        (cashPrice || 0) +
        (partExchangeSettlement || 0) -
        ((deposit || 0) + (partExchangeValue || 0));
      if (t < 0) {
        return this.createError({
          path: "finance.cashPrice",
          message: "Finance value must be greater than 0",
        });
      }
      return true;
    }
    // tslint:enable:ter-prefer-arrow-callback
    // tslint:enable:only-arrow-functions
  )
  .required();

const quotationValidationSchema = Yup.object<QuotationFormValues>().shape({
  dealerId: Yup.number()
    .label("Dealer")
    .nullable(true)
    .required()
    .default(undefined),
  title: Yup.string().max(10).label("Title").nullable(true).default(undefined),
  forename: Yup.string()
    .label("Forename")
    .max(100)
    .nullable(true)
    .default(undefined),
  middleName: Yup.string()
    .label("Middle name")
    .max(100)
    .nullable(true)
    .default(undefined),
  surname: Yup.string()
    .label("Surname")
    .max(100)
    .nullable(true)
    .default(undefined),
  mobile: Yup.string().max(25).nullable(true).default(undefined),
  email: Yup.string().max(256).nullable(true).default(undefined),
  showFinanceSection: Yup.boolean().default(false),
  showLoanDetailsSection: Yup.boolean().default(true),
  showResults: Yup.boolean().default(false),
  showCommission: Yup.boolean().default(false),
  finance: financeValidationSchema,
  vehicle: vehicleValidationSchema,
  targetBy: Yup.string()
    .required("Target by not specified")
    .default(QuotationTargetBy.APR_RATE) as any,
  targetByValue: Yup.number()
    .default(undefined)
    .typeError("Target value must be a number")
    .required("Target value not specified")
    .moreThan(0, "Target value must be more than zero"),
  productTypes: Yup.array()
    .of(Yup.string().min(1))
    .ensure()
    .min(1, "At least one loan type must be specified")
    .test(
      "hp-only-if-vehicle-not-specified",
      "Only HP loans can be selected if the vehicle is not specified",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        const skipVehicle = !!(
          this.parent &&
          this.parent.vehicle &&
          this.parent.vehicle.skipVehicle
        );
        return (
          !value ||
          !skipVehicle ||
          value.length === 0 ||
          (value.length === 1 && value[0] === ProductTypeEnum.HP)
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .default([ProductTypeEnum.PCP, ProductTypeEnum.HP]) as any,
});

export default quotationValidationSchema;
