import * as React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useAuth0 } from "../../Auth0Login/Auth0Provider";

const DirectDealCompleteScene = () => {
  const { logout, loading } = useAuth0();

  if (loading || !logout) {
    return null;
  }

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <Row className="direct-deal col-xs-12 col-md-8 col-lg-6 col-xl-5">
        <Col className="text-center px-md-4 py-5">
          <h1>Success!</h1>
          <p>Your proposal has been submitted and is currently under review.</p>
          <p>Your account manager will soon be in touch with the outcome.</p>
          <Button outline={true} onClick={logout}>
            Log out
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DirectDealCompleteScene;
