import { ApolloClient } from "apollo-boost";
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import configureApolloClient from "../../apollo/configureApolloClient";
import configureApolloLink from "../../apollo/configureApolloLink";
import { useAuth0 } from "../Auth0Login/Auth0Provider";

const DealerPortalApolloProvider = ({
  children
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [client, setClient] = React.useState<ApolloClient<any> | undefined>();
  const { loading, getToken } = useAuth0();

  React.useEffect(() => {
    if (!loading && getToken) {
      (async () => {
        // Create an apollo link which is authenticated with the Auth0 access token
        const apolloLink = await configureApolloLink({
          getToken
        });
        const apolloClient = configureApolloClient(apolloLink);
        setClient(apolloClient);
      })();
    }
  }, [loading, getToken]);

  if (!client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default DealerPortalApolloProvider;
