import gql from "graphql-tag";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
  MutationResult,
  useMutation
} from "react-apollo";

interface UpdateAnnouncementForUserData {
  success: boolean;
}

interface UpdateAnnouncementForUserVariables {
  userId: string;
  announcementId: number;
  confirmed: boolean;
  dismissed?: boolean;
}

export const UPDATE_ANNOUNCEMENT_WEB_USER = gql`
  mutation UpdateAnnouncementForUser(
    $userId: ID!
    $announcementId: ID!
    $confirmed: Boolean!
    $dismissed: Boolean
  ) {
    updateAnnouncementForUser(
      userId: $userId
      announcementId: $announcementId
      confirmed: $confirmed
      dismissed: $dismissed
    ) {
      success
    }
  }
`;

const UpdateAnnouncementWebUserMutation = ({
  children
}: {
  children: (
    mutationFunction: MutationFunction<
      UpdateAnnouncementForUserData,
      UpdateAnnouncementForUserVariables
    >,
    result: MutationResult<UpdateAnnouncementForUserData>
  ) => JSX.Element | null;
}) => <Mutation mutation={UPDATE_ANNOUNCEMENT_WEB_USER}>{children}</Mutation>;

export const useUpdateAnnouncementForUser = () => {
  const [updateAnnouncementForUser] = useMutation<
    UpdateAnnouncementForUserData,
    UpdateAnnouncementForUserVariables
  >(UPDATE_ANNOUNCEMENT_WEB_USER);
  return updateAnnouncementForUser;
};

export default UpdateAnnouncementWebUserMutation;
