import classnames from "classnames";
import { Field, FormikProps } from "formik";
import * as React from "react";
import { Alert, Button, Col, FormGroup, Row } from "reactstrap";
import { getSingleLineVehicle } from "../../../utils";
import BooleanCheckboxField from "../../Forms/BooleanCheckboxField";
import FormInputField from "../../Forms/FormInputField";
import { WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import { Proposal } from "../types";
import ProposalFormClearQuotation from "./ProposalFormClearQuotation";
import ProposalQuestion from "./ProposalQuestion";
import VehicleSection from "./VehicleSection";
import { ContextNames } from "../../types";

export const proposalVehicleSectionWatchFields: WatchFields<Proposal> = {
  proposalCustomerQuestions: {
    customerAnnualMileageRealistic: true,
  },
  vehicle: {
    regNo: true,
    dateOfRegistration: true,
    isNew: true,
    cAP: true,
    capId: true,
    make: true,
    model: true,
    derivative: true,
    bodyStyle: true,
    colour: true,
    vIN: true,
    doors: true,
    engineSize: true,
    fuel: true,
    transmission: true,
    insuranceGroup: true,
    mileage: true,
    maxAnnualMileage: true,
    isCommercial: true,
    vatQualifying: true,
    isRegUnknown: true,
    skipVehicle: true,
    regNoNotFound: true,
    vehicleType: true,
  },
};

const VehicleSummary = (
  formikProps: FormikProps<Proposal> & { context?: ContextNames }
) => {
  const {
    values: { vehicle },
    context,
  } = formikProps;
  return (
    <>
      {context !== ContextNames.DIRECT_DEAL && (
        <Alert fade={false} color="info">
          Vehicle details are fixed for the finance quotation{" "}
          <ProposalFormClearQuotation {...formikProps}>
            {({ clearQuotation }) => (
              <Button
                className="ml-3"
                type="button"
                size="sm"
                outline={true}
                color="info"
                onClick={clearQuotation}
              >
                Clear quotation and edit vehicle
              </Button>
            )}
          </ProposalFormClearQuotation>
        </Alert>
      )}
      <Row className={classnames({ "d-none": !vehicle.cAP })}>
        <Col md={8}>
          <FormGroup className="mb-3">
            {vehicle.regNo ? (
              <h4 className="vehicle-registration-static my-2">
                {vehicle.regNo.toUpperCase()}
              </h4>
            ) : null}
            <p className="mb-0">{getSingleLineVehicle(vehicle)}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <RadioField
          readOnly={true}
          title="Used or new"
          name="vehicle.isNew"
          options={[
            { label: "Used", value: false },
            { label: "New", value: true },
          ]}
        />
      </Row>
      <Row>
        <Col>
          <BooleanCheckboxField
            name="vehicle.vatQualifying"
            label="VAT qualifying"
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Field
          name="vehicle.mileage"
          type="number"
          min={0}
          placeholder="Total mileage"
          title="Mileage"
          component={FormInputField}
          units="Miles"
          unitsPosition="after"
          colSize={5}
          readOnly={true}
        />
      </Row>
      <Row>
        <Field
          name="vehicle.maxAnnualMileage"
          type="number"
          placeholder="Max mileage p/a"
          title="Max annual mileage"
          component={FormInputField}
          units="Miles"
          unitsPosition="after"
          colSize={5}
          readOnly={true}
        />
      </Row>
    </>
  );
};

const ProposalVehicleSection = (
  props: FormikProps<Proposal> & { context?: ContextNames }
) => {
  const {
    values: { quotationId },
  } = props;

  return (
    <>
      {quotationId ? (
        <VehicleSummary {...props} />
      ) : (
        <VehicleSection context={props.context} {...props} autoFocus={true} />
      )}
      {props.values.proposalCustomerQuestions?.customerPresent && (
        <ProposalQuestion
          key="vehicle-question"
          {...props}
          name="proposalCustomerQuestions.customerAnnualMileageRealistic"
          text="Are you sure the annual mileage selected is consistent with your previous annual mileage covered with your previous vehicle?
          Some finance agreements may involve mileage restrictions, going over these restrictions may incur fees. Please ensure you enter a 
          realistic figure to avoid penalties"
          additionalMessage="Please ensure you explain the responsibilities of a secured finance agreement to the customer"
        />
      )}
    </>
  );
};

export default ProposalVehicleSection;
