import * as React from "react";
import { Alert, Badge } from "reactstrap";
import { AlertsConsumer } from "./AlertsProvider";
import { AlertMessage } from "./types";

export const DismissableAlert = ({
  alert,
  removeAlert,
  fade
}: {
  alert: AlertMessage;
  removeAlert?: () => void;
  fade?: boolean;
}) => (
  <Alert
    color={alert.color}
    toggle={removeAlert}
    transition={{ in: true, timeout: 0 }}
    fade={fade === undefined ? alert.count <= 1 : fade}
  >
    <div
      onClick={alert.onClick || undefined}
      style={{ cursor: alert.onClick ? "pointer" : "default" }}
    >
      {alert.title ? <h4 className="alert-heading">{alert.title}</h4> : null}
      {alert.color === "danger" && process.env.NODE_ENV === "development" ? (
        <pre>{alert.message}</pre>
      ) : (
        alert.message
      )}
      {alert.count && alert.count > 1 ? (
        <Badge color={alert.color} className="ml-2">
          {alert.count}
        </Badge>
      ) : null}
    </div>
  </Alert>
);

const Alerts = () => (
  <AlertsConsumer>
    {({ alerts, removeAlert }) => {
      return (
        <div className="alerts-container">
          {alerts && alerts.length
            ? alerts.map(a => (
                <DismissableAlert
                  key={a.id}
                  alert={a}
                  removeAlert={() => removeAlert(a.id)}
                />
              ))
            : null}
        </div>
      );
    }}
  </AlertsConsumer>
);

export default Alerts;
