import * as React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import InformationTable, {
  CurrencyRow,
  InformationTableRow,
} from "../../shared/InformationTable";
import { QuotationResults } from "../types";

interface MultiQuoteResultCardProps {
  result?: QuotationResults | null;
  showCommission?: boolean;
  toggle: () => void;
  selectResult: (result: QuotationResults) => void;
}

const MultiQuoteResultCard = ({
  selectResult,
  result,
  showCommission,
}: MultiQuoteResultCardProps) => {
  return result ? (
    <>
      <Col lg={4}>
        <Card className="sticky-top mt-5 custom-card">
          <CardHeader className="pt-4 pb-0">
            <Row>
              <Col lg={4} className="d-flex align-items-center">
                <div className="card-product-type">{result.productType}</div>
              </Col>
              <Col lg={8} className="pl-0 d-flex align-items-center">
                <div>
                  <h5 className="font-weight-normal">{result.lenderName}</h5>
                  <div className="font-weight-bold">Representative Example</div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <hr></hr>
          <CardBody className="pb-3">
            <Row>
              <Col sm={{ size: 12 }} className="text-justify">
                <InformationTable>
                  <CurrencyRow title="Cash price" value={result.cashPrice} />
                  <CurrencyRow title="Deposit" value={result.deposit} />
                  <CurrencyRow
                    title="Amount of credit"
                    value={result.finance}
                    highlighted={true}
                  />
                  <CurrencyRow
                    title={`${result.term} monthly rentals`}
                    value={result.monthlyPayment}
                    highlighted={true}
                  />
                  <CurrencyRow
                    title="Arrangement fee"
                    value={result.arrangementFee}
                  />
                  <CurrencyRow
                    title="Completion fee"
                    value={result.completionFee}
                  />
                  <CurrencyRow
                    title={`Final rental`}
                    value={result.guaranteedFutureValue}
                  />
                  <CurrencyRow
                    title="Interest charges"
                    value={result.interestCharges}
                  />
                  <CurrencyRow
                    title="Total amount payable"
                    value={
                      result.finance +
                      result.arrangementFee +
                      result.completionFee +
                      result.interestCharges
                    }
                  />
                  <InformationTableRow
                    title="APR"
                    value={`${(Math.round(result.aprRate * 100) / 100).toFixed(
                      2
                    )}%`}
                    valueAlignRight={true}
                    valueHighlighted={true}
                  />
                  {showCommission ? (
                    <InformationTableRow
                      title="Scheme code"
                      value={result.commissionCode}
                      valueAlignRight={true}
                    />
                  ) : null}
                </InformationTable>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    selectResult(result);
                  }}
                >
                  Save this loan
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  ) : null;
};

export default MultiQuoteResultCard;
