import * as React from "react";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import InformationTable, {
  CurrencyRow,
  InformationTableRow
} from "../../shared/InformationTable";
import { ProductTypeEnum } from "../../types";
import { QuotationResults } from "../types";

interface QuotationResultModalProps {
  result?: QuotationResults | null;
  showCommission?: boolean;
  toggle: () => void;
  selectResult: (result: QuotationResults) => void;
}

const displayLoanDepositAlert = (props: QuotationResults) => {
  return (
    props &&
    (props.productType === ProductTypeEnum.LP ||
      props.productType === ProductTypeEnum.PCP) &&
    props.term > 48 &&
    (props.deposit * 100) / props.cashPrice < 10
  );
};

const QuotationResultModal: React.SFC<QuotationResultModalProps> = React.memo(
  ({
    selectResult,
    result,
    toggle,
    showCommission
  }: QuotationResultModalProps) => {
    return (
      <Modal
        isOpen={!!result}
        toggle={toggle}
        centered={true}
        modalTransition={{ timeout: 100 }}
        className="quotation-form-modal"
      >
        {result ? (
          <>
            <ModalHeader toggle={toggle}>
              {result.productType} finance, {result.term} month term
            </ModalHeader>
            <ModalBody>
              {displayLoanDepositAlert(result) ? (
                <Alert fade={false} color="info" key="deposit-message">
                  Please note: 10% minimum deposit may be required for all
                  periods exceeding 48 months and subject to the customer status
                </Alert>
              ) : null}
              <Row>
                <Col sm={{ size: 8, offset: 2 }}>
                  <InformationTable>
                    <CurrencyRow title="Cash price" value={result.cashPrice} />
                    <CurrencyRow title="Deposit" value={result.deposit} />
                    <CurrencyRow
                      title="Amount of credit"
                      value={result.finance}
                      highlighted={true}
                    />
                    <CurrencyRow
                      title={`${result.term} monthly rentals`}
                      value={result.monthlyPayment}
                      highlighted={true}
                    />
                    <CurrencyRow
                      title="Arrangement fee"
                      value={result.arrangementFee}
                    />
                    <CurrencyRow
                      title="Completion fee"
                      value={result.completionFee}
                    />
                    <CurrencyRow
                      title={`Final rental`}
                      value={result.guaranteedFutureValue}
                    />
                    <CurrencyRow
                      title="Interest charges"
                      value={result.interestCharges}
                    />
                    <CurrencyRow
                      title="Total amount payable"
                      value={
                        result.finance +
                        result.arrangementFee +
                        result.completionFee +
                        result.interestCharges
                      }
                    />
                    <InformationTableRow
                      title="APR"
                      value={`${(
                        Math.round(result.aprRate * 100) / 100
                      ).toFixed(2)}%`}
                      valueAlignRight={true}
                      valueHighlighted={true}
                    />
                    {showCommission ? (
                      <InformationTableRow
                        title="Scheme code"
                        value={result.commissionCode}
                        valueAlignRight={true}
                      />
                    ) : null}
                  </InformationTable>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" outline={true} onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  selectResult(result);
                }}
              >
                Save this loan
              </Button>
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    );
  }
);

export default QuotationResultModal;
