import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { InputGroup, InputGroupAddon } from "reactstrap";
import { compose, shouldUpdate } from "recompose";
import FormFieldWrapper from "./FormFieldWrapper";

interface FormInputFieldProps {
  colSize?: number;
  title: string;
  description?: string;
  units?: string;
  type?: string;
  unitsPosition?: "before" | "after";
  placeholder?: string;
  step?: number;
  min?: number;
  max?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  spellCheck?: boolean;
  onBlur?: (e: React.FocusEvent<any>) => void;
}

const FormInputField = <TValues extends {}>({
  units,
  description,
  placeholder,
  type,
  onBlur,
  step,
  min,
  max,
  readOnly,
  autoFocus,
  spellCheck,
  ...rest
}: FieldProps<TValues> & FormInputFieldProps) => {
  const { field } = rest;

  const input = (
    <input
      {...field}
      type={type || "text"}
      min={min}
      max={max}
      step={step}
      value={field.value || (field.value === 0 ? field.value : "")}
      placeholder={placeholder}
      readOnly={readOnly}
      autoFocus={autoFocus}
      spellCheck={!!spellCheck}
      className="form-control"
      onBlur={onBlur}
    />
  );

  const unitsPosition = rest.unitsPosition || "before";

  return (
    <FormFieldWrapper description={description} {...rest}>
      {units ? (
        <InputGroup>
          {unitsPosition === "before" ? (
            <InputGroupAddon addonType="prepend">{units}</InputGroupAddon>
          ) : null}
          {input}
          {unitsPosition === "after" ? (
            <InputGroupAddon addonType="append">{units}</InputGroupAddon>
          ) : null}
        </InputGroup>
      ) : (
        input
      )}
    </FormFieldWrapper>
  );
};

const EnhancedField = compose<
  FormInputFieldProps & FieldProps,
  FormInputFieldProps & FieldProps
>(
  shouldUpdate(
    (
      {
        readOnly,
        field: { value, name },
        form: { touched, errors },
        description,
      }: FormInputFieldProps & FieldProps,
      {
        readOnly: nextReadOnly,
        field: { value: nextValue, name: nextName },
        form: { touched: nextTouched, errors: nextErrors },
        description: nextDescription,
      }: FormInputFieldProps & FieldProps
    ) =>
      value !== nextValue ||
      readOnly !== nextReadOnly ||
      description !== nextDescription ||
      get(touched, name) !== get(nextTouched, nextName) ||
      get(errors, name) !== get(nextErrors, nextName)
  )
)(FormInputField);

export default EnhancedField;
