import gql from "graphql-tag";
import * as React from "react";
import { Mutation, MutationFunction, MutationResult } from "react-apollo";
import { QuotationFragment } from "./fragments";
import { Quotation, QuotationVariables, UnableToQuote } from "./types";

export interface TryCreateQuotationData {
  tryCreateQuotation: {
    quotation?: Quotation;
    unableToQuote?: UnableToQuote;
  };
}
export interface TryCreateMannIslandQuotationData {
  tryCreateMannIslandQuotation: {
    quotation?: Quotation;
    unableToQuote?: UnableToQuote;
  };
}

export const TRY_CREATE_QUOTATION = gql`
  mutation TryCreateQuotation($input: QuotationInput) {
    tryCreateQuotation(input: $input) {
      quotation {
        ...QuotationFragment
      }
      unableToQuote {
        term
        productType
        messages
      }
    }
  }
  ${QuotationFragment}
`;

export const TRY_CREATE_INCOMPLETE_QUOTATION = gql`
  mutation TryCreateIncompleteQuotation($input: QuotationInput) {
    tryCreateIncompleteQuotation(input: $input) {
      quotation {
        ...QuotationFragment
      }
      unableToQuote {
        term
        productType
        messages
      }
    }
  }
  ${QuotationFragment}
`;

export const TRY_CREATE_MANN_ISLAND_QUOTATION = gql`
  mutation TryCreateMannIslandQuotation($input: QuotationInput) {
    tryCreateMannIslandQuotation(input: $input) {
      quotation {
        ...QuotationFragment
      }
      unableToQuote {
        term
        productType
        messages
      }
    }
  }
  ${QuotationFragment}
`;

const TryCreateQuotationMutation = ({
  children,
}: {
  children: (
    mutationFunction: MutationFunction<
      TryCreateQuotationData,
      QuotationVariables
    >,
    result: MutationResult<TryCreateQuotationData>
  ) => JSX.Element | null;
}) => <Mutation mutation={TRY_CREATE_QUOTATION}>{children}</Mutation>;

export const TryCreateMannIslandQuotationMutation = ({
  children,
}: {
  children: (
    mutationFunction: MutationFunction<
      TryCreateMannIslandQuotationData,
      QuotationVariables
    >,
    result: MutationResult<TryCreateMannIslandQuotationData>
  ) => JSX.Element | null;
}) => (
  <Mutation mutation={TRY_CREATE_MANN_ISLAND_QUOTATION}>{children}</Mutation>
);

export default TryCreateQuotationMutation;
