import * as React from "react";
import { useState } from "react";
import { passwordlessConfig } from "../../Auth0Login/Auth0PasswordlessProvider";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "./index.scss";

const DirectDealLogin = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({
    emailError: false,
    otpError: false,
    roleError: false,
  });
  const [success, setSuccess] = useState(false);

  const handleAuth = (e: any) => {
    e.preventDefault();
    const data = localStorage.getItem("login_redirect");
    let returnTo: string | undefined;

    if (data) {
      const redirectInfo = JSON.parse(data);
      returnTo = redirectInfo.path;
    }

    localStorage.setItem("login_redirect", JSON.stringify({ path: returnTo }));

    passwordlessConfig.passwordlessStart(
      {
        connection: "email",
        send: "code",
        email: email,
      },
      function (err, res) {
        if (res.Id) {
          setSuccess(true);
        } else {
          setError({ ...error, emailError: true });
        }
      }
    );
  };

  const handleVerifyToken = (e: any) => {
    e.preventDefault();

    passwordlessConfig.passwordlessLogin(
      {
        connection: "email",
        email: email,
        verificationCode: otp,
      },
      function (err, res) {
        console.log("HIT TOKEN RESULT");
        if (err) {
          setError({ ...error, otpError: true });
          return null;
        } else {
          return;
        }
      }
    );
  };

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <Row className="direct-deal col-xs-12 col-md-8 col-lg-6 col-xl-5">
        <Col className="px-md-4 py-5">
          {!success && (
            <>
              <Row>
                <Col className="text-center">
                  <h2>
                    <b>Welcome to Eurodrive Motor Finance</b>
                  </h2>
                  <br />
                  <p>
                    To proceed enter your email address below. You will be sent
                    an authorisation code which will grant access to your
                    application.
                  </p>
                </Col>
              </Row>
              <Form onSubmit={handleAuth}>
                <FormGroup>
                  <Row>
                    <Col className="mt-3 col-12">
                      <Label htmlFor="ddCustomerEmail">
                        Enter your email address:
                      </Label>
                      <Input
                        id="ddCustomerEmail"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="your@email.com"
                        required
                      />
                    </Col>
                    <Col className="mt-3 col-12">
                      <Button color="primary" className="btn-block">
                        Request authorisation code
                      </Button>
                    </Col>
                  </Row>
                  {error.emailError && <p>Error sending mail</p>}
                  {error.roleError && <p>Unexpected error</p>}
                </FormGroup>
              </Form>
            </>
          )}
          {success && (
            <>
              <Row>
                <Col className="text-center">
                  <p>
                    Enter your authorisation code and click the button to
                    proceed to your finance application.
                  </p>
                </Col>
              </Row>
              <Form onSubmit={handleVerifyToken}>
                <FormGroup>
                  <Row>
                    <Col className="mt-3 col-12">
                      <Label htmlFor="authCode">
                        Enter authorisation code:
                      </Label>
                      <Input
                        id="authCode"
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="000000"
                        required
                      />
                    </Col>
                    <Col className="mt-3 col-12">
                      <Button color="primary" className="btn-block">
                        Verify code
                      </Button>
                    </Col>
                  </Row>
                  {error.otpError && <p>Error validating OTP</p>}
                </FormGroup>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DirectDealLogin;
