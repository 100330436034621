import * as Yup from "yup";
import {
  DATE_PATTERN,
  SPECIAL_CHARACTER_SELECT,
  testDateStringIsValid,
} from "../../../utils";
import mobileValidationSchema from "../../Forms/mobileValidationSchema";
import telephoneValidationSchema from "../../Forms/telephoneValidationSchema";
import { Address } from "../../types";
import {
  Business,
  BusinessDirector,
  BusinessType,
  DirectorOwnerStatus,
} from "../types";

export const addressSchema = Yup.object<Address>().shape({
  line1: Yup.string()
    .label("Line 1")
    .default(undefined)
    .test(
      "line1-comma-check",
      "Line 1 format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .min(3)
    .required("Line 1 of the address is required")
    .max(50)
    .nullable(true),
  line2: Yup.string().default(undefined).max(50).label("Line 2").nullable(true),
  line3: Yup.string().default(undefined).max(50).label("Line 3").nullable(true),
  town: Yup.string()
    .default(undefined)
    .test(
      "town-comma-check",
      "Town format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .min(3)
    .max(50)
    .required("Town of the address is required")
    .label("Town")
    .nullable(true),
  postcode: Yup.string()
    .default(undefined)
    .test(
      "postcode-comma-check",
      "Postcode format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .min(3)
    .max(8)
    .required("Postcode of the address is required")
    .label("Postcode")
    .nullable(true),
  countryId: Yup.string().nullable(true).default("GBR"),
  telephone: telephoneValidationSchema.label("Telephone number"),
  totalMonthlyRentOrMortgage: Yup.number()
    .label("Monthly rent or mortgage cost")
    .default(undefined)
    .nullable(true)
    .min(0, "Rent or mortgage value cannot be lower than 0")
    .typeError("Rent or mortgage must be a number")
    .integer("Rent or mortgage amount must be a whole number"),
});

export const businessAddressSchema = Yup.object<Address>().shape({
  line1: Yup.string()
    .label("Line 1")
    .default(undefined)
    .test(
      "line1-comma-check",
      "Line 1 format is incorrect",

      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
    )
    .min(3)
    .required("Line 1 of the address is required")
    .max(50)
    .nullable(true),
  line2: Yup.string().default(undefined).max(50).label("Line 2").nullable(true),
  line3: Yup.string().default(undefined).max(50).label("Line 3").nullable(true),
  town: Yup.string()
    .default(undefined)
    .test(
      "town-comma-check",
      "Town format is incorrect",

      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
    )
    .min(3)
    .max(50)
    .required("Town of the address is required")
    .label("Town")
    .nullable(true),
  postcode: Yup.string()
    .default(undefined)
    .test(
      "postcode-comma-check",
      "Postcode format is incorrect",

      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
    )
    .min(3)
    .max(8)
    .required("Postcode of the address is required")
    .label("Postcode")
    .nullable(true),
  countryId: Yup.string().nullable(true).default("GBR"),
  telephone: telephoneValidationSchema.label("Telephone number"),
});

export const directorValidationSchema = Yup.object<BusinessDirector>().shape({
  id: Yup.string().nullable(true).default(undefined),
  title: Yup.string()
    .label("Title")
    .required()
    .nullable(true)
    .default(undefined)
    .max(10),
  forename: Yup.string()
    .label("Forename")
    .nullable(true)
    .required()
    .default(undefined)
    .max(20),
  middleName: Yup.string()
    .default(undefined)
    .nullable(true)
    .label("Middle name")
    .max(100),
  surname: Yup.string()
    .label("Surname")
    .nullable(true)
    .required()
    .default(undefined)
    .max(50),
  dOB: Yup.string()
    .label("Date of birth")
    .matches(DATE_PATTERN, "Date of birth is a required field")
    .default(undefined)
    .test(
      "director-date-format-validation",
      "Date does not exist",
      testDateStringIsValid
    )
    .required()
    .typeError("Date of birth must be a date") as any,
  email: Yup.string()
    .label("Email")
    .email()
    .nullable(true)
    .default(undefined)
    .required()
    .max(256),
  earnings: Yup.number()
    .label("Earnings")
    .default(undefined)
    .nullable(true)
    .min(10200, "Earnings must be equal to or greater than £850 per month")
    .required(),
  guarantor: Yup.boolean().nullable(true).default(false).required(),
  mobile: mobileValidationSchema.label("Mobile").required(),
  owner: Yup.string()
    .label("Home ownership")
    .nullable(true)
    .default(undefined)
    .required() as any,
  otherStatus: Yup.string()
    .label("Ownership details")
    .nullable(true)
    .default(undefined)
    .max(50)
    .when("owner", {
      is: (t: DirectorOwnerStatus) => t === DirectorOwnerStatus.OTHER,
      then: Yup.string().required(),
    }),
  homeYears: Yup.number()
    .nullable(true)
    .default(undefined)
    .min(0)
    .required()
    .label("Years at address"),
  homeMonths: Yup.number()
    .nullable(true)
    .min(0)
    .lessThan(12)
    .default(undefined)
    .required()
    .label("Months at address")
    .test(
      "director-months-at-address-not-zero",
      "Months at address must be specified if years at address is zero",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return value !== 0 || !this.parent || this.parent.homeYears !== 0;
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    ),
  address: addressSchema.default(addressSchema.default()).required(),
  maritalStatus: Yup.string()
    .default(undefined)
    .label("Marital status")
    .nullable(true)
    .required() as any,
  numOfDependants: Yup.number()
    .default(undefined)
    .label("Number of dependants")
    .min(0)
    .lessThan(100, "Number of dependants must be less than 100")
    .typeError("Number of dependants must be a number")
    .nullable(true)
    .required() as any,
  drivingLicense: Yup.string()
    .default(undefined)
    .label("Driving license")
    .nullable(true)
    .required() as any,
  countryOfBirthId: Yup.string()
    .label("Country of birth")
    .nullable(true)
    .length(3)
    .required()
    .default(undefined),
  nationalityId: Yup.string()
    .label("Nationality")
    .nullable()
    .length(3)
    .required()
    .default(undefined),
  countryOfResidenceId: Yup.string()
    .label("Country of residence")
    .nullable()
    .length(3)
    .required()
    .default(undefined),
});

const businessCustomerValidationSchema = Yup.object<Business>()
  .nullable(true)
  .shape({
    id: Yup.string().nullable(true),
    name: Yup.string()
      .label("Business name")
      .max(50)
      .default(undefined)
      .required(),
    natureOfBusiness: Yup.string()
      .label("Industry")
      .nullable(true)
      .max(50)
      .default(undefined)
      .required("Please specify industry"),
    contactName: Yup.string()
      .label("Contact name")
      .nullable(true)
      .max(50)
      .default(undefined)
      .required(),
    contactPosition: Yup.string()
      .label("Contact position")
      .nullable(true)
      .max(50)
      .default(undefined)
      .required(),
    email: Yup.string()
      .label("Email")
      .nullable(true)
      .max(256)
      .default(undefined),
    mobile: mobileValidationSchema.label("Mobile telephone"),
    businessType: Yup.string()
      .label("Business type")
      .nullable(true)
      .required()
      .default(undefined) as any,
    registrationNumber: Yup.string()
      .label("Company reg no.")
      .nullable(true)
      .notRequired()
      .max(10)
      .default(undefined)
      .when("businessType", {
        is: (t: BusinessType) => t === BusinessType.LTD || t === undefined,
        then: Yup.string().required(),
      }),
    established: Yup.string()
      .label("Date established")
      .required()
      .matches(DATE_PATTERN, "Date established is a required field")
      .nullable(true)
      .test(
        "date-format-validation",
        "Date does not exist",
        testDateStringIsValid
      )
      .default(undefined) as any,
    yearsAtAddress: Yup.number()
      .label("Years at address")
      .integer("Years at address must be a whole number")
      .min(0)
      .nullable(true)
      .required()
      .default(undefined)
      .typeError("Years at address must be a number"),
    monthsAtAddress: Yup.number()
      .label("Months at address")
      .integer("Months at address must be a whole number")
      .min(0)
      .lessThan(12)
      .nullable(true)
      .required()
      .default(undefined)
      .typeError("Months at address must be a number")
      .test(
        "business-months-at-address-not-zero",
        "Months at address must be specified if years at address is zero",
        // tslint:disable:ter-prefer-arrow-callback
        // tslint:disable:only-arrow-functions
        function (value) {
          return (
            value !== 0 || !this.parent || this.parent.yearsAtAddress !== 0
          );
        }
        // tslint:enable:ter-prefer-arrow-callback
        // tslint:enable:only-arrow-functions
      ),
    address: businessAddressSchema,
    directors: Yup.array(directorValidationSchema)
      .default([directorValidationSchema.default()])
      .test(
        "earningsRequired",
        "Earnings are required",
        function (value: BusinessDirector[]) {
          if (
            value &&
            value.length &&
            value[0].earnings !== 0 &&
            !value[0].earnings
          ) {
            return this.createError({
              path: "business.directors.0.earnings",
              message: "Earnings are required",
            });
          }
          return true;
        }
      )
      .test(
        "totalMonthlyRentOrMortgageRequired",
        "Monthly rent or mortgage cost is required",
        function (value: BusinessDirector[]) {
          if (
            value &&
            value.length &&
            value[0].address.totalMonthlyRentOrMortgage !== 0 &&
            !value[0].address.totalMonthlyRentOrMortgage
          ) {
            return this.createError({
              path: "business.directors.0.address.totalMonthlyRentOrMortgage",
              message: "Monthly rent or mortgage cost is required",
            });
          }
          return true;
        }
      )
      .max(2)
      .required(),
  });

export default businessCustomerValidationSchema;
