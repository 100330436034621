import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

interface PageBreadcrumbProps {
  breadcrumbs: {
    to: string;
    title: string;
    active?: boolean;
  }[];
}

const Breadcrumbs = ({
  breadcrumbs,
  className
}: PageBreadcrumbProps & {
  className?: string;
}) => (
  <Breadcrumb className={className}>
    {breadcrumbs.map(({ to, title, active }) => (
      <BreadcrumbItem key={to} active={!!active}>
        {active ? title : <RouterLink to={to}>{title}</RouterLink>}
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);

export default Breadcrumbs;
