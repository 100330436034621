import * as React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupButtonDropdown,
} from "reactstrap";
import { useLoggedInUser } from "../../LoggedInUserQuery";
import { QuotationTargetBy, QuotationTargetByLookup } from "../types";

interface TargetByDropdownProps {
  targetBy: QuotationTargetBy;
  disabled?: boolean;
  updateValue: (value: QuotationTargetBy) => void;
}

const TargetByDropdown = ({
  targetBy,
  disabled,
  updateValue,
}: TargetByDropdownProps) => {
  const { loading, isDealer } = useLoggedInUser();
  const [active, setActive] = React.useState(false);
  const toggle = () => setActive(!active);

  const targetByInfo = QuotationTargetByLookup[targetBy];

  if (loading) {
    return null;
  }

  const options = Object.keys(QuotationTargetBy)
    .filter((k) => !isDealer || k !== QuotationTargetBy.COMMISSION_VALUE)
    .map((k) => ({
      label: QuotationTargetByLookup[k as QuotationTargetBy].description,
      value: k as QuotationTargetBy,
    }));

  return (
    <InputGroupButtonDropdown
      addonType="prepend"
      isOpen={active}
      toggle={toggle}
      disabled={disabled}
    >
      <DropdownToggle caret={true} tabIndex={-1}>
        {targetByInfo.shortDescription || targetByInfo.description}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((x) => (
          <DropdownItem key={x.value} onClick={() => updateValue(x.value)}>
            {x.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default TargetByDropdown;
