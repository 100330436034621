import * as Yup from "yup";
import { Vehicle, VehicleTypeEnum } from "../types";

/**
 * Regex pattern to validate uk number plates.
 * Found it here: https://gist.github.com/glcheetham/75c3821e5ddcad7f65bdd18474a8b8a4
 */
const UK_PLATE_PATTERN =
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)/i;

const vehicleValidationSchema = Yup.object<Vehicle>().shape({
  isCommercial: Yup.boolean().default(false),
  isNew: Yup.boolean().default(false),
  skipVehicle: Yup.boolean().default(false),
  isRegUnknown: Yup.boolean().default(false),
  regNo: Yup.string()
    .label("Vehicle registration")
    .max(8)
    .default(undefined)
    .nullable(true)
    .when(["isRegUnknown", "skipVehicle", "vIN"], {
      is: (regUnknown: boolean, skip: boolean, vIN: string) =>
        !regUnknown && !skip && !vIN,
      then: Yup.string().matches(
        UK_PLATE_PATTERN,
        "Not a valid UK number plate"
      ),
    })
    .when(["isRegUnknown", "skipVehicle"], {
      is: (regUnknown: boolean, skip: boolean) => !regUnknown && !skip,
      then: Yup.string().required().max(255),
    })
    .when(["regNoNotFound", "skipVehicle"], {
      is: (reg: string, skip: boolean) => !!reg && !skip,
      then: Yup.string().notOneOf(
        [Yup.ref("regNoNotFound")],
        "Registration not found.  Try entering the details manually."
      ),
    }),
  regNoNotFound: Yup.string().default(undefined).max(255),
  make: Yup.string()
    .nullable()
    .default(undefined)
    .when(["isRegUnknown", "skipVehicle"], {
      is: (regUnknown: boolean, skip: boolean) => !!regUnknown && !skip,
      then: Yup.string().required("Vehicle make not specified"),
    })
    .max(50),
  model: Yup.string()
    .default(undefined)
    .nullable()
    .when(["isRegUnknown", "skipVehicle"], {
      is: (regUnknown: boolean, skip: boolean) => !!regUnknown && !skip,
      then: Yup.string().required("Vehicle model not specified"),
    })
    .max(50),
  bodyStyle: Yup.string().default(null).nullable(true),
  dateOfRegistration: Yup.mixed()
    .default(undefined)
    .nullable(true)
    .when(["skipVehicle"], {
      is: (skip: boolean) => !skip,
      then: Yup.date().required("Date of registration not specified"),
    }),
  cAP: Yup.string()
    .default(undefined)
    .nullable(true)
    .when(["isRegUnknown", "skipVehicle", "vehicleType"], {
      is: (regUnknown: boolean, skip: boolean, type: string) =>
        !regUnknown && !skip && type !== VehicleTypeEnum.Motorhome,
      then: Yup.string().required("No vehicle specified").max(20),
    }),
  capId: Yup.string().max(50).default(undefined).nullable(true),
  mileage: Yup.number()
    .label("Mileage")
    .nullable(true)
    .default(undefined)
    .min(0)
    .lessThan(1000000, "It's not the starship enterprise!")
    .when("skipVehicle", {
      is: false,
      then: Yup.number()
        .integer("Mileage must be a whole number")
        .required()
        .typeError("Mileage must be a number"),
    }),
  vehicleType: Yup.string()
    .label("Vehicle type")
    .nullable(false)
    .default("Car"),
  maxAnnualMileage: Yup.number()
    .default(6000)
    .label("Max annual mileage")
    .nullable(true)
    .min(6000)
    .when("skipVehicle", {
      is: false,
      then: Yup.number()
        .integer("Annual mileage must be a whole number")
        .typeError("Annual mileage must be a number")
        .required(),
    }),
  vatQualifying: Yup.bool().default(false).nullable(true),
  vIN: Yup.string().default(undefined).nullable(true),
  colour: Yup.string().default(undefined).nullable(true),
  doors: Yup.number()
    .default(undefined)
    .nullable(true)
    .transform((value) => (value || value === 0 ? value : null)),
  transmission: Yup.string().default(undefined).nullable(true),
  insuranceGroup: Yup.string().default(undefined).nullable(true),
  derivative: Yup.string().default(undefined).nullable(true),
  engineSize: Yup.number()
    .default(undefined)
    .nullable(true)
    .transform((value) => (value || value === 0 ? value : null)),
  fuel: Yup.string().default(undefined).nullable(true),
});

export default vehicleValidationSchema;
