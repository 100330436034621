import { FieldProps } from "formik";
import * as React from "react";
import Select from "react-select";
import { Col, Label } from "reactstrap";
import { selectStyles, selectTheme } from "../../Forms/CustomSelect";

interface TitleSelectProps {
  colSize?: number;
  autoFocus?: boolean;
}

const titleOptions: { label: any; value: any }[] = [
  "Mr",
  "Ms",
  "Mrs",
  "Miss",
  "Dr",
  "Other"
].map(title => ({
  label: title,
  value: title
}));

const titleStyles = {
  ...selectStyles,
  container: (provided: any) => ({
    ...provided
  })
};

const TitleSelectField = (props: FieldProps<any> & TitleSelectProps) => {
  const {
    form: { errors, touched }
  } = props;

  const path = props.field.name.split(".");

  const isTouched = path.reduce((o, i) => o && o[i], { ...touched } as any);
  const err =
    (isTouched && path.reduce((o, i) => o && o[i], { ...errors } as any)) || "";

  const titleOption = !!props.field.value
    ? titleOptions.find(
        x => x.value.toLowerCase() === props.field.value.toLowerCase()
      ) || titleOptions.find(x => x.value === "Other")
    : null;

  return (
    <Col className="pb-3" lg={props.colSize}>
      <Label for={props.field.name}>Title</Label>
      <Select
        id="title-input"
        styles={titleStyles}
        theme={selectTheme}
        value={titleOption}
        onChange={(option: any) =>
          props.form.setFieldValue(props.field.name, option.value)
        }
        onBlur={() => props.form.setFieldTouched(props.field.name, true)}
        options={titleOptions}
        placeholder="Title"
        autoFocus={!!props.autoFocus}
      />
      {err && isTouched ? <div className="text-danger small">{err}</div> : null}
    </Col>
  );
};

export default TitleSelectField;
