import React from "react";
import { formatCurrency, getMonthsAndYearsText, getSingleLineAddress } from "../../utils";
import { HomeAddress } from "../Proposals/types";
import SummaryRow, { TelephoneSummaryRow } from "./SummarySection/SummaryRow";

const AddressRow = ({
  address,
  index,
}: {
  address: HomeAddress;
  index: number;
}) => (
  <>
    <SummaryRow
      title={`Address ${index + 1}:`}
      value={getSingleLineAddress(address)}
    />
    {index === 0 && (
      <SummaryRow
        title="Monthly rent/mortgage:"
        value={formatCurrency(address.totalMonthlyRentOrMortgage)}
      />
    )}
    <TelephoneSummaryRow title={`Telephone:`} to={address.telephone} />
    <SummaryRow
      title="Time at address:"
      value={
        (address.yearsAtAddress || address.yearsAtAddress === 0) &&
        (address.monthsAtAddress || address.monthsAtAddress === 0)
          ? getMonthsAndYearsText(
              address.yearsAtAddress * 12 + address.monthsAtAddress
            )
          : null
      }
    />
  </>
);

export default AddressRow;
