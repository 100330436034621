import * as React from "react";
import DirectDealQuery from "./DirectDealQuery";
import CreateOrUpdateDirectDealMutation, {
  DirectDealData,
  DirectDealVariables,
} from "./CreateOrUpdateDirectDealMutation";
import { DirectDeal, Proposal } from "../types";
import { FormikErrors, FormikTouched } from "formik";
import { MutationFunction } from "react-apollo";

interface DirectDealServiceProps {
  directDeal?: DirectDeal;
  loading?: boolean;
  sendDirectDeal: (directDeal: DirectDeal) => Promise<boolean | undefined>;
}

interface DirectDealServiceOuterProps {
  directDealId?: string;
  children: (props: DirectDealServiceProps) => JSX.Element | null;
}

export interface DirectDealFormData {
  values: Proposal;
  initialValues: Proposal;
  errors: FormikErrors<Proposal>;
  touched: FormikTouched<Proposal>;
  currentSection?: string;
}

const DirectDealService = ({
  directDealId,
  children,
}: DirectDealServiceOuterProps) => {
  return (
    <DirectDealQuery directDealId={directDealId}>
      {({ directDeal, loading }) => {
        return (
          <CreateOrUpdateDirectDealMutation>
            {(mutationFunction) =>
              children({
                directDeal,
                loading,
                sendDirectDeal: (directDeal) =>
                  sendDirectDeal(mutationFunction, directDeal),
              })
            }
          </CreateOrUpdateDirectDealMutation>
        );
      }}
    </DirectDealQuery>
  );
};

const sendDirectDeal = (
  mutate: MutationFunction<DirectDealData, DirectDealVariables>,
  directDeal: DirectDeal
) => {
  return mutate({ variables: { input: directDeal } }).then((result) => {
    const directDealResult = result && result.data && result.data.success;

    if (!directDealResult) {
      return;
    }

    return directDealResult;
  });
};

export default DirectDealService;
