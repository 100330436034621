import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";
import { User } from "../types";
import { QuotationFragment } from "./fragments";
import { Quotation } from "./types";

interface QuotationData {
  loggedInUser: User;
  quotation: Quotation;
}

interface QuotationArgs {
  quotationId?: number;
}

export const GET_QUOTATION = gql`
  query QuotationQuery($quotationId: ID!) {
    loggedInUser {
      id
      username
      roles
    }
    quotation(id: $quotationId) {
      ...QuotationFragment
    }
  }
  ${QuotationFragment}
`;

const QuotationQuery = ({
  children,
  quotationId
}: QuotationArgs & {
  children: (
    result: QueryResult<QuotationData, QuotationArgs> & {
      quotation?: Quotation;
    }
  ) => JSX.Element | null;
}) => (
  <Query<QuotationData, QuotationArgs>
    query={GET_QUOTATION}
    skip={!quotationId}
    variables={{ quotationId }}
  >
    {result =>
      children({
        ...result,
        quotation: result && result.data && result.data.quotation
      })
    }
  </Query>
);

export const useQuotation = (quotationId?: number) => {
  const { loading, data } = useQuery<QuotationData, QuotationArgs>(
    GET_QUOTATION,
    { variables: { quotationId }, skip: !quotationId }
  );

  return { loading, quotation: data?.quotation };
};

export default QuotationQuery;
