export interface ClientConfig {
  PUBLIC_URL: string;
  GRAPHQL_ENDPOINT: string;
  API_URL: string;
  TITLE: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  AUTH0_AUDIENCE: string;
  VERSION_CHECK_INTERVAL_MINS: number;
  MAPBOX_ACCESS_TOKEN: string;
  MAPBOX_STYLE: string;
  MAPBOX_DARK_STYLE: string;
  MAPBOX_SATELLITE_STYLE: string;
  MAPBOX_USERNAME: string;
  AUTOCONVERT_URL: string;
  AUTOCONVERT_NAME: string;
  TAWK_TO_SITE_ID: string;
  SHAREPOINT_URL: string;
}

let config: ClientConfig | undefined;

/**
 * Creates or returns the runtime configuration settings
 * for the client
 */
const getConfig = (): ClientConfig => {
  if (config) {
    return config;
  }
  const env: any = (process as any).env;

  config = {
    AUTH0_CLIENT_ID: env.REACT_APP_AUTH0_CLIENT_ID,
    AUTH0_DOMAIN: env.REACT_APP_AUTH0_DOMAIN,
    AUTH0_AUDIENCE: env.REACT_APP_AUTH0_AUDIENCE,
    TITLE: env.REACT_APP_TITLE || "Eurodealer",
    PUBLIC_URL: env.PUBLIC_URL || undefined,
    GRAPHQL_ENDPOINT: `${env.REACT_APP_API_URL || ""}/graphql`,
    API_URL: env.REACT_APP_API_URL || "",
    VERSION_CHECK_INTERVAL_MINS:
      env.REACT_APP_VERSION_CHECK_INTERVAL_MINS || 15,
    MAPBOX_ACCESS_TOKEN: env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    MAPBOX_STYLE: env.REACT_APP_MAPBOX_STYLE,
    MAPBOX_DARK_STYLE:
      env.REACT_APP_MAPBOX_DARK_STYLE || env.REACT_APP_MAPBOX_STYLE,
    MAPBOX_SATELLITE_STYLE:
      env.REACT_APP_MAPBOX_SATELLITE_STYLE || env.REACT_APP_MAPBOX_STYLE,
    MAPBOX_USERNAME: env.REACT_APP_MAPBOX_USERNAME,
    AUTOCONVERT_URL: env.REACT_APP_AUTOCONVERT_URL || "",
    AUTOCONVERT_NAME: env.REACT_APP_AUTOCONVERT_NAME || "AutoConvert",
    TAWK_TO_SITE_ID: env.REACT_APP_TAWK_TO_SITE_ID,
    SHAREPOINT_URL: env.REACT_APP_SHAREPOINT_URL,
  };

  return config;
};

export default getConfig();
