import React from "react";
import ReactMarkdown from "react-markdown";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useLatestAnnouncement } from "./AnnouncementQuery";
import { useUpdateAnnouncementForUser } from "./UpdateAnnouncementForUserMutation";

const Announcements = () => {
  const { loading, latestAnnouncement } = useLatestAnnouncement();
  const [showModal, setShowModal] = React.useState(true);
  const mutation = useUpdateAnnouncementForUser();

  const dismissModal = React.useCallback(() => {
    if (latestAnnouncement) {
      mutation({
        variables: {
          userId: latestAnnouncement?.userId,
          announcementId: latestAnnouncement?.announcementId,
          confirmed: false,
          dismissed: true
        }
      });
    }
    setShowModal(false);
  }, [latestAnnouncement, mutation, setShowModal]);

  const confirmModal = React.useCallback(() => {
    if (latestAnnouncement) {
      mutation({
        variables: {
          userId: latestAnnouncement?.userId,
          announcementId: latestAnnouncement?.announcementId,
          confirmed: true
        }
      });
    }
    setShowModal(false);
  }, [latestAnnouncement, mutation, setShowModal]);

  if (loading || !latestAnnouncement?.announcement) {
    return null;
  }

  const { announcement, dismissedCount } = latestAnnouncement;

  const {
    title,
    messageMarkdown,
    buttonText,
    maxDismissedCount
  } = announcement;

  const canDismiss =
    (!maxDismissedCount && maxDismissedCount !== 0) ||
    dismissedCount < maxDismissedCount;

  return (
    <Modal
      isOpen={showModal}
      toggle={canDismiss ? dismissModal : undefined}
      centered={false}
      modalTransition={{ timeout: 100 }}
    >
      <ModalHeader toggle={canDismiss ? dismissModal : undefined}>
        {title}
      </ModalHeader>
      <ModalBody className="markdown-container">
        <ReactMarkdown source={messageMarkdown} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={confirmModal} color="primary">
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Announcements;
