import { isArray, mergeWith } from "lodash";
import * as React from "react";
import CustomSelect from "../Forms/CustomSelect";
import { useDealerListShallow } from "./DealerList/DealerListQuery";
import { useDealerShallow } from "./DealerQuery";

interface DealerSelectProps {
  dealerId?: number;
  accountManagerId?: string;
  fundedDealersOnly?: boolean;
  onSelectDealer: (
    dealerId: number,
    isMannIslandDealer: boolean,
    isMannIslandZList: boolean,
    isMultiQuote: boolean
  ) => void;
  autoFocus?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  includeSuspended?: boolean;
}

const DealerSelect = (props: DealerSelectProps) => {
  const {
    dealerId,
    accountManagerId,
    fundedDealersOnly,
    onSelectDealer,
    autoFocus,
    isClearable,
    isDisabled,
    includeSuspended,
  } = props;
  const [query, setQuery] = React.useState("");

  const { dealer, loading } = useDealerShallow(dealerId);

  const input = {
    q: query || undefined,
    page: 1,
    pageSize: 40,
    accountManagerId,
    fundedDealersOnly,
    includeSuspended,
  };

  const { dealers, fetchMore } = useDealerListShallow(input);

  const options =
    dealers?.edges?.map(
      ({
        node: { name, id, isMannIslandDealer, isMannIslandZList, isMultiQuote },
      }) => ({
        label: name,
        value: id,
        isMannIslandDealer: isMannIslandDealer,
        isMannIslandZList: isMannIslandZList,
        isMultiQuote: isMultiQuote,
      })
    ) || [];

  const selectedOption = dealer
    ? {
        label: dealer.name,
        value: dealer.id,
      }
    : null;

  const pageInfo = dealers?.pageInfo;

  return (
    <CustomSelect
      isDisabled={!dealers || !!isDisabled}
      value={selectedOption}
      inputValue={query}
      onInputChange={setQuery}
      options={options}
      onChange={(value: any) => {
        onSelectDealer(
          value ? value.value : undefined,
          value && value.isMannIslandDealer,
          value && value.isMannIslandZList,
          value && value.isMultiQuote
        );
      }}
      noOptionsMessage={() =>
        loading ? (dealer ? dealer.name : "Loading...") : "No dealers found"
      }
      filterOption={() => true}
      placeholder={dealers ? "Type to search dealers" : "Loading..."}
      autoFocus={autoFocus}
      isClearable={!!isClearable}
      onMenuScrollToBottom={() =>
        pageInfo &&
        pageInfo.hasMorePages &&
        fetchMore({
          variables: {
            input: {
              ...input,
              page: (pageInfo.page || 1) + 1,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }

            return mergeWith(
              {},
              prev,
              fetchMoreResult,
              (objValue, srcValue) => {
                if (isArray(objValue)) {
                  return [...objValue, ...srcValue];
                }
                return;
              }
            );
          },
        })
      }
    />
  );
};

export default DealerSelect;
