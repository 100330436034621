import { Field, FieldProps } from "formik";
import * as React from "react";
import NumberFormat from "react-number-format";
import { Alert, Col, Row } from "reactstrap";
import { getFullName } from "../../../utils";
import { BooleanCheckbox } from "../../Forms/BooleanCheckboxField";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import FormInputField from "../../Forms/FormInputField";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import { Proposal } from "../types";
import ValidateBankAccountButton from "./ValidateBankAccountButton";

export const bankDetailsSectionWatchFields: WatchFields<Proposal> = {
  bankDetails: {
    branch: true,
    bank: true,
    accountName: true,
    accountNumber: true,
    sortCode: true,
    yearsWithBank: true,
    monthsWithBank: true,
    FORMSTATE_invalidBankAccount: true,
  },
  proposalCustomerQuestions: {
    customerSoleSignatoryToBankAccount: true,
  },
};

const populateBankAccountName = (props: Proposal) => {
  if (
    !!(
      props.individualCustomer &&
      props.individualCustomer.title &&
      props.individualCustomer.forename &&
      props.individualCustomer.surname
    ) &&
    !props.bankDetails.accountName
  ) {
    return getFullName(props.individualCustomer);
  }

  return props.bankDetails.accountName;
};

class BankDetailsSection extends React.Component<FormSectionProps<Proposal>> {
  public componentDidMount() {
    const { values } = this.props;

    if (populateBankAccountName(values) !== values.bankDetails.accountName) {
      this.props.setFieldValue(
        "bankDetails.accountName",
        populateBankAccountName(values)
      );
    }
  }

  public render() {
    const {
      bankDetails,
      proposalCustomerQuestions: { customerPresent },
    } = this.props.values;
    const invalidBankAccount = bankDetails.FORMSTATE_invalidBankAccount;
    const accountNotFound =
      !!invalidBankAccount &&
      bankDetails.accountNumber === invalidBankAccount.accountNumber &&
      bankDetails.sortCode === invalidBankAccount.sortCode;

    return (
      <>
        <Row>
          <Field
            colSize={5}
            name={`bankDetails.accountNumber`}
            type="text"
            title="Account number"
            autoFocus={true}
            component={FormInputField}
          />
        </Row>
        <Row>
          <Col lg={5}>
            <Field name="bankDetails.sortCode">
              {({ field, form }: FieldProps<Proposal>) => {
                return (
                  <FormGroupWrapper form={form} field={field} title="Sort code">
                    <NumberFormat
                      className="form-control"
                      displayType="input"
                      name={field.name}
                      value={field.value}
                      format="##-##-##"
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        form.setFieldValue(field.name, formattedValue);
                      }}
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                    />
                  </FormGroupWrapper>
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <ValidateBankAccountButton
              accountNumber={this.props.values.bankDetails.accountNumber}
              sortCode={this.props.values.bankDetails.sortCode}
              validateFormData={() => {
                ["bank.accountNumber", "bank.sortCode"].forEach((x) =>
                  this.props.setFieldTouched(x as any, true, false)
                );
                return this.props.validateForm().then((errors) => {
                  return (
                    !errors.bankDetails ||
                    (!errors.bankDetails.accountNumber &&
                      !errors.bankDetails.sortCode)
                  );
                });
              }}
              accountNotFound={accountNotFound}
              onBankAccountValidated={({
                isValid,
                bank,
                branch,
                accountNumber,
                sortCode,
              }) => {
                if (isValid) {
                  this.props.setFieldValue("bankDetails.bank", bank || null);
                  this.props.setFieldValue(
                    "bankDetails.branch",
                    branch || null
                  );
                } else {
                  this.props.setFieldValue(
                    "bankDetails.FORMSTATE_invalidBankAccount",
                    { accountNumber, sortCode }
                  );
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Field
            colSize={5}
            name={`bankDetails.bank`}
            type="text"
            title="Bank"
            component={FormInputField}
          />
        </Row>
        <Row>
          <Field
            colSize={5}
            name={`bankDetails.branch`}
            type="text"
            title="Branch"
            component={FormInputField}
          />
        </Row>
        <Row>
          <Field
            colSize={5}
            name={`bankDetails.accountName`}
            type="text"
            title="Account name"
            component={FormInputField}
          />
        </Row>

        <Row>
          <Col lg={5}>
            <Row>
              <Field
                colSize={6}
                name={`bankDetails.yearsWithBank`}
                type="number"
                min={0}
                title="Years with bank"
                component={FormInputField}
              />
              <Field
                colSize={6}
                name={`bankDetails.monthsWithBank`}
                type="number"
                min={0}
                title="Months with bank"
                component={FormInputField}
              />
            </Row>
          </Col>
        </Row>
        {customerPresent && (
          <Field name="proposalCustomerQuestions.customerSoleSignatoryToBankAccount">
            {(fieldProps: FieldProps<any>) => (
              <Alert fade={false} color="secondary" className="proposal-alert">
                <p className="text-info lead">Customer question</p>

                <FormGroupWrapper className="mb-0" {...fieldProps}>
                  <BooleanCheckbox
                    label="Confirm these are your bank details and you are the sole signatory"
                    {...fieldProps}
                  />
                </FormGroupWrapper>
              </Alert>
            )}
          </Field>
        )}
      </>
    );
  }
}

export default BankDetailsSection;
