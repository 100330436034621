import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldProps, FormikProps } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Row } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import { WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import { Proposal } from "../types";
import { ContextNames } from "../../types";

export const privacySectionWatchFields: WatchFields<Proposal> = {
  proposalCustomerQuestions: { customerPresent: true, privacyAgreement: true },
};

const PrivacySection = ({
  values,
  context,
}: FormikProps<Proposal> & { context?: ContextNames }) => (
  <>
    {context !== ContextNames.DIRECT_DEAL && (
      <Row>
        <RadioField
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          name="proposalCustomerQuestions.customerPresent"
          title="Are you having a direct dialogue with the customer?"
        />
      </Row>
    )}
    {values.proposalCustomerQuestions.customerPresent && (
      <Alert fade={false} color="secondary" className="proposal-alert">
        <p>
          The personal information we have collected from you will be shared
          with fraud prevention agencies who will use it to prevent fraud and
          money-laundering and to verify your identity. If fraud is detected,
          you could be refused certain services, finance, or employment. Further
          details of how your information will be used by us and these fraud
          prevention agencies, and your data protection rights, can be found on
          our{" "}
          <Link to={"/tnc"} target="_blank">
            Terms and conditions
          </Link>{" "}
          page.
        </p>

        <p>
          As we use a soft credit search, this allows you to obtain quotes
          without affecting your credit rating. These, however, may be visible
          on your credit report but will not display the search, on you, in the
          same manner as a full credit check until you decide to proceed with a
          full application for the loan. However, searches made by external
          lenders on our panel may show on your credit profile, depending on the
          type of search they use. Should you require further information please
          review our privacy policy para.6 .{" "}
          <Link to={"/tnc"} target="_blank">
            ‘Compliance with Laws’
          </Link>{" "}
        </p>
        <Field name="proposalCustomerQuestions.privacyAgreement">
          {(fieldProps: FieldProps<any>) => (
            <FormGroupWrapper className="mb-0" {...fieldProps}>
              <div className="custom-control custom-checkbox font-weight-bold">
                <input
                  id={fieldProps.field.name}
                  type="checkbox"
                  className="custom-control-input"
                  checked={!!fieldProps.field.value}
                  name={fieldProps.field.name}
                  onChange={() => {
                    fieldProps.form.setFieldValue(
                      fieldProps.field.name,
                      !fieldProps.field.value,
                      true
                    );
                    fieldProps.form.setFieldTouched(
                      fieldProps.field.name,
                      true,
                      true
                    );
                  }}
                />
                {context === ContextNames.DIRECT_DEAL ? (
                  <label
                    className="custom-control-label"
                    htmlFor={fieldProps.field.name}
                  >
                    I have been notified of the information above, as well as
                    agreeing to the{" "}
                    <a
                      href="https://www.eurodrivefinance.co.uk/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy{" "}
                      <sup>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                      </sup>
                    </a>{" "}
                    and consent
                  </label>
                ) : (
                  <label
                    className="custom-control-label"
                    htmlFor={fieldProps.field.name}
                  >
                    The customer has been notified of the information above, as
                    well as agreeing to our{" "}
                    <a
                      href="https://www.eurodrivefinance.co.uk/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy{" "}
                      <sup>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                      </sup>
                    </a>{" "}
                    and consents
                  </label>
                )}
              </div>
            </FormGroupWrapper>
          )}
        </Field>
      </Alert>
    )}
  </>
);

export default PrivacySection;
