import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as React from "react";
import { Button, Col, Row } from "reactstrap";
import DistanceInWordsText from "../Proposals/ProposalForm/DistanceInWordsText";
import FormUpdateButtons from "./FormUpdateButtons";
import { CreateOrUpdateMode, SaveDraftProps } from "./MultiSectionForm";

export interface FormSectionFooterProps extends SaveDraftProps {
  isLastSection: boolean;
  onSubmitForm: () => Promise<any>;
  isSubmitting: boolean;
  submitButtonText?: string;
  dirty: boolean;
  isValid: boolean;
  isSectionValid: boolean;
  createOrUpdate: CreateOrUpdateMode;
  canUpdate?: boolean;
  isEditing: boolean;
  onIsEditingChanged: (isEditing: boolean) => void;
  onCompleteSection: () => Promise<any>;
}

const FormSectionFooter = React.memo((props: FormSectionFooterProps) => {
  const {
    isLastSection,
    onCompleteSection,
    isSectionValid,
    isSubmitting,
    submitButtonText,
    createOrUpdate,
    draftValidationMessage,
    draftLastSaved,
    saveDraft,
    savingDraft,
  } = props;

  return (
    <Row>
      {createOrUpdate === CreateOrUpdateMode.CREATE ? (
        <>
          <Col lg={5}>
            <Button
              className="wide-button"
              size="lg"
              color="primary"
              type="button"
              onClick={onCompleteSection}
              disabled={!!isSubmitting}
            >
              {isSubmitting ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="mr-2"
                />
              ) : null}{" "}
              {isLastSection ? submitButtonText || "Submit" : "Next"}
            </Button>
            {!isSectionValid && (
              <p className="text-danger small mt-1 mb-0">
                Error in this section
              </p>
            )}
          </Col>
          {saveDraft && (
            <Col lg={4}>
              <Button
                type="button"
                size="lg"
                className="wide-button mt-3 mt-lg-0"
                outline={true}
                disabled={!!draftValidationMessage}
                onClick={(e) => {
                  e.preventDefault();
                  saveDraft();
                }}
              >
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={savingDraft ? faSpinner : faSave}
                  spin={savingDraft}
                  className="mr-2"
                />
                Save as draft
              </Button>
              {draftLastSaved ? (
                <p className="text-info small mt-1 mb-0">
                  Saved{" "}
                  <DistanceInWordsText date={moment(draftLastSaved).toDate()} />
                </p>
              ) : null}
              {draftValidationMessage ? (
                <p className="text-info small mt-1 mb-0">
                  {draftValidationMessage}
                </p>
              ) : null}
            </Col>
          )}
        </>
      ) : (
        <Col lg={{ size: 7, offset: 5 }}>
          <FormUpdateButtons {...props} />
        </Col>
      )}
    </Row>
  );
});

export default FormSectionFooter;
