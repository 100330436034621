/* eslint-disable jsx-a11y/anchor-is-valid */
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as React from "react";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import withToggleState, { ToggleStateProps } from "../withToggleState";

interface FormNavProps {
  sections: FormNavItemProps[];
  currentSection: string;
  isEditing: boolean;
}

export interface FormNavItemProps {
  section: string;
  activeSection: string;
  title: string;
  onClick: (s: string) => void;
  isSectionValid: boolean;
  isSectionTouched: boolean;
}

const FormNavDropdown = withToggleState<FormNavProps>()(
  ({
    sections,
    currentSection,
    active,
    toggle,
  }: FormNavProps & ToggleStateProps) => {
    const currentProps = sections.find((s) => s.section === currentSection);
    const index = currentProps ? sections.indexOf(currentProps) : 0;
    const title = currentProps && currentProps.title;
    return (
      <Dropdown isOpen={active} toggle={toggle} nav={true}>
        <DropdownToggle nav={true} caret={true} className="active">
          {`${index + 1}/${sections.length}: ${title}`}{" "}
          {currentProps && (
            <SectionStatusText
              {...currentProps}
              currentSection={currentSection}
            />
          )}
        </DropdownToggle>
        <DropdownMenu>
          {sections.map((s, i) => (
            <button
              type="button"
              key={s.section}
              className={classnames("dropdown-item", {
                active: currentSection === s.section,
              })}
              onClick={() => {
                toggle();
                s.onClick(s.section);
              }}
            >
              {`${s.title}`}{" "}
              <SectionStatusText {...s} currentSection={currentSection} />
            </button>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
);

const SectionStatusText = ({
  isSectionValid,
  isSectionTouched,
  section,
  currentSection,
}: FormNavItemProps & {
  currentSection: string;
}) => (
  <span className="section-status">
    {!isSectionValid ? (
      <Badge
        className="mx-3 small"
        color={currentSection === section ? "light" : "danger"}
        style={{ padding: "0.2rem 0.2rem" }}
      >
        <FontAwesomeIcon icon={faExclamation} style={{ width: "0.9rem" }} />
      </Badge>
    ) : isSectionTouched ? (
      <Badge
        className="mx-3 small"
        color={currentSection === section ? "light" : "secondary"}
        style={{ padding: "0.2rem 0.2rem" }}
      >
        <FontAwesomeIcon icon={faCheck} style={{ width: "0.9rem" }} />
      </Badge>
    ) : null}
  </span>
);

const FormNavItem = React.memo(
  ({
    isEditing,
    ...props
  }: FormNavItemProps & {
    currentSection: string;
    isEditing: boolean;
  }) => (
    <li className="nav-item">
      <a
        className={classnames("nav-link", {
          active: props.currentSection === props.section,
        })}
        id={`${props.section}-header`}
        data-toggle="tab"
        href="#"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        onClick={(e) => {
          e.preventDefault();
          props.onClick(props.section);
        }}
      >
        {props.title}
        {isEditing && <SectionStatusText {...props} />}
      </a>
    </li>
  )
);

const FormNav = ({ sections, currentSection, isEditing }: FormNavProps) => (
  <>
    <ul
      className="form-nav nav nav-pills flex-column mb-3 d-none d-lg-block"
      id="myTab"
      role="tablist"
      style={{ position: "sticky", top: 16 }}
    >
      {sections.map((s: FormNavItemProps) => (
        <FormNavItem
          {...s}
          currentSection={currentSection}
          key={s.section}
          isEditing={isEditing}
        />
      ))}
    </ul>
    <ul
      className="form-nav nav nav-pills flex-column mb-3 d-block d-lg-none"
      id="myTabDropdown"
      role="tablist"
    >
      <FormNavDropdown
        sections={sections}
        currentSection={currentSection}
        isEditing={isEditing}
      />
    </ul>
  </>
);

export default FormNav;
