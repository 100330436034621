import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";

interface AddressAutocompleteFindArgs {
  input: {
    query?: string;
    pathFilter?: string;
  };
}

interface AddressAutocompleteFindData {
  addressAutocomplete: {
    find: {
      id: string;
      type: string;
      summaryLine: string;
      locationSummary: string;
      count: number;
    }[];
  };
}

export const ADDRESS_AUTOCOMPLETE_FIND = gql`
  query AddressAutocompleteFindQuery($input: AddressAutocompleteArgsInput) {
    addressAutocomplete {
      find(input: $input) {
        summaryLine
        locationSummary
        id
        type
        count
      }
    }
  }
`;

const AddressAutocompleteFindQuery = ({
  children,
  input: { query, pathFilter }
}: AddressAutocompleteFindArgs & {
  children: (
    result: QueryResult<
      AddressAutocompleteFindData,
      AddressAutocompleteFindArgs
    >
  ) => JSX.Element | null;
}) => {
  const variables: AddressAutocompleteFindArgs = {
    input: { query, pathFilter }
  };

  return (
    <Query<AddressAutocompleteFindData, AddressAutocompleteFindArgs>
      query={ADDRESS_AUTOCOMPLETE_FIND}
      variables={variables}
    >
      {children}
    </Query>
  );
};

export const useAddressAutocompleteFind = ({
  query,
  pathFilter
}: {
  query?: string;
  pathFilter?: string;
}) => {
  const { loading, data } = useQuery<
    AddressAutocompleteFindData,
    AddressAutocompleteFindArgs
  >(ADDRESS_AUTOCOMPLETE_FIND, { variables: { input: { query, pathFilter } } });

  return { loading, addressAutoComplete: data?.addressAutocomplete };
};

export default AddressAutocompleteFindQuery;
