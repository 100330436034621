import * as querystring from "query-string";
import * as React from "react";
import { useAuth0 } from "./Auth0Provider";
import { Redirect } from "react-router";
import { useState } from "react";

const Auth0Login = () => {
  const { loading, loginWithRedirect, isAuthenticated } = useAuth0();
  const [redirectPasswordless, setRedirectPasswordless] = useState(false);

  React.useEffect(() => {
    if (!loading && !isAuthenticated && loginWithRedirect) {
      const qs = querystring.parse(window.location.search);
      const returnTo = qs && qs?.returnTo;
      const email = qs?.email;

      if (returnTo && returnTo.toLowerCase().startsWith("/directdeal")) {
        setRedirectPasswordless(true);
      } else {
        loginWithRedirect({ returnTo, email });
      }
    }
  }, [loading, loginWithRedirect, isAuthenticated]);

  if (redirectPasswordless) {
    const qs = querystring.parse(window.location.search);
    const returnTo = qs && qs?.returnTo;

    var data = localStorage.getItem("login_redirect");
    if (data) {
      const redirectInfo = JSON.parse(data);
      redirectInfo.path = returnTo;
      localStorage.setItem("login_redirect", JSON.stringify(redirectInfo));
    }

    return <Redirect to="/directdeal-login" />;
  }

  return null;
};

export default Auth0Login;
