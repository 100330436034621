import { Field, FieldProps } from "formik";
import * as React from "react";
import { InputGroup, InputGroupAddon } from "reactstrap";
import { roundNumber } from "../../utils";
import FormFieldWrapper from "./FormFieldWrapper";

const ReadOnlyNumberField = ({
  name,
  title,
  colSize,
  units,
  unitsPosition
}: {
  name: string;
  title: string;
  colSize: number;
  units?: string;
  unitsPosition?: string;
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps<any>) => {
      const input = (
        <input
          className="form-control"
          name={field.name}
          type="number"
          readOnly={true}
          value={roundNumber(field.value) || ""}
          tabIndex={-1}
        />
      );
      return (
        <FormFieldWrapper
          title={title}
          colSize={colSize}
          field={field}
          form={form}
        >
          {units ? (
            <InputGroup>
              {unitsPosition === "before" ? (
                <InputGroupAddon addonType="prepend">{units}</InputGroupAddon>
              ) : null}
              {input}
              {unitsPosition === "after" ? (
                <InputGroupAddon addonType="append">{units}</InputGroupAddon>
              ) : null}
            </InputGroup>
          ) : (
            input
          )}
        </FormFieldWrapper>
      );
    }}
  </Field>
);

export default ReadOnlyNumberField;
