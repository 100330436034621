import * as React from "react";
import { Button, Container } from "reactstrap";
import { useAuth0 } from "./Auth0Login/Auth0Provider";

const ServerError = () => {
  const { logout, loading } = useAuth0();

  if (loading || !logout) {
    return null;
  }
  return (
    <Container>
      <h1>Server error</h1>
      <p>There has been an error on the Eurodrive server.</p>
      <p>
        Contact your account manager or Eurodrive technical support to log in.
      </p>
      <Button outline={true} onClick={logout}>
        Log out
      </Button>
    </Container>
  );
};

export default ServerError;
