import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DropdownItem } from "reactstrap";

const RouterDropdownItem = withRouter(
  ({
    history,
    location,
    to,
    children,
    disabled
  }: RouteComponentProps & {
    to: string;
    children?: React.ReactNode;
    disabled?: boolean;
  }) => (
    <DropdownItem
      active={!!location.pathname && location.pathname.startsWith(to)}
      disabled={disabled}
      onClick={(e: any) => {
        e.preventDefault();
        history.push(to);
      }}
    >
      {children}
    </DropdownItem>
  )
);

export default RouterDropdownItem;
