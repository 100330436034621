import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import config from "../../config";
import Header_logo from "../../Header_logo.svg";
import { useLoggedInUser } from "../LoggedInUserQuery";
import { PermissionModuleNames } from "../Permissions/types";
import RouterDropdownItem from "../RouterDropdownItem";
import "./index.scss";
import LogoutDropdownItem from "./LogoutDropdownItem";
import { ContextNames } from "../types";

const MenuNavLink = ({
  className,
  activeClassName,
  ...props
}: NavLinkProps) => (
  <NavLink
    {...props}
    className={classnames(className, "nav-link")}
    activeClassName={activeClassName || "active"}
  />
);

const TopNavbar = ({ context }: { context?: ContextNames }) => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const toggle = () => setActive(!active);

  const {
    loggedInUser,
    checkCanRead,
    checkCanCreate,
    isDealer,
    isAccountManager,
    isRegionalSalesManager,
    isSuperUser,
    isAdministrator,
    isDirectCustomer,
  } = useLoggedInUser();

  useEffect(() =>
    history.listen(() => {
      setActive(false);
    })
  );

  return (
    <Navbar color="dark" dark={true} expand="lg" className="top-navbar mb-3">
      <Container>
        {context === ContextNames.DIRECT_DEAL ||
        (loggedInUser && isDirectCustomer) ? (
          <>
            <img alt="Eurodrive" src={Header_logo} width={200} />
            <NavbarToggler onClick={toggle} />
            {loggedInUser && (
              <Collapse isOpen={active} navbar={true}>
                <Nav className="ml-auto">
                  <UncontrolledDropdown
                    nav={true}
                    inNavbar={true}
                    className="user-dropdown mr-4"
                  >
                    <DropdownToggle nav={true} caret={true}>
                      {loggedInUser && loggedInUser.username}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      <LogoutDropdownItem>Log out</LogoutDropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            )}
          </>
        ) : loggedInUser && !isDirectCustomer && !loggedInUser.isSuspended ? (
          <>
            <NavLink className="navbar-brand" to="/">
              <img alt="Eurodrive" src={Header_logo} width={200} />
            </NavLink>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={active} navbar={true}>
              <Nav navbar={true}>
                {checkCanRead(PermissionModuleNames.Quotations) ? (
                  <NavItem>
                    <MenuNavLink to="/quotations">Quotations</MenuNavLink>
                  </NavItem>
                ) : null}
                {checkCanRead(PermissionModuleNames.Proposals) ? (
                  <NavItem>
                    <MenuNavLink to="/proposals">Proposals</MenuNavLink>
                  </NavItem>
                ) : null}
                {checkCanRead(PermissionModuleNames.Dealers) ? (
                  <NavItem>
                    {isDealer ? (
                      <MenuNavLink to="/dealer">Dealer</MenuNavLink>
                    ) : (
                      <MenuNavLink to="/dealers">Dealers</MenuNavLink>
                    )}
                  </NavItem>
                ) : null}
                {checkCanRead(PermissionModuleNames.AccountManagers) &&
                !isDealer ? (
                  <NavItem>
                    {isAccountManager ? (
                      <MenuNavLink to="/accountmanager">
                        <span className="d-none d-xl-inline">
                          Account manager
                        </span>
                        <span className="d-xl-none">AM</span>
                      </MenuNavLink>
                    ) : (
                      <MenuNavLink to="/accountmanagers">
                        <span className="d-none d-xl-inline">
                          Account managers
                        </span>
                        <span className="d-xl-none">AMs</span>
                      </MenuNavLink>
                    )}
                  </NavItem>
                ) : null}
                {checkCanRead(PermissionModuleNames.RegionalSalesManagers) &&
                !isAccountManager ? (
                  <NavItem>
                    {isRegionalSalesManager ? (
                      <MenuNavLink to="/regionalsalesmanager">
                        <span className="d-none d-xl-inline">
                          Regional manager
                        </span>
                        <span className="d-xl-none">RSM</span>
                      </MenuNavLink>
                    ) : (
                      <MenuNavLink to="/regionalsalesmanagers">
                        <span className="d-none d-xl-inline">
                          Regional managers
                        </span>
                        <span className="d-xl-none">RSMs</span>
                      </MenuNavLink>
                    )}
                  </NavItem>
                ) : null}
              </Nav>
              <Nav className="ml-auto" navbar={true}>
                {isSuperUser ? (
                  <UncontrolledDropdown nav={true} inNavbar={true}>
                    <DropdownToggle nav={true} caret={true}>
                      <FontAwesomeIcon icon={faCog} />
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      {checkCanCreate(PermissionModuleNames.Users) ? (
                        <>
                          <RouterDropdownItem to="/settings/users">
                            Users
                          </RouterDropdownItem>
                          <DropdownItem divider={true} />
                        </>
                      ) : null}
                      {checkCanRead(PermissionModuleNames.Announcements) ? (
                        <RouterDropdownItem to="/settings/announcements">
                          Announcements
                        </RouterDropdownItem>
                      ) : null}
                      {checkCanRead(PermissionModuleNames.Alerts) ? (
                        <RouterDropdownItem to="/settings/alertmessages">
                          Alert messages
                        </RouterDropdownItem>
                      ) : null}
                      {checkCanCreate(PermissionModuleNames.Permissions) ? (
                        <>
                          <DropdownItem divider={true} />
                          <RouterDropdownItem to="/settings/permissionroles">
                            Permission roles
                          </RouterDropdownItem>
                          <RouterDropdownItem to="/settings/permissionmodules">
                            Permission modules
                          </RouterDropdownItem>
                        </>
                      ) : null}
                      {isAdministrator ? (
                        <>
                          <DropdownItem divider={true} />
                          <RouterDropdownItem
                            to={`/settings/syncwith${encodeURIComponent(
                              config.AUTOCONVERT_NAME
                            ).toLowerCase()}`}
                          >
                            Sync with {config.AUTOCONVERT_NAME}
                          </RouterDropdownItem>
                        </>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}
                <UncontrolledDropdown
                  nav={true}
                  inNavbar={true}
                  className="user-dropdown mr-4"
                >
                  <DropdownToggle nav={true} caret={true}>
                    <FontAwesomeIcon icon={faUser} className="user-icon mr-2" />
                    {loggedInUser && loggedInUser.username}
                  </DropdownToggle>
                  <DropdownMenu right={true}>
                    <LogoutDropdownItem>Log out</LogoutDropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </>
        ) : null}
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
