import gql from "graphql-tag";
import * as React from "react";
import { QuotationFragment } from "./fragments";
import {
  Mutation,
  MutationFunction,
  MutationResult,
  useMutation,
} from "react-apollo";
import { Quotation, QuotationVariables } from "./types";

export interface CreateFullProposalQuotationData {
  createFullProposalQuotation?: Quotation;
}

export const CREATE_FULL_PROPOSAL_QUOTATION = gql`
  mutation CreateFullProposalQuotation($input: QuotationInput) {
    createFullProposalQuotation(input: $input) {
      ...QuotationFragment
    }
  }
  ${QuotationFragment}
`;

const CreateFullProposalQuotationMutation = ({
  children,
}: {
  children: (
    mutationFunction: MutationFunction<
      CreateFullProposalQuotationData,
      QuotationVariables
    >,
    result: MutationResult<CreateFullProposalQuotationData>
  ) => JSX.Element | null;
}) => <Mutation mutation={CREATE_FULL_PROPOSAL_QUOTATION}>{children}</Mutation>;

export const useCreateFullProposalQuotation = () => {
  const [createFullProposalQuotation] = useMutation<
    CreateFullProposalQuotationData,
    QuotationVariables
  >(CREATE_FULL_PROPOSAL_QUOTATION);

  return createFullProposalQuotation;
};

export default CreateFullProposalQuotationMutation;
