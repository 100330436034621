import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import moment from "moment";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { addHttp } from "../../../utils";

export interface SummaryRowBaseProps {
  title: string;
  highlight?: boolean;
  firstColumnSize?: number;
}

interface SummaryRowProps extends SummaryRowBaseProps {
  value?: string | number | boolean | React.ReactElement | null;
}

const SummaryRow = ({
  title,
  value,
  highlight,
  firstColumnSize = 3
}: SummaryRowProps) => (
  <Row noGutters={true}>
    <Col sm={firstColumnSize}>
      <p className={classnames("mb-2 text-muted")}>{title}</p>
    </Col>
    <Col sm={12 - firstColumnSize}>
      <p
        className={classnames("mb-2", {
          "font-weight-bold": highlight === true
        })}
      >
        {value || value === 0 || value === false ? (
          value
        ) : (
          <span className="text-muted">[not specified]</span>
        )}
      </p>
    </Col>
  </Row>
);

export const ExternalLinkSummaryRow = ({
  href,
  ...rest
}: SummaryRowBaseProps & {
  href?: string;
}) => (
  <SummaryRow
    {...rest}
    value={
      href ? (
        <a href={addHttp(href)} target="_blank" rel="noopener noreferrer">
          {href}{" "}
          <sup>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </sup>
        </a>
      ) : null
    }
  />
);

export const EmailSummaryRow = ({
  to,
  ...rest
}: SummaryRowBaseProps & {
  to?: string;
}) => (
  <SummaryRow {...rest} value={to ? <a href={`mailto:${to}`}>{to}</a> : null} />
);

export const DateSummaryRow = ({
  date,
  ...rest
}: SummaryRowBaseProps & {
  date?: string | Date | null;
}) => (
  <SummaryRow
    {...rest}
    value={date ? moment(date).format("Do MMMM YYYY") : null}
  />
);

export const YesNoSummaryRow = ({
  value,
  trueText = "Yes",
  falseText = "No",
  ...rest
}: SummaryRowBaseProps & {
  value?: boolean | null;
  trueText?: string;
  falseText?: string;
}) => (
  <SummaryRow
    {...rest}
    value={value === true ? trueText : value === false ? falseText : null}
  />
);

const extractUkTelephoneNumber = (telephone: string) => {
  const match = /^0(\d+)$/g.exec(telephone);

  if (match && match.length) {
    return `+44${match[1]}`;
  }

  return telephone;
};

export const TelephoneSummaryRow = ({
  to,
  ...rest
}: SummaryRowBaseProps & {
  to?: string;
}) => (
  <SummaryRow
    {...rest}
    value={to ? <a href={`tel:${extractUkTelephoneNumber(to)}`}>{to}</a> : null}
  />
);

export default SummaryRow;
