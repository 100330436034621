import * as React from "react";
import { useHistory } from "react-router";
import { useAuth0 } from "./Auth0Provider";

const Auth0Callback = () => {
  const { loading, handleRedirectCallback } = useAuth0();
  const history = useHistory();

  React.useEffect(() => {
    if (!loading && handleRedirectCallback) {
      (async () => {
        const { returnTo } = await handleRedirectCallback();

        history.push(returnTo);
      })();
    }
  }, [loading, handleRedirectCallback, history]);

  return null;
};

export default Auth0Callback;
