import config from "../../config";
import { WebAuth } from "auth0-js";
import { getPasswordlessRedirectUri } from "./Auth0Provider";

export const passwordlessConfig = new WebAuth({
  clientID: config.AUTH0_CLIENT_ID,
  domain: config.AUTH0_DOMAIN,
  redirectUri: getPasswordlessRedirectUri(),
  responseType: "token",
});
