import * as React from "react";
import { Button, Container } from "reactstrap";
import { useAuth0 } from "./Auth0Login/Auth0Provider";

const Forbidden = () => {
  const { logout, loading } = useAuth0();

  if (loading || !logout) {
    return null;
  }

  return (
    <Container>
      <h1>User is not authorised</h1>
      <p>
        Login succeeded, but the user is not authorised to access the Eurodrive
        dealer portal.
      </p>
      <p>
        Contact your account manager or Eurodrive technical support to log in.
      </p>
      <Button outline={true} onClick={logout}>
        Log out
      </Button>
    </Container>
  );
};

export default Forbidden;
