import { FieldProps } from "formik";
import * as React from "react";
import CustomSelect from "../../Forms/CustomSelect";
import { QuotationFormValues } from "../types";
import "./index.scss";
import VehicleStylesQuery from "./VehicleStylesQuery";

export const VehicleStyleField = (props: FieldProps<QuotationFormValues>) => {
  const { form, field } = props;
  const make = form.values.vehicle.make;
  const model = form.values.vehicle.model;

  const Placeholder = () => (
    <CustomSelect
      isDisabled={true}
      placeholder="Select style..."
      name={field.name}
    />
  );

  return (
    <>
      {make && model ? (
        <VehicleStylesQuery
          make={make}
          model={model}
          isCommercial={!!form.values.vehicle.isCommercial}
        >
          {({ loading, error, data }) => {
            const options =
              loading || !data
                ? []
                : data.vehicles.vehicleStyles.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }));

            if (loading || !data) {
              return <Placeholder />;
            }

            return (
              <CustomSelect
                placeholder="Select style..."
                isDisabled={loading || !data}
                name={field.name}
                value={
                  options.find((option) => option.value === field.value) || null
                }
                onChange={(option) => {
                  form.setFieldValue(
                    "vehicle.derivative",
                    option ? (option as any).value || null : null
                  );
                  form.setFieldValue("vehicle.cAP", null);
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
                options={options}
              />
            );
          }}
        </VehicleStylesQuery>
      ) : (
        <Placeholder />
      )}
    </>
  );
};

export default VehicleStyleField;
