import * as Yup from "yup";
import { ProposalCustomerQuestions } from "../types";

export const proposalCustomerQuestionsValidationSchema =
  Yup.object<ProposalCustomerQuestions>().shape({
    id: Yup.number().nullable(true).default(undefined),
    privacyAgreement: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("The customer has to be notified of the privacy details")
          .oneOf(
            [true],
            "The customer has to be notified of the privacy details"
          ),
      }),
    customerPresent: Yup.boolean()
      .default(true)
      .nullable(true)
      .required("Customer presence is required"),
    tNC: Yup.boolean()
      .default(false)
      .required("You must accept the terms and conditions")
      .oneOf([true], "You must accept the terms and conditions"),
    customerHasHadFinanceBefore: Yup.boolean()
      .nullable(true)
      .default(undefined),
    customerExpectsAffordabilityProblems: Yup.boolean()
      .default(undefined)
      .nullable(true),
    customerConfidentFinanceIsAffordable: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerUnderstandsAgreement: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerHappyAgreementIsGoodValue: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerAdditionalInfo: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerSaysDealerAnsweredQuestions: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerAnnualMileageRealistic: Yup.boolean()
      .default(undefined)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true, false], "Customer needs to answer this question"),
      }),
    customerSoleSignatoryToBankAccount: Yup.boolean()
      .default(false)
      .nullable(true)
      .when("customerPresent", {
        is: true,
        then: Yup.boolean()
          .required("Customer needs to answer this question")
          .oneOf([true], "Customer needs to answer this question"),
      }),
  });
