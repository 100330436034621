import { FormikErrors, FormikTouched } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import {
  cleanFormData,
  getFullName,
  getSingleLineVehicle,
} from "../../../utils";
import {
  hydrateTouchedData,
  resetFieldsWithErrors,
  serializeTouchedData,
} from "../../Forms/draftFormUtils";
import { ProposalFormInitialValues } from "../ProposalForm";
import {
  DraftProposal,
  Proposal,
  ProposalFormSectionName,
  ProposalType,
} from "../types";
import CreateOrUpdateDraftProposalMutation, {
  CreateOrUpdateDraftProposalDirectDealMutation,
  DraftProposalData,
  DraftProposalVariables,
} from "./CreateOrUpdateDraftProposalMutation";
import DraftProposalQuery, {
  GET_DRAFT_PROPOSAL_DIRECT_DEAL,
} from "./DraftProposalQuery";
import { ContextNames } from "../../types";

interface DraftProposalServiceProps {
  draftProposal?: DraftProposal;
  draftProposalInitialValues?: ProposalFormInitialValues;
  saveDraft: (formData: DraftProposalFormData) => Promise<DraftProposal | void>;
  loading?: boolean;
}

interface DraftProposalServiceOuterProps {
  draftProposalId?: number;
  context?: string;
  children: (props: DraftProposalServiceProps) => JSX.Element | null;
}

export interface DraftProposalFormData {
  values: Proposal;
  initialValues: Proposal;
  errors: FormikErrors<Proposal>;
  touched: FormikTouched<Proposal>;
  currentSection?: string;
}

/** Gets and saves draft proposals */
class DraftProposalService extends React.Component<DraftProposalServiceOuterProps> {
  public constructor(props: DraftProposalServiceOuterProps) {
    super(props);
    this.saveDraft = this.saveDraft.bind(this);
  }

  public render() {
    const { draftProposalId, children, context } = this.props;

    return (
      <DraftProposalQuery
        draftProposalId={draftProposalId}
        query={
          context === ContextNames.DIRECT_DEAL
            ? GET_DRAFT_PROPOSAL_DIRECT_DEAL
            : null
        }
      >
        {({ draftProposal, loading }) => {
          let draftProposalInitialValues: ProposalFormInitialValues;

          if (draftProposal) {
            const proposal: Proposal = cleanFormData(draftProposal.proposal, {
              removeIdFields: true,
            });

            // Indicate that a quotation is required to submit the proposal
            proposal.FORMSTATE_requiresQuotation =
              !!draftProposal.requiresQuotation;

            draftProposalInitialValues = {
              initialTouched: hydrateTouchedData(draftProposal.touchedFields),
              initialSection:
                draftProposal.currentSection as ProposalFormSectionName,
              initialValues: proposal,
              proposalType: draftProposal.proposalType,
            };

            if (draftProposal.dealer) {
              draftProposalInitialValues.initialValues.dealer =
                draftProposal.dealer;
            }
          }

          return context !== ContextNames.DIRECT_DEAL ? (
            <CreateOrUpdateDraftProposalMutation>
              {(mutateFn) =>
                children({
                  draftProposal,
                  draftProposalInitialValues,
                  saveDraft: (formData) =>
                    this.saveDraft(mutateFn, formData, draftProposal),
                  loading,
                })
              }
            </CreateOrUpdateDraftProposalMutation>
          ) : (
            <CreateOrUpdateDraftProposalDirectDealMutation>
              {(mutateFn) =>
                children({
                  draftProposal,
                  draftProposalInitialValues,
                  saveDraft: (formData) =>
                    this.saveDraft(mutateFn, formData, draftProposal),
                  loading,
                })
              }
            </CreateOrUpdateDraftProposalDirectDealMutation>
          );
        }}
      </DraftProposalQuery>
    );
  }

  /** Saves the state of the proposal form to the server */
  private saveDraft(
    mutate: MutationFunction<DraftProposalData, DraftProposalVariables>,
    formData: DraftProposalFormData,
    existing?: DraftProposal
  ) {
    const proposal = this.cleanDraftProposal(formData);
    const touched = cloneDeep(formData.touched);
    // delete touched.proposalCustomerQuestions.tNC;

    const currentSection = formData.currentSection;

    const customerDescription = proposal.individualCustomer
      ? getFullName(proposal.individualCustomer)
      : proposal.business.name;

    const vehicleDescription = getSingleLineVehicle(proposal.vehicle);

    const draftProposal: DraftProposal = {
      id: existing && existing.id,
      dealerId: proposal.dealerId || 0,
      proposal,
      touchedFields: serializeTouchedData(touched),
      currentSection,
      customerDescription,
      vehicleDescription,
      proposalType: proposal.individualCustomer
        ? ProposalType.INDIVIDUAL
        : ProposalType.BUSINESS,
      quotationId: proposal.quotationId,
      requiresQuotation: existing
        ? !!proposal.quotationId || existing.requiresQuotation
        : true,
    };

    return mutate({ variables: { input: draftProposal } }).then((result) => {
      const draft =
        result && result.data && result.data.createOrUpdateDraftProposal;

      if (!draft) {
        return;
      }

      return draft;
    });
  }

  /** Removes client only fields from the draft proposal object before submitting it to the server */
  private cleanDraftProposal(formData: DraftProposalFormData): Proposal {
    const { values, initialValues, errors } = formData;
    const isMannIslandDealer = values.dealer?.isMannIslandDealer;
    const proposal: Proposal = cleanFormData(values, { removeIdFields: true });

    // Clean up properties which are not used on the server
    // delete proposal.proposalCustomerQuestions.tNC;
    delete proposal.accountManagerId;
    delete proposal.externalSource;
    delete proposal.cancelType;
    delete proposal.directDealId;

    delete proposal.vehicle.skipVehicle;
    delete proposal.vehicle.isRegUnknown;
    delete proposal.vehicle.regNoNotFound;
    delete proposal.vehicle.LCV;

    delete proposal.finance.interestCharges;
    delete proposal.finance.totalCharges;
    delete proposal.finance.balancePayable;
    delete proposal.finance.totalAmountPayable;
    delete proposal.finance.lessRentalDeposit;
    delete proposal.finance.commissionCode;
    delete proposal.finance.totalFinance;
    delete proposal.finance.paidOutDate;
    delete proposal.finance.dealerCommission;
    delete proposal.quotationListResult;

    if (isMannIslandDealer) {
      proposal.finance.rate = 0.1;
    }
    if (proposal.isMannIslandDealer != null) {
      delete proposal.isMannIslandDealer;
    }

    if (proposal.individualCustomer) {
      delete proposal.individualCustomer.countryOfBirth;
      delete proposal.individualCustomer.nationality;
      delete proposal.individualCustomer.countryOfResidence;
      delete proposal.individualCustomer.countryOfActivity;
      delete proposal.individualCustomer.occupationType;
    }

    if (proposal.business) {
      proposal.business.directors.forEach((director) => {
        delete director.countryOfBirth;
        delete director.nationality;
        delete director.countryOfResidence;
      });
    }

    delete proposal.dealer;

    // Remove properties which have validation errors, and reset them
    // to default values
    resetFieldsWithErrors(proposal, errors, initialValues);

    return proposal;
  }
}

export default DraftProposalService;
