import * as React from "react";
import Helmet from "react-helmet";
import config from "../config";
import { LoggedInUserProps, withLoggedInUser } from "./LoggedInUserQuery";
import { User } from "./types";

const getFullName = ({ forename, surname }: User) =>
  [forename, surname].filter(x => !!x).join(" ") || undefined;

class TawkToLoader extends React.Component<LoggedInUserProps, {}> {
  public constructor(props: LoggedInUserProps) {
    super(props);
    this.setChatAttributes = this.setChatAttributes.bind(this);
    this.getApi = this.getApi.bind(this);
  }

  public componentDidMount() {
    this.setChatAttributes();
  }

  public componentDidUpdate() {
    this.setChatAttributes();
  }

  public render() {
    const { loggedInUser } = this.props;

    if (!loggedInUser?.tawkTo?.enabled) {
      return null;
    }

    if (!config.TAWK_TO_SITE_ID) {
      // tslint:disable-next-line:no-console
      console.warn("No tawk.to site id specified");
      return null;
    }

    return (
      <Helmet
        script={[
          {
            async: true,
            type: "text/javascript",
            src: `https://embed.tawk.to/${config.TAWK_TO_SITE_ID}/default`,
            crossorigin: "*",
            id: "tawk-to-script"
          }
        ]}
      />
    );
  }

  private setChatAttributes() {
    const { loggedInUser } = this.props;

    // tslint:disable-next-line:no-console
    const logErrorToConsole = (error: any) => error && console.error(error);

    if (loggedInUser?.tawkTo?.enabled) {
      this.getApi()
        .then(api => {
          api.setAttributes(
            {
              name: getFullName(loggedInUser),
              email: loggedInUser.username,
              hash: loggedInUser?.tawkTo?.hash
            },
            logErrorToConsole
          );

          if (loggedInUser.dealer) {
            api.addTags([`Dealer: ${loggedInUser.dealer.name}`]);
          }
        })
        .catch(logErrorToConsole);
    }
  }

  private getApi() {
    let tries = 0;
    const wait = 400;

    return new Promise<any>((resolve, reject) => {
      const checkForApi = () => {
        tries += 1;

        if ((window as any).Tawk_API) {
          resolve((window as any).Tawk_API);
        } else {
          if (tries > 10) {
            reject(new Error("tawk.to api not found"));
          }

          setTimeout(checkForApi, wait * tries);
        }
      };

      try {
        checkForApi();
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default withLoggedInUser(TawkToLoader);
