import gql from "graphql-tag";
import React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";
import { User } from "../types";
import { ShallowAnnouncementWebUserFragment } from "./fragments";
import { Announcement, AnnouncementWebUser } from "./types";

interface LatestAnnouncementWebUserData {
  loggedInUser: User & { latestAnnouncementWebUser?: AnnouncementWebUser };
}

export interface AnnouncementProps {
  loggedInUser: User;
  announcement: Announcement;
}

export const GET_LATEST_ANNOUNCEMENT = gql`
  query LatestAnnouncementQuery {
    loggedInUser {
      id
      applicationUserId
      username
      roles
      latestAnnouncementWebUser {
        ...ShallowAnnouncementWebUserFragment
      }
    }
  }
  ${ShallowAnnouncementWebUserFragment}
`;

const AnnouncementQuery = ({
  pollInterval,
  children
}: {
  pollInterval?: number;
  children: (
    result: QueryResult<LatestAnnouncementWebUserData> & {
      latestAnnouncementWebUser?: AnnouncementWebUser;
    }
  ) => JSX.Element | null;
}) => (
  <Query<LatestAnnouncementWebUserData>
    query={GET_LATEST_ANNOUNCEMENT}
    pollInterval={pollInterval}
  >
    {result =>
      children({
        ...result,
        latestAnnouncementWebUser:
          result.data &&
          result.data.loggedInUser &&
          result.data.loggedInUser.latestAnnouncementWebUser
      })
    }
  </Query>
);

export const useLatestAnnouncement = () => {
  const { loading, data } = useQuery<LatestAnnouncementWebUserData>(
    GET_LATEST_ANNOUNCEMENT
  );

  return {
    loading,
    latestAnnouncement: data?.loggedInUser?.latestAnnouncementWebUser
  };
};

export default AnnouncementQuery;
