import { FieldProps } from "formik";
import * as React from "react";
import { InputGroup, InputGroupAddon } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import { QuotationFormValues, QuotationTargetBy } from "../types";
import TargetByDropdown from "./TargetByDropdown";

const TargetByField = ({
  onBlur,
  ...props
}: FieldProps<QuotationFormValues> & {
  onBlur?: (e: React.FocusEvent<any>) => void;
} & { disabled?: boolean }) => {
  const targetBy: QuotationTargetBy =
    (props.form.values.targetBy as QuotationTargetBy) ||
    QuotationTargetBy.APR_RATE;
  let customOnBlur: (e: React.FocusEvent<any>) => void;

  if (onBlur) {
    customOnBlur = (e) => {
      props.field.onBlur(e);
      onBlur(e);
    };
  } else {
    customOnBlur = props.field.onBlur;
  }

  return (
    <FormGroupWrapper {...props} title="Your sell out APR">
      <InputGroup>
        <TargetByDropdown
          targetBy={targetBy as QuotationTargetBy}
          updateValue={(x: QuotationTargetBy) => {
            props.form.setFieldValue("targetBy", x);
            props.form.setFieldValue("targetByValue", "");
            props.form.setFieldTouched("targetByValue", true);
            props.form.setFieldTouched("targetBy", true);
          }}
          disabled={!!props.disabled}
        />

        {targetBy === QuotationTargetBy.COMMISSION_VALUE ||
        targetBy === QuotationTargetBy.MONTHLY_PAYMENT ? (
          <InputGroupAddon addonType="prepend">£</InputGroupAddon>
        ) : null}
        <input
          className="form-control"
          type="number"
          min={0}
          {...props.field}
          value={
            props.field.value || props.field.value === 0
              ? props.field.value
              : ""
          }
          onBlur={customOnBlur}
          disabled={!!props.disabled}
        />
        {targetBy === QuotationTargetBy.APR_RATE ||
        targetBy === QuotationTargetBy.FLAT_RATE ? (
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        ) : null}
      </InputGroup>
    </FormGroupWrapper>
  );
};

export default TargetByField;
