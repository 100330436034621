import gql from "graphql-tag";

export const AccountManagerFragment = gql`
  fragment AccountManagerFragment on AccountManager {
    id
    name
    email
    mobile
    suspended
    regionalSalesManagerId
    regionalSalesManager {
      id
      name
    }
    user {
      id
      username
    }
    autoConvertReference
    houseDealer {
      id
    }
  }
`;

export const AccountManagerShallowFragment = gql`
  fragment AccountManagerShallowFragment on AccountManager {
    id
    name
    email
  }
`;

export const AccountManagerTargetsFragmentWithoutNetProfit = gql`
  fragment AccountManagerTargetsFragment on AccountManagerTargets {
    id
    proposalsCreated
    proposalsAccepted
    proposalsPaidout
    dealerCreated
    dealerPaidout
    turnover
    dealerSetups
    startDate
    endDate
    accountManager {
      id
      name
    }
  }
`;

export const AccountManagerActualsFragmentWithoutNetProfit = gql`
  fragment AccountManagerActualsFragment on AccountManagerActuals {
    id
    totalCreatedCount
    totalPaidOutCount
    totalAcceptedCount
    totalCancelledCount
    totalFinance
    averagePaidOutFinance
    averageApr
    averagePeriod
    totalDebitBackAmount
    totalDealerCommission
    averageAcceptedFinance
    acceptance
    dealerCreated
    dealerPaidOut
    dealerSetup
  }
`;
export const AccountManagerTargetsFragment = gql`
  fragment AccountManagerTargetsFragment on AccountManagerTargets {
    id
    proposalsCreated
    proposalsAccepted
    proposalsPaidout
    dealerCreated
    dealerPaidout
    netProfit
    turnover
    dealerSetups
    startDate
    endDate
    accountManager {
      id
      name
    }
  }
`;

export const AccountManagerActualsFragment = gql`
  fragment AccountManagerActualsFragment on AccountManagerActuals {
    id
    netProfit
    netProfit100
    totalCreatedCount
    totalPaidOutCount
    totalAcceptedCount
    totalCancelledCount
    totalFinance
    averagePaidOutFinance
    averageApr
    averagePeriod
    totalDebitBackAmount
    totalDealerCommission
    averageAcceptedFinance
    acceptance
    dealerCreated
    dealerPaidOut
    dealerSetup
    holdbackInternal
  }
`;
