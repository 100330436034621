import { Field, FieldProps, Formik } from "formik";
import * as React from "react";
import { Button, Col, Container, Form, Row } from "reactstrap";
import FormGroupWrapper from "../../Forms/FormGroupWrapper";
import { DirectDeal } from "../types";
import { useCreateOrUpdateDirectDeal } from "./CreateOrUpdateDirectDealMutation";
import { GET_DIRECT_DEAL } from "./DirectDealQuery";
import FormInputField from "../../Forms/FormInputField";
import IDDValidationSchema from "./IDDValidationSchema";
import { cleanFormData } from "../../../utils";
import { defaults } from "lodash";
import "./index.scss";

const IDDForm = ({ directDeal }: { directDeal: DirectDeal }) => {
  const mutation = useCreateOrUpdateDirectDeal();

  return (
    <Container className="idd-form">
      <Row>
        <Col>
          <h3 className="idd-info">Important: Please Read</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="idd-header">Initial Disclosure Document</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          This ‘Initial Disclosure Document’ is provided to help you decide
          whether our services are suitable for you, to let you know more about
          how we make our money, what to do if you have any concerns or
          complaints and how your information is used.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">Who Are We?</div>
          Eurodrive Motor Finance is a trading style of European Vehicle
          Contracts Ltd, with a company registration number of 06532275. Our
          registered address is European House, 9 Apex Business Village,
          Newcastle upon Tyne, NE23 7BF. We are authorised and regulated by the
          Financial Conduct Authority for credit brokerage. Our FCA number is
          649225. We act as a broker, not a lender.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">
            What can we do to help finance your purchase?
          </div>
          We work with a number of carefully selected credit providers who may
          be able to offer you finance for your purchase. Please note we and
          these providers will perform checks with Credit Reference and Fraud
          Prevention agencies. We are only able to offer finance products from
          these providers who may offer us an commission to do so.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          This commission is fixed and will never impact the rate you receive.
          We will not charge you any fee for our services. All Finance is
          subject to terms and circumstance.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">
            Can we give you independent financial advice?
          </div>
          No, we are not independent financial advisers and are unable to give
          you independent financial advice. We will provide you with all the
          information in a clear, transparent manner to allow you to make an
          informed decision.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">
            What can you do if you wish to complain about our services?
          </div>
          Eurodrive Motor Finance takes all complaints seriously. If you are
          unhappy with the service you have received, please contact us by
          telephone and we will endeavor to resolve the matter straight away.
          Alternatively, if you want to write to us, please send your complaint
          to: Complaints Department, Eurodrive Motor Finance, European House 9
          Apex Business Village, Annitsford. Newcastle upon Tyne NE23 7BF
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          You have the right to refer any unresolved complaint to the Financial
          Ombudsman Service, Exchange Tower, Harbour Exchange Square, Isle of
          Dogs, London E14 9SR
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            Web:{" "}
            <a
              href="https://www.financial-ombudsman.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.financial-ombudsman.org.uk
            </a>
          </div>
          <div>
            Email:{" "}
            <a href="mailto:complaint.info@financial-ombudsman.org.uk">
              complaint.info@financial-ombudsman.org.uk
            </a>
          </div>
          <div>Telephone: 02079 641 000</div>
          <div>Fax: 020 7964 1001</div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">Your Affordability</div>
          It is extremely important that you look into finance options that are
          suitable for your current (and potential future) financial situation.
          Our team will go over all your details to ensure we have the correct
          information to pass onto our lenders, who will then try to verify the
          information. However, please note that evidence of your income may be
          requested. If you are aware or suspect that your financial position
          will change in the future, you must inform us. Your credit rating
          could be adversely affected if you do not make payments when due.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="idd-subheader">How do we use your information?</div>
          In order to process your application, you will need to provide us with
          some of your personal information. We pride ourselves on handling your
          information in the most secure and professional way we can. Your data
          will not be processed without your explicit consent for us to do so.
          Therefore, if you do not wish for your application to be processed, we
          kindly ask that this is not submitted. As mentioned above, your
          information will be passed over to our finance providers in order to
          try to obtain you a finance acceptance. Whilst we have assessed the
          providers we work with; we have included their information within our
          privacy policy so you can fully research how they will use your
          information.
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          For further detail on how your information is used by us and who it
          will be shared with, please view our privacy policy{" "}
          <a
            href="https://eurodrivefinance.co.uk/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Col>
      </Row>
      <br />
      <Row>
        <Col>Our ICO number is Z2537167.</Col>
      </Row>
      <br />
      <Formik
        initialValues={
          defaults({}, directDeal, IDDValidationSchema.default()) as any
        }
        onSubmit={(values) => {
          const rawValues = cleanFormData(values);
          const { id } = rawValues;
          return mutation({
            variables: { input: rawValues },
            refetchQueries: [
              {
                query: GET_DIRECT_DEAL,
                variables: { directDealId: id },
              },
            ],
          });
        }}
        validateOnChange={false}
        validationSchema={IDDValidationSchema}
      >
        {(formikProps) => (
          <Form
            onSubmit={(e) => {
              formikProps.handleSubmit(e);
            }}
          >
            <>
              <Field name="iddSigned">
                {(fieldProps: FieldProps<any>) => (
                  <FormGroupWrapper className="mb-0" {...fieldProps}>
                    <div className="custom-control custom-checkbox font-weight-bold">
                      <input
                        id={fieldProps.field.name}
                        type="checkbox"
                        className="custom-control-input"
                        checked={!!fieldProps.field.value}
                        name={fieldProps.field.name}
                        onChange={() => {
                          fieldProps.form.setFieldValue(
                            fieldProps.field.name,
                            !fieldProps.field.value,
                            true
                          );
                          fieldProps.form.setFieldTouched(
                            fieldProps.field.name,
                            true,
                            true
                          );
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={fieldProps.field.name}
                      >
                        I have read the above document and agree to proceed
                      </label>
                    </div>
                  </FormGroupWrapper>
                )}
              </Field>
              <Row className="pt-3">
                <Field
                  colSize={4}
                  name="printName"
                  type="text"
                  placeholder="Print name"
                  title="Print name"
                  component={FormInputField}
                />
              </Row>
              <Button type="submit" color="primary">
                Confirm and proceed
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default IDDForm;
