import { FormikErrors, FormikTouched } from "formik";
import { isNumeric } from "../../utils";

/**
 * Replaces all fields which have validation errors with provided default values
 * @param data Form data to be mutated
 * @param errors Formik errors object
 * @param defaults Default values to replace values with validation errors
 */
export const resetFieldsWithErrors = (
  data: any,
  errors: FormikErrors<any>,
  defaults: any
) => {
  Object.keys(data)
    .filter(k => errors.hasOwnProperty(k))
    .forEach(k => {
      const defaultValue =
        defaults && Array.isArray(defaults) && defaults.length
          ? defaults[0]
          : defaults && defaults[k];
      if (typeof errors[k] === "object") {
        resetFieldsWithErrors(
          data[k],
          errors[k] as FormikErrors<any>,
          defaultValue
        );
      } else {
        data[k] = defaultValue;
      }
    });
};

/** Converts the touched fields object into an array of path strings, to be saved to the server */
export const serializeTouchedData = (
  obj: FormikTouched<any>,
  parentPath?: string
): string[] => {
  return Object.keys(obj).reduce(
    (accumulator, prop) => {
      const path = parentPath ? `${parentPath}.${prop}` : prop;
      const value = obj[prop];

      if (typeof value === "object") {
        return [
          ...accumulator,
          ...serializeTouchedData(value as FormikTouched<any>, path)
        ];
      }
      return [...accumulator, path];
    },
    [] as string[]
  );
};

/** Converts an array of field paths from the server to a formik touched object */
export const hydrateTouchedData = (paths: string[]): FormikTouched<any> => {
  const touched: FormikTouched<any> = {};
  paths.forEach(path => {
    const pathArray = path.split(".");

    pathArray.reduce((obj, val, i) => {
      if (i === pathArray.length - 1) {
        obj[val] = true;
        return obj;
      }
      if (!obj[val]) {
        const valueIsArray =
          i < pathArray.length - 1 && isNumeric(pathArray[i + 1]);
        obj[val] = valueIsArray ? ([] as object) : {};
      }
      return obj[val] as FormikTouched<any>;
    }, touched);
  });
  return touched;
};
