/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faInfoCircle,
  faSave,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Field, FieldProps, FormikProps } from "formik";
import * as React from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import { cleanFormData, formatCurrency, roundNumber } from "../../../utils";
import FormInputField from "../../Forms/FormInputField";
import { FormSectionProps, WatchFields } from "../../Forms/MultiSectionForm";
import RadioField from "../../Forms/RadioField";
import ReadOnlyNumberField from "../../Forms/ReadOnlyNumberField";
import TargetByField from "../../Quotations/QuotationForm/TargetByField";
import {
  Quotation,
  QuotationRequest,
  QuotationResults,
  QuotationTargetBy,
  QuotationVariables,
} from "../../Quotations/types";
import {
  Proposal,
  ProposalFinance,
  ProposalFormSectionName,
  Vehicle,
} from "../types";
import { ContextNames, ProductTypeEnum } from "../../types";
import ProposalFormClearQuotation from "./ProposalFormClearQuotation";
import ProposalFormCreateQuotation from "./ProposalFormCreateQuotation";
import ProposalQuestion from "./ProposalQuestion";
import { termOptions } from "../../Quotations/QuotationForm/FinanceFormSection";
import SelectField from "../../Forms/SelectField";
import { getErrorPaths } from "../../Quotations/QuotationForm";
import CalculateQuotationService from "../../Quotations/CalculateQuotationService";
import ProposalMultiQuoteResult from "./ProposalMultiQuoteResult";
import ProposalMultiQuoteResultModal from "./ProposalMultiQuoteResultModal";
import quotationValidationSchema from "../../Quotations/QuotationForm/quotationValidationSchema";
import CreateFullProposalQuotationMutation, {
  CreateFullProposalQuotationData,
} from "../../Quotations/CreateFullProposalQuotationMutation";
import { MutationFunction } from "react-apollo";

const productTypeOptions = Object.keys(ProductTypeEnum).map((x) => ({
  label: x,
  value: x,
}));

const financeFields: (keyof ProposalFinance)[] = [
  "monthlyPayment",
  "rate",
  "aprRate",
  "balloonPayment",
];

export const financeSectionWatchFields: WatchFields<Proposal> = {
  proposalCustomerQuestions: {
    customerConfidentFinanceIsAffordable: true,
  },
  quotationId: true,
  finance: {
    rate: true,
    aprRate: true,
    period: true,
    monthlyPayment: true,
    balloonPayment: true,
    partExchange: true,
    partExchangeSettlement: true,
    cashPrice: true,
    cashDeposit: true,
    rFL: true,
    extras: true,
    productType: true,
  },
  targetBy: true,
  targetByValue: true,
  FORMSTATE_noQuotationResults: true,
  FORMSTATE_noQuotationResultsReasons: true,
};

const FinanceSectionMessages = ({
  isSectionTouched,
  navigateToSection,
  ...formikProps
}: FormSectionProps<Proposal> & { context?: ContextNames }) => {
  const { values, errors, context } = formikProps;
  const hasVehicleErrors =
    values.finance.productType !== ProductTypeEnum.HP &&
    (!values.vehicle.cAP ||
      (!!errors && !!errors.vehicle && !!Object.keys(errors.vehicle).length));

  return (
    <>
      {!values.dealerId && !values.dealer ? (
        <Alert key="dealer-required" fade={false} color="warning">
          <b>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigateToSection(ProposalFormSectionName.DEALER);
              }}
              style={{ color: "inherit" }}
              className="strong"
            >
              Dealer
            </a>
          </b>{" "}
          is required before loan details can be calculated
        </Alert>
      ) : null}
      {hasVehicleErrors ? (
        <Alert key="vehicle-required" fade={false} color="warning">
          <b>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigateToSection(ProposalFormSectionName.VEHICLE);
              }}
              style={{ color: "inherit" }}
              className="strong"
            >
              Vehicle details
            </a>
          </b>{" "}
          are required before non-HP loan details can be calculated
        </Alert>
      ) : null}
      {!values.dealer?.isMultiQuote && values.FORMSTATE_noQuotationResults && (
        <Alert key="unable-to-quote" fade={true} color="warning">
          <p className="mb-1">
            Unable to quote for the selected vehicle and finance
          </p>
          {values.FORMSTATE_noQuotationResultsReasons
            ? values.FORMSTATE_noQuotationResultsReasons.map((x) => (
                <p key={x} className="mb-0 small">
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  {x}
                </p>
              ))
            : null}
          <p className="mb-1">
            You can submit an application without a quote and we'll fix it for
            you
          </p>
          <ProposalFormCreateQuotation {...formikProps}>
            {({ createIncompleteQuotation, loading }) => (
              <>
                <>
                  <Button
                    className="w-100 mb-3"
                    onClick={createIncompleteQuotation}
                    color="secondary"
                    outline={true}
                  >
                    <FontAwesomeIcon
                      fixedWidth={true}
                      icon={loading ? faSpinner : faSave}
                      className="mr-2"
                      spin={loading}
                    />
                    Continue without quote
                  </Button>
                </>
              </>
            )}
          </ProposalFormCreateQuotation>
        </Alert>
      )}
      {context !== ContextNames.DIRECT_DEAL && values.quotationId ? (
        <Alert key="quotation-info" fade={false} color="info">
          Finance details are fixed for this quotation{" "}
          <ProposalFormClearQuotation {...formikProps}>
            {({ clearQuotation }) => (
              <Button
                className="ml-3"
                type="button"
                size="sm"
                outline={true}
                color="info"
                onClick={clearQuotation}
              >
                Clear quotation and edit finance
              </Button>
            )}
          </ProposalFormClearQuotation>
        </Alert>
      ) : null}
    </>
  );
};

const getMultiQuoteRequest = (
  proposalValues: Proposal,
  isMulti: boolean = false,
  isMannIslandDealer: boolean | undefined = undefined,
  isMannIslandZList: boolean | undefined = undefined
): QuotationRequest => {
  const {
    dealerId,
    finance: {
      productType,
      cashPrice,
      cashDeposit,
      partExchange,
      partExchangeSettlement,
    },
    vehicle: {
      cAP,
      capId,
      mileage,
      maxAnnualMileage,
      isNew,
      dateOfRegistration,
      imported,
      regNo,
      vehicleType,
      isCommercial,
      vatQualifying,
    },
    targetBy,
    targetByValue,
  } = proposalValues;

  let returnValues = {
    minTerm: 12,
    maxTerm: 60,
    dealerId: dealerId ? dealerId : proposalValues.dealer.id,
    productTypes: [ProductTypeEnum[productType || "HP"]],
    cAP,
    mileage,
    maxAnnualMileage,
    regNo,
    targetBy,
    targetByValue,
    isNew,
    dateOfRegistration,
    cashPrice,
    cashDeposit,
    partExchange,
    partExchangeSettlement,
    extras: 0,
    rFL: 0,
  };

  if (!isMulti && isMannIslandDealer && !isMannIslandZList) {
    return {
      ...returnValues,
      capId: capId ? +capId : undefined,
      import: imported,
      regNo,
      vehicleType,
      usage: isCommercial ? "commercial" : "Social",
      vatQualify: vatQualifying,
      minTerm: 36,
    };
  }
  return returnValues;
};

class FinanceSection extends React.Component<
  FormSectionProps<Proposal>,
  {
    modalResult: QuotationResults | null;
    aprDisabled: boolean;
    showResults: boolean;
    multiQuoteLoading: boolean;
  }
> {
  public constructor(props: FormSectionProps<Proposal>) {
    super(props);
    this.state = {
      modalResult: null,
      aprDisabled: false,
      showResults: false,
      multiQuoteLoading: false,
    };

    this.getFinanceValue = this.getFinanceValue.bind(this);
    this.handleCalculateMultiQuote = this.handleCalculateMultiQuote.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.selectResult = this.selectResult.bind(this);
    this.getProposalQuotation = this.getProposalQuotation.bind(this);
    this.cleanProposalQuotation = this.cleanProposalQuotation.bind(this);
    if (props.values.dealer && props.values.dealer.agreedApr) {
      this.props.values.targetByValue = this.props.values.dealer.agreedApr; // need to round the apr rate to display correctly
      this.props.setFieldValue(
        "targetByValue",
        roundNumber(props.values.dealer.agreedApr)
      );
      this.props.values.targetBy = QuotationTargetBy.APR_RATE;
      this.props.setFieldValue("targetBy", QuotationTargetBy.APR_RATE);
    }

    if (!!props.values.vehicle.isRegUnknown) {
      this.props.setFieldValue("finance.productType", ProductTypeEnum.HP);
    }
  }

  public render() {
    const { isSectionTouched, navigateToSection, ...formikProps } = this.props;
    const { values } = formikProps;

    const isMulti = values.dealer && values.dealer.isMultiQuote;

    const dealer = this.props.values.dealer;

    const hasReg = !values.vehicle.isRegUnknown;
    const readOnly = !!values.quotationId;

    const multiQuoteRequest = this.state.showResults
      ? getMultiQuoteRequest(values, isMulti)
      : undefined;

    return (
      <>
        <FinanceSectionMessages {...this.props} />
        {isMulti ? (
          <>
            <CalculateQuotationService input={multiQuoteRequest} isMulti={true}>
              {({ calculateQuotationList, loading }) => {
                if (
                  !!multiQuoteRequest &&
                  !values.quotationListResult &&
                  !loading
                ) {
                  this.props.setFieldValue(
                    "quotationListResult",
                    calculateQuotationList,
                    false
                  );
                } else if (!!values.quotationListResult && loading) {
                  this.props.setFieldValue(
                    "quotationListResult",
                    undefined,
                    false
                  );
                }

                return (
                  <>
                    <Row>
                      <Field
                        colSize={4}
                        name="finance.cashPrice"
                        type="number"
                        title="Price of vehicle"
                        component={FormInputField}
                        units="£"
                        min={0}
                        autoFocus={true}
                        readOnly={readOnly}
                      />

                      <Field
                        colSize={4}
                        name="finance.cashDeposit"
                        type="number"
                        title="Customer deposit"
                        component={FormInputField}
                        units="£"
                        min={0}
                        readOnly={readOnly}
                      />

                      <Field
                        colSize={4}
                        name="finance.partExchange"
                        type="number"
                        title="Part exchange value"
                        component={FormInputField}
                        units="£"
                        step={10}
                        min={0}
                        readOnly={readOnly}
                      />

                      <Field
                        colSize={4}
                        name="finance.partExchangeSettlement"
                        type="number"
                        title="Part ex. settlement"
                        component={FormInputField}
                        units="£"
                        min={0}
                        readOnly={readOnly}
                      />

                      <Col sm={6} lg={4}>
                        <FormGroup>
                          <Label>Total finance value</Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              £
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              type="number"
                              value={
                                this.getFinanceValue(values).toFixed(2) || ""
                              }
                              readOnly={true}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm={6} lg={4}>
                        <Field
                          name="targetByValue"
                          type="number"
                          min={0}
                          disabled={!!values.dealer?.agreedApr}
                          component={TargetByField}
                        />
                      </Col>

                      <SelectField
                        colSize={4}
                        name="finance.period"
                        title="Period"
                        options={termOptions}
                        disabled={readOnly}
                      />

                      <Field
                        colSize={4}
                        name="vehicle.maxAnnualMileage"
                        type="number"
                        placeholder="Max mileage p/a"
                        min={0}
                        title="Max annual mileage"
                        component={FormInputField}
                        units="Miles"
                        unitsPosition="after"
                        readOnly={readOnly}
                      />

                      <RadioField
                        colSize={4}
                        name="finance.productType"
                        title="Loan type"
                        readOnly={readOnly || !hasReg}
                        options={productTypeOptions}
                      />
                      <Col lg={12}>
                        <button
                          key="show-loans"
                          onClick={() => {
                            this.handleCalculateMultiQuote(formikProps);
                          }}
                          className={classnames("btn wide-button btn-lg mb-2", {
                            "btn-primary": !this.state.showResults,
                            "btn-outline-secondary": this.state.showResults,
                          })}
                          type="button"
                          disabled={this.state.showResults || readOnly}
                        >
                          {loading && (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              className="mr-2"
                            />
                          )}{" "}
                          Show loans
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      {!readOnly ? (
                        <ProposalMultiQuoteResult
                          loading={this.state.multiQuoteLoading}
                          multiQuoteResult={values.quotationListResult}
                          targetBy={formikProps.values.targetBy}
                          props={formikProps}
                          onSelectResult={(x) => {
                            this.showModal(x);
                          }}
                        />
                      ) : (
                        <>
                          <Col className="custom-header mt-3 mb-3" lg={12}>
                            <h3>Saved Quotation:</h3>
                          </Col>
                          <Col>
                            <div className="custom-row selected d-flex">
                              <Col
                                className="d-flex align-items-center product-lender"
                                lg={7}
                              >
                                <div className="custom-header">{`${values.finance.lenderName}`}</div>
                              </Col>
                              <Col className="text-left py-3" lg={5}>
                                <div className="font-weight-normal">
                                  {`Monthly payment: ${formatCurrency(
                                    values.finance.monthlyPayment
                                  )}`}
                                </div>
                                <p>
                                  {values.finance.productType === "PCP"
                                    ? `Balloon payment: ${formatCurrency(
                                        values.finance.balloonPayment
                                      )}`
                                    : null}
                                </p>
                                <p>{`Period: ${values.finance.period} months`}</p>
                                <p>{`Product type: ${values.finance.productType}`}</p>
                                {values.finance.aprRate && (
                                  <p className="d-inline">
                                    {`APR rate: ${(
                                      Math.round(values.finance.aprRate * 100) /
                                      100
                                    ).toFixed(2)}%`}
                                  </p>
                                )}
                              </Col>
                            </div>
                          </Col>
                        </>
                      )}
                      <CreateFullProposalQuotationMutation {...formikProps}>
                        {(mutationFunction) => (
                          <ProposalMultiQuoteResultModal
                            result={this.state.modalResult}
                            toggle={this.hideModal}
                            selectResult={(result) => {
                              this.selectResult(
                                mutationFunction,
                                result,
                                formikProps
                              );
                            }}
                          />
                        )}
                      </CreateFullProposalQuotationMutation>
                    </Row>
                  </>
                );
              }}
            </CalculateQuotationService>
          </>
        ) : (
          <Row key="form-row">
            <Col lg={6}>
              <Row>
                <Field
                  colSize={10}
                  name={"finance.cashPrice"}
                  type="number"
                  title="Cash price"
                  units="£"
                  min={0}
                  unitsPosition="before"
                  readOnly={readOnly}
                  component={FormInputField}
                  autoFocus={true}
                />
              </Row>
              <Row>
                <Field
                  colSize={10}
                  name={"finance.cashDeposit"}
                  type="number"
                  title="Deposit"
                  units="£"
                  unitsPosition="before"
                  min={0}
                  readOnly={readOnly}
                  component={FormInputField}
                />
              </Row>
              <Row>
                <Field
                  colSize={10}
                  name={"finance.partExchange"}
                  type="number"
                  title="Part exchange value"
                  units="£"
                  unitsPosition="before"
                  min={0}
                  readOnly={readOnly}
                  component={FormInputField}
                />
              </Row>
              <Row>
                <Field
                  colSize={10}
                  name={"finance.partExchangeSettlement"}
                  type="number"
                  title="Part exchange settlement"
                  units="£"
                  unitsPosition="before"
                  min={0}
                  readOnly={readOnly}
                  component={FormInputField}
                />
              </Row>
              <Row>
                <Col lg={10}>
                  <FormGroup>
                    <Label>Total finance value</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">£</InputGroupAddon>
                      <input
                        className="form-control"
                        type="number"
                        readOnly={true}
                        value={roundNumber(this.getFinanceValue(values)) || "0"}
                        tabIndex={-1}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Field
                  colSize={10}
                  name={"finance.period"}
                  type="number"
                  title="Period"
                  units="Months"
                  unitsPosition="after"
                  min={0}
                  readOnly={readOnly}
                  component={FormInputField}
                />
              </Row>
              <Row>
                <RadioField
                  colSize={10}
                  name="finance.productType"
                  title="Loan type"
                  readOnly={readOnly || !hasReg}
                  options={productTypeOptions}
                />
              </Row>
              {!readOnly && !dealer?.agreedApr ? (
                <Row>
                  <Col lg={{ size: 10 }}>
                    <Field
                      name="targetByValue"
                      type="number"
                      disabled={!!dealer?.agreedApr}
                      min={0}
                      component={TargetByField}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col lg={{ size: 10 }}>
                  <ProposalFormCreateQuotation {...formikProps}>
                    {({ canCreateQuotation, createQuotation, loading }) => (
                      <>
                        {!readOnly ? (
                          <>
                            <Button
                              className="w-100 mb-3"
                              disabled={!canCreateQuotation}
                              onClick={createQuotation}
                              color="secondary"
                              outline={!canCreateQuotation}
                            >
                              <FontAwesomeIcon
                                fixedWidth={true}
                                icon={loading ? faSpinner : faSave}
                                className="mr-2"
                                spin={loading}
                              />
                              Save quotation
                            </Button>
                            {canCreateQuotation ? (
                              formikProps.values
                                .FORMSTATE_noQuotationResults ? (
                                <p className="text-danger small">
                                  Unable to quote
                                </p>
                              ) : (
                                <p
                                  className={classnames(
                                    "small",
                                    formikProps.errors.quotationId &&
                                      formikProps.touched.quotationId
                                      ? "text-danger"
                                      : "text-info"
                                  )}
                                >
                                  Save the quotation to store the finance values
                                  for this proposal
                                </p>
                              )
                            ) : null}
                          </>
                        ) : null}
                      </>
                    )}
                  </ProposalFormCreateQuotation>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <ReadOnlyNumberField
                  name="finance.monthlyPayment"
                  title={
                    values.finance.period
                      ? `${values.finance.period} monthly payments`
                      : "Monthly payment"
                  }
                  colSize={10}
                  units="£"
                  unitsPosition="before"
                />
              </Row>
              {values.finance.productType !== ProductTypeEnum.HP ? (
                <Row>
                  <ReadOnlyNumberField
                    name="finance.balloonPayment"
                    title="Balloon payment"
                    colSize={10}
                    units="£"
                    unitsPosition="before"
                  />
                </Row>
              ) : null}
              <Row>
                <ReadOnlyNumberField
                  name="finance.aprRate"
                  title="APR"
                  colSize={10}
                  units="%"
                  unitsPosition="after"
                />
              </Row>
              <Row>
                <ReadOnlyNumberField
                  name="finance.rate"
                  title="Flat rate"
                  colSize={10}
                  units="%"
                  unitsPosition="after"
                />
              </Row>
              {values.finance.rFL || values.finance.extras ? (
                <>
                  <Row key="extra-finance-fields">
                    <Field
                      colSize={10}
                      name={"finance.rFL"}
                      type="number"
                      title="Road fund license"
                      units="£"
                      unitsPosition="before"
                      component={FormInputField}
                    />
                  </Row>
                  <Row>
                    <Field
                      colSize={10}
                      name={"finance.extras"}
                      type="number"
                      title="Extras"
                      units="£"
                      unitsPosition="before"
                      component={FormInputField}
                    />
                  </Row>
                </>
              ) : null}
            </Col>
          </Row>
        )}

        {!hasReg && (
          <Row>
            <Col>
              <Field>
                {({ field, form }: FieldProps<any>) => (
                  <Alert
                    fade={false}
                    color="secondary"
                    className="proposal-alert"
                  >
                    <p className="text-info lead">
                      Request a different loan type (optional)
                    </p>
                    <p className="text-info mb-2">
                      For manually selected vehicles PCP or LP quotes have to be
                      manually processed by one of our advisors. Please let us
                      know if you want to convert your quote
                    </p>
                    <Row>
                      <RadioField
                        options={[
                          {
                            label: ProductTypeEnum.PCP,
                            value: ProductTypeEnum.PCP,
                          },
                          {
                            label: ProductTypeEnum.LP,
                            value: ProductTypeEnum.LP,
                          },
                          {
                            label: "Do not convert",
                            value: "",
                          },
                        ]}
                        name="finance.requestDifferentLoanType"
                      />
                    </Row>
                  </Alert>
                )}
              </Field>
            </Col>
          </Row>
        )}
        {!!formikProps.values.targetByValue &&
          formikProps.values.targetByValue > 13.9 && (
            <Row>
              <Col>
                <Alert color="warning">
                  <b>Warning:</b> Some lenders only lend up to 13.9% APR
                </Alert>
              </Col>
            </Row>
          )}
        {this.props.values.proposalCustomerQuestions?.customerPresent && (
          <ProposalQuestion
            {...this.props}
            name="proposalCustomerQuestions.customerConfidentFinanceIsAffordable"
            text="Are you confident you will be able to meet the finance
                    payments without undue difficulty on your current income and
                    expenses and the penalties or consequences involved in not being 
                    able to keep up with the monthly repayments?"
            additionalMessage="Please ensure you explain the responsibilities
                    of a secured finance agreement to the customer"
          />
        )}
      </>
    );
  }

  private getFinanceValue(values: Proposal) {
    const {
      finance: { cashPrice, cashDeposit, partExchange, partExchangeSettlement },
    } = values;

    return (
      (cashPrice || 0) +
      (partExchangeSettlement || 0) -
      ((cashDeposit || 0) + (partExchange || 0))
    );
  }

  private async handleCalculateMultiQuote(formikProps: FormikProps<Proposal>) {
    const quoteValues = [
      "finance.cashPrice",
      "finance.cashDeposit",
      "finance.partExchange",
      "finance.partExchangeSettlement",
      "targetBy",
      "targetByValue",
      "productType",
    ];

    quoteValues.forEach((x) =>
      formikProps.setFieldTouched(x as any, true, false)
    );

    formikProps.validateForm().then((err) => {
      if (!getErrorPaths(err).some((x) => quoteValues.indexOf(x) >= 0)) {
        this.setState({ showResults: true });
      }
    });
  }

  private showModal(results: QuotationResults | null) {
    this.setState({ modalResult: results });
  }

  private hideModal() {
    this.setState({ modalResult: null });
  }

  private getProposalQuotation(
    proposal: Proposal,
    quotationResults: QuotationResults
  ): Quotation {
    let { dealerId, targetBy, individualCustomer, dealer = null } = proposal;

    let {
      arrangementFee,
      commission,
      commissionCode,
      completionFee,
      guaranteedFutureValue,
      aprRate,
      flatRate,
      term,
      productType,
      cashPrice,
      deposit,
      monthlyPayment,
    } = quotationResults;

    dealerId == null && (dealerId = dealer?.id);
    targetBy == null && (targetBy = QuotationTargetBy.APR_RATE);

    const { partExchange, partExchangeSettlement } = proposal.finance;

    const vehicle = cleanFormData(proposal.vehicle) as Vehicle;

    delete vehicle.skipVehicle;
    delete vehicle.isRegUnknown;

    const quotation = {
      dealerId,
      title: individualCustomer ? individualCustomer.title : undefined,
      forename: individualCustomer ? individualCustomer.forename : undefined,
      surname: individualCustomer ? individualCustomer.surname : undefined,
      finance: {
        productType,
        term,
        cashPrice,
        deposit,
        partExchangeSettlement,
        partExchangeValue: partExchange,
        aprRate,
        arrangementFee,
        commission,
        commissionCode,
        completionFee,
        flatRate,
        guaranteedFutureValue,
        monthlyPayment,
      },
      skipVehicle: false,
      vehicle,
      targetBy,
      targetByValue: aprRate,
      isHidden: true,
    };

    return quotation;
  }

  private selectResult(
    mutationFn: MutationFunction<
      CreateFullProposalQuotationData,
      QuotationVariables
    >,
    result: QuotationResults,
    { isSubmitting, setFieldValue, handleSubmit, values }: FormikProps<Proposal>
  ) {
    if (!isSubmitting) {
      values.finance.aprRate = result.aprRate;
      values.finance.rate = result.flatRate;
      values.finance.balloonPayment = result.guaranteedFutureValue;
      values.finance.productType = result.productType;
      values.finance.period = result.term;
      values.finance.monthlyPayment = result.monthlyPayment;
      values.finance.lenderId = result.lenderId;
      values.finance.lenderName = result.lenderName;
      values.FORMSTATE_requiresQuotation = false;

      setTimeout(handleSubmit, 0);
    }

    const { finance, quotationId } = values;

    const hasFinanceValues = financeFields.every((x) => {
      return !!finance[x] || finance[x] === 0;
    });
    const quotation = this.getProposalQuotation(values, result);

    const canCreateQuotation =
      !quotationId &&
      hasFinanceValues &&
      quotationValidationSchema.isValidSync(quotation);

    return canCreateQuotation
      ? mutationFn({
          variables: { input: this.cleanProposalQuotation(quotation) },
        }).then((result) => {
          setFieldValue(
            "quotationId",
            result.data?.createFullProposalQuotation?.id,
            false
          );
        })
      : null;
  }

  private cleanProposalQuotation(formData: Quotation) {
    const proposalQuotation: Quotation = cleanFormData(formData);

    if (proposalQuotation.vehicle) {
      delete proposalQuotation.vehicle.isRegUnknown;
      delete proposalQuotation.vehicle.skipVehicle;
      delete proposalQuotation.vehicle.regNoNotFound;
      delete proposalQuotation.vehicle.LCV;
    }

    return proposalQuotation;
  }
}

export default FinanceSection;
