import classnames from "classnames";
import { Field, FieldProps } from "formik";
import * as React from "react";

import "./formstyles.scss";

export interface BooleanCheckboxFieldProps {
  label: string;
  readOnly?: boolean;
  className?: string;
  submitOnChange?: boolean;
}

export const BooleanCheckbox = ({
  form,
  field,
  label,
  className,
  readOnly,
  submitOnChange
}: FieldProps<any> & BooleanCheckboxFieldProps) => (
  <div className={classnames("custom-control custom-checkbox", className)}>
    <input
      id={field.name}
      type="checkbox"
      className="custom-control-input"
      checked={!!field.value}
      name={field.name}
      readOnly={!!readOnly}
      onChange={() => {
        if (!readOnly) {
          form.setFieldValue(field.name, !field.value, !submitOnChange);
          form.setFieldTouched(field.name, true, !submitOnChange);
          submitOnChange && form.submitForm();
        }
      }}
    />
    <label className="custom-control-label" htmlFor={field.name}>
      {label}
    </label>
  </div>
);

const BooleanCheckboxField = ({
  name,
  ...props
}: BooleanCheckboxFieldProps & { name: string }) => (
  <Field name={name}>
    {(fieldProps: FieldProps<any>) => (
      <BooleanCheckbox {...fieldProps} {...props} />
    )}
  </Field>
);

export default BooleanCheckboxField;
