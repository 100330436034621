import moment from "moment";
import * as Yup from "yup";
import {
  DATE_PATTERN,
  getMonthsAndYearsText,
  SPECIAL_CHARACTER_SELECT,
  testDateStringIsValid,
} from "../../../utils";
import mobileValidationSchema from "../../Forms/mobileValidationSchema";
import telephoneValidationSchema from "../../Forms/telephoneValidationSchema";
import { Address } from "../../types";
import {
  EarningsFrequency,
  EmploymentDetails,
  EmploymentTypesRequiringEmployerName,
  HomeAddress,
  HomeOwnership,
  Individual,
} from "../types";

export const INDIVIDUAL_ADDRESS_MAX_REQUIRED = 3;
export const INDIVIDUAL_ADDRESS_YEARS_REQUIRED = 3;

export const EMPLOYERS_MAX_REQUIRED = 3;
export const EMPLOYERS_YEARS_REQUIRED = 3;

export const homeAddressSchema = Yup.object<HomeAddress>().shape({
  id: Yup.string().default(undefined).nullable(true),
  line1: Yup.string()
    .label("Line 1")
    .default(undefined)
    .nullable(true)
    .test(
      "line1-comma-check",
      "Line 1 format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .min(3)
    .required("Line 1 of the address is required")
    .max(50),
  line2: Yup.string().default(undefined).nullable(true).max(50).label("Line 2"),
  line3: Yup.string().default(undefined).nullable(true).max(50).label("Line 3"),
  town: Yup.string()
    .default(undefined)
    .nullable(true)
    .test(
      "town-comma-check",
      "Town format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .max(50)
    .min(3)
    .required("Town of the address is required")
    .label("Town"),
  postcode: Yup.string()
    .default(undefined)
    .nullable(true)
    .test(
      "postcode-comma-check",
      "Postcode format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .max(8)
    .min(3)
    .required("Postcode of the address is required")
    .label("Postcode"),
  countryId: Yup.string().nullable(true).default("GBR"),
  telephone: telephoneValidationSchema.label("Telephone number"),
  yearsAtAddress: Yup.number()
    .default(undefined)
    .label("Years at address")
    .min(0)
    .lessThan(100, "Years at address must be less than 100")
    .typeError("Years at address must be a number")
    .nullable(true)
    .required()
    .integer("Years must be a whole number"),
  monthsAtAddress: Yup.number()
    .default(undefined)
    .label("Months at address")
    .nullable(true)
    .typeError("Months at address must be a number")
    .lessThan(12, "Months must be less than 12")
    .min(0)
    .required()
    .test(
      "individual-months-at-address-not-zero",
      "Months at address must be specified if years at address is zero",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return value !== 0 || !this.parent || this.parent.yearsAtAddress !== 0;
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    ),
  ownership: Yup.string()
    .required("Ownership details required")
    .nullable(true)
    .default(undefined) as any,
  otherOwnership: Yup.string()
    .label("Ownership details")
    .default(undefined)
    .nullable(true)
    .max(50)
    .when("ownership", {
      is: (ownership: string) => ownership === HomeOwnership.OTHER,
      then: Yup.string().required(),
    }),
  totalMonthlyRentOrMortgage: Yup.number()
    .label("Monthly rent or mortgage cost")
    .default(undefined)
    .nullable(true)
    .min(0, "Rent or mortgage value cannot be lower than 0")
    .typeError("Rent or mortgage must be a number")
    .integer("Rent or mortgage amount must be a whole number"),
});

export const addressSchema = Yup.object<Address>().shape({
  id: Yup.string().default(undefined).nullable(true),
  line1: Yup.string()
    .default(undefined)
    .nullable(true)
    .test(
      "line1-address-comma-check",
      "Line 1 format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .min(3)
    .max(50)
    .required("Line 1 of the address is required")
    .label("Line 1"),
  line2: Yup.string().max(50).default(undefined).nullable(true).label("Line 2"),
  line3: Yup.string().max(50).default(undefined).nullable(true).label("Line 3"),
  town: Yup.string()
    .max(50)
    .default(undefined)
    .nullable(true)
    .label("Town")
    .test(
      "town-address-comma-check",
      "Town format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .required("Town of the address is required"),
  postcode: Yup.string()
    .max(8)
    .test(
      "postcode-address-comma-check",
      "Postcode format is incorrect",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          !!value &&
          value.replace(SPECIAL_CHARACTER_SELECT, "").trim().length > 2
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .default(undefined)
    .nullable(true)
    .label("Postcode")
    .required("Postcode of the address is required"),
  telephone: telephoneValidationSchema.label("Telephone"),
  extension: Yup.string().default(undefined).nullable(true).max(8),
  fax: telephoneValidationSchema.label("Fax"),
});

export const employmentDetailsSchema = Yup.object<EmploymentDetails>().shape({
  id: Yup.string().default(undefined).nullable(true),
  employmentTerms: Yup.string()
    .default(undefined)
    .nullable(true)
    .required("Employment terms are required") as any,
  employmentStatus: Yup.string()
    .default(undefined)
    .nullable(true)
    .required("Employment status is required") as any,
  employmentType: Yup.string()
    .default(undefined)
    .nullable(true)
    .required("Employment type is required") as any,
  occupation: Yup.string()
    .default(undefined)
    .nullable(true)
    .max(50)
    .when("employmentType", {
      is: (t: string) =>
        EmploymentTypesRequiringEmployerName.some((e) => e === t),
      then: Yup.string().required("Occupation is required"),
    }),
  employerName: Yup.string()
    .default(undefined)
    .nullable(true)
    .max(50)
    .when("employmentType", {
      is: (t: string) =>
        EmploymentTypesRequiringEmployerName.some((e) => e === t),
      then: Yup.string().required("Employer name is required"),
    }),
  earnings: Yup.number()
    .label("Earnings")
    .default(undefined)
    .nullable(true)
    .min(10200, "Earnings must be equal to or greater than £850 per month")
    .typeError("Income must be a number"),
  earningsPer: Yup.string().default(EarningsFrequency.YEARLY).nullable(true),
  yearsWithEmployer: Yup.number()
    .default(undefined)
    .min(0)
    .nullable(true)
    .integer("Years must be a whole number")
    .required("Years with employer is required")
    .typeError("Years must be a number"),
  monthsWithEmployer: Yup.number()
    .default(undefined)
    .min(0)
    .nullable(true)
    .lessThan(12, "Months must be less than 12")
    .required("Months with employer is required")
    .typeError("Months must be a number")
    .test(
      "employer-months-with-employer-not-zero",
      "Months at with employer must be specified if years with employer is zero",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value) {
        return (
          value !== 0 || !this.parent || this.parent.yearsWithEmployer !== 0
        );
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    ),
  address: addressSchema.nullable(true),
  industry: Yup.string()
    .default(undefined)
    .nullable(true)
    .required("Please specify industry") as any,
});

const individualCustomerValidationSchema = Yup.object<Individual>().shape({
  id: Yup.string().nullable(true),
  proposalId: Yup.number().nullable(true),
  title: Yup.string()
    .default(undefined)
    .nullable(true)
    .label("Title")
    .required()
    .max(10),
  forename: Yup.string()
    .default(undefined)
    .nullable(true)
    .label("First name")
    .required()
    .max(100),
  middleName: Yup.string()
    .default(undefined)
    .nullable(true)
    .label("Middle name")
    .max(100),
  surname: Yup.string()
    .default(undefined)
    .nullable(true)
    .label("Last name")
    .required()
    .max(100),
  dOB: Yup.string()
    .matches(DATE_PATTERN, "Date of birth is a required field")
    .default(undefined)
    .label("Date of birth")
    .test(
      "dob-age-restriction",
      "Customer has to be over 18 years old",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value: string) {
        if (value && value.match(DATE_PATTERN)) {
          const dateValue = moment(value);
          if (dateValue.isValid()) {
            return (
              dateValue.toDate() <= moment().subtract(18, "years").toDate()
            );
          }
        }

        return true;
      }
      // tslint:enable:ter-prefer-arrow-callback
      // tslint:enable:only-arrow-functions
    )
    .test(
      "date-format-validation",
      "Date does not exist",
      testDateStringIsValid
    )
    .required()
    .typeError("Date of birth must be a date") as any,
  maritalStatus: Yup.string()
    .default(undefined)
    .label("Marital status")
    .nullable(true)
    .required() as any,
  numOfDependants: Yup.number()
    .default(undefined)
    .label("Number of dependants")
    .min(0)
    .lessThan(100, "Number of dependants must be less than 100")
    .typeError("Number of dependants must be a number")
    .nullable(true)
    .required() as any,
  drivingLicense: Yup.string()
    .default(undefined)
    .label("Driving license")
    .nullable(true)
    .required() as any,
  mobile: mobileValidationSchema.label("Mobile").required(),
  email: Yup.string()
    .label("Email")
    .email("Email must be in the format example@example.com")
    .max(256)
    .nullable(true)
    .required(),
  homeAddresses: Yup.array(homeAddressSchema)
    .default([homeAddressSchema.default()])
    .test(
      "more-addresses-required",
      "More home address history is required",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value: HomeAddress[]) {
        const yearsRequired = INDIVIDUAL_ADDRESS_YEARS_REQUIRED;
        const maxAddresses = INDIVIDUAL_ADDRESS_MAX_REQUIRED;

        if (value && value.length && value.length < maxAddresses) {
          const hasEmptyValues = value.some(
            (addr) =>
              (!addr.monthsAtAddress && addr.monthsAtAddress !== 0) ||
              (!addr.yearsAtAddress && addr.yearsAtAddress !== 0)
          );

          if (!hasEmptyValues) {
            const totalMonths = value.reduce(
              (val, emp) =>
                val +
                (emp.monthsAtAddress || 0) +
                (emp.yearsAtAddress || 0) * 12,
              0
            );

            if (totalMonths < yearsRequired * 12) {
              const durationMessage = ` (${getMonthsAndYearsText(
                totalMonths
              )} added, ${yearsRequired} years required)`;

              // Use a custom nonexistent path "individualCustomer.homeAddressesError".
              // This stops the other address messages from being replaced with this one.
              // It is a hacky hack :-(
              return this.createError({
                path: "individualCustomer.homeAddressesError",
                message: `More home address history is required${
                  totalMonths ? durationMessage : ""
                }`,
              });
            }
          }
        }
        return true;
      }
    )
    .test(
      "totalMonthlyRentOrMortgageRequired",
      "Monthly rent or mortgage cost is required",
      function (value: HomeAddress[]) {
        if (
          value &&
          value.length &&
          value[0].totalMonthlyRentOrMortgage !== 0 &&
          !value[0].totalMonthlyRentOrMortgage
        ) {
          return this.createError({
            path: "individualCustomer.homeAddresses.0.totalMonthlyRentOrMortgage",
            message: "Monthly rent or mortgage cost is required",
          });
        }
        return true;
      }
    )
    .required("Home address history required")
    .max(3, "A maximum of three addresses are required")
    .nullable(true),
  homeAddressesError: Yup.string().default(undefined).nullable(true),
  employmentDetails: Yup.array(employmentDetailsSchema)
    .default([employmentDetailsSchema.default()])
    .test(
      "more-employers-required",
      "More employer history is required",
      // tslint:disable:ter-prefer-arrow-callback
      // tslint:disable:only-arrow-functions
      function (value: EmploymentDetails[]) {
        const yearsRequired = EMPLOYERS_YEARS_REQUIRED;
        const employersRequired = EMPLOYERS_MAX_REQUIRED;

        if (value && value.length && value.length < employersRequired) {
          const hasEmptyValues = value.some(
            (emp) =>
              (!emp.monthsWithEmployer && emp.monthsWithEmployer !== 0) ||
              (!emp.yearsWithEmployer && emp.yearsWithEmployer !== 0)
          );

          if (!hasEmptyValues) {
            const totalMonths = value.reduce(
              (val, emp) =>
                val +
                (emp.monthsWithEmployer || 0) +
                (emp.yearsWithEmployer || 0) * 12,
              0
            );

            if (totalMonths < yearsRequired * 12) {
              const durationMessage = ` (${getMonthsAndYearsText(
                totalMonths
              )} added, ${yearsRequired} years required)`;
              return this.createError({
                path: "individualCustomer.employmentDetailsError",
                message: `More employer history is required${
                  totalMonths ? durationMessage : ""
                }`,
              });
            }
          }
        }
        return true;
      }
    )
    .test(
      "earningsRequired",
      "Earnings are required",
      function (value: EmploymentDetails[]) {
        if (
          value &&
          value.length &&
          value[0].earnings !== 0 &&
          !value[0].earnings
        ) {
          return this.createError({
            path: "individualCustomer.employmentDetails.0.earnings",
            message: "Earnings are required",
          });
        }
        return true;
      }
    )
    .test(
      "telephoneRequired",
      "Telephone is required",
      function (value: EmploymentDetails[]) {
        if (
          value &&
          value.length &&
          value[0].address &&
          !value[0].address.telephone
        ) {
          return this.createError({
            path: "individualCustomer.employmentDetails.0.address.telephone",
            message: "Telephone is required",
          });
        }
        return true;
      }
    )
    .required("Employment history required")
    .max(3, "A maximum of three employers is required"),
  employmentDetailsError: Yup.string().nullable(true),
  countryOfBirthId: Yup.string()
    .label("Country of birth")
    .nullable(true)
    .length(3)
    .required()
    .default(undefined),
  nationalityId: Yup.string()
    .label("Nationality")
    .nullable()
    .length(3)
    .required()
    .default(undefined),
  countryOfResidenceId: Yup.string()
    .label("Country of residence")
    .nullable()
    .length(3)
    .required()
    .default(undefined),
  selfEmployedDirectorOrBoardMember: Yup.boolean()
    .label("Self employed, director or board member")
    .required()
    .default(undefined),
  countryOfActivityId: Yup.string()
    .label("Country of activity")
    .nullable()
    .length(3)
    .default(undefined)
    .when("selfEmployedDirectorOrBoardMember", {
      is: true,
      then: Yup.string().required(),
    }),
});

export default individualCustomerValidationSchema;
