import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult, useQuery } from "react-apollo";
import { DebounceKeys, SearchResults, User } from "../../types";
import { DealerFragment, SingleDealerFragment } from "../fragments";
import { Dealer, DealerSearchArgs } from "../types";

export interface DealerListData {
  loggedInUser: User & { dealers: SearchResults<Dealer> };
}

export const DEALER_LIST = gql`
  query DealerListQuery($input: DealerSearchArgsInput) {
    loggedInUser {
      id
      username
      roles
      dealers(input: $input) {
        pageInfo {
          hasMorePages
          totalResults
          page
          pageSize
          first
          last
        }
        edges {
          node {
            ...DealerFragment
          }
        }
      }
    }
  }
  ${DealerFragment}
`;

export const DEALER_LIST_SHALLOW = gql`
  query ShallowDealerListQuery($input: DealerSearchArgsInput) {
    loggedInUser {
      id
      username
      roles
      dealers(input: $input) {
        pageInfo {
          hasMorePages
          totalResults
          page
          pageSize
          first
          last
        }
        edges {
          node {
            ...SingleDealerFragment
          }
        }
      }
    }
  }
  ${SingleDealerFragment}
`;

const DealerListQuery = ({
  children,
  query,
  ...input
}: DealerSearchArgs & {
  query?: any;
  children: (
    result: QueryResult<DealerListData, { input: DealerSearchArgs }> & {
      dealers?: SearchResults<Dealer>;
    }
  ) => JSX.Element | null;
}) => (
  <Query<DealerListData, { input: DealerSearchArgs }>
    query={query || DEALER_LIST}
    variables={{ input }}
    context={{ debounceKey: DebounceKeys.DEALER_LIST, debounceTimeout: 300 }}
  >
    {result =>
      children({
        ...result,
        dealers:
          result.data &&
          result.data.loggedInUser &&
          result.data.loggedInUser.dealers
      })
    }
  </Query>
);

export const useDealerList = (input: DealerSearchArgs) => {
  const { loading, data, fetchMore } = useQuery<
    DealerListData,
    { input: DealerSearchArgs }
  >(DEALER_LIST, { variables: { input } });
  return { loading, dealers: data?.loggedInUser?.dealers, fetchMore };
};

export const useDealerListShallow = (input: DealerSearchArgs) => {
  const { loading, data, fetchMore } = useQuery<
    DealerListData,
    { input: DealerSearchArgs }
  >(DEALER_LIST_SHALLOW, { variables: { input } });
  return { loading, dealers: data?.loggedInUser?.dealers, fetchMore };
};

export default DealerListQuery;
