import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { SearchResults, User } from "../types";
import { AlertMessage } from "./types";

export const AlertMessageFragment = gql`
  fragment AlertMessageFragment on AlertMessage {
    id
    title
    message
    redirectPath
    startDate
    endDate
    priority
  }
`;

export interface AlertMessagesListData {
  loggedInUser: User;
  alertMessageSearch: SearchResults<AlertMessage>;
}

export interface AlertMessageData {
  alertMessage: AlertMessage;
}

export const ALERT_MESSAGE_LIST = gql`
  query AlertMessageSearchQuery($input: AlertMessageSearchArgsInput) {
    loggedInUser {
      id
      roles
      username
    }
    alertMessageSearch(input: $input) {
      pageInfo {
        hasMorePages
        totalResults
        page
        pageSize
        first
        last
      }
      edges {
        node {
          ...AlertMessageFragment
        }
      }
    }
  }
  ${AlertMessageFragment}
`;

export const GET_ALERT_MESSAGE = gql`
  query AlertMessageQuery($id: ID) {
    alertMessage(id: $id) {
      ...AlertMessageFragment
    }
  }
  ${AlertMessageFragment}
`;

export const CREATE_OR_UPDATE_ALERT_MESSAGE = gql`
  mutation CreateOrUpdateAlertMessage($input: AlertMessageInput) {
    createOrUpdateAlertMessage(input: $input) {
      ...AlertMessageFragment
    }
  }
  ${AlertMessageFragment}
`;

export const DELETE_ALERT_MESSAGE = gql`
  mutation DeleteAlertMessage($id: ID) {
    deleteAlertMessage(id: $id) {
      success
    }
  }
`;

export const GET_ACTIVE_ALERT_MESSAGES = gql`
  query ActiveAlertMessageQuery {
    loggedInUser {
      id
      username
      roles
    }
    activeAlertMessages {
      ...AlertMessageFragment
    }
  }
  ${AlertMessageFragment}
`;

export const useAlertMessage = (id?: string) => {
  const { data } = useQuery<{ alertMessage: AlertMessage }>(GET_ALERT_MESSAGE, {
    variables: { id },
    skip: !id,
  });

  return data?.alertMessage;
};

export const useActiveAlertMessages = (pollInterval?: number) => {
  const { data } = useQuery<{ activeAlertMessages: AlertMessage[] }>(
    GET_ACTIVE_ALERT_MESSAGES,
    {
      pollInterval,
    }
  );

  return data?.activeAlertMessages;
};
