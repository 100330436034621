import gql from "graphql-tag";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { DebounceKeys } from "../../types";

interface VehicleModelsData {
  vehicles: {
    vehicleModels: {
      id: string;
      name: string;
    }[];
  };
}

interface VehicleModelArgs {
  isCommercial?: boolean;
  make: string;
}

interface VehicleModelVariables {
  input: VehicleModelArgs;
}

const VEHICLE_MODELS = gql`
  query VehicleModelsQuery($input: VehicleModelArgsInput) {
    vehicles {
      vehicleModels(input: $input) {
        id
        name
      }
    }
  }
`;

const VehicleModelsQuery = ({
  children,
  isCommercial,
  make
}: VehicleModelArgs & {
  children: (
    result: QueryResult<VehicleModelsData, VehicleModelVariables>
  ) => JSX.Element | null;
}) => (
  <Query<VehicleModelsData, VehicleModelVariables>
    query={VEHICLE_MODELS}
    variables={{ input: { isCommercial: !!isCommercial, make } }}
    context={{ debounceKey: DebounceKeys.VEHICLE_MODELS, debounceTimeout: 300 }}
  >
    {children}
  </Query>
);

export default VehicleModelsQuery;
