import React, { useState } from "react";
import CustomSelect from "../../Forms/CustomSelect";
import { useCountries, useCountry } from "./CountryQuery";

interface CountrySelectProps {
  countryId?: string;
  onSelectCountry: (countryId: string) => void;
  className?: string;
}

const CountrySelect = ({
  countryId,
  onSelectCountry,
  className
}: CountrySelectProps) => {
  const country = useCountry(countryId);
  const countries = useCountries();

  const [inputValue, setInputValue] = useState("");

  const options = countries
    ? countries.map(({ id, name }) => ({ label: name, value: id }))
    : [];
  const selectedOption =
    countryId && country ? { value: country.id, label: country.name } : null;

  return (
    <CustomSelect
      className={className}
      isDisabled={!countries}
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={(value: any) => setInputValue(value)}
      options={options}
      onChange={(value: any) =>
        onSelectCountry(value ? value.value : undefined)
      }
      noOptionsMessage={() => (countries ? "Loading..." : "No countries found")}
      placeholder={countries ? "Type to search countries" : "Loading..."}
      autoFocus={false}
      isClearable={false}
    />
  );
};

export default CountrySelect;
