import { connect, FormikContext } from "formik";
import { isArray } from "lodash";
import * as React from "react";
import { DATE_PATTERN } from "../../utils";
import SummaryRow, {
  DateSummaryRow,
  YesNoSummaryRow,
} from "../shared/SummarySection/SummaryRow";
import { FormSectionSummaryProps, WatchFields } from "./MultiSectionForm";

interface FieldInfo {
  label: string;
  value: string;
}

const getFieldComponents = (
  values: any,
  fields: WatchFields<any>,
  schema?: any
): FieldInfo[] =>
  Object.keys(fields).reduce((prev: FieldInfo[], k: any) => {
    const value = values && values[k];
    const field = fields[k];
    const fieldSchema = schema && schema.fields ? schema.fields[k] : undefined;

    if (isArray(value) && typeof field === "object") {
      const arraySchema = fieldSchema ? fieldSchema.of : undefined;
      return [
        ...prev,
        ...(value as any[]).reduce(
          (acc: FieldInfo[], item) => [
            ...acc,
            ...getFieldComponents(item, field, arraySchema),
          ],
          []
        ),
      ];
    }

    if (typeof field === "object") {
      return [...prev, ...getFieldComponents(value, field, fieldSchema)] as any;
    }

    const label = (fieldSchema && fieldSchema.describe().label) || k;

    let displayValue = value;
    if (typeof displayValue === "boolean") {
      displayValue = displayValue ? "Yes" : "No";
    }

    if (!displayValue && displayValue !== 0) {
      displayValue = undefined;
    }
    return [...prev, { label, value: displayValue }];
  }, []);

interface AutoGeneratedFormSectionSummaryProps<Values>
  extends FormSectionSummaryProps<Values> {
  firstColumnSize?: number;
}

const AutoGeneratedFormSectionSummary = <Values extends {}>({
  section: { watchFields },
  firstColumnSize,
  formik: { initialValues, validationSchema },
}: AutoGeneratedFormSectionSummaryProps<Values> & {
  formik: FormikContext<Values>;
}) => {
  const fieldInfo = getFieldComponents(
    initialValues,
    watchFields,
    validationSchema
  );

  return (
    <>
      {fieldInfo.map(({ label, value }, i) => {
        const summaryProps = {
          title: label,
          firstColumnSize,
        };
        const key = `${label}_${i}`;

        if (value && typeof value === "boolean") {
          return <YesNoSummaryRow key={key} value={value} {...summaryProps} />;
        }

        if (value && new RegExp(DATE_PATTERN).test(value)) {
          return <DateSummaryRow key={key} {...summaryProps} date={value} />;
        }

        return <SummaryRow key={key} {...summaryProps} value={value} />;
      })}
    </>
  );
};

export default connect<AutoGeneratedFormSectionSummaryProps<any>>(
  AutoGeneratedFormSectionSummary
);
