import classnames from "classnames";
import * as React from "react";

interface FileDownloadLinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  target?: string;
}

const FileDownloadLink = ({
  href,
  className,
  children,
  disabled,
  target
}: FileDownloadLinkProps) => {
  return (
    <a
      href={href}
      target={target}
      className={classnames(className, { disabled })}
    >
      {children}
    </a>
  );
};

export default FileDownloadLink;
