import { FormikProps } from "formik";
import * as React from "react";
import { Alert, Button, Col, FormGroup, Row } from "reactstrap";
import { GET_DIRECT_DEAL_DEALER, useDealer } from "../../Dealers/DealerQuery";
import DealerSelect from "../../Dealers/DealerSelect";
import { WatchFields } from "../../Forms/MultiSectionForm";
import { Proposal } from "../types";
import "./index.scss";
import ProposalFormClearQuotation from "./ProposalFormClearQuotation";
import { ContextNames } from "../../types";

export const dealerWatchFields: WatchFields<Proposal> = {
  dealerId: true,
};

const DealerSection = (
  formikProps: FormikProps<Proposal> & { context?: ContextNames }
) => {
  const { setFieldTouched, setFieldValue, values, context } = formikProps;
  const { dealer } = useDealer(
    values.dealerId,
    context === ContextNames.DIRECT_DEAL ? GET_DIRECT_DEAL_DEALER : null
  );

  return (
    <>
      <Row>
        <Col lg={10}>
          {context !== ContextNames.DIRECT_DEAL && values.quotationId ? (
            <Alert key="quotation-info" fade={false} color="info">
              Dealer is fixed for this quotation{" "}
              <ProposalFormClearQuotation {...formikProps}>
                {({ clearQuotation }) => (
                  <Button
                    className="ml-3"
                    type="button"
                    size="sm"
                    outline={true}
                    color="info"
                    onClick={clearQuotation}
                  >
                    Clear quotation and change dealer
                  </Button>
                )}
              </ProposalFormClearQuotation>
            </Alert>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col lg={7}>
          {values.quotationId && values.dealerId ? (
            dealer ? (
              <p>{dealer.name}</p>
            ) : null
          ) : (
            <FormGroup>
              <DealerSelect
                dealerId={values.dealerId}
                onSelectDealer={(dealerId, isMannIslandDealer) => {
                  setFieldTouched("dealerId", true);
                  setFieldValue("dealerId", dealerId);
                }}
                autoFocus={true}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DealerSection;
