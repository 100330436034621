import * as React from "react";
import { capitalizeFirstLettersOnly } from "../../../utils";
import FormikEffects from "../../Forms/FormikEffects";

/**
 * Automatically capitalizes the customer name on the quotation form
 */
const CapitalizeQuotationCustomerName = () => (
  <FormikEffects
    onChange={(prevValues, nextValues, { setFieldValue }) => {
      // Automatically capitalize the first letters of the customer name
      ["title", "forename", "middleName", "surname"].forEach(field => {
        const prevVal = prevValues[field];
        const nextVal = nextValues[field];
        if (nextVal && prevVal !== nextVal) {
          const titleCase = capitalizeFirstLettersOnly(nextVal);
          if (titleCase !== nextVal) {
            setFieldValue(`${field}`, titleCase, false);
          }
        }
      });
    }}
  />
);

export default CapitalizeQuotationCustomerName;
