import * as Yup from "yup";
import { DirectDeal } from "../types";

const IDDValidationSchema = Yup.object<DirectDeal>().shape({
  iddSigned: Yup.boolean()
    .required("This box must be checked in order to proceed")
    .default(false)
    .nullable(true)
    .oneOf([true], "This box must be checked in order to proceed"),
  printName: Yup.string()
    .required("Print your full name to accept and continue")
    .default(undefined)
    .nullable(true)
    .typeError("This field can only contain letters"),
});

export default IDDValidationSchema;
