import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject
} from "apollo-boost";
import { ApolloCache } from "apollo-cache";
import { toIdValue } from "apollo-utilities";

const dataIdFromObject = (object: any): string | null => {
  const typename = object.__typename as string;
  let key: any;
  switch (typename) {
    case "Proposal":
      key = (object.proposalRef || "").toLowerCase();
      break;
    default:
      // ids with the graphql ID type are returned as strings, so treat "0" as empty
      key = object.id && object.id !== "0" ? object.id : null;
      break;
  }

  if (typename && key) {
    return `${typename}:${key}`;
  }
  return null;
};

const defaultIdResolver = (typename: string) => (_: any, args: any) => {
  const dataId = dataIdFromObject({
    id: args.id,
    __typename: typename
  });
  return dataId ? toIdValue(dataId) : null;
};

const configureApolloClient = (link?: ApolloLink) => {
  if (!link) {
    throw new Error("No apollo link provided");
  }

  const cache: ApolloCache<NormalizedCacheObject> = new InMemoryCache({
    cacheRedirects: {
      Query: {
        identityProviderUser: defaultIdResolver("IdentityProviderUser"),
        proposal: (_, args) => {
          // Get proposals by proposalRef not id
          const dataId = args.proposalRef
            ? dataIdFromObject({
                proposalRef: (args.proposalRef as string).toLowerCase(),
                __typename: "Proposal"
              })
            : null;
          return dataId ? toIdValue(dataId) : undefined;
        },
        user: defaultIdResolver("User"),
        draftProposal: defaultIdResolver("DraftProposal"),
        quotation: defaultIdResolver("Quotation"),
        dealer: defaultIdResolver("Dealer"),
        accountManager: defaultIdResolver("AccountManager"),
        regionalSalesManager: defaultIdResolver("RegionalSalesManager"),
        proposalNote: defaultIdResolver("ProposalNote"),
        dealerContact: defaultIdResolver("DealerContact"),
        target: defaultIdResolver("AccountManagerTargets"),
        draftDealer: defaultIdResolver("DraftDealer"),
        country: defaultIdResolver("Country"),
        occupationType: defaultIdResolver("OccupationType"),
        announcement: defaultIdResolver("Announcement"),
        alertMessage: defaultIdResolver("AlertMessage"),
        activity: defaultIdResolver("AccountManagerActivity")
      },
      PermissionsSection: {
        role: defaultIdResolver("PermissionRole"),
        module: defaultIdResolver("PermissionModule"),
        permission: (_: any, args: any) => {
          const dataId = dataIdFromObject({
            id: `${args.roleId}:${args.moduleId}`,
            __typename: "Permission"
          });
          return dataId ? toIdValue(dataId) : null;
        }
      }
    },
    dataIdFromObject
  });

  const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all"
      },
      query: {
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  });

  return client;
};

export default configureApolloClient;
