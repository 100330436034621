import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql } from "apollo-boost";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { Button } from "reactstrap";

interface ValidateBankAccountButtonData {
  bankAccountLookup: {
    validateBankAccount?: {
      isValid: boolean;
      bank?: string;
      branch?: string;
    };
  };
}

export const VALIDATE_BANK_ACCOUNT = gql`
  query ValidateBankAccountQuery($input: ValidateBankAccountArgsInputType) {
    bankAccountLookup {
      validateBankAccount(input: $input) {
        isValid
        bank
        branch
      }
    }
  }
`;

interface ValidateBankAccountButtonProps {
  accountNumber?: string;
  sortCode?: string;
  accountNotFound: boolean;
  validateFormData: () => Promise<boolean>;
  onBankAccountValidated: (account: {
    isValid: boolean;
    accountNumber: string;
    sortCode: string;
    bank?: string;
    branch?: string;
  }) => void;
}

class ValidateBankAccountButton extends React.Component<
  WithApolloClient<ValidateBankAccountButtonProps>,
  { loading: boolean }
> {
  constructor(props: WithApolloClient<ValidateBankAccountButtonProps>) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { loading: false };
  }

  public render() {
    const { accountNotFound } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Button
          color="secondary"
          type="button"
          className="mb-2"
          onMouseDown={this.handleClick}
        >
          {loading && (
            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
          )}
          Find bank details
        </Button>
        {accountNotFound && (
          <p className="small text-danger account-not-found">
            Bank account not found
          </p>
        )}
      </>
    );
  }

  private handleClick(e: any) {
    e.preventDefault();
    const {
      validateFormData,
      onBankAccountValidated,
      accountNumber,
      sortCode
    } = this.props;

    validateFormData().then(formIsValid => {
      const { client } = this.props;

      if (formIsValid && accountNumber && sortCode) {
        this.setState({ loading: true });
        client
          .query<ValidateBankAccountButtonData>({
            query: VALIDATE_BANK_ACCOUNT,
            variables: { input: { accountNumber, sortCode } }
          })
          .then(({ data }) => {
            const result = data && data.bankAccountLookup.validateBankAccount;

            onBankAccountValidated({
              isValid: !!result && result.isValid,
              accountNumber,
              sortCode,
              bank: (result && result.bank) || undefined,
              branch: (result && result.branch) || undefined
            });
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  }
}

export default withApollo<ValidateBankAccountButtonProps>(
  ValidateBankAccountButton
);
