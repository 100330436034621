import * as Yup from "yup";

const crazyNumbers = [
  "0000000000",
  "00000000000",
  "000000000000",
  "1111111111",
  "11111111111",
  "111111111111",
  "2222222222",
  "22222222222",
  "222222222222",
  "3333333333",
  "33333333333",
  "333333333333",
  "4444444444",
  "44444444444",
  "444444444444",
  "5555555555",
  "55555555555",
  "555555555555",
  "6666666666",
  "66666666666",
  "666666666666",
  "7777777777",
  "77777777777",
  "777777777777",
  "8888888888",
  "88888888888",
  "888888888888",
  "9999999999",
  "99999999999",
  "999999999999",
];

// tslint:disable:ter-prefer-arrow-callback
// tslint:disable:only-arrow-functions
const telephoneValidationSchema = Yup.string()
  .default(undefined)
  .min(10)
  .max(12)
  .nullable(true)
  .matches(/^\d*$/, "Field must be numbers only")
  .transform((value) => (value || value === 0 ? value : null))
  .test("prevent-crazy-numbers", "Phone number invalid", function (value) {
    return !crazyNumbers.includes(value);
  });
export default telephoneValidationSchema;
