import * as Yup from "yup";
import { ProposalFinance } from "../types";

const financeValidationSchema = Yup.object<ProposalFinance>()
  .shape({
    id: Yup.string().nullable(true).default(undefined),
    rate: Yup.number()
      .label("Flat rate")
      .nullable(true)
      .required()
      .default(undefined),
    aprRate: Yup.number()
      .label("APR")
      .nullable(true)
      .required()
      .default(undefined),
    period: Yup.number()
      .label("Period")
      .integer("Period must be a whole number")
      .moreThan(0)
      .nullable(true)
      .required()
      .default(36)
      .typeError("Period is a required field"),
    monthlyPayment: Yup.number()
      .label("Monthly payment")
      .required()
      .moreThan(0)
      .nullable(true)
      .default(undefined),
    balloonPayment: Yup.number()
      .label("Balloon payment")
      .required()
      .default(undefined)
      .nullable(true)
      .default(undefined),
    partExchange: Yup.number()
      .default(0)
      .min(0)
      .label("Part exchange")
      .typeError("Part exchange is a required field")
      .nullable(true)
      .required(),
    partExchangeSettlement: Yup.number()
      .default(0)
      .label("Part exchange settlement")
      .required()
      .nullable(true)
      .min(0)
      .default(0)
      .typeError("Part exchange settlement is a required field"),
    cashPrice: Yup.number()
      .label("Cash price")
      .moreThan(0)
      .required()
      .typeError("Cash price is a required field")
      .nullable(true)
      .default(undefined),
    cashDeposit: Yup.number()
      .default(0)
      .min(0)
      .label("Deposit")
      .typeError("Deposit must is a required field")
      .nullable(true)
      .required(),
    rFL: Yup.number().default(0).label("Road fund license").nullable(true),
    extras: Yup.number().default(0).label("Extras").nullable(true).required(),
    requestDifferentLoanType: Yup.string().default("").nullable(true),
    productType: Yup.string()
      .default("HP")
      .label("Product type")
      .nullable(true)
      .required() as any,
  })
  .test("negative-finance-value", "message", function (value: ProposalFinance) {
    const { cashPrice, cashDeposit, partExchange, partExchangeSettlement } =
      value;
    const t =
      (cashPrice || 0) +
      (partExchangeSettlement || 0) -
      ((cashDeposit || 0) + (partExchange || 0));
    if (t < 0) {
      return this.createError({
        path: "finance.cashPrice",
        message: "Finance value must be greater than 0",
      });
    }
    return true;
  });

export default financeValidationSchema;
